import axios from "axios"
import Cookies from "js-cookie"
import { signout } from "./logout"

export const getNewRefresh_token = async (refresh_token) => {
    const payload = { test: "aa" }
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_AUTH_REFRESH_NEW_TOKEN, payload, {
        headers: {
            Authorization: `Bearer ${refresh_token}`
        }
    })
        .then((res) => {
            if (res.statusText === "OK") {
                sessionStorage.setItem("access_token", res.data.access_token);
                sessionStorage.setItem("refresh_token", res.data.refresh_token);
                Cookies.set("access_token", res.data.access_token, { expires: 1, secure: true, })
                Cookies.set("refresh_token", res.data.refresh_token, { expires: 7, secure: true, })
            }
        })
        .catch((err) => {
            console.error(err);
            signout()
        })
}

export const checkRefresh = async (user) => {
    await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_GET_USERPROFILE, {
        headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`
        }
    })
        .then((res) => {
            sessionStorage.setItem("access_token", user.access_token);
            sessionStorage.setItem("refresh_token", user.refresh_token);
            Cookies.set("access_token", res.data.access_token, { expires: 1, secure: true, })
            Cookies.set("refresh_token", res.data.refresh_token, { expires: 7, secure: true, })
        })
        .catch((err) => {
            getNewRefresh_token(Cookies.get("refresh_token"))
        })
}