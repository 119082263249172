import axios from "axios"


export const sendEmailForgotPassword = async (payload, setCurrentStage, setError) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_AUTH_FORGET_PASSWORD, payload)
        .then((res) => {
            setCurrentStage("dominate")
        })
        .catch((err) => {
            setError('email', {
                message: "Invalid email"
            })
            console.error(err);
        })
}

export const resetPassword = async (payload, Auth, setResetSuccess) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_AUTH_CHANGE_PASSWORD, payload, {
        headers: {
            Authorization: `Bearer ${Auth}`
        }
    })
        .then((res) => {
            setResetSuccess(true)
        })
        .catch((err) => {
            console.error(err);
        })
}