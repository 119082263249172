import React from "react";
import IconOrderItem from "../../../../assets/images/delivery/OrderItem.png";
import { BorderButton, SubmitButton } from "../../../Button";
import IconErrorDelivery from "../../../../assets/images/delivery/LegalDocs.svg";
import dayjs from "dayjs";

const DetailItemOrderDelivery = ({
  data,
  handleOpenDialogCancelOrder,
  handleOpenDialogConfirmOrderForSeller,
  handleOpenDialogConfirmOrderForBuyer,
}) => {
  return (
    <div>
      <div className="py-3 px-5 flex xl:gap-5 md:gap-3 items-center shadow-[0_8px_17px_rgba(0,0,0,0.25)]">
        <div>
          <img
            src={IconOrderItem}
            alt="IconDelivery"
            className="min-w-60 min-h-60"
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-xl font-[700] text-white">
            Order No. {data?.transactionId}
          </p>
          <p className="text-base font-[500] text-GRAY920">
            {data?.txnDatetime
              ? dayjs(data?.txnDatetime).format("MMMM DD, YYYY HH:mm:ss")
              : ""}
          </p>
        </div>
      </div>

      <div className="py-5 px-[6%] flex flex-col gap-5">
        <div className="flex items-center gap-3 py-4 px-5 bg-GRAY910 rounded">
          <img src={IconErrorDelivery} alt="WarningText" className="w-6 h-6" />
          <p className="text-sm font-[400] text-YELLOW100">
            An email confirmation has been sent to you. To protect your benefit,
            <br />
            please record your screen during the delivery process.
          </p>
        </div>

        <div className="flex gap-5 w-full ">
          <div className="w-[7.5rem] h-[7.5rem] min-w-[7.5rem] min-h-[7.5rem] rounded">
            <img
              src={data?.itemPic["media_url1"]}
              alt="pictureItem"
              className="w-full h-full rounded object-cover"
            />
          </div>
          <div className="flex flex-wrap min-w-[20%] grow">
            <div className="flex flex-col xl:justify-around py-3 min-w-[20%] grow">
              <p className="text-xl font-[700] text-white truncate ">
                {data?.itemName}
              </p>
              <div className="flex w-[100%] gap-1">
                <p className="text-xs font-[700] text-white whitespace-nowrap">
                  {data?.itemDesc ? `Option : ` : ""}
                </p>
                <p className="text-xs font-[400] text-white truncate">
                  {data?.itemDesc}
                </p>
              </div>
              <p className="text-xs font-[700] text-RED001 underline">
                {data?.gameName}
              </p>
            </div>
            <div className="flex flex-col xl:items-end text-start justify-center xl:py-3 py-0 grow">
              <p className="text-xl font-[700] text-white">
                {data?.itemCount ? `x${data?.itemCount}` : ""}
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between  py-6 px-5 bg-GRAY910 rounded">
          <p className="text-xl font-[700] text-white">Grand total</p>
          <p className="text-xl font-[700] text-GREEN050">
            {data?.grandTotalAmount
              ? `${Number(data?.grandTotalAmount).toLocaleString()} THB`
              : `THB`}
          </p>
        </div>

        <div className="flex flex-col gap-3 ">
          <p className="text-xl font-[700] text-white">Delivery Process</p>
          <p className="text-base font-[400] text-white">
            Please make a delivery within 24 hours, otherwise order will be
            cancelled.
          </p>
        </div>

        <div className="flex flex-col gap-5">
          {data?.tokenSeller ? (
            data?.sellerConfirmed ? (
              <SubmitButton
                text="Waiting..."
                styleBtn="bg-RED004 hover:bg-RED004 !cursor-default"
              />
            ) : (
              <SubmitButton
                text="Confirm delivery"
                onClick={handleOpenDialogConfirmOrderForSeller}
              />
            )
          ) : data?.sellerConfirmed ? (
            <SubmitButton
              text="Confirm delivery"
              onClick={handleOpenDialogConfirmOrderForBuyer}
            />
          ) : (
            <SubmitButton
              text="Waiting..."
              styleBtn="bg-RED004 hover:bg-RED004 !cursor-default"
            />
          )}
          <BorderButton
            text="Cancel order"
            styleBtn="border-none"
            onClick={handleOpenDialogCancelOrder}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailItemOrderDelivery;
