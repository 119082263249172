import axios from "axios"
import Cookies from "js-cookie"

export const signin = async (formValues, setError, keepVerifiedStatus) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_AUTH_SIGNIN, {
        account_name: formValues.email,
        account_password: formValues.password
    })
        .then((res) => {
            keepVerifiedStatus(res?.data?.display_name, res?.data?.email_verification_status)
            console.log(res);
            if (formValues.checkbox === true) {
                Cookies.set('access_token', res.data.access_token, { expires: 1, secure: true, })
                Cookies.set('refresh_token', res.data.refresh_token, { expires: 30, secure: true, })
            } else {
                Cookies.set('access_token', res.data.access_token, { expires: 1, secure: true, })
                // Cookies.set('refresh_token', res.data.refresh_token, { expires: 1, secure: true, })
            }
            if (!res?.data?.email_verification_status) {
                window.location.href = '/register?stage=verify_email'
            }
            else if (!res?.data?.display_name?.length > 0) {
                window.location.href = '/register/create_displayname'
            } else window.location.href = '/'
        })
        .catch((err) => {
            console.error(err);
            if (err?.response?.data?.message === "Access Denied" || err?.response?.data?.message === "Password Denied") {
                setError('email', {
                    message: `Invalid email or password Please try again.`
                })
                setError('password', {
                    message: `Invalid email or password Please try again.`
                })
            }
        })
}

const checkVerified_Email = async (access_token) => {
    const res = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_CHECK_VERIFY_EMAIL, {
        headers: {
            Authorization: `Bearer ${access_token}`
        }
    })
        .then((res) => {

        })
        .catch((err) => {
            console.error(err);
        })
    return res
}