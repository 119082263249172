export default function Icon_authenticator(props) {
    return (
        <div {...props}>
            <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.7379 13.4484C14.4819 6.56769 21.0027 0.419825 27.8432 0.802087C34.6197 0.610956 40.8532 6.66358 40.725 13.4484C39.5421 13.2253 38.3597 13.0026 37.1768 12.7794C36.6336 8.31987 32.7019 4.46525 28.1306 4.27412C23.272 3.95542 18.7007 7.96917 18.2212 12.7474C17.0705 13.0021 15.8881 13.2253 14.7373 13.4484H14.7379Z" fill="#939393"/>
                <path d="M6.23438 18.3869C19.5633 13.7678 34.4276 13.7998 47.7576 18.3869C47.6938 22.4007 48.0132 26.478 47.2144 30.4599C45.1368 40.8445 37.8166 49.9552 28.2271 54.4463C27.5879 54.765 26.7886 54.9561 26.1174 54.5738C15.5368 49.8271 7.70547 39.41 6.39472 27.9422C6.10703 24.7565 6.29882 21.5713 6.23461 18.3856M10.0388 21.125C10.0388 23.1639 9.97497 25.2343 10.1346 27.2732C11.2216 37.3076 17.9022 46.4497 27.0122 50.814C35.3872 46.7367 41.7483 38.6777 43.538 29.5351C44.1134 26.7637 43.9537 23.9288 43.9854 21.1254C32.958 17.9077 21.0341 17.9077 10.0384 21.1249L10.0388 21.125Z" fill="#939393"/>
                <path d="M22.8563 30.8416C22.6007 28.548 24.6145 26.4136 26.8839 26.2864C29.3134 26.2544 31.5827 28.7076 31.0711 31.1283C30.9115 32.4342 29.8566 33.3263 28.9614 34.1544C30.0484 34.8233 31.167 35.5559 31.8062 36.7026C32.4454 37.7219 32.3495 38.9642 32.4137 40.111C28.8018 40.0474 25.1577 40.079 21.5454 40.079C21.1939 37.5623 22.7599 35.1416 25.0297 34.1219C24.007 33.2943 22.92 32.2431 22.8563 30.8416Z" fill="#939393"/>
            </svg>
        </div>
    )
}