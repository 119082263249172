import React, { useState, useEffect } from "react";
import IconClose from "../../../../assets/images/delivery/IconClose.svg";
import Defaultprofile from "../../../../assets/images/MyWallet/bg_defaultprofile.jpg";
import { changePxToRem } from "../../../../utils/changePixeltoRem";
import ChatRoom from "./ChatRoom";
import dayjs from "dayjs";

const DeliveryProgress = ({ data, dataChatHistory }) => {
  const [openDeliveryProgress, SetOpenDeliveryProgress] = useState(true);
  const sellerResponse = data?.sellerAvailable;
  const sellerConfirm = data?.sellerConfirmed;
  const buyerConfirm = data?.buyerConfirmed;

  const isSeller = data?.tokenSeller;

  return (
    <div className="h-full">
      {/* DeliveryProgress & ChatRoom */}
      <div className="mobile:hidden desktop:flex flex-col gap-4 h-full">
        <div className="flex flex-col gap-4 h-full">
          <div className="h-full">
            <div className="flex flex-col rounded h-full">
              <div className="py-6 px-9 flex gap-2 xl:gap-0 flex-wrap justify-between items-center rounded-sm  shadow-[0_8px_17px_rgba(0,0,0,0.25)]">
                <div className="flex gap-5 items-center min-w-[0px] 3xl:w-[73%] w-[60%] grow">
                  <div className="w-9 h-9 min-w-[2.25rem] min-h-[2.25rem]">
                    <img
                      src={data?.sellerPic ? data?.buyerPic : data?.sellerPic}
                      alt="pictureProfile"
                      className="w-full h-full rounded-full object-cover"
                    />
                  </div>
                  <p className="text-xl font-[700] text-white uppercase w-full break-all">
                    {isSeller ? data?.buyerName : data?.sellerName}
                  </p>
                </div>

                <div className="grow">
                  <p className="text-xs font-[400] text-white text-end">
                    {data?.transactionTrackId}
                  </p>
                </div>
              </div>

              <div className="py-5 flex flex-col gap-2 h-full">
                {/* Delivery in progress */}
                {openDeliveryProgress ? (
                  <div className="relative py-4 px-9 bg-GRAY910 flex flex-col gap-3 rounded mx-5">
                    <p className="text-base font-[600] text-white">
                      Delivery in progress
                    </p>

                    <div className="flex flex-col gap-2">
                      {/* Awaiting seller response */}
                      <div className="flex items-center w-full">
                        <p
                          className={`text-base font-[400] ${
                            sellerResponse ? "text-GREEN050" : "text-RED007"
                          } w-[35%]`}
                        >
                          {data?.txnDatetime
                            ? dayjs(data?.txnDatetime).format(
                                "DD MMM YYYY - HH:mm"
                              )
                            : ""}
                        </p>
                        <div className="w-[25%] flex justify-center">
                          <div
                            className={`w-3 h-3 ${
                              sellerResponse ? "bg-GREEN050" : "bg-RED007"
                            } rounded-full`}
                          ></div>
                        </div>
                        <p
                          className={`text-base font-[400] ${
                            sellerResponse ? "text-GREEN050" : "text-RED007"
                          } w-[40%] text-end`}
                        >
                          Awaiting seller response
                        </p>
                      </div>

                      {/* Seller confirm delivery */}
                      <div className="flex items-center w-full">
                        <p
                          className={`text-base font-[400] ${
                            sellerResponse
                              ? sellerConfirm
                                ? "text-GREEN050"
                                : "text-RED007"
                              : "text-GRAY920"
                          } w-[35%] ${
                            sellerConfirm ? "text-start" : "text-center"
                          }`}
                        >
                          {sellerConfirm
                            ? dayjs(sellerConfirm).format("DD MMM YYYY - HH:mm")
                            : "-"}
                        </p>
                        <div className="w-[25%] flex justify-center">
                          <div
                            className={`w-3 h-3  ${
                              sellerResponse
                                ? sellerConfirm
                                  ? "bg-GREEN050"
                                  : "bg-RED007"
                                : "bg-GRAY920"
                            } rounded-full`}
                          ></div>
                        </div>
                        <p
                          className={`text-base font-[400]  ${
                            sellerResponse
                              ? sellerConfirm
                                ? "text-GREEN050"
                                : "text-RED007"
                              : "text-GRAY920"
                          } w-[40%] text-end`}
                        >
                          Seller confirm delivery
                        </p>
                      </div>

                      {/* Buyer confirm delivery */}
                      <div className="flex items-center w-full">
                        <p
                          className={`text-base font-[400] ${
                            sellerConfirm
                              ? buyerConfirm
                                ? "text-GREEN050"
                                : "text-RED007"
                              : "text-GRAY920"
                          } w-[35%] ${
                            buyerConfirm ? "text-start" : "text-center"
                          }`}
                        >
                          {buyerConfirm
                            ? dayjs(buyerConfirm).format("DD MMM YYYY - HH:mm")
                            : "-"}
                        </p>
                        <div className="w-[25%] flex justify-center">
                          <div
                            className={`w-3 h-3  ${
                              sellerConfirm
                                ? buyerConfirm
                                  ? "bg-GREEN050"
                                  : "bg-RED007"
                                : "bg-GRAY920"
                            } rounded-full`}
                          ></div>
                        </div>
                        <p
                          className={`text-base font-[400]  ${
                            sellerConfirm
                              ? buyerConfirm
                                ? "text-GREEN050"
                                : "text-RED007"
                              : "text-GRAY920"
                          } w-[40%] text-end`}
                        >
                          Buyer confirm delivery
                        </p>
                      </div>
                    </div>

                    <div
                      className="absolute top-3 right-3 cursor-pointer p-1"
                      onClick={() =>
                        SetOpenDeliveryProgress(!openDeliveryProgress)
                      }
                    >
                      <img
                        src={IconClose}
                        alt="IconCloseWindowProgress"
                        className="w-[0.8rem] h-[0.8rem]"
                        onClick={() =>
                          SetOpenDeliveryProgress(!openDeliveryProgress)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center">
                    <p
                      className="text-base font-[600] text-GRAY636 underline cursor-pointer"
                      onClick={() =>
                        SetOpenDeliveryProgress(!openDeliveryProgress)
                      }
                    >
                      Delivery in progress
                    </p>
                  </div>
                )}
                <div className="flex flex-col items-center gap-2 h-full">
                  <p className="text-base font-[400] text-GRAY920">
                    {data?.txnDatetime
                      ? dayjs(data?.txnDatetime).format(
                          "MMMM DD, YYYY HH:mm:ss"
                        )
                      : ""}
                  </p>
                  <ChatRoom
                    openDeliveryProgress={openDeliveryProgress}
                    dataChatHistory={dataChatHistory}
                    data={data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryProgress;
