import { useState } from "react";
import { searchGamesIndex } from "../../api/get/searchGames";
import Dropdown from "../Dropdown";
import { useNavigate } from "react-router-dom";
import { search } from "../../assets/home/icons";
import { SearchResultsList } from "./SearchResultList";

export const SearchBar = ({
  setResults,
  results,
  setIsOpen,
  input,
  setInput,
}) => {
  const navigate = useNavigate();
  const [category, setCategory] = useState("All Categories");
  const [focusInput, setFocusInput] = useState(false);

  //   const [input, setInput] = useState("");
  const handleChange = (value) => {
    setInput(value);
    searchGamesIndex(value, setResults);
  };

  const handleSubmitSearchDesktop = () => {
    if (!!input) {
      //   setInput("");
      //   setResults([]);
      navigate({
        pathname: `/marketplace`,
        search: `?name=${input}`,
      });
    } else {
      alert(`The make selected was ${input}`);
    }
  };

  const handleSubmitSearchMobile = () => {
    if (!!input) {
      setInput("");
      setResults([]);
      setIsOpen(false);
      navigate({
        pathname: `/marketplace`,
        search: `?name=${input}`,
      });
    } else {
      alert(`The make selected was ${input}`);
    }
  };

  return (
    <>
      <div
        className={`desktop:hidden desktop:relative desktop:[@media(min-width:985px)]:flex w-full mobile:w-full desktop:h-12 mobile:h-[42px] bg-[#414141] rounded mobile:flex ${
          focusInput ? "border border-GRAY636" : "border border-transparent"
        }`}
        onFocus={() => setFocusInput(true)}
        onBlur={() => setFocusInput(false)}
      >
        <input
          className="grow py-2 px-8 mobile:pr-2 desktop:p-3 desktop:pr-0 text-base text-white min-w-0 font-IBMPlex rounded-l-sm bg-[#414141] focus:border-transparent  focus:outline-none focus:ring-0"
          placeholder="Search games, items, display names"
          value={input}
          onChange={(e) => handleChange(e.target.value)}
        />

        <div className="desktop:flex h-full mobile:hidden ">
          <Dropdown
            category={category}
            setCategory={setCategory}
            className="bg-[#282828] "
          />
        </div>

        {/* icon search desktop */}
        <div className="desktop:block mobile:hidden">
          <button
            onClick={handleSubmitSearchDesktop}
            //   className="w-12 h-[110%] bg-RED001 flex justify-center items-center rounded-[0.25rem] hover:bg-RED001/[0.8] cursor-pointer desktop:absolute desktop:-right-[2px] desktop:-top-[2px]"
            className="w-12 min-w-[48px] h-full bg-RED001 flex justify-center items-center rounded-[0.25rem] hover:bg-RED001/[0.8] cursor-pointer"
          >
            <img src={search} alt="" width="24" height="24" />
          </button>
        </div>

        {/* icon search Mibole */}
        <div className="desktop:hidden mobile:block">
          <button
            onClick={handleSubmitSearchMobile}
            //   className="w-12 h-[110%] bg-RED001 flex justify-center items-center rounded-[0.25rem] hover:bg-RED001/[0.8] cursor-pointer desktop:absolute desktop:-right-[2px] desktop:-top-[2px]"
            className="w-12 min-w-[48px] h-full bg-RED001 flex justify-center items-center rounded-[0.25rem] hover:bg-RED001/[0.8] cursor-pointer"
          >
            <img src={search} alt="" width="24" height="24" />
          </button>
        </div>

        <div
          className={`absolute w-full desktop:top-[3.915rem] mobile:top-[3.25rem] ${
            input === "" ? "hidden" : "block"
          }`}
        >
          {results?.length > 0 ? (
            <SearchResultsList
              results={results}
              setInput={setInput}
              setIsOpen={setIsOpen}
              setResults={setResults}
            />
          ) : (
            <div
              className={`w-full overflow-x-hidden p-4 text-[16px] text-white rounded-md bg-[#282828] z-50 `}
            >
              <div className="text-white cursor-default text-center my-20 text-[20px] font-IBMPlex">
                No matching results
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
