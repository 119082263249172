import React, { useState } from "react";
import {
  Grid,
  TextField,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import {
  RED001,
  RED002,
  RED004,
  GRAY700,
  GRAY750,
  GRAY760,
  GRAY910,
  GRAY920,
  RED003,
  GRAY100,
} from "../../../../../theme/color";
import { changePxToRem } from "../../../../../utils/changePixeltoRem";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { SubmitButton } from "../../../../Button";

const WithdrawConfirmation3 = ({
  onChangeInputCodeGoogleAuth,
  endCode,
  onClickConfirmWithdrawStep3,
}) => {
  const [openEnterCode, setOpenEnterCode] = useState(false);
  return (
    <div className="flex flex-col gap-24">
      <div>
        <p className="text-base font-[400] text-white">
          Please verify your Google Authentication Code (2FA 6 numbers)
        </p>
      </div>

      <div className="flex flex-col gap-4">
        <Grid sx={{ position: "relative" }}>
          <DialogContent
            sx={{
              bgcolor: GRAY700,
              padding: "0",
            }}
          >
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                display: "flex",
                gap: changePxToRem(12),
                // justifyContent: "center",
              }}
            >
              <TextField
                //   name={}
                inputProps={{
                  autocomplete: "off",
                  maxlength: "6",
                }}
                type={openEnterCode ? "text" : "password"}
                placeholder="Enter Code"
                value={endCode}
                sx={{
                  width: "100%",
                  input: {
                    position: "relative",
                    color: "white",
                    backgroundColor: GRAY910,
                    padding: `${changePxToRem(11)} ${changePxToRem(10)}`,
                    borderRadius: "4px",
                    "&::placeholder": {
                      // <----- Add this.
                      opacity: 0.7,
                      color: GRAY920,
                    },
                  },
                }}
                color="secondary"
                onChange={onChangeInputCodeGoogleAuth}
              ></TextField>
            </DialogContentText>
          </DialogContent>
          {openEnterCode ? (
            <VisibilityIcon
              sx={{
                position: "absolute",
                top: changePxToRem(10),
                right: changePxToRem(20),
                color: GRAY920,
              }}
              onClick={() => setOpenEnterCode(!openEnterCode)}
            />
          ) : (
            <VisibilityOffIcon
              sx={{
                position: "absolute",
                top: changePxToRem(10),
                right: changePxToRem(20),
                color: GRAY920,
              }}
              onClick={() => setOpenEnterCode(!openEnterCode)}
            />
          )}
        </Grid>

        <Grid>
          <div className="w-[100%]">
            <SubmitButton
              type="submit"
              text="Confirm"
              styleBtn={
                endCode
                  ? "bg-RED001"
                  : "bg-RED004 hover:bg-RED004 !cursor-default"
              }
              onClick={onClickConfirmWithdrawStep3}
            />
          </div>
        </Grid>

        <div className="flex flex-wrap gap-1">
          <p className="text-base font-[400] text-white">Do you need help ?</p>
          <p className="text-base font-[400] text-white underline cursor-pointer">
            Click for Google Authenticator
          </p>
        </div>
      </div>
    </div>
  );
};

export default WithdrawConfirmation3;
