import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import Header from "../component/layout/Header"
import { bg_main, bg_shadow_1, bg_shadow_2 } from "../assets/register";
import { Link, useLocation } from "react-router-dom";
import { sendEmailForgotPassword, resetPassword } from "../api/post/forgotpassword";
import { icon_hidePassword, icon_showPassword, retohub_icon } from "../assets/login";
import { icon_correct2 } from "../assets/register";
import FooterLRF from "../component/layout/FooterLRF";

const Forgotpassword = () => {
    const pathName = useLocation().pathname.split('/')
    const [email, setEmail] = useState("")
    const [currentStage, setCurrentStage] = useState("reg")
    const [showError, setShowError] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setConfirmShowPassword] = useState(false)
    const [resetSuccess, setResetSuccess] = useState(false)
    const { register, handleSubmit, setError, formState: { errors }, getValues } = useForm({});

    const sendEmail = (formValues) => {
        const payload = {
            "email": formValues.email
        }
        sendEmailForgotPassword(payload, setCurrentStage, setError)
    }

    const handleKeyDownSendEmail = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(sendEmail)()
        }
    }

    return (
        <div className="desktop:w-full desktop:h-full  desktop:relative desktop:min-h-[650px] "  >
            <div
                className="desktop:w-full desktop:h-screen desktop:absolute bg-black desktop:min-h-[650px]"
                style={{
                    backgroundImage: `url(${bg_main})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }}></div>
            <div
                className="desktop:w-full desktop:h-screen desktop:absolute desktop:min-h-[650px]"
                style={{
                    backgroundImage: `url(${bg_shadow_1})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }}></div>
            <div
                className="desktop:w-full desktop:h-screen desktop:absolute desktop:min-h-[650px]"
                style={{
                    backgroundImage: `url(${bg_shadow_2})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }}></div>
            <div className="flex flex-col w-full desktop:h-screen  desktop:max-h-screen desktop:min-h-[650px]  pb-10 mobile:h-screen">
                <div className="flex w-full desktop:max-h-full desktop:min-h-[550px] desktop:h-full mobile:h-full  z-10 justify-between">
                    <div className="desktop:flex mobile:hidden desktop:max-w-[50%] desktop:w-[50%] desktop:min-w-0 justify-center items-center">
                        <div className="desktop:flex mobile:hidden max-h-[20rem] max-w-[20rem] z-10">
                            {/* absolute left-[21.5vw] -translate-y-[50%] top-[50%] */}
                            <img src={retohub_icon} alt="retohub_icon" className="w-full h-full object-contain" />
                        </div>
                    </div>

                    <div className="desktop:max-w-[35.27vw] mobile:w-full mobile:h-screen desktop:w-[35.27vw] desktop:min-w-0 desktop:min-h-[650px] desktop:h-full mobile:min-w-0 mobile:min-h-[650px]  z-10 flex desktop:justify-start desktop:items-center mobile:items-start">
                        <div className="flex flex-col  mobile:bg-[#1E1E1E] desktop:bg-transparent mobile:w-full  mobile:h-full desktop:h-screen desktop:max-w-[400px]  desktop:w-[400px] desktop:min-w-0 desktop:justify-center mobile:justify-start">
                            <div className="mobile:flex desktop:hidden mobile:w-full">
                                <Header />
                            </div>
                            {currentStage === "reg" && pathName?.length === 2 && (
                                <div className=" z-10  flex flex-col items-start mobile:justify-start desktop:justify-center mobile:px-3 mobile:bg-[#1E1E1E] desktop:bg-transparent gap-4 mobile:w-full desktop:w-full desktop:minw-w-0 pt-[16px] desktop:h-[550px]">
                                    <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold">FORGOT PASSWORD?</p>
                                    <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-normal">The reset link already sent to your email address.</p>
                                    {/* email */}
                                    <div className="w-full h-[78px] flex flex-col items-start justify-between relative">
                                        <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">Email address</p>
                                        <input
                                            {...register("email", {
                                                required: `Please enter your email`,
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    // 	message: `This will be displayed on your profile and can be seen by other users. Special characters such as _or- are allowed to be used or number 0-9.
                                                    // -*/\|&^%$#`,
                                                    message: `Invalid email Please try again`,
                                                }
                                            })}
                                            type="email"
                                            className={`w-full h-[48px]  focus:outline-none px-[12px] py-[11px] rounded-[4px] bg-[#282828] text-white text-[16px] ${errors.email ? "border border-[#AE3C33] " : "border-none"}`}
                                            placeholder="Enter email address"
                                            onClick={() => setShowError("email")}
                                            onKeyDown={handleKeyDownSendEmail}
                                        />

                                        {errors.email && showError === "email" && (
                                            <div className="mobile:hidden desktop:flex absolute desktop:p-2 top-[calc(50%+14px)] -translate-y-[50%] right-[calc(100%+10px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
                                                <div className="flex justify-center items-center relative w-full h-full ">
                                                    <p className=" text-white font-IBMPlex">{errors.email.message}</p>
                                                    <div
                                                        className="w-0 h-0 absolute left-[100%] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"
                                                    ></div>
                                                </div>
                                            </div>
                                        )}
                                        {errors.email && <p className="mobile:flex desktop:hidden text-xs italic text-red-500">{errors.email.message}</p>}
                                    </div>

                                    <button
                                        className="w-full h-[52px] bg-[#BB213D] hover:bg-RED001/[0.8] flex justify-center items-center rounded-[4px] font-IBMPlex text-[#FFFFFF] text-[16px] font-bold"
                                        onClick={handleSubmit((formValues) => {
                                            const payload = {
                                                "email": formValues.email
                                            }
                                            sendEmailForgotPassword(payload, setCurrentStage, setError)
                                        })}

                                    >
                                        SEND
                                    </button>
                                    <Link
                                        to="/login"
                                        className="w-full h-[52px] border border-[#BB213D] hover:bg-GRAY666/[0.2] flex justify-center items-center rounded-[4px] font-IBMPlex text-[#FFFFFF] text-[16px] font-bold"
                                    >
                                        BACK TO LOGIN
                                    </Link>
                                </div>
                            )}

                            {currentStage === "dominate" && pathName?.length === 2 && (
                                <div className="  flex flex-col items-start mobile:px-3 mobile:bg-[#1E1E1E] space-y-[16px] mobile:w-full desktop:w-[400px] desktop:bg-transparent desktop:px-0 mt-[16px]">
                                    <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold">RESET PASSWORD</p>
                                    <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-normal">Password reset link has expired in 5 mins. Back to <Link to="/login" className="text-[#FFD262]">Login</Link></p>
                                </div>
                            )}

                            {pathName?.length === 3 && pathName[2] !== null && pathName[2] !== "" && !resetSuccess && (
                                <div className="  flex flex-col items-start mobile:px-3 mobile:bg-[#1E1E1E] desktop:bg-transparent space-y-[16px] mobile:w-full desktop:w-[400px] mt-[16px]">
                                    <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold">RESET PASSWORD</p>
                                    <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-normal">It must contain minimum 8 characters in length, at least one uppercase letter (A-Z), lowercase letter (a-z), number (0-9) and special letter {"(.!@#$+-,<>?/[]\*)"}</p>

                                    {/* password */}
                                    <div className="w-full h-[78px] flex flex-col items-start justify-between relative" >
                                        <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">Password</p>
                                        <input
                                            {...register("password", {
                                                required: 'Please enter your password'
                                            })}
                                            type={showPassword ? "text" : "password"}
                                            className={`w-full h-[48px]  focus:outline-none px-[12px] py-[11px] rounded-[4px] bg-[#282828] text-white text-[16px] ${errors.password ? "border border-[#AE3C33] " : "border-none"}`}
                                            placeholder="Enter password"
                                            onClick={() => showError === "password" ? setShowError("") : setShowError("password")}
                                        />
                                        <div className="absolute  right-[12px] flex  bottom-[12px]">
                                            {showPassword
                                                ? <div className="w-6 h-6 cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                                                    <img src={icon_showPassword} alt="icon_showPassword" className="w-full" />
                                                </div>
                                                : <div className="w-6 h-6 cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                                                    <img src={icon_hidePassword} alt="icon_showPassword" className="w-full" />
                                                </div>
                                            }
                                        </div>
                                        {errors.password && showError === "password" && (
                                            <div className="mobile:hidden desktop:flex absolute h-12 top-[calc(50%-9px)] right-[calc(100%+12px)] bg-[#AE3C33] w-[400px]  rounded-[4px]">
                                                <div className="flex justify-center items-center relative w-full h-full ">
                                                    <p className=" text-white font-IBMPlex">{errors.password.message}</p>
                                                    <div
                                                        className="w-0 h-0 absolute left-[calc(100%-6px)] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"
                                                    ></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {errors.password && (
                                        <p className="mobile:flex desktop:hidden text-xs italic text-red-500">{errors.password.message}</p>
                                    )}
                                    {/* confirm password */}
                                    <div className="w-full h-[78px] flex flex-col items-start justify-between relative">
                                        <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">Confirm password</p>
                                        <input
                                            {...register("confirmpassword", {
                                                required: {
                                                    value: true,
                                                    message: "Please enter your password",
                                                },
                                                validate: (value) => {
                                                    if (value === getValues("password")) {
                                                        return true;
                                                    }
                                                    if (value === getValues("new_password")) {
                                                        return true;
                                                    }

                                                    return "Please make sure your password match";
                                                },
                                            })}
                                            type={showConfirmPassword ? "text" : "password"}
                                            className={`w-full h-[48px]  focus:outline-none px-[12px] py-[11px] rounded-[4px] bg-[#282828] text-white text-[16px] ${errors.confirmpassword ? "border border-[#AE3C33] " : "border-none"}`}
                                            placeholder="Re-confirm password"
                                            onClick={() => showError === "confirmpassword" ? setShowError("") : setShowError("confirmpassword")}
                                        />
                                        <div className="absolute  right-[12px] flex  bottom-[12px]">
                                            {showConfirmPassword
                                                ? <div className="w-6 h-6 cursor-pointer" onClick={() => setConfirmShowPassword(!showConfirmPassword)}>
                                                    <img src={icon_showPassword} alt="icon_showPassword" className="w-full" />
                                                </div>
                                                : <div className="w-6 h-6 cursor-pointer" onClick={() => setConfirmShowPassword(!showConfirmPassword)}>
                                                    <img src={icon_hidePassword} alt="icon_showPassword" className="w-full" />
                                                </div>
                                            }
                                        </div>
                                        {errors.confirmpassword && showError === "confirmpassword" && (
                                            <div className="mobile:hidden desktop:flex absolute h-12 top-[calc(50%-9px)] right-[calc(100%+12px)] bg-[#AE3C33] w-[400px]  rounded-[4px]">
                                                <div className="flex justify-center items-center relative w-full h-full ">
                                                    <p className=" text-white font-IBMPlex">{errors.confirmpassword.message}</p>
                                                    <div
                                                        className="w-0 h-0 absolute left-[calc(100%-6px)] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"
                                                    ></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {errors.confirmpassword && (
                                        <p className="mobile:flex desktop:hidden text-xs italic text-red-500">{errors.confirmpassword.message}</p>
                                    )}
                                    <button className="w-full h-[52px] bg-[#BB213D] hover:bg-RED001/[0.8] flex justify-center items-center rounded-[4px] font-IBMPlex text-[#FFFFFF] text-[16px] font-bold" onClick={handleSubmit((formValues) => {
                                        const payload = {
                                            account_password: formValues.password
                                        }
                                        resetPassword(payload, pathName[2], setResetSuccess)
                                    })}>
                                        RESET PASSWORD
                                    </button>
                                </div>
                            )}


                            {pathName?.length === 3 && pathName[2] !== null && pathName[2] !== "" && resetSuccess && (
                                <div className="  flex flex-col items-start desktop:justify-center mobile:justify-start h-full mobile:px-3 mobile:bg-[#1E1E1E] desktop:bg-transparent space-y-[16px] mobile:w-full desktop:w-[400px] pt-[16px] ">
                                    <div className="flex items-center gap-3">
                                        <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold">CHANGE COMPLETED</p>
                                        <img src={icon_correct2} alt="icon_correct2" className="w-9 h-9" />
                                    </div>
                                    <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-normal">You can now login using your new password. <Link className="text-[#FFD262]" to="/login">Login</Link> here.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <FooterLRF />
            </div>
        </div >

    )
}

export default Forgotpassword