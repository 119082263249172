import React from 'react'

const legal = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.43978 22.0602C3.721 22.3414 4.1023 22.4996 4.5 22.5H12L15 21.0802L18 22.5V16.845C18.4715 16.4245 18.8489 15.9092 19.1074 15.3328C19.3659 14.7563 19.4997 14.1318 19.5 13.5C19.5015 12.749 19.3151 12.0095 18.9576 11.3491C18.6001 10.6886 18.0831 10.128 17.4535 9.71858C16.8239 9.30911 16.1019 9.06373 15.3532 9.00479C14.6045 8.94585 13.8529 9.07522 13.167 9.38113C12.4812 9.68704 11.8827 10.1597 11.4263 10.7562C10.9699 11.3526 10.67 12.0537 10.554 12.7957C10.438 13.5377 10.5095 14.297 10.7621 15.0042C11.0147 15.7115 11.4402 16.3443 12 16.845L12 21H4.5V12H9V10.5H4.5V7.5H11.25V6H4.5V3H16.5V6H18V3C17.9996 2.6023 17.8414 2.221 17.5602 1.93978C17.279 1.65856 16.8977 1.5004 16.5 1.5H4.5C4.1023 1.5004 3.721 1.65856 3.43978 1.93978C3.15856 2.221 3.0004 2.6023 3 3V21C3.0004 21.3977 3.15856 21.779 3.43978 22.0602ZM15 19.4198L16.5 20.13V17.7375C15.5306 18.0876 14.4694 18.0876 13.5 17.7375V20.13L15 19.4198ZM13.3333 15.9944C13.8266 16.3241 14.4067 16.5 15 16.5C15.7953 16.499 16.5578 16.1826 17.1202 15.6202C17.6826 15.0578 17.999 14.2953 18 13.5C18 12.9067 17.8241 12.3266 17.4944 11.8333C17.1648 11.3399 16.6962 10.9554 16.1481 10.7284C15.5999 10.5013 14.9967 10.4419 14.4147 10.5576C13.8328 10.6734 13.2982 10.9591 12.8787 11.3787C12.4591 11.7982 12.1734 12.3328 12.0576 12.9147C11.9419 13.4967 12.0013 14.0999 12.2284 14.6481C12.4554 15.1962 12.8399 15.6648 13.3333 15.9944Z" fill="white" />
        </svg>

    )
}

export default legal