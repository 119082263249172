
import { useState, useEffect, useRef } from "react";
import {
    Dialog,
    DialogBody,
    ThemeProvider,
} from "@material-tailwind/react";
import CloseButton from "./closeButton";
import Webcam from "react-webcam";
import { uploadPicture } from "../../../api/post/setting/kyc";
import { iconDragonCardID, icon_camera } from "../../../assets/setting/KYC";

const Step_2 = ({ theme, open, windowSize, handleOpen, setOpenNotifyStep2, setErrorMessage, setSuccess3Step, setPendingPicture }) => {
    const [picture, setPicture] = useState([])
    const [hasWebcam, setHaswebcam] = useState(null)
    const [frontSuccess, setFrontSuccess] = useState(null)
    const [letfSuccess, setLetfSuccess] = useState(null)
    const [rightSuccess, setRightSuccess] = useState(null)

    const handleTakePhoto = (data_url) => {
        let setThreePic = [...picture, data_url].slice(0, 3)
        setPicture(setThreePic)
    }
    const deletePicture = (index) => {
        let newArr = [...picture]
        newArr.splice(index, picture?.length - index)
        setPicture(newArr)
    }
    const videoConstraints = {
        width: 400,
        height: 267,
        facingMode: "user"
    };

    const handleStep2 = () => {
        setSuccess3Step(true)
        handleOpen()
    }
    const validationFaceRecongition = () => {
        let status = [frontSuccess, letfSuccess, rightSuccess]
        uploadPicture(picture, handleStep2, status, setFrontSuccess, setLetfSuccess, setRightSuccess, setOpenNotifyStep2, setErrorMessage, setPendingPicture)
    }


    useEffect(() => {
        if (open === "face") {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({ video: true })
                    .then(function (stream) {
                        setHaswebcam(true)
                        stream.getTracks().forEach(track => track.stop());
                    })
                    .catch(function (error) {

                        setHaswebcam(false)
                    });
            } else {
                console.log('getUserMedia is not supported on this browser');
            }
        }
    }, [open])

    return (
        <ThemeProvider
            value={theme}
        >
            <Dialog
                open={open === "face"}
                size={windowSize[0] > 768 ? "sm" : "xs"}
                className="bg-[#1A1A1A] relative rounded overflow-hidden button-0"
            >
                <DialogBody
                    className="desktop:relative  flex flex-col desktop:items-center desktop:h-[772px] mobile:h-[90vh] overflow-auto"
                >
                    {/*Close Button  */}
                    <CloseButton handleOpen={handleOpen} />
                    <div className="w-full h-full desktop:p-10 flex flex-col desktop:justify-between desktop:items-center gap-5">
                        <div className="flex justify-center flex-col items-center">
                            <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold">
                                {
                                    picture?.length === 0 && "STEP 1: FRONT VIEW"
                                }
                                {
                                    picture?.length === 1 && "Step 2: Left view"
                                }
                                {
                                    picture?.length >= 2 && "Step 3: Right view"
                                }
                            </p>
                            <p className="font-IBMPlex text-[#FFFFFF] text-[16px] font-normal">Please place your face in the given frame</p>
                        </div>
                        {!!hasWebcam && (
                            <div className="desktop:w-[580px]  mobile:w-full flex flex-col gap-5">
                                <div className=" relative flex justify-center items-center">
                                    <div className=" relative">
                                        <Webcam
                                            audio={false}
                                            height={400}
                                            screenshotFormat="image/jpeg"
                                            width={620}
                                            videoConstraints={videoConstraints}

                                        >
                                            {({ getScreenshot }) => (
                                                <button
                                                    onClick={() => {
                                                        const imageSrc = getScreenshot()
                                                        handleTakePhoto(imageSrc)
                                                    }}
                                                    className=" absolute -bottom-2 left-[calc(50%-30px)] w-[60px] h-[60px] border-4 border-white rounded-[50%] flex justify-center items-center z-20"
                                                >
                                                    <div className="border-4 border-white rounded-[50%] w-[45px] h-[45px] bg-white">
                                                    </div>
                                                </button>
                                            )}
                                        </Webcam>
                                        <div className="absolute  top-0 left-0 desktop:w-[580px] desktop:h-[390px] mobile:w-full mobile:h-full flex justify-center items-center">
                                            <div className="w-full h-full flex justify-center items-center relative">
                                                <div className="w-[280px] h-[280px] rounded-full border-dashed border-[6px] border-[#1EFD78]">
                                                </div>
                                                <div className="absolute top-0 left-0 w-[30px] h-[30px] border-t-[7px] border-l-[7px] border-[#1EFD78]" />
                                                <div className="absolute top-0 right-0 w-[30px] h-[30px] border-t-[7px] border-r-[7px]  border-[#1EFD78]" />
                                                <div className="absolute bottom-0 left-0 w-[30px] h-[30px] border-b-[7px] border-l-[7px]  border-[#1EFD78]" />
                                                <div className="absolute bottom-0 right-0 w-[30px] h-[30px] border-b-[7px] border-r-[7px] border-[#1EFD78]" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="desktop:w-full mobile:w-full flex justify-between gap-4">
                                    <div className="relative group/img" >
                                        {picture[0]
                                            ? (
                                                <div>
                                                    <img
                                                        src={picture[0]}
                                                        alt={`pic1`}
                                                        className="h-[7.5rem] object-contain"
                                                    />
                                                    <div
                                                        className="absolute top-0 right-0 text-black bg-gray-500/70 text-2xl font-bold  justify-center item-center px-2 desktop:hidden group-hover/img:desktop:flex mobile:flex cursor-default font-kanit"
                                                        onClick={() => deletePicture(0)}
                                                    >
                                                        x
                                                    </div>
                                                </div>
                                            )
                                            : (
                                                <>
                                                    <img src={iconDragonCardID} alt="dragon" className=" object-cover w-full h-[7.5rem] opacity-30 bg-gray-50/20" />
                                                    <div className=" absolute flex flex-col top-0 w-full h-full items-center justify-center gap-[22px]">
                                                        <img src={icon_camera} alt="upload" className="" />
                                                        <p className="text-white font-IBMPlex font-bold text-[16px]">Front view</p>
                                                    </div>
                                                </>
                                            )
                                        }


                                    </div>
                                    <div className="relative group/img" >
                                        {picture[1]
                                            ? (
                                                <div>
                                                    <img
                                                        src={picture[1]}
                                                        alt={`pic1`}
                                                        className="h-[7.5rem] object-contain"
                                                    />
                                                    <div
                                                        className="absolute top-0 right-0 text-black bg-gray-500/70 text-2xl font-bold  justify-center item-center px-2 desktop:hidden group-hover/img:desktop:flex  mobile:flex cursor-default font-kanit"
                                                        onClick={() => deletePicture(1)}
                                                    >
                                                        x
                                                    </div>
                                                </div>
                                            )
                                            : (
                                                <>
                                                    <img src={iconDragonCardID} alt="dragon" className=" object-cover w-full h-[7.5rem] opacity-30 bg-gray-50/20" />
                                                    <div className=" absolute flex flex-col top-0 w-full h-full items-center justify-center gap-[22px]">
                                                        <img src={icon_camera} alt="upload" className="" />
                                                        <p className="text-white font-IBMPlex font-bold text-[16px]">Left view</p>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className="relative group/img" >
                                        {picture[2]
                                            ? (
                                                <div>
                                                    <img
                                                        src={picture[2]}
                                                        alt={`pic1`}
                                                        className="h-[7.5rem] object-contain"
                                                    />
                                                    <div
                                                        className="absolute top-0 right-0 text-black bg-gray-500/70 text-2xl font-bold  justify-center item-center px-2 desktop:hidden group-hover/img:desktop:flex mobile:flex cursor-default font-kanit"
                                                        onClick={() => deletePicture(2)}
                                                    >
                                                        x
                                                    </div>
                                                </div>
                                            )
                                            : (
                                                <>
                                                    <img src={iconDragonCardID} alt="dragon" className=" object-cover w-full h-[7.5rem] opacity-30 bg-gray-50/20" />
                                                    <div className=" absolute flex flex-col top-0 w-full h-full items-center justify-center gap-[22px]">
                                                        <img src={icon_camera} alt="upload" className="" />
                                                        <p className="text-white font-IBMPlex font-bold text-[16px]">Right view</p>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                        <div>
                            <div className="flex desktop:mt-[4px] mobile:mt-4 desktop:w-[420px] desktop:justify-between ">
                                <button
                                    className="desktop:w-[200px] desktop:flex  desktop:justify-center desktop:items-center desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold desktop:border-[3px] desktop:border-[#BB213D]  desktop:bg-[#1A1A1A] desktop:text-white active:bg-[#242424] hover:bg-GRAY666/[0.2] mobile:hidden"
                                    onClick={handleOpen}
                                >
                                    Cancel
                                </button>
                                <button
                                    disabled={picture?.length < 3}
                                    className={`desktop:w-[200px] desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold  ${picture?.length === 3 ? " hover:bg-RED001/[0.8] bg-[#BB213D] opacity-100" : "bg-[#BB213D]/90 opacity-60"} text-white active:bg-[#BB213D]/80 mobile:h-[52px]  mobile:w-full `}
                                    onClick={() => {
                                        validationFaceRecongition()
                                        setPendingPicture(true)
                                    }}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </DialogBody>
            </Dialog>
        </ThemeProvider>
    )
}

export default Step_2



