export const PRIMARY_COLOR = "#1FA37C";
export const PRIMARY_COLOR25 = "#1D9B89";
export const PRIMARY_OPACITY = "rgba(0, 178, 153, 0.25)";
export const SECONDARY_HOVER = "#35DBAA";
export const SECONDARY_DANGER = "#921C1E";
export const WHITE = "#fff";
export const GRAY100 = "#F5F5F5";
export const GRAY111 = "#111111";
export const GRAY200 = "#EDEBE9";
export const GRAY666 = "#666666";
export const GRAY600 = "#87807E";
export const GRAY650 = "#1E1E1E";
export const GRAY700 = "#1A1A1A";
export const GRAY710 = "#151515";
export const GRAY720 = "#191919";
export const GRAY750 = "#414141";
export const GRAY760 = "#575757";
export const GRAY770 = "#090909";
export const GRAY90 = "#E5E5E5";
export const GRAYC4 = "#C4C4C4";
export const GRAY400 = "#BFBBBA";
export const GRAY900 = "#292726";
export const GRAY910 = "#282828";
export const GRAY920 = "#939393";
export const GRAY930 = "#292C34";
export const DIVIDER_FOOTER = "#00664E";
export const GRAY = "#00B299";
export const RED = "#E84D4E";
export const RED001 = "#BB213D";
export const RED002 = "#BA203C";
export const RED003 = "#681B29";
export const RED004 = "#7A1E2F";
export const RED005 = "#D80027";
export const RED_HOVER = "#EC7071";
export const GREEN_PROFILE = "#51ADAD";
export const ORANGE = "#E89F1E";
export const VEKIN_GREEN = "#1FA37C";
export const VEKIN_CORAL = "#D8907D";
export const ERROR050 = "#FBD1CC";
export const ERROR100 = "#DC5D5E";
export const GREEN100 = "#1FA37C";
export const GREEN040 = "#A5DACB";
export const GREEN050 = "#00B67A";
export const GRAY333 = "#333333";
export const YELLOW100 = "#FEE362";
