import { useMutation } from "@tanstack/react-query";
import { securityApi } from "../../services";

export const useMutationClientQRGoogleAuthSecurity = ({
  onSuccess,
  onError,
}) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return securityApi.QRGoogleAuthSecurity(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationClientVerifyGoogleAuthSecurity = ({
  onSuccess,
  onError,
}) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return securityApi.verifyGoogleAuthSecurity(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationClientWalletUserWithdraw = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return securityApi.walletUserWithdraw(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};
