import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDisplay } from "../../../../../slice/goBackBtnSlice";
import { useMutationClientDeliveryTransaction } from "../../../../../api/mutation/delivery";

const DeliveryGuideBookPage = () => {
  const navigate = useNavigate();
  const goBack = useDispatch();
  const [isMobile, setIsMobile] = useState(true);
  const [dataGuidebook, setDataGuidebook] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const trackId = searchParams.get("trackId");

  const locationPathName = useLocation();
  const pathnameCurrent = locationPathName.pathname.split("/")[1];

  //   mutateGetDetailOrderNumber
  const {
    mutate: mutateDeliveryTransaction,
    isSuccess: isSuccessDeliveryTransaction,
  } = useMutationClientDeliveryTransaction({
    onSuccess: (resp) => {
      setDataGuidebook(resp?.data);
    },
    onError: () => {},
  });

  useEffect(() => {
    if (trackId !== undefined) {
      const dataTransaction = {
        transactionTrackId: trackId || "",
      };

      mutateDeliveryTransaction(dataTransaction);
    }
  }, [trackId, mutateDeliveryTransaction]);

  useEffect(() => {
    goBack(setDisplay(false));
  }, []);

  useEffect(() => {
    if (trackId === undefined || trackId === null) {
      navigate("/");
    }
  }, [trackId]);

  useEffect(() => {
    const handleWindowResize = () => {
      const isMobileScreen = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(isMobileScreen);
    };

    // Initial check when the component mounts
    handleWindowResize();

    // When isn't MobileScreen setIsTransactionPurchasePage
    if (isMobile === false) {
      navigate({
        pathname: `/${pathnameCurrent}`,
        search: `${createSearchParams({
          itemName: dataGuidebook?.itemName,
          trackId: trackId,
        })}`,
      });
    }

    // Add event listener to update the state on window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isMobile]);
  return (
    <div className="bg-GRAY111 h-[100vh] desktop:hidden">
      <div className="py-4 px-4 flex flex-col gap-4">
        <div>
          <p className="font-[700] text-2xl text-white ">GUIDE BOOK</p>
        </div>

        <div className="flex flex-col gap-3">
          <div>
            <p className="font-[400] text-xs text-white ">
              We don’t allow discussion outside of the platform for secure. If
              we found, Your ID account will be banned immediately without any
              arguments.
            </p>
          </div>
          <div>
            <p className="font-[400] text-xs text-white ">
              An email confirmation has been sent to you. To protect your
              benefit, Please record your screen during the delivery process.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryGuideBookPage;
