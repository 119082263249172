export default function NoResult_pic(props) {
    return (
        <div {...props}>
            <svg width="408" height="272" viewBox="0 0 408 272" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2257_81508)">
                    <path d="M126.812 19.904C-2.22212 72.4999 41.9915 212.4 119.718 231.864C137.143 236.236 149.727 223.361 167.425 223.745C184.351 224.111 191.327 234.996 204.782 242.995C236.557 261.885 290.221 248.607 311.87 222.351C351.105 174.765 326.683 60.1172 252.31 23.0382C245.091 19.4415 185.085 -3.8488 126.812 19.904Z" fill="#1A1A1A"/>
                    <path d="M102.967 53.9219C-2.95605 135.188 49.3541 251.61 132.129 252.114C159.55 252.279 162.825 236.401 181.354 232.473C199.074 228.715 206.324 239.565 221.922 242.419C277.574 252.599 311.671 227.173 332.224 195.935C369.473 139.326 319.075 28.8689 235.041 18.097C208.715 14.7258 152.794 15.6946 102.967 53.9219Z" stroke="#737373" stroke-width="2.30118" stroke-miterlimit="10" stroke-dasharray="6.9 6.9"/>
                    <path d="M37.7816 165.254C31.2336 121.384 71.0842 81.1203 89.0987 67.6215C136.9 31.8013 232.454 4.02599 294.837 51.4143C328.012 76.6146 366.775 128.274 344.404 178.976C326.184 220.273 285.382 228.868 251.225 224.334C215.636 219.61 210.419 194.937 177.311 193.959C138.293 192.808 142.36 227.749 89.101 221.131C61.9623 217.753 41.9988 193.501 37.7816 165.254Z" fill="black" fill-opacity="0.58"/>
                    <path d="M126.692 223.077C126.949 223.681 127.016 224.348 126.884 224.991C126.752 225.633 126.428 226.221 125.953 226.676C125.478 227.131 124.875 227.432 124.225 227.54C123.574 227.648 122.906 227.557 122.308 227.279C121.949 227.125 121.623 226.902 121.351 226.622C121.08 226.342 120.867 226.01 120.725 225.648C120.468 225.044 120.401 224.377 120.533 223.734C120.665 223.092 120.99 222.504 121.465 222.049C121.939 221.594 122.542 221.292 123.193 221.185C123.843 221.077 124.511 221.168 125.109 221.446C125.469 221.599 125.794 221.823 126.066 222.103C126.338 222.383 126.55 222.714 126.692 223.077Z" fill="#636363"/>
                    <path d="M326.021 34.701C326.628 33.0312 325.758 31.1884 324.079 30.585C322.399 29.9815 320.546 30.8459 319.939 32.5157C319.332 34.1855 320.201 36.0283 321.881 36.6317C323.56 37.2352 325.414 36.3708 326.021 34.701Z" fill="#636363"/>
                    <path d="M3.95797 135.009C2.20318 140.717 0.880172 146.548 0 152.452C2.23822 155.904 11.3601 169.071 27.7753 172.026C31.9976 172.776 36.3175 172.805 40.5495 172.111C41.8388 171.738 43.253 171.301 44.7668 170.781C51.7337 168.386 60.8232 164.257 69.6418 157.011C71.4449 155.529 73.2387 153.918 74.9978 152.162C78.7533 148.417 82.1039 144.29 84.9946 139.851C80.0251 141.153 54.6733 147.258 31.9045 132.351C22.366 126.106 16.5726 118.33 13.2835 112.906C9.44185 119.946 6.31779 127.351 3.95797 135.009Z" fill="#2E2E2E"/>
                    <path d="M49.1644 161.37C48.622 164.856 47.0972 168.119 44.7666 170.779C51.7336 168.384 60.823 164.255 69.6417 157.009C66.1606 157.268 62.6601 157.009 59.256 156.24C55.4874 155.393 51.8583 154.019 48.4769 152.16C49.1435 154.521 49.7152 157.775 49.1644 161.37Z" fill="#222222"/>
                    <path d="M325.845 91.0371C328.863 91.413 331.881 91.7927 334.898 92.1762C338.337 92.6134 341.776 93.0575 345.214 93.5086C342.337 99.6804 334.675 104.621 328.951 107.776C322.748 111.189 318.137 112.024 309.435 113.195C316.346 106.982 321.927 99.4486 325.845 91.044V91.0371Z" fill="#222222"/>
                    <path d="M384.576 59.8516C382.421 67.2028 379.058 74.1493 374.624 80.4103C354.209 109.085 317.93 112.314 310.407 112.825C314.358 111.778 334.731 105.958 345.221 86.039C350.517 75.9806 351.137 66.426 351.04 61.0298L384.576 59.8516Z" fill="#2E2E2E"/>
                    <path d="M327.69 189.805C317.303 197.186 307.889 205.835 299.672 215.55C306.311 221.676 314.197 226.311 322.797 229.14C331.396 231.969 340.506 232.926 349.51 231.946L395.274 218.489C389.682 219.612 372.526 222.318 354.033 213.366C338.965 206.072 330.889 194.853 327.69 189.805Z" fill="#2E2E2E"/>
                    <path d="M350.311 231.672L394.52 218.785C390.324 220.092 385.987 220.895 381.599 221.176C378.178 221.406 371.832 220.177 368.913 219.579C367.466 221.74 366.469 222.403 362.923 225.063C357.553 229.111 354.683 230.648 350.311 231.672Z" fill="#222222"/>
                    <path d="M275.639 1.19201L278.995 6.71256L274.025 9.69719L275.363 11.9017L280.333 8.91709L283.689 14.4399L285.672 13.2479L282.316 7.72508L287.286 4.74044L285.946 2.53821L280.976 5.52284L277.622 0L275.639 1.19201Z" fill="#959595"/>
                    <path d="M361.061 144.01L364.417 149.533L359.447 152.518L360.785 154.722L365.755 151.735L369.111 157.258L371.094 156.068L367.738 150.545L372.708 147.561L371.37 145.356L366.4 148.341L363.044 142.82L361.061 144.01Z" fill="#636363"/>
                    <path d="M392.316 102.282C390.586 102.532 388.969 103.285 387.67 104.448C386.37 105.611 385.447 107.131 385.016 108.816C384.586 110.5 384.667 112.274 385.251 113.912C385.834 115.551 386.893 116.981 388.294 118.021C389.695 119.061 391.374 119.665 393.12 119.756C394.866 119.847 396.6 119.422 398.102 118.533C399.605 117.645 400.809 116.333 401.562 114.764C402.314 113.196 402.582 111.44 402.332 109.72C402.166 108.578 401.775 107.479 401.181 106.487C400.588 105.495 399.804 104.629 398.874 103.938C397.944 103.248 396.886 102.746 395.761 102.462C394.636 102.178 393.465 102.117 392.316 102.282ZM394.4 116.55C393.292 116.709 392.163 116.538 391.153 116.059C390.144 115.58 389.3 114.814 388.728 113.858C388.156 112.902 387.882 111.799 387.941 110.688C388 109.577 388.389 108.509 389.058 107.618C389.728 106.727 390.648 106.053 391.702 105.682C392.757 105.311 393.898 105.259 394.983 105.532C396.067 105.806 397.045 106.393 397.794 107.22C398.542 108.046 399.028 109.075 399.189 110.176C399.295 110.907 399.255 111.652 399.071 112.368C398.888 113.084 398.564 113.757 398.119 114.349C397.674 114.94 397.116 115.439 396.477 115.816C395.838 116.194 395.131 116.442 394.395 116.548L394.4 116.55Z" fill="#959595"/>
                    <path d="M73.8266 243.438C72.0957 243.687 70.4778 244.441 69.1777 245.604C67.8776 246.767 66.9536 248.287 66.5226 249.972C66.0917 251.658 66.1731 253.432 66.7566 255.071C67.3401 256.71 68.3994 258.141 69.8006 259.181C71.2018 260.222 72.882 260.826 74.6285 260.917C76.3751 261.008 78.1096 260.582 79.6126 259.693C81.1157 258.804 82.3197 257.492 83.0726 255.922C83.8254 254.353 84.0931 252.597 83.8419 250.876C83.5058 248.569 82.2618 246.49 80.3836 245.095C78.5053 243.7 76.1467 243.104 73.8266 243.438ZM75.9097 257.706C74.802 257.866 73.6713 257.696 72.6608 257.217C71.6503 256.738 70.8053 255.972 70.2327 255.016C69.6601 254.06 69.3857 252.956 69.4441 251.844C69.5025 250.733 69.8911 249.664 70.5608 248.772C71.2304 247.88 72.1511 247.206 73.2063 246.834C74.2615 246.463 75.4038 246.411 76.4888 246.685C77.5737 246.959 78.5526 247.546 79.3016 248.374C80.0505 249.201 80.5359 250.23 80.6964 251.332C80.9101 252.808 80.5258 254.308 79.6279 255.503C78.73 256.697 77.3919 257.489 75.9074 257.703L75.9097 257.706Z" fill="#959595"/>
                    <path d="M94.5057 100.845C96.2868 99.8324 96.9051 97.5761 95.8867 95.8054C94.8683 94.0346 92.5989 93.4198 90.8178 94.4323C89.0367 95.4448 88.4183 97.7011 89.4367 99.4719C90.4552 101.243 92.7246 101.857 94.5057 100.845Z" fill="#636363"/>
                    <path d="M130.58 269.308C131.115 267.339 129.944 265.312 127.963 264.78C125.982 264.247 123.943 265.413 123.407 267.382C122.872 269.351 124.044 271.379 126.025 271.911C128.005 272.443 130.045 271.278 130.58 269.308Z" fill="#636363"/>
                    <path d="M44.6554 83.0009L38.0495 84.9316L36.3135 79.0566L33.6772 79.8252L35.4155 85.7002L28.8096 87.6309L29.504 89.9758L36.1075 88.0451L37.8435 93.92L40.4798 93.1491L38.7439 87.2742L45.3474 85.3458L44.6554 83.0009Z" fill="#959595"/>
                    <path d="M69.5446 54.6308C69.7552 52.7984 69.4144 50.9451 68.5655 49.3053C67.7166 47.6655 66.3976 46.3128 64.7754 45.4183C63.1532 44.5238 61.3006 44.1276 59.4519 44.2799C57.6033 44.4322 55.8415 45.1261 54.3895 46.2739C52.9375 47.4217 51.8605 48.9718 51.2947 50.7281C50.7288 52.4845 50.6995 54.3682 51.2105 56.1411C51.7216 57.914 52.7499 59.4964 54.1656 60.6883C55.5812 61.8801 57.3205 62.6278 59.1636 62.8368C61.6346 63.1164 64.1161 62.409 66.0627 60.8703C68.0093 59.3315 69.2617 57.0872 69.5446 54.6308ZM54.2682 52.898C54.4027 51.7254 54.8839 50.6187 55.651 49.718C56.4182 48.8173 57.4367 48.1631 58.5778 47.8379C59.719 47.5127 60.9315 47.5313 62.062 47.8913C63.1926 48.2513 64.1903 48.9365 64.9291 49.8603C65.6679 50.7841 66.1146 51.905 66.2126 53.0811C66.3107 54.2573 66.0557 55.436 65.4799 56.4681C64.9041 57.5002 64.0335 58.3393 62.978 58.8794C61.9225 59.4195 60.7296 59.6363 59.5502 59.5024C58.7667 59.4143 58.0083 59.1735 57.3185 58.7939C56.6287 58.4142 56.0209 57.903 55.5299 57.2897C55.0389 56.6763 54.6743 55.9727 54.457 55.2191C54.2398 54.4656 54.174 53.6768 54.2636 52.898H54.2682Z" fill="#636363"/>
                    <path d="M402.984 160.465C402.235 160.254 401.567 159.827 401.062 159.239C400.557 158.65 400.24 157.926 400.149 157.158C400.058 156.39 400.198 155.612 400.552 154.923C400.906 154.234 401.457 153.665 402.136 153.287C402.814 152.909 403.591 152.74 404.366 152.801C405.142 152.862 405.882 153.15 406.493 153.629C407.104 154.108 407.558 154.756 407.799 155.491C408.039 156.227 408.055 157.016 407.845 157.761C407.704 158.256 407.467 158.719 407.147 159.123C406.827 159.527 406.43 159.864 405.978 160.115C405.527 160.366 405.03 160.526 404.516 160.586C404.002 160.646 403.482 160.605 402.984 160.465Z" fill="#959595"/>
                    <path d="M182.518 132.532C184.523 135.848 187.407 137.8 188.978 136.905L202.919 128.897C204.498 127.99 204.15 124.543 202.144 121.197L202.111 121.146C200.107 117.83 197.223 115.879 195.654 116.774L181.71 124.782C180.132 125.689 180.479 129.136 182.486 132.482L182.518 132.532Z" fill="#1A1A1A"/>
                    <path d="M199.586 130.72L195.158 133.368L187.696 121.368L192.494 118.498C192.494 118.498 191.698 121.45 194.307 125.482C197.38 130.229 199.586 130.72 199.586 130.72Z" fill="#111111"/>
                    <path d="M240.986 149.205C269.633 149.205 292.856 126.117 292.856 97.6359C292.856 69.1549 269.633 46.0664 240.986 46.0664C212.338 46.0664 189.115 69.1549 189.115 97.6359C189.115 126.117 212.338 149.205 240.986 149.205Z" fill="#2E2E2E"/>
                    <path d="M270.328 126.809C286.533 110.698 286.533 84.5759 270.328 68.4646C254.122 52.3532 227.848 52.3532 211.643 68.4646C195.438 84.5759 195.438 110.698 211.643 126.809C227.848 142.92 254.122 142.92 270.328 126.809Z" fill="#111111"/>
                    <path d="M240.985 136.831C262.757 136.831 280.407 119.283 280.407 97.6371C280.407 75.991 262.757 58.4434 240.985 58.4434C219.212 58.4434 201.562 75.991 201.562 97.6371C201.562 119.283 219.212 136.831 240.985 136.831Z" fill="#636363"/>
                    <path d="M89.165 180.124C89.047 182.975 90.4913 185.458 91.3199 186.887C92.1486 188.316 93.7827 191.126 96.6435 192.481C99.9765 194.064 103.587 192.985 107.36 191.595C112.452 189.724 119.69 186.62 128.247 181.403L172.65 154.852C188.121 145.601 199.218 135.451 197.308 132.301C195.282 130.854 193.573 129.012 192.288 126.887C191.002 124.762 190.167 122.398 189.832 119.941C187.922 116.784 173.701 121.768 158.228 131.019L113.816 157.563C105.133 162.711 98.9813 167.596 94.9701 171.14C92.0791 173.671 89.3108 176.382 89.165 180.124Z" fill="#2E2E2E"/>
                    <path d="M245.251 131.279C245.721 132.06 246.439 132.664 247.291 132.997C248.143 133.33 249.082 133.373 249.961 133.12C264.413 128.941 271.179 119.543 274.311 112.409C279.275 101.081 278.359 88.2101 274.887 81.3434C274.393 80.3669 273.529 79.6256 272.485 79.2825C271.441 78.9394 270.303 79.0227 269.32 79.514C268.338 80.0053 267.592 80.8644 267.247 81.9023C266.902 82.9402 266.986 84.0718 267.48 85.0483C269.654 89.3446 270.85 99.6493 266.707 109.107C263.182 117.15 256.768 122.567 247.642 125.216C247.033 125.393 246.472 125.706 246.004 126.132C245.536 126.558 245.172 127.085 244.941 127.673C244.71 128.261 244.618 128.893 244.671 129.522C244.724 130.151 244.922 130.759 245.248 131.3L245.251 131.279Z" fill="#555555"/>
                    <path d="M269.827 109.73C270.044 110.09 270.367 110.374 270.753 110.544C271.001 110.656 271.269 110.718 271.541 110.726C271.813 110.735 272.084 110.69 272.339 110.594C272.593 110.499 272.826 110.354 273.024 110.168C273.223 109.983 273.382 109.76 273.494 109.514C275.868 104.31 276.883 98.5943 276.445 92.8967C276.13 88.9884 275.126 85.1655 273.48 81.6025C273.218 81.1601 272.801 80.8298 272.309 80.6757C271.817 80.5216 271.285 80.5546 270.816 80.7683C270.346 80.9821 269.974 81.3613 269.77 81.8325C269.566 82.3038 269.545 82.8336 269.712 83.3192C271.155 86.4407 272.035 89.7899 272.313 93.2143C272.691 98.2233 271.794 103.246 269.705 107.82C269.568 108.124 269.507 108.457 269.529 108.79C269.55 109.123 269.652 109.445 269.827 109.73Z" fill="#6A6A6A"/>
                    <path d="M223.06 89.6054C224.109 85.416 221.543 81.1743 217.329 80.1313C213.115 79.0884 208.849 81.639 207.8 85.8284C206.751 90.0178 209.316 94.2596 213.53 95.3025C217.744 96.3455 222.01 93.7948 223.06 89.6054Z" fill="#555555"/>
                    <path d="M219.843 91.6927C221.573 90.945 222.367 88.9442 221.615 87.2238C220.862 85.5034 218.85 84.7149 217.12 85.4626C215.389 86.2103 214.596 88.2111 215.348 89.9315C216.1 91.6519 218.113 92.4404 219.843 91.6927Z" fill="#6A6A6A"/>
                    <path d="M158.002 131.141L133.046 146.065C134.637 155.557 139.871 164.065 147.646 169.797L172.602 154.869C164.827 149.137 159.593 140.629 158.002 131.137V131.141Z" fill="#1A1A1A"/>
                    <path d="M167.536 140.951C165.244 137.09 163.537 132.914 162.47 128.559C163.11 128.2 163.748 127.851 164.384 127.512C166.593 136.641 171.627 144.85 178.778 150.984C178.181 151.381 177.573 151.778 176.952 152.176C173.228 148.961 170.049 145.17 167.536 140.951Z" fill="#111111"/>
                    <path d="M162.469 128.561C164.714 137.738 169.776 145.993 176.952 152.182C175.563 153.075 174.126 153.965 172.642 154.852L172.602 154.875C164.827 149.143 159.593 140.635 158.002 131.142L158.222 131.011C159.651 130.158 161.067 129.341 162.469 128.561Z" fill="#0D0D0D"/>
                </g>
                <defs>
                    <clipPath id="clip0_2257_81508">
                        <rect width="408" height="272" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}