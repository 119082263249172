import { useState, useEffect } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import rigthArrow from '../../assets/rigth_arrow.svg'
import DownArrow from '../../assets/DownArrow'
import NoResult_pic from '../../assets/NoResult_pic'
import ItemCard from '../../component/ItemCard'
import {
    icon_helmet, icon_gold, icon_sword, icon_growth,
    icon_helmet_activated, icon_gold_activated, icon_sword_activated, icon_growth_activated,
    icon_filter, icon_sort, icon_all
} from '../../assets/icons'
import Searchbar_marketplace from '../../component/Searchbar_marketplace'
import { getItemFilter_Favorite, } from '../../api/get/getItemById'
import { getFilter } from '../../api/get/marketplace'
import { useSelector, useDispatch } from 'react-redux'
import InfiniteScroll from "react-infinite-scroll-component";
import { Price, Checkbox, AnotherFilter, Server } from './filter'
import { getViewGame, createFavorite } from '../../api/post/marketplace'
import NoticeKYCstatus from '../../component/NoticeKYCstatus'
import Filtermobile from './Filtermobile'
import { setUrlPath, setDisplay } from '../../slice/goBackBtnSlice'

export default function ItemsIndex() {
    const [open, setOpen] = useState(false)
    const [actCate, setActCate] = useState('')
    const [openInv, setOpenInv] = useState(false)
    const [openFilter, setOpenFilter] = useState(true)
    const [value, setValue] = useState([])
    const [items, setItems] = useState([])
    const [favorite_item, setFavorite_item] = useState([])
    const [maxPage, setMaxPage] = useState()
    const [itemQuantity, setItemQuantity] = useState()
    const [filterQuantity, setFilterQuantity] = useState()
    const [hitWord, setHitword] = useState([])
    const [item_name, setItem_name] = useState()
    const [page, setPage] = useState(1)
    const [minPrice, setMinPrice] = useState()
    const [maxPrice, setMaxPrice] = useState()
    const [item_server, setItem_server] = useState()
    const breadcrumbsLink = [
        { label: 'Home', path: '/' },
        { label: 'Game Index', path: '/marketplace' },
    ]
    const [filter, setFilter] = useState([])
    const [mobileFilter, setMobileFilter] = useState([])
    const mock_filterBtn = ['Character', 'Armor', 'Weapon', 'Accessory', 'Skin']
    const market = useSelector((state) => state.marketplace)
    const user = useSelector((state) => state.user)
    const goBack = useDispatch()
    const pathName = useLocation().pathname.split('/')
    const [searchParams] = useSearchParams()
    const [getDataMore, setGetDataMore] = useState(true);
    const [openFilterMobile, setOpenFilterMobile] = useState(false)
    const [addFavorite, setAddFavorite] = useState(false)
    const itemCate = [
        { name: 'all', label: 'All', inactive: icon_all, active: icon_all, isActive: true },
        { name: 'account', label: 'Account', inactive: icon_helmet, active: icon_helmet_activated, isActive: true },
        { name: 'gold', label: 'Gold', inactive: icon_gold, active: icon_gold_activated, isActive: true },
        { name: 'item', label: 'Item', inactive: icon_sword, active: icon_sword_activated, isActive: true },
        { name: 'service', label: 'Service', inactive: icon_growth, active: icon_growth_activated, isActive: false },
    ]
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);


    const countFilterActive = () => {
        let count = 0
        if (!!item_server && !hitWord.includes(item_server)) {
            count++
        }
        if (!!item_name) {
            count++
        }
        if (!!minPrice || !!maxPrice) {
            count++
        }
        if (value?.length > 0) {
            count += value?.length
        }
        setFilterQuantity(count)
    }

    const fetchData = () => {
        let nextPage = page + 1;
        if (nextPage <= maxPage) {
            setPage(nextPage);
        } else {
            setGetDataMore(false);
        }
    };

    const handleCategoryChange = (btnName) => {
        setActCate(btnName)
        if (btnName !== actCate) {
            setItems([])
            setPage(1)
        }
    }

    const handleInvBtn = () => {
        setOpenInv(prev => !prev)
        setOpenFilter(false)
    }

    const handleFilterBtn = () => {
        setOpenFilter(prev => !prev)
        setOpenInv(false)
    }

    const handleChangeValue = event => {
        let text = []
        text.push(event.target.value)
        if (text?.length < 255) {
            setItem_name(text)
        }
    }

    const payload = {
        category_id: searchParams.get("cate"),
        gameId: searchParams.get("gameID")
    }

    const keyEnterSearchbar = (e) => {
        if (e.key === 'Enter') {

            const timer = setTimeout(() => {
                countFilterActive()
                getItemFilter_Favorite(page, (openInv || openFilter) ? 24 : 30, [], setItems, setFavorite_item, setMaxPage, setItemQuantity, payload, market, minPrice, maxPrice, item_name, value, item_server)
            }, 300)
            return () => clearTimeout(timer)
        }
    }

    const getAllItem = () => {
        getItemFilter_Favorite(
            page,
            (openInv || openFilter) ? 24 : 30,
            items,
            setItems,
            setFavorite_item,
            setMaxPage,
            setItemQuantity,
            payload,
            market,
            minPrice,
            maxPrice,
            item_name,
            value,
            item_server,
        )
    }


    useEffect(() => {

        getItemFilter_Favorite(
            page,
            (openInv || openFilter) ? 24 : 30,
            items,
            setItems,
            setFavorite_item,
            setMaxPage,
            setItemQuantity,
            payload,
            market,
            minPrice,
            maxPrice,
            item_name,
            value,
            item_server,
        )
        countFilterActive()
        getFilter(payload, setFilter, checkFilter, setMobileFilter)
        // window.scrollTo(0, 0);

    }, [actCate, page, addFavorite])

    useEffect(() => {
        setActCate(searchParams.get("cate") ? searchParams.get("cate") : "All")
        if (searchParams.get("gameID")) {
            getViewGame(searchParams.get("gameID"))
        }
        goBack(setDisplay(false))
        goBack(
            setUrlPath(
                `/marketplace`
            )
        );
    }, [])

    const checkFilter = (filterX) => {
        if (filterX.filter(x => !!x.filter_name)?.length > 2) {
            let fil = filterX
            fil.splice(2, 0, {
                filter_name: "Price",
                filter_type: "Range"
            })
            setFilter(fil)
        } else {
            let fil = filterX
            fil.push({
                filter_name: "Price",
                filter_type: "Range"
            })
            setFilter(fil)
        }
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    useEffect(() => {
        if (windowSize[0] > 768) {
            setOpenFilterMobile(false)
        }
    }, [windowSize])
    return (
        <main className='desktop:w-full desktop:max-w-[99.8125rem] desktop:min-w-[769px] text-white font-IBMPlex mobile:w-full mobile:max-w-[768px] mobile:min-w-0 desktop:pt-10 mobile:pt-4 mobile:px-4'>
            <section className='flex-col desktop:flex gap-9 mobile:hidden font-IBMPlex'>
                <div className='flex items-start text-white shrink'>
                    {breadcrumbsLink?.map((link, index) =>
                        <div key={index} className='flex shrink-0'>
                            <Link to={link.path}>
                                <h3 className='inline text-base font-bold underline'>{link.label}</h3>
                            </Link>
                            <img src={rigthArrow} alt='' width='8' height='12'
                                className='inline-block mx-3'
                            />
                        </div>
                    )}
                    <h3 className='break-all text-base font-normal text-[#CB8E44] '>
                        {pathName[2]?.replace("%20", " ")}
                    </h3>
                </div>
            </section>
            <section className='desktop:mt-9 mobile:mt-0 mobile:min-w-0 mobile:max-w-[768px] mobile:w-full desktop:max-w-full desktop:w-full desktop:min-w-0'>
                <div className='flex items-center justify-between w-full'>
                    <h1 className='mt-1 desktop:text-[2rem] font-bold uppercase mobile:text-2xl desktop:mt-0'>
                        Marketplace
                    </h1>
                    <button className='rounded bg-GRAY910 py-2 px-2.5 desktop:hidden' onClick={() => setOpenFilterMobile(true)}>
                        <img src={icon_filter} alt='' width='21' height='14' />
                    </button>
                </div>
                <div className='flex items-end justify-between overflow-auto '>
                    <div className='relative flex justify-start gap-x-4 desktop:mt-5 mobile:mt-[1.625rem] desktop:w-full mobile:max-w-[768px] mobile:min-w-[496px]'
                    >
                        {itemCate.map((cate, index) =>
                            <Link
                                to={cate?.isActive ? `/marketplace/${pathName[2]}?gameID=${searchParams.get("gameID")}&cate=${cate.label}` : ''}
                                key={index}
                                onClick={() => {
                                    if (cate?.isActive) {
                                        handleCategoryChange(cate.label)
                                    }
                                }}

                                className={`relative flex flex-col items-center justify-center rounded
                                ${actCate == cate.label ? 'bg-black ' : 'bg-GRAY910 '}  
                                desktop:w-120 desktop:h-120 
                                mobile:min-w-24 mobile:min-h-24 mobile:w-24 mobile:h-24 ${cate?.isActive ? 'cursor-pointer hover:bg-GRAY666/[0.2]   ' : 'cursor-default'}`}
                            >
                                <img src={cate.active} alt=''
                                    className={`desktop:w-50 desktop:h-50 mobile:w-10 mobile:h-10 ${actCate === cate.label ? "grayscale-0" : "grayscale"}`}
                                />
                                <h3 className='text-base font-bold '>{cate.label}</h3>
                            </Link>
                        )}
                    </div>
                    {!!user?.kyc_step_seller
                        ? (
                            <Link

                                className='h-12 w-[9.375rem] flex justify-center items-center font-bold  rounded font-kanit uppercase  bg-RED001 hover:bg-RED001/[0.8] desktop:flex mobile:hidden'
                                to={`/create_order?gameId=${searchParams.get("gameID")}`}
                            >
                                Create Order
                            </Link>
                        )
                        : (
                            <div
                                className='h-12 w-[9.375rem] flex justify-center items-center font-bold  rounded font-kanit uppercase cursor-pointer bg-RED001 hover:bg-RED001/[0.8] desktop:flex mobile:hidden'
                                onClick={() => setOpen(true)}
                            >
                                Create Order
                            </div>
                        )
                    }

                </div>
                <div className='flex w-full justify-between items-center desktop:mt-5 mobile:mt-3'>
                    <div className='flex w-full items-center 
                        desktop:w-auto desktop:flex-row mobile:w-full mobile:flex-col'
                    >
                        <div className='desktop:flex gap-x-3 mobile:hidden'>
                            <button className={`h-12 px-4 py-[.687rem] rounded uppercase ${openInv ? 'bg-black' : 'bg-GRAY910'}`}
                                onClick={handleInvBtn}
                            >
                                <span className='text-base font-bold'>My Inventory</span>
                            </button>
                            <button className={`h-12 px-3.5 py-[.687rem] rounded uppercase ${openFilter ? 'bg-black' : 'bg-GRAY910'}`}
                                onClick={handleFilterBtn}
                            >
                                <img src={icon_filter} alt='' width='24.32' height='24' className='inline' />
                                <span className='inline ml-2 text-base font-bold align-bottom'>Filter ({filterQuantity})</span>
                            </button>
                        </div>
                        {/* <div className='btn-category flex items-center overflow-auto
                            desktop:w-auto desktop:gap-x-3 desktop:ml-6 
                            mobile:w-full mobile:ml-0 mobile:gap-x-2'
                        >
                            {mock_filterBtn.map(btn =>
                                <button className='text-xs font-medium py-[0.31rem] px-[0.935rem] rounded border border-white opacity-[60%]'>
                                    {btn}
                                </button>
                            )}
                        </div> */}
                        <div className='flex desktop:w-auto desktop:max-w-[450px] desktop:overflow-auto mobile:w-full gap-5 desk desktop:px-3 desktop:ml-3 mobile:m-o mobile:p-0'>
                            {hitWord?.map((word, index) => {
                                let num = 0
                                if (!!word && num < 5) {
                                    num++
                                    return (
                                        <div key={index} className='px-4 py-1 border border-white rounded'>
                                            <p>{word}</p>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <span className='block text-base font-normal mt-3 ml-0 mr-auto
                            desktop:ml-3 desktop:mt-0 desktop:inline-block'
                        >
                            <h3 className='inline-block font-bold'>{itemQuantity}</h3> Results
                        </span>
                    </div>
                    <button className='items-center px-3 py-2 rounded gap-x-2 bg-GRAY910 desktop:flex mobile:hidden'>
                        <img src={icon_sort} alt='' width='24' height='24' />
                        <span className='test-base font-bold uppercase'>Most Popular</span>
                        <DownArrow />
                    </button>
                </div>
            </section>
            <section className='w-full flex gap-x-6 desktop:justify-start mobile:justify-center desktop:mt-5 desktop:mb-10 mobile:mt-3'>
                {openInv &&
                    <div className='desktop:flex mobile:hidden flex-col'>
                        <Searchbar_marketplace
                            handleChange={handleChangeValue}
                            keyEnterSearchbar={keyEnterSearchbar}
                        />
                    </div>
                }
                {(openFilter) &&
                    <div className='desktop:flex mobile:hidden flex-col gap-4'>
                        <Searchbar_marketplace
                            handleChange={handleChangeValue}
                            keyEnterSearchbar={keyEnterSearchbar}
                        />
                        {filter.map((filter, index) => (
                            // <Filter filter={filter} key={index} />
                            <>
                                {filter?.filter_name?.toLowerCase() === "server" && (
                                    <div>
                                        <Server filter={filter} item_server={item_server} setItem_server={setItem_server} />
                                        <div className="w-full h-[1px] bg-[#939393]">
                                        </div>
                                    </div>
                                )}
                                {filter?.filter_type === "Checkbox" && filter?.filter_name?.toLowerCase() !== "server" && (
                                    <div>
                                        <Checkbox filter={filter} value={value} setValue={setValue} />
                                        <div className="w-full h-[1px] bg-[#939393]">
                                        </div>
                                    </div>

                                )}
                                {filter?.filter_type === "Range" && (
                                    <div className="flex flex-col gap-5">
                                        <Price
                                            getItemFilter_Favorite={getItemFilter_Favorite}
                                            page={page}
                                            itemPerPage={(openInv || openFilter) ? 24 : 30}
                                            items={items}
                                            setItems={setItems}
                                            setFavorite_item={setFavorite_item}
                                            setMaxPage={setMaxPage}
                                            setItemQuantity={setItemQuantity}
                                            payload={payload}
                                            market={market}
                                            item_name={item_name}
                                            minPrice={minPrice}
                                            setMinPrice={setMinPrice}
                                            maxPrice={maxPrice}
                                            setMaxPrice={setMaxPrice}
                                            value={value}
                                            item_server={item_server}
                                            setHitWord={setHitword}
                                            countFilterActive={countFilterActive}
                                        />
                                        <div className="w-full h-[1px] bg-[#939393]">
                                        </div>
                                    </div>
                                )}
                            </>
                        ))}
                    </div>
                }
                <InfiniteScroll
                    dataLength={(openInv || openFilter) ? 24 : 30}
                    next={fetchData}
                    hasMore={getDataMore}
                    height={2400}
                >
                    <div className={`desktop:max-w-full desktop:min-0 desktop:w-full desktop:flex desktop:flex-wrap ${(openInv || openFilter) ? "desktop:gap-3" : "desktop:gap-5"} mobile:grid mobile:grid-cols-2 mobile:gap-5`}>
                        {favorite_item?.map((item, index) => (
                            <ItemCard
                                item={item.item_game}
                                textSize={"16px"}
                                mobileText={"10px"}
                                viewSize={"12px"}
                                priceSize={"20px"}
                                heightCard={"399px"}
                                mobileHeight={"269px"}
                                mobileWeight={"200px"}
                                fav={true}
                                key={index}
                                addFavorite={addFavorite}
                                setAddFavorite={setAddFavorite}
                                setItems={setItems}
                            />
                        ))}
                        {items?.map(item =>
                            <ItemCard
                                item={item}
                                textSize={"16px"}
                                mobileText={"10px"}
                                viewSize={"12px"}
                                priceSize={"20px"}
                                heightCard={"399px"}
                                mobileHeight={"269px"}
                                mobileWeight={"200px"}
                                fav={false}
                                addFavorite={addFavorite}
                                setAddFavorite={setAddFavorite}
                                setItems={setItems}
                            />
                        ) ??
                            <div className='flex-col items-center justify-center w-full text-white desktop:flex'>
                                <NoResult_pic className='w-[25.5rem] h-[17rem]' />
                                <h1 className='text-[2rem] font-bold mt-5'>
                                    No Result Found
                                </h1>
                                <span className='mt-5 text-base font-normal'>
                                    Please try selecting different filters or try another
                                </span>
                                <span className='text-base font-normal'>
                                    keywords for better results.
                                </span>
                            </div>
                        }
                    </div>


                </InfiniteScroll>
                <NoticeKYCstatus open={open} setOpen={setOpen} />
                <Filtermobile
                    open={openFilterMobile}
                    setOpen={setOpenFilterMobile}
                    handleChangeValue={handleChangeValue}
                    mobileFilter={mobileFilter}
                    minPrice={minPrice}
                    setMinPrice={setMinPrice}
                    maxPrice={maxPrice}
                    setMaxPrice={setMaxPrice}
                    value={value}
                    setValue={setValue}
                    item_server={item_server}
                    setItem_server={setItem_server}
                    getItemFilter_Favorite={getItemFilter_Favorite}
                    page={page}
                    itemPerPage={30}
                    setItems={setItems}
                    setFavorite_item={setFavorite_item}
                    setMaxPage={setMaxPage}
                    payload={payload}
                    market={market}
                    item_name={item_name}
                    keyEnterSearchbar={keyEnterSearchbar}
                    setHitWord={setHitword}
                    countFilterActive={countFilterActive}
                />
            </section>
        </main>
    )
}
