import axios from 'axios'
import Cookies from 'js-cookie'


export const getItemDetail = async (id, setState) => {
    await axios
        .get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_GET_ITEM_ID + `${id}`)
        .then(res => setState(res.data.item_game))
        .catch(({ response }) => {
            if (response.data.status === 'Fail') window.location.replace('/marketplace')
        })
}


export const getItemFilter_Favorite = async (
    page,
    itemPerPage,
    items,
    setItems,
    setFavorite_item,
    setMaxPage,
    setItemQuantity,
    payload,
    market,
    minPrice,
    maxPrice,
    item_name,
    value,
    item_server,
    // setItemQuantity
) => {
    const category = [
        { name: "Account", id: 2 },
        { name: "Gold", id: 3 },
        { name: "Item", id: 1 },
        { name: "Service", id: 4 },
    ]
    let param = ""
    if (market.user_id) param += `user_id=${market.user_id}&`
    // if(market.category_id) param += ``
    if (category.findIndex(cate => cate.name === payload?.category_id) >= 0) {
        let cate_id = category.findIndex(cate => cate.name === payload?.category_id)
        param += `category_id=${category[cate_id]?.id}&`
    }

    if (payload?.gameId) {
        let newGameId = ""
        let status = false
        for (let i = 0; i < payload?.gameId?.length; i++) {
            if (!status) {
                if (Number(payload?.gameId[i]) > 0) {
                    status = true
                    newGameId += payload?.gameId[i]
                }
            } else {
                newGameId += payload?.gameId[i]
            }
        }
        param += `game_id=${newGameId}&`
    }

    if (!!minPrice) param += `min_price=${minPrice}&`
    if (!!maxPrice) param += `max_price=${maxPrice}&`

    let addItem_name_payload = []

    if (!!item_name || value?.length > 0) {
        if (value?.length > 0) {
            for (let i = 0; i < value?.length; i++) {
                addItem_name_payload.push(`"${value[i]}"`)
            }
        }
        if (!!item_name) addItem_name_payload.push(`"${item_name}"`)
        param += `item_name=[${addItem_name_payload}]&`

    }
    if (!!item_server) {
        param += `item_server=${item_server}&`
    }

    await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_GET_ITEM_FILTER_FAVORITE + `/${itemPerPage}/${page}?` + param)
        .then((res) => {
            setMaxPage(res.data.max_page)
            setFavorite_item(res.data.favorite_item)
            setItems([...items, ...res.data.item])
            setItemQuantity(res.data.item_count)

        })
        .catch((err) => {
            console.error(err);
        })
}

export const getUserid = async (dispatch, getUserID) => {
    await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_GET_USER_ID, {
        headers: {
            Authorization: `Bearer ${Cookies.get('access_token')}`,
        }
    })
        .then((res) => {
            dispatch(getUserID({
                user_id: res.data.user_id
            }))
        })
        .catch((err) => {
            console.error(err);
        })
}