import React, { useState, useEffect } from "react";
import IconGuideBook from "../../../../../assets/images/delivery/Other/IconGuideBook.svg";
import IconInformation from "../../../../../assets/images/delivery/Other/IconInformation.svg";
import IconNoti from "../../../../../assets/images/delivery/Other/IconNoti.svg";
import IconContact from "../../../../../assets/images/delivery/Other/IconContact.svg";
import { useDispatch } from "react-redux";
import { setDisplay } from "../../../../../slice/goBackBtnSlice";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
} from "react-router-dom";
import { useMutationClientDeliveryTransaction } from "../../../../../api/mutation/delivery";

const DeliveryOtherPage = () => {
  const navigate = useNavigate();
  const goBack = useDispatch();
  const [isMobile, setIsMobile] = useState(true);
  const [dataDelivery, setDataDelivery] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const trackId = searchParams.get("trackId");

  const locationPathName = useLocation();
  const pathnameCurrent = locationPathName.pathname.split("/")[1];
  const isSeller = dataDelivery?.tokenSeller;

  //   mutateGetDetailOrderNumber
  const {
    mutate: mutateDeliveryTransaction,
    isSuccess: isSuccessDeliveryTransaction,
  } = useMutationClientDeliveryTransaction({
    onSuccess: (resp) => {
      setDataDelivery(resp?.data);
    },
    onError: () => {},
  });

  useEffect(() => {
    if (trackId !== undefined) {
      const dataTransaction = {
        transactionTrackId: trackId || "",
      };

      mutateDeliveryTransaction(dataTransaction);
    }
  }, [trackId, mutateDeliveryTransaction]);

  useEffect(() => {
    goBack(setDisplay(false));
  }, []);

  useEffect(() => {
    if (trackId === undefined || trackId === null) {
      navigate("/");
    }
  }, [trackId]);

  useEffect(() => {
    const handleWindowResize = () => {
      const isMobileScreen = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(isMobileScreen);
    };

    // Initial check when the component mounts
    handleWindowResize();

    // When isn't MobileScreen setIsTransactionPurchasePage
    if (isMobile === false) {
      navigate({
        pathname: `/${pathnameCurrent}`,
        search: `${createSearchParams({
          itemName: dataDelivery?.itemName,
          trackId: trackId,
        })}`,
      });
    }

    // Add event listener to update the state on window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isMobile]);

  const onClickToGuidebook = (e) => {
    e.preventDefault();
    navigate({
      pathname: "guidebook",
      search: `${createSearchParams({
        trackId: trackId,
      })}`,
    });
  };

  const onClickToInformation = (e) => {
    e.preventDefault();
    navigate({
      pathname: "information",
      search: `${createSearchParams({
        trackId: trackId,
      })}`,
    });
  };

  const onClickToSubscribe = (e) => {
    e.preventDefault();
    navigate("/comingsoon");
  };

  const onClickToContact = (e) => {
    e.preventDefault();
    window.zE && window.zE("webWidget", "open");
  };

  return (
    <div className="bg-GRAY111 h-[100vh] w-full desktop:hidden">
      <div className="py-4 px-3 flex flex-col gap-4">
        <div
          className="py-3 px-5 bg-GRAY910 rounded flex items-center gap-3 cursor-pointer"
          onClick={onClickToGuidebook}
        >
          <img src={IconGuideBook} alt="guildBook" className="w-5 h-5" />
          <p className="font-[700] text-base text-white">Guide Book</p>
        </div>

        <div
          className="py-3 px-5 bg-GRAY910 rounded flex items-center gap-3 cursor-pointer"
          onClick={onClickToInformation}
        >
          <img src={IconInformation} alt="guildBook" className="w-5 h-5" />
          <p className="font-[700] text-base text-white ">Full Information</p>
        </div>

        {!isSeller && (
          <div
            className="py-3 px-5 bg-GRAY910 rounded flex items-center gap-3 cursor-pointer"
            onClick={
              isSeller ? () => navigate("/create_order") : onClickToSubscribe
            }
          >
            <img src={IconNoti} alt="guildBook" className="w-5 h-5" />
            <p className="font-[700] text-base text-white ">
              {isSeller
                ? "Create New Order"
                : `Subscribe ${dataDelivery?.sellerName}`}
            </p>
          </div>
        )}

        <div
          className="py-3 px-5 bg-GRAY910 rounded flex items-center gap-3 cursor-pointer"
          onClick={onClickToContact}
        >
          <img src={IconContact} alt="guildBook" className="w-5 h-5" />
          <p className="font-[700] text-base text-white ">
            Contact Help Centre
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeliveryOtherPage;
