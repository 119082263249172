import { useState } from "react";
import { SearchBar } from "../search/SearchBar";
import SideBarButton from "./SideBarButton";

const SideBar = ({
  isOpen,
  setIsOpen,
  mock_menu,
  setInput,
  input,
  results,
  setResults,
}) => {
  return (
    <>
      <div
        className={`mobile:justify-between mobile:overflow-auto mobile:z-20 mobile:items-center mobile:w-full mobile:h-full desktop:hidden bg-GRAY111 left-0 top-[4.5rem] px-4 ${
          isOpen ? "mobile:absolute" : "hidden"
        }`}
      >
        <div>
          <div
            className={`relative h-[60px] mt-4 md:block desktop:h-full z-50 `}
          >
            <i className="ri-search-line absolute inset-y-0 text-white left-2 -top-5 flex items-center pointer-events-none"></i>
            <SearchBar
              setResults={setResults}
              input={input}
              setInput={setInput}
              results={results}
              setIsOpen={setIsOpen}
            />
          </div>
        </div>

        <div className="flex flex-col gap-4">
          {mock_menu?.map((menu, index) => {
            return (
              <div key={`${menu?.id} ${index}`}>
                <SideBarButton menu={menu} setIsOpen={setIsOpen} />
              </div>
            );
          })}
        </div>

        {/* <Footer/> */}
        <footer className="mobile:bg-GRAY111 w-full z-10 fixed bottom-0 left-0 py-4">
          <ul className="flex items-center justify-around text-[10px] text-white">
            <li>Terms of Conditions</li>
            <li>Privacy Policy</li>
            <li>Copyright Policy</li>
            <li>Cookie Policy</li>
          </ul>
          <div className="flex justify-center">
            <p className="font-bold text-white text-[12px]">
              @2023 RETO HUB. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default SideBar;
