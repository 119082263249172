import axios from "axios";
import Cookies from "js-cookie";

export const ProfilePage = (page, pageSize, tab, setIsProfile) => {
  axios
    .post(
      process.env.REACT_APP_BACKEND_ENDPOINT +
        process.env.REACT_APP_ENDPOINT_POST_GETPROFILEDATA,
      {
        page: page,
        pageSize: pageSize,
        tab: tab,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
      }
    )
    .then((res) => {
      setIsProfile(res.data.data);
    })
    .catch((err) => {
      console.error(err);
      if (err?.response?.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      }
    });
};
