import { Link, useLocation, Navigate, useSearchParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { updateProfile, updateProfileFirstTime } from "../../api/post/setting/account";
import { icon_correct2 } from "../../assets/register";
import { useState, useEffect, useRef } from "react";
import Header from "../../component/layout/Header";
import { checkEmail, verificated, } from "../../api/post/register";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { setLogin_success } from "../../slice/userslice";

const VerifyEmail = () => {
    const { register, handleSubmit, setError, formState: { errors }, getValues } = useForm({});
    const pathName = useLocation().pathname.split('/')
    const Authorization = Cookies.get("access_token")
    const [createSuccess, setCreateSuccess] = useState(false)
    const [show, setShow] = useState(true)
    const [searchParams] = useSearchParams()
    const [JWT, setJWT] = useState()
    const [showError, setShowError] = useState("display_name")
    const lock = useRef(false)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const containsSpecialCharacter = (value) => {
        const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~]/;
        return specialCharRegex.test(value);
    };

    if (pathName[2] === "create_displayname" && !Authorization) return <Navigate to="/" />




    return (
        <div className="w-full ">
            <div className="mobile:flex desktop:hidden mobile:w-full">
                <Header show={show} />
            </div>
            {pathName[2] === "verified_email" && createSuccess === false && (
                <div className="  flex flex-col items-start desktop:justify-center mobile:justify-start h-full mobile:px-3 mobile:bg-[#1E1E1E] desktop:bg-transparent space-y-[16px] mobile:w-full desktop:w-[400px] pt-[16px] ">
                    <div className="flex items-center gap-3">
                        <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold">CONGRATULATIONS</p>
                        <img src={icon_correct2} alt="icon_correct2" className="w-9 h-9" />
                    </div>
                    <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-normal">You have successfully verified account! <Link className="text-[#FFD262] cursor-pointer" to="/login">Login</Link> here.</p>
                </div>
            )}


            {pathName[2] === "create_displayname" && createSuccess === false && (
                <div className="  flex flex-col items-start mobile:px-3 desktop:bg-transparent mobile:bg-[#1E1E1E] space-y-[16px] mobile:w-full desktop:w-[400px] pt-[16px]">
                    <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold">DISPLAY NAME</p>
                    <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[14px] font-normal">Anyone can see this name when they communicate with you or view  content you create in RETO HUB services.</p>
                    {/* display Name */}
                    <div className="w-full h-[78px] flex flex-col items-start justify-between relative">
                        <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">Desired name</p>
                        <input
                            {...register("display_Name", {
                                // required: "Display name is required"
                                required: {
                                    value: "true",
                                    message: [`Display Name must be between 3 and 15 characters`, `Special characters such as -*/|&^%$# and spaces are not allowed.`],
                                },
                                minLength: {
                                    value: 4,
                                    message: ["Please enter username morer than 4 characters"],
                                },
                                maxLength: {
                                    value: 15,
                                    message: ["Please enter username not longer than 16 characters"],
                                },
                                pattern: {
                                    // value: /^[a-zA-Z0-9]\w+$/g,
                                    value: containsSpecialCharacter,
                                    message: [`The username will be displayed on your profile and can be seen by other users. Special characters such as _or- or numbers 0-9 are allowed`]
                                }
                            })}
                            type="text"
                            className={`w-full h-[48px]  focus:outline-none px-[12px] py-[11px] rounded-[4px] bg-[#282828] text-white text-[16px] ${errors.display_Name ? "border border-[#AE3C33] " : "border-none"}`}
                            placeholder="Enter display name"
                            onClick={() => setShowError(showError === "display_name" ? "" : "display_name")}

                        />
                        {errors.display_Name && showError === "display_name" && (
                            <div className="mobile:hidden desktop:flex absolute p-2 top-[calc(50%+16px)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw] rounded-[4px]">
                                <div className="flex justify-center items-start relative w-full h-full flex-col desktop:w-[25rem] ">
                                    {errors?.display_Name?.message?.map((message, index) => (
                                        <p className="text-white font-IBMPlex text-[14px]" key={index}>{message}</p>
                                    ))}

                                    <div
                                        className="w-0 h-0 absolute left-[calc(100%)] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"
                                    ></div>
                                </div>
                            </div>
                        )}
                    </div>
                    <button className="w-full h-[52px] bg-[#BB213D] flex justify-center items-center rounded-[4px] font-IBMPlex text-[#FFFFFF] text-[16px] font-bold hover:bg-RED001/[0.8]" onClick={handleSubmit((formValues) => {
                        const payload = {
                            "display_name": formValues.display_Name,
                        }
                        updateProfileFirstTime(payload, setCreateSuccess, dispatch, setLogin_success)
                    })}>
                        CONFIRM
                    </button>
                    {errors.display_Name && (
                        <div className="desktop:hidden mobile:flex justify-center items-start w-full h-full flex-col ">
                            {errors.display_Name.message.map((message, index) => (
                                <p className=" text-[#FF002E] font-IBMPlex text-[14px]" key={index}>{message}</p>
                            ))}
                        </div>
                    )}

                </div>
            )}


            {createSuccess === true && (
                <div className="  flex flex-col items-start desktop:justify-center mobile:justify-start h-full mobile:px-3 desktop:p-0 mobile:bg-[#1E1E1E] desktop:bg-transparent space-y-[16px] mobile:w-full desktop:w-[26rem] pt-[16px] ">
                    <div className="flex items-center w-full gap-3">
                        <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold">WELCOME TO RETO HUB</p>
                        <img src={icon_correct2} alt="icon_correct2" className="w-9 h-9" />
                    </div>
                    <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-normal">All-In-One Digital Marketplace. <Link className="text-[#FFD262] cursor-pointer" to="/">Click</Link> here.</p>
                </div>
            )}
        </div>


    )
}

export default VerifyEmail