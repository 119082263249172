import { useState } from 'react'
import { Icon_search } from '../assets/icons'

export default function Searchbar_Index({
    handleChange,
    onClick,
    value
}) {

    return (
        <div className='desktop:flex items-center justify-between w-[25rem] h-12 mt-10 rounded bg-GRAY910 mobile:hidden'>
            <div className='flex w-full py-[0.687rem] px-[0.875rem]'>
                <Icon_search className='opacity-50' />
                <input
                    type='text'
                    placeholder='Search Games'
                    onChange={handleChange}
                    value={value}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onClick()
                        }
                    }}
                    className='w-full ml-[1.125rem] bg-transparent focus-visible:outline-none 
                    placeholder:text-GRAY920'
                />
            </div>
            <button onClick={onClick} className='flex items-center mr-[0.187rem] h-[2.625rem] 
                px-[1.75rem] py-[0.687rem] text-base font-bold uppercase outline outline-[0.187rem] 
                outline-RED001 rounded'
            >
                search
            </button>
        </div>
    )
}