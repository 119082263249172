import { useQuery } from "@tanstack/react-query";
import { paymentApi } from "../../services";

// export const useQueryAllItems = (options) => {
//   const query = useQuery("AllItems", itemsApi.GetAllItems, options);
//   return query;
// };

export const useQueryGetCheckout = () => {
  const query = useQuery({
    queryKey: ["checkout"],
    queryFn: paymentApi.GetCheckOut,
  });
  return query;
};
