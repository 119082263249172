import React, { useState, useEffect } from "react";
import IconQRCode from "../../../../assets/images/payment/QRCode.svg";
import ChangeIcon from "../../../../assets/images/payment/IconChange.svg";
import ImagePromptPay from "../../../../assets/images/payment/PromptPay.png";
import { changePxToRem } from "../../../../utils/changePixeltoRem";
import { BorderButton } from "../../../Button";
import Skeleton from "@mui/material/Skeleton";

const PaymentQRCode = ({
  data,
  handleOpenDialogCancelOrder,
  QRCode,
  transactionId,
  transactionRef,
  onClickChangeGateway,
  paymentSuccess,
}) => {
  const totalPrice = data?.subtotalAmount;

  return (
    <div className="h-full">
      {/*  PaymentQR Code Mobile */}
      <div className="desktop:hidden mobile:flex flex-col justify-between gap-4 h-full">
        <div className="mobile:flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2  ">
              <p className="text-2xl font-[700] text-white pl-1">
                BANKING PAYMENT
              </p>
              <p className="text-xs font-[400] text-white pl-1">
                Purchasing with QR Code, Please check the details carefully.
              </p>
            </div>
            <div>
              <div className="bg-GRAY700  flex flex-col h-auto px-[0.1875rem]">
                {QRCode ? (
                  <img
                    src={QRCode ? `data:image/png;base64, ${QRCode}` : ""}
                    alt="QRCode"
                    className="w-full h-auto"
                  />
                ) : (
                  <div className="w-full h-[80vw]">
                    <Skeleton
                      sx={{
                        bgcolor: "grey.800",
                        width: "100%",
                        height: "100%",
                      }}
                      variant="rectangular"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 px-1 ">
            <div className="flex justify-between">
              <p className="text-sm font-[500] text-GRAY920 ">Order no.</p>
              <p className="text-sm font-[400] text-GRAY920 ">
                {transactionId ? transactionId : ""}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm font-[500] text-GRAY920 ">Account Bank</p>
              <p className="text-sm font-[400] text-GRAY920 ">
                Retohub Banking
              </p>
            </div>
            <div className="flex flex-wrap justify-between">
              <p className="text-sm font-[500] text-GRAY920 ">
                Confirmation code
              </p>
              <p className="text-sm font-[400] text-GRAY920 text-end grow">
                {transactionRef ? transactionRef : ""}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm font-[500] text-GRAY920 ">
                Payment gateway Fee
              </p>
              <p className="text-sm font-[400] text-GRAY920 ">
                {data?.gatewayFee
                  ? `${Number(data?.gatewayFee).toLocaleString()} THB`
                  : "THB"}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm font-[500] text-white ">Total</p>
              <p className="text-sm font-[400] text-GREEN050 ">
                {totalPrice
                  ? `${Number(totalPrice).toLocaleString()} THB`
                  : "THB"}
              </p>
            </div>
          </div>
        </div>

        <div>
          <BorderButton
            text="Cancel"
            styleBtn="bg-[#1A1A1A] border-none"
            onClick={handleOpenDialogCancelOrder}
          />
        </div>
      </div>

      {/* Select Gateway Payment Desktop */}
      <div className="mobile:hidden desktop:flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div>
            <div className="  flex flex-col  rounded">
              <div className="py-5 px-8 flex flex-wrap justify-between items-center rounded-sm  border-b-[1px] border-GRAY750 gap-2">
                <div className="grow">
                  <div className="flex gap-4 items-center">
                    <img
                      src={IconQRCode}
                      alt="QRCodePaymentGateway"
                      style={{
                        width: changePxToRem(36),
                        height: changePxToRem(36),
                      }}
                    />
                    <p className="text-xl font-[700] text-white uppercase">
                      QR CODE
                    </p>
                  </div>
                </div>

                <div className="flex justify-end grow">
                  <div
                    className={`py-3 px-6   ${
                      paymentSuccess
                        ? "hover:bg-transparent bg-transparent"
                        : "bg-GRAY910 hover:bg-GRAY900/[0.5]"
                    }  flex items-center gap-3 rounded cursor-pointer w-[9rem]`}
                    onClick={onClickChangeGateway}
                  >
                    <img
                      src={ChangeIcon}
                      alt="ChangeGatewayPayment"
                      style={{
                        width: changePxToRem(18),
                        height: changePxToRem(16),
                      }}
                    />
                    <p className="text-base font-[700] text-white uppercase">
                      Change
                    </p>
                  </div>
                </div>
              </div>

              <div className="py-10 flex justify-center">
                {QRCode ? (
                  <div className="flex flex-col items-center w-full px-[17.2%]">
                    <img
                      src={ImagePromptPay}
                      alt="ImagePromptPay"
                      className="w-full h-auto"
                    />
                    <img
                      src={QRCode ? `data:image/png;base64, ${QRCode}` : ""}
                      alt="QRCode"
                      className="w-full h-auto"
                    />
                  </div>
                ) : (
                  <div className="flex flex-col items-center w-full px-[17%]">
                    <div className="w-full h-[100%] md:h-[24vw] ">
                      <Skeleton
                        sx={{
                          bgcolor: "grey.800",
                          width: "100%",
                          height: "100%",
                        }}
                        variant="rectangular"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentQRCode;
