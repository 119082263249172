import axios from "axios";
import Cookies from "js-cookie";

export const getFavoriteStatus = async(item_id, setIsFavorite) => {
    await axios.post(
            process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_GET_FAVORITE_STATUS,
            {
                item_id: Number(item_id)
            },
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get("access_token")}`,
                }
            }
        )
        .then( ({data}) => setIsFavorite(data) )
        .catch( err => console.log(err))
}