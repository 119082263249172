import { useState } from "react"
import CreateOrder from "./CreateOrder"
import CreateSuccess from "./CreateSuccess"


const Create = () => {
    const [createSuccess, setCreateStatus] = useState({
        status: "unsuccess",
        item_game_id: null,
        item_product_id: null,
    })
    const [pictureUpload, setPictureUpload] = useState()

    if (createSuccess?.status === "success") {
        window.scroll(0, 0)
        return <CreateSuccess createSuccess={createSuccess} pictureUpload={pictureUpload} />
    } else {
        return <CreateOrder setCreateStatus={setCreateStatus} pictureUpload={pictureUpload} setPictureUpload={setPictureUpload} />
    }
}
export default Create