import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setDisplay, setUrlPath } from '../../slice/goBackBtnSlice'
import { Link, Navigate } from 'react-router-dom'
import { Alert } from '@material-tailwind/react'
import { Icon_duplicate } from '../../assets/icons'
import googlePlay from '../../assets/google_play.png'
import appStore from '../../assets/app_store.png'
import qr_googleAuth from '../../api/post/setting/qr_googleAuth'
import { icon_hidePassword, icon_showPassword } from '../../assets/login'
import { check_2fa_status, activate_2fa } from '../../api/post/security'

export default function QRCode_Authenticator() {
    const goBack = useDispatch()
    const [qrCode, setQRCode] = useState()
    const [textCode, setTextCode] = useState()
    const [isCopied, setIsCopied] = useState(false)
    const [inputCode, setInputCode] = useState()
    const [showCode, setShowCode] = useState(false)
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])
    const [status_2fa, setStatus_2fa] = useState(null)

    const copyToClipboard = () => {
        navigator.clipboard.writeText(textCode);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1000);
    };

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight])
        }

        window.addEventListener('resize', handleWindowResize)
        return () => window.removeEventListener('resize', handleWindowResize)
    }, [])

    useEffect(() => {
        if (window?.innerWidth > 768) {
            return () => window.location.href = "/setting/security"
        }
    }, [windowSize])

    useEffect(() => {
        check_2fa_status(setStatus_2fa)
    }, [])

    useEffect(() => {
        goBack(setDisplay(false))
        goBack(setUrlPath('/setting/security'))
        qr_googleAuth(setQRCode, setTextCode)
    }, [])

    if (!!status_2fa) return <Navigate to="/setting/security" />

    return (
        <main className='relative w-full p-4 bg-black flex flex-col items-center'>
            <Alert open={isCopied} className='absolute p-1 top-[-0.7rem] left-1 w-fit mx-auto text-[.75rem] border-b rounded !opacity-90 bg-GRAY910 text-GREEN100 border-GREEN100'>
                Copied
            </Alert>
            <h1 className='text-2xl font-bold text-center text-white'>Google Authenticator (2FA)</h1>
            <div className='flex flex-col items-center mt-3 gap-y-4'>
                <p className='text-base font-normal text-center text-white'>
                    Please scan this QR code or type the out authenticator code
                    into your authenticator application
                </p>
                <img src={qrCode} alt='' width='272' height='272' className='' />
                <div className='relative w-full'>
                    <input
                        type={showCode ? "text" : "password"}
                        name="inputCode"
                        className={`w-full h-[48px]  focus:outline-none px-[12px] py-[11px] rounded-[4px] bg-[#282828] text-white text-[16px] placeholder:text-[#939393]`}
                        placeholder='Enter 2FA code'
                        maxLength="6"
                        onKeyDown={(e) =>
                            (e?.key?.length === 1 && e.key.match(/[^0-9]/g)) && e.preventDefault()
                        }
                        onChange={(e) => setInputCode(e?.target?.value)}
                    />
                    <div className="absolute  right-[12px] flex  bottom-[12px]">
                        {showCode
                            ? <div className="w-6 h-6 cursor-pointer" onClick={() => setShowCode(prev => !prev)}>
                                <img src={icon_showPassword} alt="icon_showPassword" className="w-full" />
                            </div>
                            : <div className="w-6 h-6 cursor-pointer" onClick={() => setShowCode(prev => !prev)}>
                                <img src={icon_hidePassword} alt="icon_showPassword" className="w-full" />
                            </div>
                        }
                    </div>
                </div>
                <div className='text-base font-normal text-white 
                    rounded w-full py-14 px-3 text-center gap-2 flex justify-center'>
                    <p>Do you need help?</p>
                    <Link to="/comingsoon" className=' underline underline-offset-4'>
                        What’s Google Authenticator (2FA)
                    </Link>
                </div>
                <button className=' rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold bg-[#BB213D] text-white hover:bg-RED001/[0.8] active:bg-[#BB213D]/80 h-[52px] w-full'
                    onClick={() => activate_2fa(inputCode, setStatus_2fa)}
                >
                    ENABLE
                </button>
            </div>
        </main>
    )
}