import axios from "axios"
import Cookies from "js-cookie";
import { redirect, useNavigate } from "react-router-dom";


export const signout = () => {
    axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_AUTH_LOGOUT,
        {},
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get('access_token')}`,
            }
        })
        .then((res) => {
            window.sessionStorage.clear();
            window.localStorage.clear()
            Cookies.remove('access_token')
            Cookies.remove('refresh_token')
        })
        .catch((err) => {
        })
}
