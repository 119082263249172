import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDisplay } from "../../../../../slice/goBackBtnSlice";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
  Link,
} from "react-router-dom";
import { useMutationClientDeliveryTransaction } from "../../../../../api/mutation/delivery";

const DeliveryInformationPage = () => {
  const goBack = useDispatch();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(true);
  const [dataInformation, setDataInformation] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const trackId = searchParams.get("trackId");

  const locationPathName = useLocation();
  const pathnameCurrent = locationPathName.pathname.split("/")[1];

  //   mutateGetDetailOrderNumber
  const {
    mutate: mutateDeliveryTransaction,
    isSuccess: isSuccessDeliveryTransaction,
  } = useMutationClientDeliveryTransaction({
    onSuccess: (resp) => {
      setDataInformation(resp?.data);
    },
    onError: () => {
      navigate("/");
    },
  });

  useEffect(() => {
    if (trackId !== undefined) {
      const dataTransaction = {
        transactionTrackId: trackId || "",
      };

      mutateDeliveryTransaction(dataTransaction);
    }
  }, [trackId, mutateDeliveryTransaction]);

  useEffect(() => {
    goBack(setDisplay(false));
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      const isMobileScreen = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(isMobileScreen);
    };

    // Initial check when the component mounts
    handleWindowResize();

    // When isn't MobileScreen setIsTransactionPurchasePage
    if (isMobile === false) {
      navigate({
        pathname: `/${pathnameCurrent}`,
        search: `${createSearchParams({
          itemName: dataInformation?.itemName,
          trackId: trackId,
        })}`,
      });
    }

    // Add event listener to update the state on window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isMobile]);

  return (
    <div className="bg-GRAY111 h-[100vh] desktop:hidden">
      <div className="py-4 px-4 flex flex-col gap-3">
        <div>
          <p className="font-[700] text-2xl text-white ">INFORMATION</p>
        </div>

        <div className="flex flex-col gap-4">
          {/* Detail Item Order */}
          <div className=" flex gap-4 ">
            <div className="w-20 h-20 min-w-[5rem] min-h-[5rem]">
              <img
                src={dataInformation?.itemPic["media_url1"]}
                alt="pictureItem"
                className="w-full h-full rounded object-cover"
              />
            </div>
            <div className="flex py-1 pt-2 justify-between w-full">
              <div className="flex flex-col justify-around  w-[75%]">
                <p className="text-base font-[700] text-white truncate ">
                  {dataInformation?.itemName}
                </p>
                <div className="flex gap-1 items-center">
                  <p className="text-[0.625rem] font-[700] text-white ">
                    Option&nbsp;:
                  </p>
                  <p className="text-[0.625rem] font-[400] text-white truncate">
                    {dataInformation?.itemDesc}
                  </p>
                </div>
                <Link
                  to={`/marketplace/${dataInformation?.gameName}?gameID=${dataInformation?.gameId}&cate=All`}
                >
                  <p className="text-[0.625rem] font-[700] text-RED001 underline">
                    {dataInformation?.gameName}
                  </p>
                </Link>
              </div>
              <div className="flex items-center">
                <p className="text-base font-[700] text-white">
                  {dataInformation?.itemCount
                    ? `x${dataInformation?.itemCount}`
                    : 0}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="font-[400] text-sm text-white ">
              Copyright is reserved under the law by RETO HUB, and any related
              logos are considered trademarks, service marks, and/or registered
              trademarks of RETO HUB
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex justify-between">
              <p className="font-[500] text-sm text-GRAY920 ">Buyer Name</p>
              <p className="font-[700] text-sm text-white ">
                {dataInformation?.buyerName}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="font-[500] text-sm text-GRAY920 ">Buyer ID</p>
              <p className="font-[400] text-sm text-GRAY920 ">
                {dataInformation?.buyerId}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="font-[500] text-sm text-GRAY920 ">Seller Name</p>
              <p className="font-[700] text-sm text-white ">
                {dataInformation?.sellerName}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="font-[500] text-sm text-GRAY920 ">Seller ID</p>
              <p className="font-[400] text-sm text-GRAY920 ">
                {dataInformation?.sellerId}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="font-[500] text-sm text-GRAY920 ">Order no.</p>
              <p className="font-[400] text-sm text-GRAY920 ">
                {dataInformation?.transactionId}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="font-[500] text-sm text-GRAY920 ">Account Bank</p>
              <p className="font-[400] text-sm text-GRAY920 ">
                Retohub Banking
              </p>
            </div>
            <div className="flex justify-between">
              <p className="font-[500] text-sm text-GRAY920 ">
                Transaction no.
              </p>
              <p className="font-[400] text-sm text-GRAY920 ">
                {dataInformation?.transactionTrackId}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="font-[500] text-sm text-GRAY920 ">
                Payment gateway Fee
              </p>
              <p className="font-[400] text-sm text-GRAY920 ">
                {dataInformation?.gatewayFee}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="font-[500] text-sm text-white ">Total</p>
              <p className="font-[700] text-base text-GREEN050">
                {dataInformation?.grandTotalAmount
                  ? `${Number(
                      dataInformation?.grandTotalAmount
                    ).toLocaleString()} THB`
                  : `THB`}
              </p>
            </div>
          </div>
          <div>
            <p className="font-[400] text-xs text-GRAY920 text-center">
              {dataInformation?.dateTime}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryInformationPage;
