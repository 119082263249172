import {
  requestClient,
  requestClientPaymentAPIkey,
  requestClientPaymentAuth,
} from "./client";

export const ClientReviewRating = async (data) => {
  const res = await requestClientPaymentAPIkey.post(
    process.env.REACT_APP_COMPLETE_TRANSACTION_ENDPOINT,
    data
  );
  return res;
};

export const ClientGetReviewRating = async (userId, review, page, pageSize) => {
  let param = `?id=${userId}&page=${page}&pageSize=${pageSize}${
    review !== "all" ? `&rating=${review}` : ""
  }`;
  const res = await requestClientPaymentAuth.get(
    process.env.REACT_APP_ENDPOINT_GET_REVIEWRATING + param
  );
  return res;
};

export const ClientMyWallet = async (data) => {
  const res = await requestClientPaymentAPIkey.post(
    process.env.REACT_APP_COMPLETE_PAYMENTWALLET_ENDPOINT,
    data
  );
  return res;
};

export const ClientMailReceipt = async (data) => {
  const res = await requestClient.post(
    process.env.REACT_APP_COMPLETE_MAILRECEIPT_ENDPOINT,
    data
  );
  return res;
};
