export default function Icon_share(props) {
    return (
        <div {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 22C17.1667 22 16.4583 21.7083 15.875 21.125C15.2917 20.5417 15 19.8333 15 19C15 18.8833 15.0083 18.7623 15.025 18.637C15.0417 18.5123 15.0667 18.4 15.1 18.3L8.05 14.2C7.76667 14.45 7.45 14.6457 7.1 14.787C6.75 14.929 6.38333 15 6 15C5.16667 15 4.45833 14.7083 3.875 14.125C3.29167 13.5417 3 12.8333 3 12C3 11.1667 3.29167 10.4583 3.875 9.875C4.45833 9.29167 5.16667 9 6 9C6.38333 9 6.75 9.07067 7.1 9.212C7.45 9.354 7.76667 9.55 8.05 9.8L15.1 5.7C15.0667 5.6 15.0417 5.48767 15.025 5.363C15.0083 5.23767 15 5.11667 15 5C15 4.16667 15.2917 3.45833 15.875 2.875C16.4583 2.29167 17.1667 2 18 2C18.8333 2 19.5417 2.29167 20.125 2.875C20.7083 3.45833 21 4.16667 21 5C21 5.83333 20.7083 6.54167 20.125 7.125C19.5417 7.70833 18.8333 8 18 8C17.6167 8 17.25 7.929 16.9 7.787C16.55 7.64567 16.2333 7.45 15.95 7.2L8.9 11.3C8.93333 11.4 8.95833 11.5123 8.975 11.637C8.99167 11.7623 9 11.8833 9 12C9 12.1167 8.99167 12.2373 8.975 12.362C8.95833 12.4873 8.93333 12.6 8.9 12.7L15.95 16.8C16.2333 16.55 16.55 16.354 16.9 16.212C17.25 16.0707 17.6167 16 18 16C18.8333 16 19.5417 16.2917 20.125 16.875C20.7083 17.4583 21 18.1667 21 19C21 19.8333 20.7083 20.5417 20.125 21.125C19.5417 21.7083 18.8333 22 18 22Z" 
                    fill="#939393"
                />
            </svg>
        </div>
        
    )
}