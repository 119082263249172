import React, { useState, useEffect, useRef } from "react";
import StepProgress from "../../component/layout/walletPayment/StepProgress";
import PaymentQRCode from "../../component/layout/walletPayment/payment/PaymentQRCode";
import DetailItemOrderPayment from "../../component/layout/walletPayment/payment/DetailItemOrderPayment";
import BaseDialog from "../../component/common/BaseDialog";
import { DialogHeader, DialogBody } from "@material-tailwind/react";
import { BorderButton, SubmitButton } from "../../component/Button";
import IconClose from "../../assets/images/Icon/IconClose.svg";
import {
  useMutationGenerateQRPayment,
  useMutationGetDetailOrderNumber,
  useMutationPaymentChangeOrder,
  useMutationPaymentConfirm,
} from "../../api/mutation/checkout";
import dayjs from "dayjs";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { useMutationClientCancelOrder } from "../../api/mutation/delivery";
import useTimer from "easytimer-react-hook";
import { useDispatch } from "react-redux";
import { setDisplay } from "../../slice/goBackBtnSlice";

const PaymentPage = () => {
  const keepInterval = useRef();
  const navigate = useNavigate();
  const goBack = useDispatch();
  const [date, SetDate] = useState();
  const [timer, isTargetAchieved] = useTimer({
    countdown: true,
    startValues: { seconds: 0, minutes: 15 },
    target: { seconds: 0, minutes: 0 },
  });
  const [endTime, setEndTime] = useState(false);
  const [dataOrderItem, setDataOrderItem] = useState();
  const [openDialogCancelOrder, setOpenDialogCancelOrder] = useState(false);
  const [openDialogComplete, setOpenDialogComplete] = useState(false);
  const stepProgressName = "payment";
  const [searchParams, setSearchParams] = useSearchParams();
  const orderNumber = searchParams.get("orderNo");

  //   QR Payment
  const [QRCode, setQRCode] = useState("");
  const [transactionRef, setTransactionRef] = useState();
  const [transactionId, setTransactionId] = useState();
  const [transactionTrackId, setTransactionTrackId] = useState();
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const getDate = () => {
    const day = dayjs();
    const timeDay = day.format("YYYY-MM-DD");
    SetDate(timeDay);
  };

  //   mutateGetDetailOrderNumber
  const {
    mutate: mutateGetDetailOrderNumber,
    isSuccess: isSuccessGetDetailOrderNumber,
  } = useMutationGetDetailOrderNumber({
    onSuccess: (resp) => {
      setDataOrderItem(resp?.data?.data);
      const transactionTrackId = resp?.data?.data?.transactionTrackId;
      const item_id = resp?.data?.data?.items[0]?.item_id;
      const seller_id = resp?.data?.data?.items[0]?.seller_id;
      const product_count = resp?.data?.data?.items[0]?.product_count;
      const amount = resp?.data?.data?.items[0]?.amount;
      const subtotalAmount = resp?.data?.data?.subtotalAmount;
      const subtotalFee = resp?.data?.data?.subtotalFee;

      const dataGenerateQR = {
        transactionTrackId: transactionTrackId ? transactionTrackId : "",
        orderId: orderNumber,
        items: [
          {
            item_id: item_id,
            seller_id: seller_id,
            product_count: product_count,
            amount: amount,
          },
        ],
        subtotalAmount: subtotalAmount,
        subtotalFee: subtotalFee,
        subtotalVatAmount: "28.01",
        ref1: "REFERENCE1",
        ref2: "REFERENCE2",
        ref3: 1234,
      };
      getDate();
      mutateGenerateQRPayment(dataGenerateQR);
    },
    onError: (e) => {
      if (
        e?.response?.data?.message === "Invalid Buyer" ||
        e?.response?.data?.message === "Unauthorized"
      ) {
        navigate("/", { replace: true });
      }
    },
  });

  //   mutateGenerateQRPayment
  const {
    mutate: mutateGenerateQRPayment,
    isSuccess: isSuccessGenerateQRPayment,
  } = useMutationGenerateQRPayment({
    onSuccess: (resp) => {
      setEndTime(false);
      const respQR = resp?.data?.data;
      setQRCode(respQR[`qrImage`]);
      setTransactionRef(respQR[`transactionRef`]);
      setTransactionId(respQR[`transactionId`]);
      setTransactionTrackId(respQR[`transactionTrackId`]);
      timer.start();
    },
    onError: (err) => {
      if (err?.response?.data?.message === "In process of delivery") {
        setOpenDialogComplete(true);
      }
      if (err?.response?.data?.message === "Unauthorized") {
        navigate("/", { replace: true });
      }
    },
  });

  //  mutateCancelOrder
  const { mutate: mutateCancelOrder, isSuccess: isSuccessCancelOrder } =
    useMutationClientCancelOrder({
      onSuccess: (resp) => {
        navigate("/", { replace: true });
      },
      onError: () => {},
    });

  //  mutateCancelOrder
  const { mutate: mutateChangeOrder, isSuccess: isSuccessChangeOrder } =
    useMutationPaymentChangeOrder({
      onSuccess: (resp) => {
        const dataConfirm = {
          reasonId: 0,
          comment: "Cancel order Payment Page",
          transactionTrackId: transactionTrackId,
        };

        mutateChangeGateway(dataConfirm);
      },
      onError: () => {},
    });

  //  mutateChangeGateway
  const { mutate: mutateChangeGateway, isSuccess: isSuccessChangeGateway } =
    useMutationClientCancelOrder({
      onSuccess: (resp) => {
        navigate("/cartorder", { replace: true });
      },
      onError: () => {},
    });

  //  mutatePaymentConfirm
  const { mutate: mutatePaymentConfirm, isSuccess: isSuccessPaymentConfirm } =
    useMutationPaymentConfirm({
      onSuccess: (resp) => {
        clearInterval(keepInterval.current);
        setOpenDialogComplete(!openDialogComplete);
        setPaymentSuccess(true);
      },
      onError: (err) => {},
    });

  useEffect(() => {
    if (orderNumber) {
      mutateGetDetailOrderNumber({
        orderId: orderNumber,
      });
    } else {
      navigate("/", { replace: true });
    }
  }, [mutateGetDetailOrderNumber, orderNumber]);

  //---- call PaymentConfirm
  useEffect(() => {
    const dataPaymentConfirm = {
      transactionRef: transactionRef,
      transactionDate: date,
    };
    if (keepInterval.current) {
      clearInterval(keepInterval.current);
    }
    keepInterval.current = setInterval(function () {
      mutatePaymentConfirm(dataPaymentConfirm);
      if (openDialogComplete) {
        clearInterval(keepInterval.current);
        timer.stop();
      }
    }, 2000);
    if (paymentSuccess) {
      clearInterval(keepInterval.current);
    }
    return () => {
      clearInterval(keepInterval.current);
    };
  }, [
    date,
    mutatePaymentConfirm,
    openDialogComplete,
    transactionRef,
    timer,
    paymentSuccess,
  ]);

  useEffect(() => {
    goBack(setDisplay(false));
  }, []);

  //   handleOpenDialogCancelOrder
  const handleOpenDialogCancelOrder = (e) => {
    if (paymentSuccess === false) {
      setOpenDialogCancelOrder(!openDialogCancelOrder);
    }
  };

  const handleOpenDialogComplete = (e) => {
    e.preventDefault();
    navigate(
      {
        pathname: "/delivery",
        search: `${createSearchParams({
          itemName: dataOrderItem?.items[0]?.item_name,
          trackId: transactionTrackId,
        })}`,
      },
      { replace: true }
    );
  };

  // onClickRecode
  const onClickRecode = () => {
    const item_id = dataOrderItem?.items[0]?.item_id;
    const seller_id = dataOrderItem?.items[0]?.seller_id;
    const product_count = dataOrderItem?.items[0]?.product_count;
    const amount = dataOrderItem?.items[0]?.amount;
    const subtotalAmount = dataOrderItem?.subtotalAmount;
    const subtotalFee = dataOrderItem?.subtotalFee;
    const dataGenerateQR = {
      transactionTrackId: transactionTrackId ? transactionTrackId : "",
      orderId: orderNumber,
      items: [
        {
          item_id: item_id,
          seller_id: seller_id,
          product_count: product_count,
          amount: amount,
        },
      ],
      subtotalAmount: subtotalAmount,
      subtotalFee: subtotalFee,
      subtotalVatAmount: "28.01",
      ref1: "REFERENCE1",
      ref2: "REFERENCE2",
      ref3: 1234,
    };
    mutateGenerateQRPayment(dataGenerateQR);
    setEndTime(true);
    timer.reset();
  };

  // onClickConfirmCancelOrder
  const onClickConfirmCancelOrder = (e) => {
    e.preventDefault();
    const dataConfirm = {
      reasonId: 0,
      comment: "Cancel order Payment Page",
      transactionTrackId: transactionTrackId,
    };
    mutateCancelOrder(dataConfirm);
  };

  // onClickChangeGateway
  const onClickChangeGateway = (e) => {
    e.preventDefault();
    if (paymentSuccess === false) {
      const dataChangeOrder = {
        item_id: dataOrderItem?.items[0]?.item_id,
        product_count: dataOrderItem?.items[0]?.product_count,
      };

      mutateChangeOrder(dataChangeOrder);
    }
  };

  return (
    <div className=" bg-GRAY111 desktop:bg-GRAY710 w-[100%] mobile:min-h-[calc(100vh-4.5rem)] mobile:py-4 mobile:px-3 desktop:py-0 desktop:px-0 flex flex-col desktop:justify-between gap-4 desktop:gap-5">
      <div className="flex gap-4 items-center ">
        <StepProgress stepProgressName={stepProgressName} />
      </div>
      <div className="desktop:flex desktop:px-[17.605%] h-full gap-6 desktop:pb-60  ">
        <div className="desktop:w-[50%] desktop:bg-GRAY700 rounded h-full">
          <PaymentQRCode
            data={dataOrderItem}
            handleOpenDialogCancelOrder={handleOpenDialogCancelOrder}
            QRCode={QRCode}
            transactionId={transactionId}
            transactionRef={transactionRef}
            onClickChangeGateway={onClickChangeGateway}
            paymentSuccess={paymentSuccess}
          />
        </div>
        <div className="mobile:hidden desktop:block  desktop:w-[50%] desktop:bg-GRAY700 rounded">
          <DetailItemOrderPayment
            data={dataOrderItem}
            handleOpenDialogCancelOrder={handleOpenDialogCancelOrder}
            handleOpenDialogComplete={handleOpenDialogComplete}
            transactionId={transactionId}
            transactionRef={transactionRef}
            timer={timer}
            endTime={endTime}
            onClickRecode={onClickRecode}
            QRCode={QRCode}
            paymentSuccess={paymentSuccess}
          />
        </div>
      </div>

      {/* Dialog Cancel Order */}
      <BaseDialog
        open={openDialogCancelOrder}
        handler={handleOpenDialogCancelOrder}
      >
        <div className="w-[100%] flex flex-col gap-5">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            CANCEL CONFIRMATION
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  gap-7 p-0">
            <div className="flex justify-center">
              <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                Are you sure you want to cancel payment?
              </p>
            </div>
            <div className="flex justify-center desktop:gap-5">
              <div className="desktop:w-[40%] mobile:w-[50%] ">
                <BorderButton
                  text="cancel"
                  styleBtn="mobile:rounded-none mobile:rounded-bl desktop:rounded"
                  onClick={handleOpenDialogCancelOrder}
                />
              </div>
              <div className="desktop:w-[40%] mobile:w-[50%]">
                <SubmitButton
                  text="Confirm"
                  styleBtn="mobile:rounded-none mobile:rounded-br desktop:rounded"
                  onClick={onClickConfirmCancelOrder}
                />
              </div>
            </div>
          </DialogBody>
        </div>
        <div
          className="absolute desktop:block  mobile:hidden top-5 right-5 cursor-pointer p-2"
          onClick={handleOpenDialogCancelOrder}
        >
          <img src={IconClose} alt="closeIcon" className="w-4 h-4 " />
        </div>
      </BaseDialog>

      {/* Dialog Complete */}
      <BaseDialog open={openDialogComplete}>
        <div className="w-[100%] flex flex-col gap-5">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            SUCCESSFUL PAYMENT
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  gap-7 p-0">
            <div className="flex justify-center">
              <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                Your payment has been processed.
              </p>
            </div>
            <div className="flex justify-center desktop:gap-5">
              <div className="desktop:w-[70%] mobile:w-[100%]">
                <SubmitButton
                  text="CONTINUE"
                  styleBtn="mobile:rounded-none mobile:rounded-b desktop:rounded"
                  onClick={handleOpenDialogComplete}
                />
              </div>
            </div>
          </DialogBody>
        </div>
      </BaseDialog>
    </div>
  );
};

export default PaymentPage;
