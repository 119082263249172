import React from "react";
import IconRatingStarII from "./IconRatingStarII";

const BtnRatingStar = ({
  rating,
  onClick,
  selectFilterRating,
  quantityRating,
  style,
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex flex-col gap-2 items-center justify-center pt-1 desktop:pt-2 border cursor-pointer ${
        selectFilterRating === rating ? `border-red-800` : `border-white`
      } hover:border-red-500 rounded min-w-[7.5rem] w-[7.5rem] h-[3.125rem] ${style}`}
    >
      <IconRatingStarII rating={rating} />
      <p className="text-[0.625rem] text-white font-normal">{`(${
        quantityRating ? quantityRating : 0
      })`}</p>
    </div>
  );
};

export default BtnRatingStar;
