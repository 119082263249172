import React from "react";
import ImageNoResult from "../../assets/images/NoResultFound.svg";

const NoResult = () => {
  return (
    <div className="flex flex-col items-center mobile:gap-3 desktop:gap-6 mobile:py-6 desktop:pt-16">
      <img
        src={ImageNoResult}
        alt="ImageNoResult"
        className="desktop:w-450 mobile:w-56 desktop:h-300 mobile:h-150"
      />
      <p className="desktop:text-3xl mobile:text-sm font-[700] text-white">
        No Result Found
      </p>
    </div>
  );
};

export default NoResult;
