export const SubmitButton = ({ text, styleBtn, onClick }) => {
  return (
    <div
      className={`w-full bg-RED001 flex justify-center items-center rounded text-center  hover:bg-RED001/[0.8] desktop:h-[3.5rem] mobile:h-[3.25rem]  cursor-pointer ${styleBtn}`}
      onClick={onClick}
    >
      <p className={`uppercase   font-[700] text-base text-white`}>{text}</p>
    </div>
  );
};

export const BorderButton = ({ text, styleBtn, onClick }) => {
  return (
    <div
      className={`w-full flex justify-center items-center desktop:border-[3px] mobile:border-2 text-center border-RED001 desktop:h-[3.5rem] mobile:h-[3.25rem] rounded hover:bg-GRAY666/[0.2] cursor-pointer ${styleBtn}`}
      onClick={onClick}
    >
      <p className={`uppercase    font-[700] text-base text-white`}>{text}</p>
    </div>
  );
};

export const IconButton = ({
  text,
  styleBtn,
  styleImage,
  onClick,
  iconButton,
}) => {
  return (
    <div
      className={`flex items-center justify-center gap-3 py-4 bg-GRAY910 hover:bg-white/[0.1] rounded w-[100%] cursor-pointer ${styleBtn}`}
      onClick={onClick}
    >
      <img
        src={iconButton}
        alt="Notification"
        className={`w-6 h-6 rounded ${styleImage}`}
      />
      <p className="text-base font-[700] text-white uppercase">{text}</p>
    </div>
  );
};
