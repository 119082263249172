import React from 'react'

const security = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4841 0.98956L11.8613 3.22631C11.8613 3.22631 11.8613 3.22632 11.8613 3.22632C11.3904 3.41361 11.0705 3.86507 11.0396 4.38043L10.8207 8.0267C10.8207 8.02671 10.8207 8.02671 10.8207 8.02671C10.5728 12.1538 12.5685 16.0827 16.0049 18.2276L16.0578 18.1428L16.0049 18.2276L17.2801 19.0234C17.6916 19.2804 18.2083 19.2804 18.6198 19.0234L19.8952 18.2276L19.8952 18.2276C23.3312 16.0827 25.3271 12.1533 25.0794 8.02671L24.8604 4.38043C24.8295 3.8651 24.5096 3.41361 24.0387 3.22632L18.4159 0.989551C18.1161 0.870153 17.7838 0.870143 17.4841 0.98956ZM18.9871 16.6954L18.9871 16.6955L17.9499 17.343L16.9127 16.6955L16.9127 16.6954C14.0318 14.8974 12.3561 11.6013 12.5641 8.13699L12.4643 8.131L12.5641 8.13699L12.7651 4.78939L17.9501 2.72702L23.1347 4.78939L23.3357 8.13699C23.5437 11.6014 21.8678 14.8975 18.9871 16.6954Z" fill="white" stroke="white" stroke-width="0.2" />
            <path d="M9.48595 7.73123L9.48595 7.7312C9.50058 7.3471 9.27617 6.99367 8.924 6.85358C8.92398 6.85357 8.92396 6.85357 8.92394 6.85356L8.58809 6.71976L8.58806 6.71974C8.28573 6.5994 7.95053 6.59942 7.64802 6.71973L1.971 8.97562C1.971 8.97562 1.971 8.97562 1.97099 8.97562C1.49592 9.16436 1.17316 9.61957 1.14194 10.1389L1.24176 10.1449L1.14194 10.1389L0.920888 13.8163C0.670549 17.9784 2.68562 21.9406 6.15464 24.1036L6.20755 24.0188L6.15465 24.1037L7.44217 24.9063C7.85749 25.1652 8.37867 25.1652 8.79383 24.9063C8.79384 24.9063 8.79384 24.9063 8.79385 24.9063L10.0814 24.1037L10.0285 24.0188L10.0814 24.1036C11.6901 23.1006 12.9862 21.7105 13.8886 20.0932L13.8887 20.0931C14.0051 19.8841 14.1153 19.6715 14.2186 19.455C14.386 19.1046 14.2867 18.6888 14.0002 18.4391L14.0002 18.4391C13.5407 18.0388 12.8456 18.2383 12.5822 18.7714L12.5821 18.7716C12.5289 18.8799 12.4734 18.987 12.4158 19.0933C11.6566 20.4943 10.5487 21.6979 9.16574 22.5602L9.16573 22.5602L8.118 23.2136L7.07026 22.5602L7.07025 22.5602C4.16141 20.7467 2.46927 17.4223 2.67933 13.9278L2.57951 13.9218L2.67933 13.9278L2.88229 10.5511L8.11784 8.4707L8.28728 8.53796C8.28728 8.53796 8.28729 8.53797 8.2873 8.53797C8.85449 8.76325 9.46233 8.34673 9.48595 7.73123Z" fill="white" stroke="white" stroke-width="0.2" />
        </svg>

    )
}

export default security