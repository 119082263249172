import { Dialog, ThemeProvider } from "@material-tailwind/react";
import React from "react";

const BaseDialog = ({ children, open, handler, stylesDialog }) => {
  const theme = {
    dialog: {
      styles: {
        base: {
          backdrop: {
            blur: "!backdrop-blur-none",
          },
        },
      },
    },
  };
  return (
    <ThemeProvider value={theme}>
      <Dialog
        open={open}
        handler={handler}
        className={`flex bg-GRAY700 rounded mobile:min-w-[90%] desktop:min-w-[40%]  desktop:py-10 desktop:px-[6vw] mobile:pt-5 ${stylesDialog}`}
      >
        {children}
      </Dialog>
    </ThemeProvider>
  );
};

export default BaseDialog;
