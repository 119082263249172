
const darkmode = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21.0004C9.48333 21.0004 7.354 20.1294 5.612 18.3874C3.87067 16.6461 3 14.5171 3 12.0004C3 9.70039 3.75 7.70439 5.25 6.01239C6.75 4.32106 8.66667 3.33372 11 3.05039C11.4167 3.00039 11.7417 3.15039 11.975 3.50039C12.2083 3.85039 12.2 4.21706 11.95 4.60039C11.6667 5.03372 11.454 5.49206 11.312 5.97539C11.1707 6.45872 11.1 6.96706 11.1 7.50039C11.1 9.00039 11.625 10.2754 12.675 11.3254C13.725 12.3754 15 12.9004 16.5 12.9004C17.0167 12.9004 17.5293 12.8254 18.038 12.6754C18.546 12.5254 19 12.3171 19.4 12.0504C19.75 11.8171 20.1083 11.8044 20.475 12.0124C20.8417 12.2211 21 12.5504 20.95 13.0004C20.7167 15.3004 19.7377 17.2087 18.013 18.7254C16.2877 20.2421 14.2833 21.0004 12 21.0004ZM12 19.0004C13.4667 19.0004 14.7833 18.5964 15.95 17.7884C17.1167 16.9797 17.9667 15.9254 18.5 14.6254C18.1667 14.7087 17.8333 14.7754 17.5 14.8254C17.1667 14.8754 16.8333 14.9004 16.5 14.9004C14.45 14.9004 12.704 14.1797 11.262 12.7384C9.82067 11.2964 9.1 9.55039 9.1 7.50039C9.1 7.16706 9.125 6.83372 9.175 6.50039C9.225 6.16706 9.29167 5.83372 9.375 5.50039C8.075 6.03372 7.021 6.88372 6.213 8.05039C5.40433 9.21706 5 10.5337 5 12.0004C5 13.9337 5.68333 15.5837 7.05 16.9504C8.41667 18.3171 10.0667 19.0004 12 19.0004Z" fill="white" />
        </svg>

    )
}

export default darkmode