import React, { useState, useEffect } from "react";
import bg_defaultprofile from "../../../../../assets/images/MyWallet/bg_defaultprofile.jpg";
import IconReceipt from "../../../../../assets/images/complete/Receipt.svg";
import IconReview from "../../../../../assets/images/complete/review.svg";
import IconWallet from "../../../../../assets/images/complete/Wallet.png";
import IconDone from "../../../../../assets/images/complete/DoneIcon.png";
import { BorderButton, SubmitButton } from "../../../../Button";
import SocialShare from "../SocialShare";
import { useSelector, useDispatch } from "react-redux";
import {
  getStatusOpenDialogMyWallet,
  setOpenDialogMyWallet,
} from "../../../../../slice/myWalletSlice";
import { Link, useNavigate } from "react-router-dom";

const DetailCompleteResponseMobile = ({
  data,
  handleOpenDialogReceipt,
  handleOpenDialogReview,
  ratedReView,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openMyWalletFromReduce = useSelector(getStatusOpenDialogMyWallet);
  const isSeller = data?.tokenSeller;

  const handleOpenDialogMyWallet = () => {
    dispatch(setOpenDialogMyWallet(!openMyWalletFromReduce));
    navigate("/", { replace: true });
  };

  return (
    <div>
      <div className="desktop:hidden mobile:flex flex-col gap-4 mobile:px-1">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2  ">
            <p className="text-[5.5vw] font-[700] text-white pl-1">
              {isSeller ? "THANK YOU" : "CONGRATULATION"}
            </p>
            <div className="flex">
              <p className="text-xs font-[700] text-white pl-1">
                {data?.itemName}
              </p>
            </div>
          </div>
        </div>

        <div className="w-full h-[80vw]">
          <img
            src={
              data?.itemPic["media_url1"]
                ? data?.itemPic["media_url1"]
                : bg_defaultprofile
            }
            alt="pictureItem"
            className="w-full h-full rounded object-cover"
          />
        </div>

        <div className="flex flex-wrap gap-3 w-full">
          <div className="min-w-[8rem] grow">
            <div
              className={`flex gap-3 items-center justify-center py-3 rounded cursor-pointer bg-GRAY910 hover:bg-GRAY900/[0.5]`}
              onClick={handleOpenDialogReceipt}
            >
              <img src={IconReceipt} alt="Receipt" className={`w-5 h-5`} />
              <p className="text-base font-[700] text-white uppercase">
                Receipt
              </p>
            </div>
          </div>

          {isSeller ? (
            <div className="min-w-[8rem] grow">
              <div
                className={`flex gap-[0.625rem] items-center justify-center py-3 rounded cursor-pointer bg-GRAY910 hover:bg-GRAY900/[0.5] min-w-[8.375rem]`}
                onClick={handleOpenDialogMyWallet}
              >
                <img
                  src={IconWallet}
                  alt="Rating"
                  className={`${ratedReView ? "w-5 h-4" : "w-5 h-5"}`}
                />
                <p className={`text-base font-[700] text-white uppercase`}>
                  Wallet
                </p>
              </div>
            </div>
          ) : (
            <div className="min-w-[8rem] grow">
              <div
                className={`flex gap-3 items-center justify-center py-3 rounded cursor-pointer ${
                  ratedReView
                    ? "mobile:bg-black"
                    : "bg-GRAY910 hover:bg-GRAY900/[0.5]"
                }`}
                onClick={handleOpenDialogReview}
              >
                <img
                  src={ratedReView ? IconDone : IconReview}
                  alt="WebSite"
                  className={`${ratedReView ? "w-5 h-4" : "w-5 h-5"}`}
                />
                <p className={`text-base font-[700] text-white uppercase`}>
                  review
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="mobile:block desktop::hidden gap-6 desktop:pb-10 ">
          <SocialShare />
        </div>

        <div className="flex flex-col gap-4">
          <Link
            to={`/marketplace/${data?.gameName}?gameID=${data?.gameId}&cate=All`}
          >
            <SubmitButton text="GO TO MARKETPLACE" />
          </Link>

          <Link to="/comingsoon">
            <BorderButton
              text="GO TO INVENTORY"
              styleBtn="border-none bg-GRAY910 hover:hover:bg-white/[0.1]"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DetailCompleteResponseMobile;
