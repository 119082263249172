import React, { useState, useEffect, useRef } from "react";
import { Button } from "@material-tailwind/react";
import IconSendMessage from "../../../../assets/images/delivery/SendMessage.png";
import Defaultprofile from "../../../../assets/images/MyWallet/bg_defaultprofile.jpg";
import io from "socket.io-client";
import dayjs from "dayjs";
import Cookies from "js-cookie";

const ChatRoom = ({ openDeliveryProgress, dataChatHistory, data }) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const sellerAvailable = data?.sellerAvailable;
  const isSeller = data?.tokenSeller;
  const trackId = data?.transactionTrackId;
  const sellerIn = data?.sellerIn;
  const buyerIn = data?.buyerIn;
  // userIs
  const userType = isSeller ? "seller" : "buyer";

  const socketRef = useRef();
  const scrollRef = useRef();
  let prvSenderRef;
  const tokenSession =
    typeof window !== "undefined" ? Cookies.get("access_token") : "";

  useEffect(() => {
    setMessages(dataChatHistory);
    if (trackId && dataChatHistory) {
      const socket = io(process.env.REACT_APP_SOCKET_CHATDELIVERY_ENDPOINT, {
        transports: ["websocket"],
      });

      socketRef.current = socket;

      socket.on("connect", () => {
        const send = {
          transaction_track_id: trackId,
          type: userType,
          access_token: tokenSession,
        };
        socket.emit("room", send);
      });

      socket.on("chatmessage", (data) => {
        setMessages((prev) => {
          return [...prev, data];
        });
      });

      socket.on("message", (data) => {
        setMessages((prev) => {
          return [...prev, data];
        });
      });

      socket.on("disconnect", () => {});

      return () => {
        socket.disconnect();
      };
    }
  }, [trackId, dataChatHistory, userType, tokenSession]);

  useEffect(() => {
    scrollRef.current.scrollTop += 500000000;
  }, [messages]);

  const onSubmitChatRoom = (e) => {
    if (inputValue?.length > 0) {
      const send = {
        transaction_track_id: trackId,
        message: inputValue,
        type: userType,
        access_token: tokenSession,
      };
      socketRef.current.emit("message", send);
      setInputValue("");
    }
  };

  const handleKeyDownChatRoom = (event) => {
    if (event.key === "Enter") {
      if (inputValue?.length > 0) {
        const send = {
          transaction_track_id: trackId,
          message: inputValue,
          type: userType,
          access_token: tokenSession,
        };
        socketRef.current.emit("message", send);
        setInputValue("");
      }
    }
  };

  return (
    <div
      className={`w-full h-full flex flex-col justify-around bg-transparent gap-2`}
    >
      <div
        ref={scrollRef}
        className={`desktop:px-5 mobile:px-2 text-white flex flex-col gap-2 ${
          openDeliveryProgress ? "h-[298px]" : "h-[430px] "
        } overflow-auto`}
      >
        {messages &&
          messages?.map(
            (
              { message, type, create_date, display_name, profile_picture },
              index,
              arr
            ) => {
              const isSameAsPrevious = prvSenderRef === type;
              const isSelfMassage = userType === type;
              const date = dayjs(create_date).format("HH:mm:ss");
              prvSenderRef = type;
              return (
                <div
                  className={`flex ${
                    isSelfMassage ? "flex-row-reverse" : "flex-row"
                  }`}
                  key={type + index}
                >
                  {profile_picture === null || undefined ? (
                    isSameAsPrevious ? (
                      <div className="w-9 h-9 bg-transparent rounded-full"></div>
                    ) : (
                      <img
                        src={Defaultprofile}
                        alt="profilePicture"
                        className="w-9 h-9 rounded-full object-cover"
                      />
                    )
                  ) : isSameAsPrevious ? (
                    <div className="w-9 h-9 bg-transparent rounded-full "></div>
                  ) : (
                    <img
                      src={profile_picture}
                      alt="profilePicture"
                      className="w-9 h-9 rounded-full object-cover"
                    />
                  )}
                  <p
                    className={`desktop:text-base mobile:text-sm font-[500] text-white border-2 border-GRAY910 py-1 px-4 rounded cursor-default text-start max-w-[60%] break-words ml-${
                      isSelfMassage ? 2 : 4
                    }
                            mr-${isSelfMassage ? 4 : 2}`}
                  >
                    {message}
                  </p>
                  <div className="flex flex-col justify-center gap-1">
                    <p className="desktop:text-sm mobile:text-[0.625rem] font-[500] text-GRAY790 cursor-default">
                      {date}
                    </p>
                  </div>
                </div>
              );
            }
          )}
      </div>
      <div className="desktop:px-5">
        <div className="flex desktop:gap-[0.9vw] mobile:gap-3">
          <input
            type="text"
            value={inputValue}
            placeholder="Write a reply..."
            maxLength="500"
            className="bg-GRAY910 rounded p-[0.625rem] w-full text-white placeholder:text-GRAY780 "
            onChange={(e) => {
              const value = e.target.value;

              setInputValue(value);
            }}
            onKeyDown={handleKeyDownChatRoom}
          />
          <Button
            className="flex justify-center items-center rounded bg-RED001 w-[20%] max-w-[6.5625rem] min-w-[5rem] mobile:p-0 desktop:py-4"
            onClick={onSubmitChatRoom}
          >
            <img
              src={IconSendMessage}
              alt="SendMessage"
              className="mobile:w-5 mobile:h-4 desktop:w-6 desktop:h-5 pl-[2px]"
            />
          </Button>
        </div>
        {/* </form> */}
      </div>
    </div>
  );
};

export default ChatRoom;
