import React from "react";
import IconReceipt from "../../../../assets/images/complete/Receipt.svg";
import IconReview from "../../../../assets/images/complete/review.svg";
import IconWallet from "../../../../assets/images/complete/Wallet.png";
import IconDone from "../../../../assets/images/complete/DoneIcon.png";
import { BorderButton, SubmitButton } from "../../../Button";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const DetailOrderComplete = ({
  data,
  handleOpenDialogReceipt,
  handleOpenDialogReview,
  ratedReView,
  handleOpenDialogMyWallet,
}) => {
  const isSeller = data?.tokenSeller;

  return (
    <div>
      <div className="py-6 pb-5  px-8 flex flex-col gap-3 items-center border-b-2 border-GRAY750">
        <p className="text-2xl font-[700] text-white">
          Order No. {data?.transactionId}
        </p>
        <p className="text-base font-[400] text-GRAY920">
          {data?.txnDatetime
            ? dayjs(data?.txnDatetime).format("MMMM DD, YYYY HH:mm:ss")
            : ""}
        </p>
        <p className="text-base font-[400] text-white">
          Copyright is reserved under the law by RETO HUB, and any related logos
          are considered trademarks, service marks, and/or registered trademarks
          of RETO HUB
        </p>
      </div>

      <div className="py-5 px-10 flex flex-col gap-5 border-GRAY750">
        <div className="flex flex-wrap justify-between items-center w-full gap-2">
          <p className="text-xl font-[700] text-white">Payment Detail</p>
          <div className="flex justify-end grow">
            <div
              className={`flex gap-[0.625rem] items-center justify-center py-3 rounded min-w-[9rem] w-36 bg-GRAY910 hover:bg-GRAY900/[0.5] cursor-pointer`}
              onClick={handleOpenDialogReceipt}
            >
              <img src={IconReceipt} alt="Receipt" className={`w-5 h-5`} />
              <p className="text-base font-[700] text-white uppercase">
                Receipt
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap justify-between items-center w-full gap-2">
          <p className="text-xl font-[700] text-white">
            {isSeller ? "Go to Wallet" : "Review Order"}
          </p>

          {isSeller ? (
            <div className="flex justify-end grow">
              <div
                className={`flex gap-[0.625rem] items-center justify-center py-3 rounded cursor-pointer min-w-[9rem] w-36 bg-GRAY910 hover:bg-GRAY900/[0.5]`}
                onClick={handleOpenDialogMyWallet}
              >
                <img
                  src={IconWallet}
                  alt="Rating"
                  className={`${ratedReView ? "w-5 h-4" : "w-5 h-5"}`}
                />
                <p className={`text-base font-[700] text-white uppercase`}>
                  Wallet
                </p>
              </div>
            </div>
          ) : (
            <div className="flex justify-end grow">
              <div
                className={`flex gap-[0.625rem] items-center justify-center py-3 rounded min-w-[9rem] w-36 ${
                  ratedReView
                    ? "desktop:bg-transparent cursor-default"
                    : "bg-GRAY910 hover:bg-GRAY900/[0.5] cursor-pointer "
                }`}
                onClick={handleOpenDialogReview}
              >
                <img
                  src={ratedReView ? IconDone : IconReview}
                  alt="Rating"
                  className={`${ratedReView ? "w-5 h-4" : "w-5 h-5"}`}
                />
                <p className={`text-base font-[700] text-white uppercase`}>
                  review
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className=" py-10 px-10 flex flex-col gap-5  border-y-2 border-GRAY750">
        <div className="flex justify-between items-center">
          <p className="text-2xl font-[700] text-white">Total</p>
          <p className="text-2xl font-[700] text-GREEN050">
            {data?.grandTotalAmount
              ? `${Number(data?.grandTotalAmount).toLocaleString()} THB`
              : `THB`}
          </p>
        </div>
      </div>

      <div className=" py-5 px-9 flex flex-col gap-5">
        <Link
          to={`/marketplace/${data?.gameName}?gameID=${data?.gameId}&cate=All`}
        >
          <SubmitButton text="GO TO MARKETPLACE" />
        </Link>

        <Link to="/comingsoon">
          <BorderButton
            text="GO TO INVENTORY"
            styleBtn="border-none bg-GRAY910 hover:hover:bg-white/[0.1]"
          />
        </Link>
      </div>
    </div>
  );
};

export default DetailOrderComplete;
