import React, { useState, useEffect } from "react";
import { Pagination, Stack } from "@mui/material";
import { changePxToRem } from "../../../utils/changePixeltoRem";
import { RED001, GRAY710, GRAY930 } from "../../../theme/color";

const PaginationCheckoutProgress = ({ pageForPagination, page, setPage }) => {
  const changeToPage = (event, page) => {
    setPage(page);
  };
  return (
    <div className="flex justify-center pt-3">
      <Stack>
        <Pagination
          count={pageForPagination}
          //   size={xs: "large", sm: "medium" }
          page={page}
          onChange={changeToPage}
          sx={{
            "& .MuiPaginationItem-root": {
              color: "white",
              fontSize: { xs: changePxToRem(12), sm: changePxToRem(20) },
              fontWeight: 700,
              maxWidth: {
                xs: changePxToRem(30),
                sm: changePxToRem(40),
                lg: changePxToRem(50),
              },
              maxHeight: {
                xs: changePxToRem(30),
                sm: changePxToRem(40),
                lg: changePxToRem(50),
              },
              borderRadius: changePxToRem(50),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            "& .MuiPaginationItem-root:active": {
              borderRadius: changePxToRem(50),
              bgcolor: GRAY710,
            },
            "& .Mui-selected": {
              color: RED001,
              bgcolor: GRAY710,
            },
            "& .Mui-selected:hover": {
              bgcolor: GRAY710,
            },
            "& .MuiPaginationItem-icon": {
              bgcolor: GRAY930,
              width: {
                xs: changePxToRem(30),
                sm: changePxToRem(40),
                lg: changePxToRem(50),
              },
              height: {
                xs: changePxToRem(30),
                sm: changePxToRem(40),
                lg: changePxToRem(50),
              },
              borderRadius: changePxToRem(50),
              margin: changePxToRem(0),
            },
            "& .MuiPagination-ul": {
              gap: {
                xs: changePxToRem(0),
                sm: changePxToRem(25),
                lg: changePxToRem(40),
              },
            },
          }}
        />
      </Stack>
    </div>
  );
};

export default PaginationCheckoutProgress;
