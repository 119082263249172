import React, { useEffect, useState } from "react";
import { changePxToRem } from "../../../../utils/changePixeltoRem";
import {
  Grid,
  Box,
  FormControl,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Select,
} from "@mui/material";
import { GRAY910 } from "../../../../theme/color";
import {
  useMutationClientVerifyGoogleAuthSecurity,
  useMutationClientWalletUserWithdraw,
} from "../../../../api/mutation/security";
import { DialogHeader, DialogBody } from "@material-tailwind/react";
import iconSCB from "../../../../assets/images/payment/SCB.png";
import { SubmitButton } from "../../../Button";
import WithdrawConfirmation1 from "./myWalletResponseMobile/WithdrawConfirmation1";
import WithdrawConfirmation2 from "./myWalletResponseMobile/WithdrawConfirmation2";
import WithdrawConfirmation3 from "./myWalletResponseMobile/WithdrawConfirmation3";
import BaseDialog from "../../../common/BaseDialog";
import { Link, useNavigate } from "react-router-dom";
import { useMutationClientGetMyWallet } from "../../../../api/mutation/complete";
import {
  getStatusOpenDialogMyWallet,
  setOpenDialogMyWallet,
} from "../../../../slice/myWalletSlice";
import { useDispatch, useSelector } from "react-redux";

const MyWalletResponseMobile = () => {
  const [expanded, setExpanded] = useState(false);
  const [stateWallet, setStateWallet] = useState("wallet");
  const [endCode, setEndCode] = useState();
  const [myWalletData, setMyWalletData] = useState();
  const [openDialogSuccessWithdraw, setOpenDialogSuccessWithdraw] =
    useState(false);
  const [
    openDialogFailWithdrawVerifyGoogleAuth,
    setOpenDialogFailWithdrawVerifyGoogleAuth,
  ] = useState(false);
  const [
    openDialogFailWalletUserWithdraw,
    setOpenDialogFailWalletUserWithdraw,
  ] = useState(false);
  const [inputWithDrawMoney, setInputWithDrawMoney] = useState();
  const [withDrawMoneyAmount, setWithDrawMoneyAmount] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openMyWalletFromReduce = useSelector(getStatusOpenDialogMyWallet);

  const googleAuth = myWalletData?.data?.googleAuth;
  const totalValues = myWalletData
    ? Number(myWalletData?.data?.balance).toFixed(0)
    : 0;

  //   dataMyWallet
  const { mutate: mutateMyWallet, isSuccess: isSuccessMyWallet } =
    useMutationClientGetMyWallet({
      onSuccess: (resp) => {
        setMyWalletData(resp);
      },
      onError: () => {},
    });

  useEffect(() => {
    mutateMyWallet({});
  }, []);

  const {
    mutate: mutateWalletUserWithdraw,
    isSuccess: isSuccessWalletUserWithdraw,
  } = useMutationClientWalletUserWithdraw({
    onSuccess: (resp) => {
      setEndCode("");
      setOpenDialogSuccessWithdraw(!openDialogSuccessWithdraw);
    },
    onError: () => {
      setEndCode("");
      setOpenDialogFailWalletUserWithdraw(!openDialogFailWalletUserWithdraw);
    },
  });

  const {
    mutate: mutateVerifyGoogleAuthSecurity,
    isSuccess: isSuccessVerifyGoogleAuthSecurity,
  } = useMutationClientVerifyGoogleAuthSecurity({
    onSuccess: (resp) => {
      setEndCode("");
      const dataAmount = {
        amount: withDrawMoneyAmount,
      };
      mutateWalletUserWithdraw(dataAmount);
    },
    onError: () => {
      setEndCode("");
      setOpenDialogFailWithdrawVerifyGoogleAuth(
        !openDialogFailWithdrawVerifyGoogleAuth
      );
    },
  });

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeWithDrawMoney = (e) => {
    setInputWithDrawMoney(e.target.value);
    setWithDrawMoneyAmount(Number(e.target.value));
  };

  const onChangeInputCodeGoogleAuth = (e) => {
    setEndCode(e.target.value);
  };

  const onClickConfirmWithdrawStep3 = (e) => {
    e.stopPropagation();
    if (endCode) {
      const dataConfirm = {
        token: endCode,
      };
      mutateVerifyGoogleAuthSecurity(dataConfirm);
    }
  };

  const onClickSuccessWithdraw = (e) => {
    e.stopPropagation();
    setOpenDialogFailWithdrawVerifyGoogleAuth(false);
    setWithDrawMoneyAmount(0);
  };

  const onClickFailWithdraw = (e) => {
    e.stopPropagation();
    setOpenDialogFailWithdrawVerifyGoogleAuth(
      !openDialogFailWithdrawVerifyGoogleAuth
    );
  };

  const onClickFailWithdrawVerifyGoogleAuth = (e) => {
    e.stopPropagation();
    setWithDrawMoneyAmount(0);
    setInputWithDrawMoney("");
    setOpenDialogFailWithdrawVerifyGoogleAuth(
      !openDialogFailWithdrawVerifyGoogleAuth
    );
    setStateWallet("withdrawConfirmation1");
  };

  return (
    <div className="bg-GRAY111 h-full  p-4 flex flex-col gap-4">
      <div className="flex flex-col gap-3">
        <p className="text-2xl font-[700] text-white uppercase">
          {stateWallet === "wallet" ? "My Wallet" : "WITHDRAW CONFIRMATION"}
        </p>
        {stateWallet === "wallet" && (
          <p className="text-sm font-[400] text-white">
            Trust Wallet is a non-custodial digital wallet that uses hot storage
            for cryptocurrencies. It's extremely secure, free to use, and
            supports many blockchains and cryptocurrencies.
          </p>
        )}
      </div>

      <div>
        {stateWallet === "withdrawConfirmation3" ? (
          ""
        ) : (
          <div>
            <div className="flex justify-between border-b border-GRAY920 pb-4">
              <p className="text-base font-[700] text-white">Total Value :</p>
              <p className="text-base font-[700] text-GREEN050">
                {Math.ceil(totalValues).toLocaleString()} Bath
              </p>
            </div>
            <div className="flex justify-between py-4">
              <p className="text-xs font-[400] text-white">Withdraw limit :</p>
              <p className="text-xs font-[400] text-white">(20-100,000 Baht)</p>
              {/* <p className="text-base font-[700] text-white">Fee Credit :</p>
              <p className="text-base font-[700] text-white">
                {Math.ceil(feeCredit).toLocaleString()} Bath
              </p> */}
            </div>
          </div>
        )}
        <div>
          {/* state wallet select withdraw Gateway*/}
          {stateWallet === "wallet" && (
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: changePxToRem(12),
              }}
            >
              {/* Online Banking */}
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChangeAccordion("panel1")}
                sx={{
                  padding: "0",
                  borderRadius: changePxToRem(4),
                  "&.MuiAccordion-root": {
                    borderRadius: changePxToRem(4),
                    bgcolor: GRAY910,
                    "&.Mui-expanded": {
                      margin: changePxToRem(0),
                    },
                  },
                  "& .MuiAccordionSummary-root": {
                    "&.Mui-expanded": {
                      minHeight: changePxToRem(48),
                    },
                  },
                }}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    bgcolor: GRAY910,
                    padding: `${changePxToRem(0)} ${changePxToRem(12)}`,
                    borderRadius: changePxToRem(4),
                    margin: changePxToRem(0),
                    "& .MuiAccordionSummary-content": {
                      margin: `${changePxToRem(10)} ${changePxToRem(0)}`,
                      "&.Mui-expanded": {
                        margin: `${changePxToRem(10)} ${changePxToRem(0)}`,
                      },
                    },
                  }}
                >
                  <p className="text-sm font-[500] text-white">
                    Online Banking
                  </p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    bgcolor: "black",
                    padding: `${changePxToRem(0)} ${changePxToRem(12)}`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderBottomLeftRadius: changePxToRem(4),
                    borderBottomRightRadius: changePxToRem(4),
                  }}
                >
                  <Grid
                    sx={{
                      padding: `${changePxToRem(0)} ${changePxToRem(0)}`,
                      paddingTop: changePxToRem(12),
                    }}
                  >
                    {googleAuth ? (
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ minWidth: "100%" }}>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value="SCB"
                              renderValue={(filter) => (
                                <Grid
                                  sx={{
                                    display: "flex",
                                    gap: changePxToRem(10),
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={iconSCB}
                                    alt="SCBIcon"
                                    width={24}
                                    height={24}
                                  />
                                  <p className="text-[min(3.59vw,16px)] font-[400] text-white leading-none">
                                    SCB (ธนาคารไทยพาณิชย์)
                                  </p>
                                </Grid>
                              )}
                              //   onChange={handleChange}
                              sx={{
                                bgcolor: GRAY910,
                                "& .MuiOutlinedInput-input": {
                                  padding: `${changePxToRem(11)}`,
                                },
                                "& .MuiSelect-icon": {
                                  color: "white",
                                  fontSize: changePxToRem(35),
                                },
                              }}
                              MenuProps={{
                                sx: {
                                  marginTop: changePxToRem(3),
                                  "& .MuiList-root": {
                                    backgroundColor: GRAY910,
                                  },
                                  "& .MuiPopover-paper": {
                                    backgroundColor: GRAY910,
                                  },
                                },
                              }}
                            >
                              <MenuItem
                                disabled
                                value="payment"
                                sx={{
                                  fontSize: changePxToRem(16),
                                  fontWeight: 700,
                                  padding: changePxToRem(20),
                                  backgroundColor: GRAY910,
                                  "&:hover": {
                                    backgroundColor: GRAY910,
                                  },
                                }}
                              ></MenuItem>
                              {/* <MenuItem
                            value="items Qc"
                            sx={{
                              fontSize: changePxToRem(16),
                              fontWeight: 700,
                            }}
                          ></MenuItem> */}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <p className="text-base font-[400] text-white text-center">
                          Checking identity making Withdrawals
                          <br /> Mobile Phone (OTP) or Google Authenticator
                        </p>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    sx={{
                      padding: `${changePxToRem(12)} ${changePxToRem(0)}`,
                      width: "100%",
                    }}
                  >
                    {googleAuth ? (
                      <SubmitButton
                        text="Withdraw"
                        onClick={() => setStateWallet("withdrawConfirmation1")}
                      />
                    ) : (
                      <SubmitButton
                        text="GO TO security"
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(
                            setOpenDialogMyWallet(!openMyWalletFromReduce)
                          );
                          navigate("/setting/security", { replace: true });
                        }}
                      />
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>

              {/* Wallet */}
              <Accordion
                disabled
                expanded={expanded === "panel2"}
                onChange={handleChangeAccordion("panel2")}
                sx={{
                  padding: "0",
                  borderRadius: changePxToRem(4),
                  "&.MuiAccordion-root": {
                    borderRadius: changePxToRem(4),
                  },
                  "& .Mui-disabled": {
                    borderRadius: changePxToRem(4),
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <p className="text-xs font-[300] text-GRAY920">
                      Coming Soon
                    </p>
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    bgcolor: "black",
                    padding: `${changePxToRem(0)} ${changePxToRem(12)}`,
                  }}
                >
                  <p className="text-sm font-[500] text-white">Wallet</p>
                </AccordionSummary>
              </Accordion>

              {/* PayPal */}
              <Accordion
                disabled
                expanded={expanded === "panel3"}
                onChange={handleChangeAccordion("panel3")}
                sx={{
                  padding: "0",
                  borderRadius: changePxToRem(4),
                  "&.MuiAccordion-root": {
                    borderRadius: changePxToRem(4),
                  },
                  "& .Mui-disabled": {
                    borderRadius: changePxToRem(4),
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <p className="text-xs font-[300] text-GRAY920">
                      Coming Soon
                    </p>
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    bgcolor: "black",
                    padding: `${changePxToRem(0)} ${changePxToRem(12)}`,
                  }}
                >
                  <p className="text-sm font-[500] text-white">PayPal</p>
                </AccordionSummary>
              </Accordion>

              {/* Credit/Debit Card */}
              <Accordion
                disabled
                expanded={expanded === "panel4"}
                onChange={handleChangeAccordion("panel4")}
                sx={{
                  padding: "0",
                  borderRadius: changePxToRem(4),
                  "&.MuiAccordion-root": {
                    borderRadius: changePxToRem(4),
                  },
                  "& .Mui-disabled": {
                    borderRadius: changePxToRem(4),
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <p className="text-xs font-[300] text-GRAY920">
                      Coming Soon
                    </p>
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    bgcolor: "black",
                    padding: `${changePxToRem(0)} ${changePxToRem(12)}`,
                  }}
                >
                  <p className="text-sm font-[500] text-white">
                    Credit/Debit Card
                  </p>
                </AccordionSummary>
              </Accordion>

              {/* Cryptocurrency */}
              <Accordion
                disabled
                expanded={expanded === "panel5"}
                onChange={handleChangeAccordion("panel5")}
                sx={{
                  padding: "0",
                  borderRadius: changePxToRem(4),
                  "&.MuiAccordion-root": {
                    borderRadius: changePxToRem(4),
                  },
                  "& .Mui-disabled": {
                    borderRadius: changePxToRem(4),
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <p className="text-xs font-[300] text-GRAY920">
                      Coming Soon
                    </p>
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    bgcolor: "black",
                    padding: `${changePxToRem(0)} ${changePxToRem(12)}`,
                  }}
                >
                  <p className="text-sm font-[500] text-white">
                    Cryptocurrency
                  </p>
                </AccordionSummary>
              </Accordion>
            </Grid>
          )}

          {/* WithdrawConfirmation 1 */}
          {stateWallet === "withdrawConfirmation1" && (
            <WithdrawConfirmation1
              totalValues={totalValues}
              inputWithDrawMoney={inputWithDrawMoney}
              setInputWithDrawMoney={setInputWithDrawMoney}
              setWithDrawMoneyAmount={setWithDrawMoneyAmount}
              handleChangeWithDrawMoney={handleChangeWithDrawMoney}
              withDrawMoneyAmount={withDrawMoneyAmount}
              setStateWallet={setStateWallet}
            />
          )}

          {/* WithdrawConfirmation 2 */}
          {stateWallet === "withdrawConfirmation2" && (
            <WithdrawConfirmation2
              withDrawMoneyAmount={withDrawMoneyAmount}
              myWalletData={myWalletData}
              setStateWallet={setStateWallet}
            />
          )}

          {/* WithdrawConfirmation 3 */}
          {stateWallet === "withdrawConfirmation3" && (
            <WithdrawConfirmation3
              onChangeInputCodeGoogleAuth={onChangeInputCodeGoogleAuth}
              endCode={endCode}
              onClickConfirmWithdrawStep3={onClickConfirmWithdrawStep3}
            />
          )}

          {/* openPopupSuccessWithdraw */}
          <BaseDialog
            open={openDialogSuccessWithdraw}
            stylesDialog="desktop:min-w-[45%]"
          >
            <div className="w-[100%] flex flex-col desktop:gap-6 mobile:gap-3">
              <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white uppercase">
                Processing Withdrawal
              </DialogHeader>
              <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
                <div className="flex justify-center">
                  <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                    Please wait while we are processing your withdrawal
                    appoximal 3-7 days
                  </p>
                </div>
                <div className="flex justify-center desktop:gap-5">
                  <div className="desktop:w-[30%] mobile:w-[100%] ">
                    <Link to="/marketplace">
                      <SubmitButton
                        text="OK"
                        styleBtn="mobile:rounded-none mobile:rounded-b desktop:rounded"
                        onClick={onClickSuccessWithdraw}
                      />
                    </Link>
                  </div>
                </div>
              </DialogBody>
            </div>
          </BaseDialog>

          {/* openDialogFailWithdrawVerifyGoogleAuth */}
          <BaseDialog
            open={openDialogFailWithdrawVerifyGoogleAuth}
            stylesDialog="desktop:min-w-[45%]"
          >
            <div className="w-[100%] flex flex-col desktop:gap-6 mobile:gap-3">
              <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white">
                WITHDRAW FAILED!
              </DialogHeader>
              <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
                <div className="flex justify-center">
                  <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                    The Google Authenticator code is incorrect or has expired.
                  </p>
                </div>
                <div className="flex justify-center desktop:gap-5">
                  <div className="desktop:w-[30%] mobile:w-[100%] ">
                    <SubmitButton
                      text="Try again"
                      styleBtn="mobile:rounded-none mobile:rounded-b desktop:rounded"
                      onClick={onClickFailWithdraw}
                    />
                  </div>
                </div>
              </DialogBody>
            </div>
          </BaseDialog>

          {/* openDialogFailWalletUserWithdraw */}
          <BaseDialog
            open={openDialogFailWalletUserWithdraw}
            stylesDialog="desktop:min-w-[45%]"
          >
            <div className="w-[100%] flex flex-col desktop:gap-6 mobile:gap-3">
              <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white">
                WITHDRAW FAILED!
              </DialogHeader>
              <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
                <div className="flex justify-center">
                  <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                    Please check amount wallet for you
                  </p>
                </div>
                <div className="flex justify-center desktop:gap-5">
                  <div className="desktop:w-[30%] mobile:w-[100%] ">
                    <SubmitButton
                      text="Try again"
                      styleBtn="mobile:rounded-none mobile:rounded-b desktop:rounded"
                      onClick={onClickFailWithdrawVerifyGoogleAuth}
                    />
                  </div>
                </div>
              </DialogBody>
            </div>
          </BaseDialog>
        </div>
      </div>
    </div>
  );
};

export default MyWalletResponseMobile;
