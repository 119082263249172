import axios from 'axios'

export const getSimilarityItem = async(gameId, cateId, itemName, setState) => {
    await axios
        .post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_GET_SIMILARITY_ITEM,{
            game_id: gameId,
            category_id: cateId,
            item_name: itemName
        })
        .then(
            ({data}) => setState(data.data)
        )
        .catch(err => console.log(err))
}