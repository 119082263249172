import {
  requestClientPaymentAuth,
  requestClient,
  requestClientPaymentAPIkey,
} from "./client";

export const ClientGenerateQRPayment = async (data) => {
  const res = await requestClientPaymentAuth.post(
    process.env.REACT_APP_PAYMENT_QRCODE_ENDPOINT,
    data
  );
  return res;
};

export const ClientPaymentConfirm = async (data) => {
  const res = await requestClientPaymentAuth.post(
    process.env.REACT_APP_PAYMENT_CONFIRM_ENDPOINT,
    data
  );
  return res;
};

export const ClientPaymentChangeOrder = async (data) => {
  const res = await requestClientPaymentAuth.post(
    process.env.REACT_APP_PAYMENT_CHANGEORDER_ENDPOINT,
    data
  );
  return res;
};

export const ClientCheckoutProgress = async (data) => {
  const response = await requestClientPaymentAuth.post(
    process.env.REACT_APP_PAYMENT_TRANSACTIONHISTORY_ENDPOINT,
    data
  );
  return response;
};

export const ClientCheckoutProgressItemQC = async (data) => {
  const response = await requestClientPaymentAuth.post(
    process.env.REACT_APP_PAYMENT_TRANSACTIONHISTORYITEMQC_ENDPOINT,
    data
  );
  return response;
};

export const ClientCreateOrderNumber = async (data) => {
  const response = await requestClientPaymentAPIkey.post(
    process.env.REACT_APP_PAYMENT_CREATEORDERNUMBER_ENDPOINT,
    data
  );
  return response;
};

export const ClientGetDetailOrderNumber = async (data) => {
  const response = await requestClientPaymentAPIkey.post(
    process.env.REACT_APP_PAYMENT_GETDETAILORDERNUMBER_ENDPOINT,
    data
  );
  return response;
};

export const GetCheckOut = async () => {
  const response = await requestClient.get(
    process.env.REACT_APP_PAYMENT_GETITEMCHECKOUT_ENDPOINT
  );
  return response;
};

export const ClientItemCheckout = async (data) => {
  const response = await requestClient.post(
    process.env.REACT_APP_PAYMENT_INSERTITEMCHECKOUT_ENDPOINT,
    data
  );
  return response;
};
