import axios from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie";


export const uploadmultiple = (profileData, payload, profileImage, date, GENDER, selectFrameProfile, setCreateSuccess, coverPicture) => {
    let formdata = new FormData();
    if (profileImage?.length > 0) {
        formdata.append("files", profileImage[0]?.file);
    }

    if (coverPicture?.length > 0) {
        formdata.append("files", coverPicture[0]?.file);
    }
    axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_UPLOAD_MULTIPLE,
        formdata,
        {
            headers: {
                accept: "application/json",
                "Content-Type":
                    "multipart/form-data; boundary=<calculated when request is sent>",
                Authorization: `Bearer ${Cookies.get("access_token")}`
            }
        })
        .then((res) => {
            updateProfile(profileData, res.data.upload, payload, date, GENDER, selectFrameProfile, setCreateSuccess, profileImage, coverPicture)
        })
        .catch((err) => {
        })
}

export const updateProfile = async (profileData, img, payload2, date, GENDER, selectFrameProfile, setCreateSuccess, profileImage, coverPicture) => {
    const payload = {
        "display_name": payload2?.display_name ? payload2?.display_name : profileData?.display_name,
        "bio": payload2?.bio ? payload2?.bio : profileData?.bio,
        "first_name": payload2?.first_name ? payload2?.first_name : profileData?.first_name,
        "last_name": payload2?.last_name ? payload2?.last_name : profileData?.last_name,
        "birth_date": date ? dayjs(date?.$d).format("YYYY-MM-DD") : profileData?.birth_date?.slice(0, 10),
        "gender": GENDER ? GENDER : profileData?.gender,
        "address": payload2?.address ? payload2?.address : profileData?.address,
        "country": 1,
        "city": payload2?.city ? payload2?.city : profileData?.city,
        "district": payload2?.district ? payload2?.district : profileData?.district,
        "zipcode": payload2?.zipcode ? Number(payload2?.zipcode) : Number(profileData?.zipcode),
        "profile_picture": !!profileImage?.length > 0 ? img?.img1 : profileData.profile_picture,
        "frame_picture": !!selectFrameProfile ? selectFrameProfile : profileData?.frame_profile?.frame_profile_id,
        "cover_picture": !!profileImage?.length > 0 && !!coverPicture?.length > 0 ? img?.img2 ? img?.img2 : profileData?.cover_picture : !!coverPicture > 0 && !profileImage?.length ? img?.img1 : profileData?.cover_picture
    }
    await axios.patch(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_UDATEPRFILE, payload, {
        headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`
        }
    })
        .then((res) => {
            setCreateSuccess(true)
        })
        .catch((err) => {
            console.error(err);
        })
}


export const updateProfileFirstTime = async (payload, setCreateSuccess, dispatch, setLogin_success) => {
    await axios.patch(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_UDATEPRFILE, payload, {
        headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`
        }
    })
        .then((res) => {
            setCreateSuccess(true)
            dispatch(
                setLogin_success({
                    display_Name: payload?.display_name,
                    email_verification_status: true
                })
            )
        })
        .catch((err) => {
            console.error(err);
        })
}