import { useState, useEffect } from 'react'
import { Icon_views, Icon_dropdown } from '../assets/icons'
import NoResult_pic from '../assets/NoResult_pic'
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterMarletplace } from '../slice/marketplace'
import bg_dragon from '../assets/bg_dragon.png'
import { data } from 'autoprefixer'

export default function GameGrouping({
    cate,
    hold,
    value,
}) {
    const [isOpen, setIsOpen] = useState(false)
    const [mobileDisplay, setMobileDisplay] = useState()
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])
    const dispatch = useDispatch()
    const [imageError, setImageError] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const cover_picture = data.game_cover_picture ? data.game_cover_picture : undefined
    const name = searchParams.get("name");

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight])
        }

        window.addEventListener('resize', handleWindowResize)
        return () => window.removeEventListener('resize', handleWindowResize)

    }, [])

    // useEffect(() => {
    //     setIsOpen(hold === cate)
    //     console.log(windowSize[0] <= 768 && !!value[cate])
    //     setMobileDisplay(
    //         !!value[cate]
    //     )
    // }, [hold])

    const imageNotfound = (id) => {
        let newId = []
        newId.push(id)
        setImageError([...newId])
    }


    const handleDropdown = () => {
        setIsOpen(prev => !prev)
    }
    return (
        <>
            <section key={cate} onClick={windowSize[0] <= 768 ? handleDropdown : null}
                className={`relative w-full desktop:justify-start desktop:h-auto
                desktop:flex desktop:gap-x-[6.44rem] desktop:mt-10 desktop:items-start 
                desktop:bg-transparent mobile:flex mobile:${isOpen ? 'bg-black' : 'bg-GRAY910'} 
                mobile:rounded mobile:justify-center mobile:mt-4 mobile:items-center mobile:h-[3.25rem] `}
            >
                <h1 className='desktop:mt-[-.3rem] w-[3.5rem] desktop:text-2xl font-bold 
                    mobile:text-base mobile:mt-0 mobile:text-center cursor-default'>
                    {cate}
                </h1>
                <div className={value[cate] || value[name] ? 'desktop:flex flex-wrap w-full gap-y-5 justify-evenly mobile:hidden' : 'flex w-full justify-center'}>
                    {
                        value[cate]?.map((data, index) =>
                            <Link to={`/marketplace/${data.game_name}?gameID=${data.game_id}&cate=All`} key={index} className='flex w-[24.2rem] h-[13.75rem] gap-5'>
                                <Link
                                    to={`/marketplace/${data.game_name}?gameID=${data.game_id}&cate=All`}
                                    className='w-[10rem] h-[13.75rem]'
                                    style={{
                                        backgroundImage: imageError ? `url(${bg_dragon})` : '',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'start'
                                    }}
                                >
                                    {/* <img src={data.game_cover_picture}
                                    onError={imageNotfound}
                                    className={`cursor-pointer w-full h-full object-cover ${imageError ? 'flex' : ' bg-transparent z-0'}`}
                                /> */}
                                    {imageError.includes(data?.id)
                                        ? (
                                            <img src={bg_dragon}
                                                className='cursor-pointer w-full h-full object-cover bg-left'
                                            />
                                        )
                                        : (
                                            <img src={data.game_cover_picture}
                                                onError={() => imageNotfound(data.id)}
                                                className='cursor-pointer w-full h-full object-cover'
                                            />
                                        )
                                    }
                                </Link>
                                <div className='w-[12.95rem] flex flex-col flex-grow-1 ml-5 gap-y-2.5 text-GRAY920'>
                                    <Link to={`/marketplace/${data.game_name}?gameID=${data.game_id}&cate=All`} className='mt-[-.3rem] text-2xl font-bold text-white cursor-pointer'
                                        onClick={() => {
                                            // push(`/marketplace/${data.game_name}`)
                                            // getView(data?.id)
                                        }}
                                    >
                                        {data.game_name}
                                    </Link>
                                    <div className='w-full'>
                                        <Icon_views className='inline-block mb-0.5 [&>svg>path]:fill-GRAY920' />
                                        <span className='text-base font-medium ml-1.5 cursor-default'>
                                            {data.game_view_count} views
                                        </span>
                                    </div>
                                    <div className='flex flex-col gap-y-1'>
                                        {data.item_category.map((cate, index) => (
                                            <Link
                                                to={`/marketplace/${data?.game_name}?gameID=${data.game_id}&cate=${cate}`}
                                                key={index}
                                                className='text-sm font-normal cursor-pointer flex justify-between w-full'
                                            // onClick={() => dispatch(setFilterMarletplace({
                                            //     category_id: cate,
                                            //     gameId: data.game_id
                                            // }))}
                                            >
                                                {cate}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </Link>
                        ) ??
                        <div className='flex-col items-center justify-center w-full text-white desktop:flex'>
                            <NoResult_pic className='w-[25.5rem] h-[17rem]' />
                            <h1 className='text-[2rem] font-bold mt-5'>
                                No Result Found
                            </h1>
                            <span className='mt-5 text-base font-normal'>
                                Please try selecting different filters or try another
                            </span>
                            <span className='text-base font-normal'>
                                keywords for better results.
                            </span>
                        </div>
                    }
                </div>
                <Icon_dropdown className={`absolute my-auto right-3 desktop:hidden ${isOpen && 'rotate-180'}`} />
            </section>
            {windowSize[0] <= 768 && isOpen &&
                <section className='grid grid-cols-1 mt-4 gap-y-4'>
                    {value[cate]?.map((data, index) =>
                        <div key={index} className='flex w-full'>
                            <Link to={`/marketplace/${data.game_name}?gameID=${data.game_id}&cate=All`}
                                className='relative w-[7.81rem] h-[10.75rem] bg-GRAY920 rounded'>
                                <img src={data.game_cover_picture}
                                    className='absolute object-contain w-full h-full cursor-pointer'
                                />
                            </Link>
                            <div className='flex grow flex-col ml-5 gap-y-2.5 text-GRAY920'>
                                <Link to={`/marketplace/${data.game_name}?gameID=${data.game_id}&cate=All`}
                                    // to={`/marketplace/${data.game_name}`} 
                                    className='mt-[-.3rem] text-base font-bold text-white cursor-pointer'
                                    onClick={() => {
                                        // push(`/marketplace/${data.game_name}`)
                                        // getView(data?.id)
                                    }}
                                >
                                    {data.game_name}
                                </Link>
                                <div className='flex flex-col gap-y-3'>
                                    {data.item_category.map((cate, index) => (
                                        <Link
                                            // to={`/marketplace/${data?.game_name}`}
                                            to={`/marketplace/${data.game_name}?gameID=${data.game_id}&cate=All`}
                                            key={index}
                                            className='text-sm font-normal cursor-pointer flex justify-between w-full'
                                            onClick={() => {
                                                // push(`/marketplace/${data.game_name}?category=${cate}`)
                                            }}
                                        >
                                            {cate}
                                        </Link>

                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            }
            <hr className='mt-10 desktop:block border-GRAY920 last:hidden mobile:hidden' />
        </>
    )
}