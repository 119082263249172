import axios from 'axios'

export default async function getGames(setGames, setSearchName, name) {
    await axios
        .get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_GET_DATAGAMEINDEX)
        .then(res => {
            // console.log(res);

            let result = {}
            let char = ''
            if (!!name) {
                res.data.sortByName
                    .filter((game) => game?.game_name?.toLowerCase().includes(name?.toLocaleLowerCase()))
                    .map(data => {
                        char = data.game_name[0].toUpperCase()
                        if (result[char]) {
                            result[char].push(data)
                        } else {
                            result[char] = [data]
                        }
                    })
            } else
                res.data.sortByName?.map(data => {
                    char = data.game_name[0].toUpperCase()
                    if (result[char]) {
                        result[char].push(data)
                    } else {
                        result[char] = [data]
                    }
                })

            if (!!name) {
                setGames({
                    'NEW': res.data.sortByDate.filter((game) => game?.game_name?.toLowerCase().includes(name?.toLocaleLowerCase())),
                    ...result
                })
                setSearchName(res.data.sortByName
                    .filter((game) => game?.game_name?.toLowerCase().includes(name?.toLocaleLowerCase())))
            } else {
                setGames({
                    'NEW': res.data.sortByDate,
                    ...result
                })
                setSearchName(res.data.sortByName)
            }

        })
        .catch(err => console.log(err))
}