import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  TextField,
  FormControl,
  SwipeableDrawer,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import {
  RED001,
  RED002,
  RED004,
  GRAY700,
  GRAY750,
  GRAY760,
  GRAY910,
  GRAY920,
  RED003,
  GRAY100,
} from "../../../../../theme/color";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { SubmitButton, BorderButton } from "../../../../Button";
import { changePxToRem } from "../../../../../utils/changePixeltoRem";

const WithdrawConfirmation1 = ({
  totalValues,
  inputWithDrawMoney,
  setInputWithDrawMoney,
  setWithDrawMoneyAmount,
  handleChangeWithDrawMoney,
  withDrawMoneyAmount,
  setStateWallet,
}) => {
  const onClickMaxAmount = () => {
    setWithDrawMoneyAmount(totalValues);
    setInputWithDrawMoney(totalValues);
  };

  const onClickWithdraw1 = () => {
    if (
      withDrawMoneyAmount < 20 ||
      withDrawMoneyAmount > 100000 ||
      withDrawMoneyAmount > totalValues ||
      withDrawMoneyAmount === undefined
    ) {
    } else {
      setStateWallet("withdrawConfirmation2");
    }
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col bg-black rounded py-3 px-4">
        <p className="text-sm font-[400] text-YELLOW262">
          *ถอนเงินฟรี 2 สัปดาห์ ต่อครั้ง ไม่เกิน 100,000 บาท ต่อวัน
        </p>
        <div className="flex flex-wrap gap-1">
          <p className="text-sm font-[400] text-YELLOW262">
            *ระยะเวลาในการโอนเงินภายใน 7 วันทำการ
          </p>
          <p className="text-sm font-[400] text-white underline cursor-pointer">
            (อ่านเพิ่มเติม)
          </p>
        </div>
      </div>

      <Grid sx={{ position: "relative" }}>
        <DialogContent
          sx={{
            bgcolor: GRAY700,
            padding: "0",
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              gap: changePxToRem(12),
            }}
          >
            <TextField
              //   name={}
              inputProps={{
                autocomplete: "off",
              }}
              type="number"
              placeholder="ระบุจำนวนเงินที่ต้องการถอน"
              onChange={(e) => handleChangeWithDrawMoney(e)}
              onKeyDown={(e) =>
                (e.key === "e" && e.preventDefault()) ||
                (e.key === "-" && e.preventDefault()) ||
                (e.key === "+" && e.preventDefault()) ||
                (e.key === "." && e.preventDefault())
              }
              value={inputWithDrawMoney}
              sx={{
                width: "100%",
                input: {
                  position: "relative",
                  color: "white",
                  backgroundColor: GRAY910,
                  padding: `${changePxToRem(11)} ${changePxToRem(10)}`,
                  borderRadius: changePxToRem(4),
                  "&::placeholder": {
                    // <----- Add this.
                    opacity: 0.7,
                    color: GRAY920,
                  },
                },
              }}
              color="secondary"
            ></TextField>
          </DialogContentText>
        </DialogContent>
        <p
          className="text-base font-[500] text-RED001 cursor-pointer absolute top-[0.725rem] right-[4vw]"
          onClick={onClickMaxAmount}
        >
          MAX
        </p>
        <Grid
          sx={{
            display: "flex",
            gap: changePxToRem(20),
            paddingTop: changePxToRem(16),
            width: "100%",
          }}
        >
          <div className="w-[100%]">
            <SubmitButton
              type="submit"
              text="Withdraw"
              styleBtn={
                withDrawMoneyAmount < 20 ||
                withDrawMoneyAmount > 100000 ||
                withDrawMoneyAmount > totalValues ||
                withDrawMoneyAmount === undefined
                  ? "bg-RED004 hover:!bg-RED004 !cursor-default"
                  : "bg-RED001"
              }
              onClick={onClickWithdraw1}
            />
          </div>
        </Grid>
      </Grid>
      <div></div>
    </div>
  );
};

export default WithdrawConfirmation1;
