import { useState } from "react";
import {
  profile_icon,
  inventory,
  logout,
  payment,
  setting,
  support,
} from "../../assets/home/icons/index";
import { Link, useNavigate } from "react-router-dom";
import { signout } from "../../api/post/logout";
import { useDispatch } from "react-redux";
import { setLogout, deleteKYCstatus } from "../../slice/userslice";
import Cookies from "js-cookie";
import {
  IconMenuProfile,
  IconMenuInventory,
  IconMenuTransaction,
  IconMenuSupport,
  IconMenuSetting,
  IconMenuLogout,
} from "../../assets/icons/icons_ProfileMenu/iconProfileMenu";

function Profile_menu({ isProfile, setIsProfile }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [nameMenuProfileSelect, setNameMenuProfileSelect] = useState("");
  const onLogOut = (event) => {
    signout();
    window.sessionStorage.clear();
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    dispatch(setLogout());
    dispatch(deleteKYCstatus());
    localStorage.clear();
    if (Cookies.get("access_token") == null) navigate("/");
    // dispatch(setLogout())
  };

  return (
    <>
      <div
        className={`desktop:inline mobile:absolute desktop:px-0 mobile:justify-between mobile:z-20 desktop:top-0 mobile:top-[72px] mobile:items-center mobile:w-full h-full bg-[#202020] left-0 mobile:px-4 ${
          isProfile ? "block" : "hidden"
        } `}
      >
        <BtnMenuProfile
          Icon={
            <IconMenuProfile
              fill={nameMenuProfileSelect === "Profile" ? "white" : "#939393"}
            />
          }
          namBth="Profile"
          directPath="profile"
          setNameMenuProfileSelect={setNameMenuProfileSelect}
          onClick={() => {
            setIsProfile(!isProfile);
          }}
        />

        <BtnMenuProfile
          Icon={
            <IconMenuInventory
              fill={nameMenuProfileSelect === "Inventory" ? "white" : "#939393"}
            />
          }
          namBth="Inventory"
          directPath="comingsoon"
          setNameMenuProfileSelect={setNameMenuProfileSelect}
        />

        <BtnMenuProfile
          Icon={
            <IconMenuTransaction
              fill={
                nameMenuProfileSelect === "Transaction" ? "white" : "#939393"
              }
            />
          }
          namBth="Transaction"
          directPath="comingsoon"
          setNameMenuProfileSelect={setNameMenuProfileSelect}
        />

        <BtnMenuProfile
          Icon={
            <IconMenuSupport
              fill={nameMenuProfileSelect === "Support" ? "white" : "#939393"}
            />
          }
          namBth="Support"
          directPath="comingsoon"
          setNameMenuProfileSelect={setNameMenuProfileSelect}
        />

        <BtnMenuProfile
          Icon={
            <IconMenuSetting
              fill={nameMenuProfileSelect === "Setting" ? "white" : "#939393"}
            />
          }
          namBth="Setting"
          directPath="setting"
          setNameMenuProfileSelect={setNameMenuProfileSelect}
        />

        <BtnMenuProfile
          Icon={
            <IconMenuLogout
              fill={nameMenuProfileSelect === "Logout" ? "white" : "#939393"}
            />
          }
          namBth="Logout"
          directPath="comingsoon"
          setNameMenuProfileSelect={setNameMenuProfileSelect}
          onClick={onLogOut}
        />
      </div>
    </>
  );
}

export default Profile_menu;

const BtnMenuProfile = ({
  Icon,
  namBth,
  directPath,
  onClick,
  setNameMenuProfileSelect,
}) => {
  return (
    <div
      className="odd:bg-GRAY111 text-GRAY920 hover:text-white desktop:text-2xl font-medium hover:font-bold p-5 hover:ease-out hover:duration-500 "
      onMouseEnter={
        setNameMenuProfileSelect
          ? () => {
              setNameMenuProfileSelect(namBth);
            }
          : () => {}
      }
      onMouseLeave={() => {
        setNameMenuProfileSelect("");
      }}
    >
      <Link
        to={directPath}
        onClick={onClick ? onClick : () => {}}
        className="flex items-center gap-5 px-3 py-2"
      >
        <div className="min-w-[30px] min-h-[30px] flex items-center justify-center">
          {Icon && Icon}
        </div>
        <span className="font-IBMPlex uppercase lg:capitalize">{namBth}</span>
      </Link>
    </div>
  );
};
