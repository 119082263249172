import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDisplay, setUrlPath } from "../../slice/goBackBtnSlice";
import { useLocation, Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { next_home, prev_home } from "../../assets/home/icons";
import rigthArrow from "../../assets/rigth_arrow.svg";
import { getTrendingItems, getItemDetail } from "../../api";
import { useMutationClientItemCheckout } from "../../api/mutation/checkout";
import { createFavorite } from "../../api/post/marketplace";
import { getFavoriteStatus } from "../../api/post/favoriteStatus";
import {
  Icon_dropdown,
  Icon_views,
  Icon_star,
  Icon_share,
  Icon_ellipse,
  Icon_guarantee,
  Icon_thumbUp,
  Icon_fullstar,
} from "../../assets/icons";
import icon_product from "../../assets/icons/icon_product.svg";
import bg_dragon from "../../assets/bg_dragon.png";
import profile_pic from "../../assets/profile_pic.png";
import IconStarRating from "../../assets/images/IconRating/IconStarRating.svg";
import IconStarRated from "../../assets/images/IconRating/IconStarRated.svg";
import IconStarHalfRating from "../../assets/images/IconRating/IconStarHalfRating.svg";
import ItemCard from "../../component/ItemCard";
import expFormat from "../../utils/expFormat";
import { getSimilarityItem } from "../../api/post/getsSimilarityItem";
import Cookies from "js-cookie";

import { SubmitButton } from "../../component/Button";
import BaseDialog from "../../component/common/BaseDialog";
import { DialogBody, DialogHeader } from "@material-tailwind/react";

export default function ItemDetail() {
  const pathname = useLocation().pathname.split("/");
  const user = useSelector((state) => state.marketplace);
  const navigate = useNavigate();
  const [swipe, setSwipe] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const goBack = useDispatch();
  const [itemDetail, setItemDetail] = useState();
  const [similarItem, setSimilarItems] = useState();
  const [openDes, setOpenDes] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isImgErr, setIsImgErr] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });

  const accessToken = Cookies.get("access_token");

  const textItemNameRef = useRef(null);
  const textGameNameRef = useRef(null);

  const [isEllipsisGameName, setIsEllipsisGameName] = useState(false);
  const [isEllipsisItemName, setIsEllipsisItemName] = useState(false);
  const [focusGameName, setFocusGameName] = useState(false);
  const [focusItemName, setFocusItemName] = useState(false);

  const breadcrumbsLink = [
    { label: "Home", path: "/" },
    { label: "Game Index", path: "/marketplace" },
    {
      label: `${itemDetail?.game.game_name}`,
      path: `/marketplace/${itemDetail?.game.game_name}?gameID=${itemDetail?.game.game_id}&cate=All`,
    },
  ];

  const ItemCheckout = useMutationClientItemCheckout({
    onSuccess: () =>
      navigate({
        pathname: "/cartorder",
      }),
    onError: (err) => console.log(err),
  });

  const handleFav = (item_id) =>
    createFavorite(item_id, isFavorite, setIsFavorite);

  useEffect(() => {
    goBack(setDisplay(false));
    getItemDetail(pathname[2], setItemDetail);

    if (accessToken) {
      getFavoriteStatus(pathname[2], setIsFavorite);
    }
  }, [pathname[2], accessToken]);

  useEffect(() => {
    if (itemDetail) {
      getSimilarityItem(
        itemDetail.game.id,
        itemDetail.item_game_category.item_category_id,
        itemDetail.item_name,
        setSimilarItems
      );

      goBack(
        setUrlPath(
          `/marketplace/${itemDetail?.game.game_name}?gameID=${itemDetail?.game.game_id}&cate=All`
        )
      );
    }
  }, [itemDetail]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [windowSize]);

  useEffect(() => {
    const textItemDescription = textGameNameRef.current;
    const textItemName = textItemNameRef.current;

    if (textItemDescription) {
      const actualWidth = textItemDescription.clientWidth;
      const scrollWidth = textItemDescription.scrollWidth;

      setIsEllipsisGameName(scrollWidth > actualWidth);
    }

    if (textItemName) {
      const actualWidth = textItemName.clientWidth;
      const scrollWidth = textItemName.scrollWidth;

      setIsEllipsisItemName(scrollWidth > actualWidth);
    }
  }, [itemDetail, windowSize]);

  const ratingStars = (ratingScroll) =>
    [
      ...Array(Math.floor(ratingScroll)).fill(IconStarRated),
      ...(ratingScroll % 1 ? [IconStarHalfRating] : []),
      ...Array(5 - Math.ceil(ratingScroll)).fill(IconStarRating),
    ].slice(0, 5);

  const handleClickBuynow = (e) => {
    e.preventDefault();
    if (accessToken) {
      if (user?.user_id === itemDetail?.account?.id) {
        setIsOwner(user?.user_id === itemDetail.account.id);
      } else {
        ItemCheckout.mutate({ item_id: itemDetail?.item_id });
      }
    }
  };

  const handleCloseDialog = (e) => {
    e.preventDefault();
    setIsOwner(false);
  };

  const handleGoToMarketplace = (e) => {
    e.preventDefault();
    navigate(
      `/marketplace/${itemDetail?.game.game_name}?gameID=${itemDetail?.game.game_id}&cate=All`
    );
  };

  if (!itemDetail) {
    return <main className="w-full h-screen bg-GRAY650"></main>;
  }

  return (
    <main
      className="relative w-full max-w-[1597px] flex flex-col
            desktop:w-[83.18%] desktop:py-1 mobile:p-0 bg-GRAY650 "
    >
      <section className="flex-col  w-full desktop:flex mt-9 gap-9 mobile:hidden">
        <div className="flex items-start text-white w-full ">
          {breadcrumbsLink?.map((link, index) => (
            <div key={index} className="flex shrink-0 ">
              <Link to={link.path}>
                <h3 className="inline text-base font-bold underline">
                  {link.label}
                </h3>
              </Link>
              <img
                src={rigthArrow}
                alt=""
                width="8"
                height="12"
                className="inline-block mx-3"
              />
            </div>
          ))}
          <h3 className=" text-base font-bold text-[#CB8E44] inline break-all ">
            {itemDetail?.item_name ?? "game name"}
          </h3>
        </div>
        <hr className="border-b opacity-25 border-b-white" />
      </section>
      <section className="flex w-full desktop:mt-10 desktop:justify-between desktop:flex-row-reverse mobile:flex-col mobile:mt-0">
        <div
          className="flex-col mobile:hidden desktop:flex desktop:w-[43.83%] 
                    desktop:max-w-[43.75rem] desktop:h-[30.25vw] desktop:max-h-[36.25rem]"
        >
          <div className="relative w-full desktop:h-[68.95%] desktop:max-h-[25rem] mobile:h-[26.875rem]">
            <img
              src={
                isImgErr["1"] ? bg_dragon : itemDetail?.item_media.media_url1
              }
              alt=""
              onError={() => setIsImgErr((prev) => ({ ...prev, 1: true }))}
              className="object-contain w-full h-full bg-gray-800 rounded"
            />
          </div>
          <div className="mt-5 w-full h-[27.58%] desktop:flex gap-x-5 mobile:hidden">
            {[2, 3, 4].map((i) => (
              <div className="relative w-[32%] h-full bg-gray-800">
                <img
                  src={
                    isImgErr[i]
                      ? bg_dragon
                      : itemDetail?.item_media[`media_url${i}`]
                  }
                  alt=""
                  onError={() =>
                    setIsImgErr((prev) => ({ ...prev, [i]: true }))
                  }
                  className="object-contain w-full h-full bg-gray-800 rounded"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="swiper-itemDetail-mobile w-full h-[26.875rem] desktop:hidden">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            pagination={{
              type: "fraction",
            }}
            modules={[Pagination]}
            className="mySwiper w-full h-full"
          >
            {Array(4)
              .fill()
              .map((_, i) => (
                <SwiperSlide>
                  <div className="relative w-full h-full bg-gray-800">
                    <img
                      src={
                        isImgErr[i + 1]
                          ? bg_dragon
                          : itemDetail?.item_media[`media_url${i + 1}`]
                      }
                      alt=""
                      onError={() =>
                        setIsImgErr((prev) => ({ ...prev, [i + 1]: true }))
                      }
                      className="object-contain w-full h-full bg-gray-800 rounded"
                    />
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <div
          className="text-white mobile:pt-[0.56rem] mobile:px-5 mobile:w-full
                    desktop:p-0 desktop:w-[49.15%] desktop:max-w-[49.06rem]"
        >
          <div className="w-full flex items-center flex-wrap desktop:gap-3 mobile:gap-1 justify-between">
            <div
              className={`${isEllipsisGameName ? "group" : ""
                } flex items-center w-[50%] grow relative`}
              onMouseEnter={() => setFocusGameName(true)}
              onMouseLeave={() => setFocusGameName(false)}
            >
              <div className="w-7 h-7 min-w-[1.75rem] min-h-[1.75rem]">
                <img
                  src={itemDetail?.game.game_picture_icon ?? icon_product}
                  alt=""
                  className="w-full h-full object-cover rounded-[50%]"
                />
              </div>
              <span
                ref={textGameNameRef}
                className="max-w-full font-bold ml-[0.81rem] uppercase whitespace-nowrap text-ellipsis overflow-hidden
                                mobile:text-xs desktop:text-xl"
              >
                {`${itemDetail?.game.game_name} · ${itemDetail?.item_server} Server`}
              </span>

              <div
                className={`${focusGameName && isEllipsisGameName ? "flex" : "hidden"
                  } absolute z-50 desktop:top-9 mobile:top-8 max-w-full bg-[#363636] p-2 rounded transition-opacity opacity-100`}
              >
                <p
                  className={`max-w-full desktop:text-xl mobile:text-xs text-white font-normal normal-case break-words cursor-default `}
                >
                  {`${itemDetail?.game.game_name} · ${itemDetail?.item_server} Server`}
                </p>
              </div>
            </div>
            <div className="w-[26%] desktop:min-w-[11.75rem] desktop:max-w-[11.75rem] mobile:min-w-[4.7rem] mobile:max-w-[4.7rem] flex desktop:items-center gap-x-3 desktop:gap-x-6 overflow-hidden">
              <div className="flex items-center opacity-50 desktop:opacity-100">
                <Icon_views className="aspect-square w-5 desktop:w-6 flex items-center" />
                <span className="ml-2 text-base font-normal">
                  {itemDetail?.item_view_count ?? "..."}
                </span>
              </div>
              <button
                className="inline-block"
                onClick={
                  itemDetail?.item_id
                    ? () => handleFav(itemDetail.item_id)
                    : null
                }
                disabled={!accessToken}
              >
                {isFavorite ? (
                  <Icon_fullstar className="scale-[80%]" />
                ) : (
                  <Icon_star />
                )}
              </button>
              <button className="hidden desktop:inline-block">
                <Icon_share />
              </button>
              <button className="hidden desktop:inline-block">
                <Icon_ellipse />
              </button>
            </div>
          </div>

          <div
            className={`${isEllipsisItemName ? "group" : ""} relative w-full`}
            onMouseEnter={() => setFocusItemName(true)}
            onMouseLeave={() => setFocusItemName(false)}
          >
            <h1
              ref={textItemNameRef}
              className="relative w-[full] desktop:text-[2rem] font-bold desktop:mt-6 mobile:text-lg mobile:mt-[0.56rem] whitespace-nowrap text-ellipsis overflow-hidden"
            >
              {`${itemDetail?.item_name} (x${itemDetail?.item_quantity})`}
            </h1>

            <div
              className={`${focusItemName && isEllipsisItemName ? "flex" : "hidden"
                } absolute z-50 desktop:top-8 mobile:top-7 max-w-full bg-[#363636] p-2 rounded transition-opacity opacity-100`}
            >
              <p
                className={`max-w-full desktop:text-[2rem] mobile:text-lg text-white font-normal normal-case break-words cursor-default `}
              >
                {`${itemDetail?.item_name} (x${itemDetail?.item_quantity})`}
              </p>
            </div>
          </div>

          <div className="w-full flex flex-wrap desktop:gap-2 items-center desktop:mt-4 mobile:mt-2">
            <div className="flex items-center">
              <div className="desktop:w-10 desktop:h-10 mobile:w-6 mobile:h-6">
                <img
                  src={itemDetail?.account.profile_picture ?? profile_pic}
                  alt=""
                  className="w-full h-full object-cover rounded-[50%]"
                />
              </div>
              <span className="ml-2 dekstop:ml-3 text-[10px] desktop:text-base font-bold">
                {itemDetail?.account.display_name}
              </span>
              <Icon_guarantee className="ml-1.5 desktop:ml-2.5 desktop:scale-100 mobile:scale-[60%]" />
            </div>
            <div className="ml-1.5 flex gap-1.5 items-center border-r border-white/20">
              {ratingStars(itemDetail?.account.rating ?? 0).map(
                (star, index) => (
                  <img
                    key={`star-${index}`}
                    src={star}
                    alt=""
                    className="aspect-square desktop:w-6 mobile:w-[.875rem]"
                  />
                )
              )}
              <span
                className="pt-0.5 mx-2 ml-1 text-xs font-bold text-[#FFD02A]
                                desktop:text-white desktop:text-xl desktop:pt-1.5 desktop:mx-[.875rem]"
              >
                {itemDetail?.account.rating ?? 0}
              </span>
            </div>
            {/* <hr className="w-4 mx-0.5 desktop:mx-1 desktop:w-[1.75rem] rotate-90 opacity-50" /> */}
            <div className="items-center desktop:pt-1.5 whitespace-nowrap desktop:ml-3 mobile:ml-2">
              <span className="text-xs desktop:text-xl font-bold">
                {itemDetail?.account.rated_count ?? "..."} Ratings
              </span>
            </div>
          </div>
          <div className="flex justify-between mt-3.5 desktop:hidden">
            <div>
              <img
                src={icon_product}
                alt=""
                width="16"
                height="16"
                className="inline"
              />
              <span className="ml-3 text-sm font-normal text-GRAY920">
                PD{itemDetail?.item_product_id}
              </span>
            </div>
            <h1 className="font-bold">{itemDetail?.item_price} THB</h1>
          </div>
          <hr className="mt-6 border-b opacity-25 border-b-white" />
          <div className="relative desktop:mt-10 mobile:mt-4">
            <div className="justify-between w-full desktop:flex mobile:hidden">
              <div className="flex flex-col gap-y-4 desktop:w-[50.95%]">
                <div className="flex justify-between items-center flex-wrap">
                  <h1 className="text-2xl font-bold mr-4">
                    {itemDetail?.item_price} THB
                  </h1>
                  <span className="text-base font-normal">
                    End In {expFormat(itemDetail?.item_expire_date)}
                  </span>
                </div>
                <button
                  className={`text-base font-bold ${!!itemDetail ? "bg-RED001" : "bg-black"
                    } 
                    rounded-[0.25rem] py-[0.94rem] hover:bg-RED001/80 active:bg-RED001/80`}
                  onClick={handleClickBuynow}
                  disabled={!itemDetail?.item_quantity}
                >
                  {itemDetail?.item_quantity != undefined
                    ? itemDetail?.item_quantity >= 1
                      ? "BUY NOW"
                      : "OUT OF STOCK"
                    : "..."}
                </button>
                <button
                  className="text-base font-bold border rounded-[0.25rem] border-RED001 py-[0.94rem] hover:border-RED001/80"
                  onClick={handleGoToMarketplace}
                >
                  GO TO MARKETPLACE
                </button>
              </div>
              <hr className="h-[11.75rem] border-r opacity-25 border-r-white" />
              <div className="grid grid-cols-2 gap-[4.57%] desktop:w-[40%]">
                <div>
                  <h1 className="text-base font-bold">Item Per Unit</h1>
                  <span className="text-base font-normal text-GRAY920">
                    {itemDetail?.item_per_unit}
                  </span>
                </div>
                <div>
                  <h1 className="text-base font-bold">Currency</h1>
                  <span className="text-base font-normal text-GRAY920">
                    {itemDetail?.item_currency?.currency_short_name ?? "THB"}
                  </span>
                </div>
                <div>
                  <h1 className="text-base font-bold">Stock (Total Units)</h1>
                  <span className="text-base font-normal text-GRAY920">
                    {itemDetail?.item_quantity}
                  </span>
                </div>
                <div>
                  <h1 className="text-base font-bold">Min Units Per Order</h1>
                  <span className="text-base font-normal text-GRAY920">
                    {itemDetail?.item_min_unit}
                  </span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-y-2 gap-x-1 desktop:hidden">
              <div>
                <h1 className="text-sm font-bold">Quantity</h1>
                <span className="text-sm font-normal text-GRAY920">
                  {itemDetail?.item_per_unit > 1 ? "Bundles" : "Single"}
                </span>
              </div>
              <div>
                <h1 className="text-sm font-bold">Genre</h1>
                <span className="text-sm font-normal text-GRAY920">
                  {itemDetail?.game.game_genre}
                </span>
              </div>
              <div>
                <h1 className="text-sm font-bold">Region</h1>
                <span className="text-sm font-normal text-GRAY920 break-words">
                  {itemDetail?.item_server}
                </span>
              </div>
              <div>
                <h1 className="text-sm font-bold">Product Type</h1>
                <span className="text-sm font-normal text-GRAY920">
                  {itemDetail?.item_game_category.item_category_name}
                </span>
              </div>
            </div>
            <hr className="mt-4 border border-white opacity-25" />
            <div className="mt-4">
              <div
                className="flex items-center justify-between"
                onClick={
                  windowSize[0] > 786 ? null : () => setOpenDes((prev) => !prev)
                }
              >
                <span className="font-bold uppercase desktop:text-xl mobile:text-sm ">
                  Description
                </span>
                <Icon_dropdown
                  className={`desktop:hidden ${openDes ? "rotate-180" : ""}`}
                />
              </div>
              <p
                className={
                  !openDes
                    ? "desktop:block mt-3 text-base font-normal mobile:hidden break-words"
                    : "font-normal text-xs mt-1.5 break-words"
                }
              >
                {itemDetail?.item_description}
              </p>
              <hr className="mt-4 border-b opacity-25 border-b-white desktop:hidden" />
            </div>
          </div>
        </div>
        <div className={`sticky bottom-[1rem] my-4 w-full px-4 desktop:hidden`}>
          <button
            className={`w-full px-1 py-3 text-base font-bold text-white uppercase rounded 
                        ${!!itemDetail?.item_quantity ? "bg-RED001" : "bg-black"
              } active:bg-RED001/80`}
            onClick={handleClickBuynow}
            disabled={!itemDetail?.item_quantity}
          >
            {itemDetail?.item_quantity != undefined
              ? itemDetail?.item_quantity >= 1
                ? "Buy now"
                : "Out of stock"
              : "..."}
          </button>
        </div>
      </section>
      <section className="desktop:block h-[28.68rem] text-white mt-5 uppercase mobile:hidden">
        {similarItem ? (
          <>
            <h1 className="text-2xl font-bold">Similar Items</h1>
            <div className="relative w-full mt-5 gap-x-6">
              <button
                className="absolute w-[3.26%] min-w-[30px] aspect-square -left-[5.76%] top-1/2 -translate-y-1/2"
                onClick={() => swipe.slidePrev()}
              >
                <img alt="" src={prev_home} />
              </button>
              <button
                className="absolute w-[3.26%] min-w-[30px] aspect-square -right-[5.76%] top-1/2 -translate-y-1/2"
                onClick={() => swipe.slideNext()}
              >
                <img alt="" src={next_home} />
              </button>
              <Swiper
                className="mySwiper"
                breakpoints={{
                  0: { slidesPerView: 2 },
                  1150: { slidesPerView: 3 },
                  1530: { slidesPerView: 4 },
                  1920: { slidesPerView: 5 },
                }}
                spaceBetween={30}
                onBeforeInit={(swiper) => setSwipe(swiper)}
                modules={[Navigation]}
              >
                {similarItem?.map((item) => (
                  <SwiperSlide key={item.id}>
                    <ItemCard
                      item={item}
                      textSize={"16px"}
                      mobileText={"10px"}
                      viewSize={"12px"}
                      priceSize={"20px"}
                      heightCard={"400px"}
                      mobileHeight={"269px"}
                      mobileWeight={"200px"}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        ) : null}
      </section>

      {/* Dialog when the user buys their own product */}
      <BaseDialog open={isOwner} stylesDialog="desktop:min-w-[45%]">
        <div className="w-[100%] flex flex-col desktop:gap-6 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            UNABLE TO PURCHASE THIS ITEM!
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
            <div className="flex justify-center">
              <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                You are the product owner.
              </p>
            </div>
            <div className="flex justify-center desktop:gap-5">
              <div className="desktop:w-[60%] mobile:w-[100%] ">
                <SubmitButton
                  text="OK"
                  styleBtn="mobile:rounded-none mobile:rounded-br desktop:rounded"
                  onClick={handleCloseDialog}
                />
              </div>
            </div>
          </DialogBody>
        </div>
      </BaseDialog>
    </main>
  );
}
