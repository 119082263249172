import React from 'react'

const dbsync = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0173 8.64609C17.9438 5.8125 15.2087 3.79688 12.0048 3.79688C8.80085 3.79688 6.06569 5.81016 4.99226 8.64375C2.97429 9.17578 1.48835 11.0203 1.50007 13.2094C1.51413 15.6562 3.41257 17.6461 5.80319 17.8594C5.91335 17.8687 6.0071 17.782 6.0071 17.6719V16.2562C6.0071 16.1625 5.93679 16.0828 5.84304 16.0711C5.21022 15.9914 4.61257 15.7148 4.15319 15.2625C3.59069 14.7117 3.28132 13.9711 3.28132 13.1859C3.28132 12.5297 3.4946 11.9109 3.89538 11.3953C4.28679 10.8938 4.83757 10.5305 5.4446 10.3711L6.33288 10.1367L6.65866 9.27656C6.86022 8.74219 7.14147 8.24062 7.49538 7.78828C7.8446 7.33828 8.25944 6.94453 8.72351 6.61641C9.68679 5.93906 10.8212 5.58047 12.0048 5.58047C13.1883 5.58047 14.3227 5.93906 15.286 6.61875C15.7524 6.94687 16.1649 7.34062 16.5141 7.79062C16.868 8.24297 17.1493 8.74453 17.3508 9.27891L17.6743 10.1367L18.5602 10.3711C19.8305 10.7086 20.7188 11.8641 20.7188 13.1836C20.7188 13.9711 20.4094 14.7094 19.8469 15.2602C19.3899 15.7102 18.7946 15.9891 18.1618 16.0687C18.068 16.0805 18.0001 16.1602 18.0001 16.2539V17.6719C18.0001 17.782 18.0962 17.8687 18.2063 17.8594C20.5899 17.6438 22.4837 15.6562 22.5001 13.2141C22.5141 11.025 21.0305 9.18047 19.0173 8.64609Z" fill="white" />
            <path d="M8.83366 15.3844C8.87585 14.5992 9.20163 13.868 9.75944 13.3078C10.3548 12.7102 11.1657 12.375 12.0094 12.375C12.8579 12.375 13.6571 12.7055 14.2594 13.3078C14.2923 13.3406 14.3227 13.3734 14.3555 13.4086L13.7696 13.868C13.7417 13.8896 13.7204 13.9186 13.7083 13.9517C13.6961 13.9849 13.6936 14.0208 13.7009 14.0553C13.7083 14.0898 13.7252 14.1216 13.7498 14.1469C13.7744 14.1722 13.8056 14.1901 13.8399 14.1984L16.1415 14.7609C16.2587 14.7891 16.3735 14.7 16.3735 14.5805L16.3852 12.2063C16.3852 12.0492 16.2071 11.9602 16.0829 12.0586L15.5391 12.4852C14.6813 11.5008 13.4204 10.8773 12.0118 10.8773C9.4946 10.8773 7.44148 12.8719 7.33835 15.3703C7.33366 15.4758 7.41804 15.5648 7.52585 15.5648H8.64851C8.74694 15.5625 8.82898 15.4852 8.83366 15.3844ZM16.4766 15.5625H15.354C15.2555 15.5625 15.1735 15.6398 15.1665 15.7406C15.1243 16.5258 14.7985 17.257 14.2407 17.8172C13.6454 18.4148 12.8344 18.75 11.9907 18.75C11.1423 18.75 10.343 18.4195 9.74069 17.8172C9.70788 17.7844 9.67741 17.7516 9.6446 17.7164L10.2305 17.257C10.2585 17.2354 10.2797 17.2064 10.2919 17.1733C10.304 17.1401 10.3066 17.1042 10.2992 17.0697C10.2919 17.0352 10.2749 17.0034 10.2503 16.9781C10.2257 16.9528 10.1945 16.9349 10.1602 16.9266L7.85866 16.3641C7.74147 16.3359 7.62663 16.425 7.62663 16.5445L7.61726 18.9211C7.61726 19.0781 7.79538 19.1672 7.9196 19.0687L8.46335 18.6422C9.32116 19.6266 10.5821 20.25 11.9907 20.25C14.5079 20.25 16.561 18.2555 16.6641 15.757C16.6688 15.6516 16.5844 15.5625 16.4766 15.5625Z" fill="white" />
        </svg>

    )
}

export default dbsync