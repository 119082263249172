import axios from "axios"
import Cookies from "js-cookie"

export const checkEmail = (formValues, setCurrentStage, setError, setAccesee_token) => {
    axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_CHECK_EMAIL, {
        email: formValues.email
    })
        .then((res) => {
            setCurrentStage("verify_email")
            const payload = {
                "account_name": formValues.email,
                "account_password": formValues.password,
            }
            createAccount(payload, setAccesee_token)
        })
        .catch((err) => {
            setError('email', {
                message: "This email has been used Please try again"
            })
        })
}


export const createAccount = async (payload, setAccess_token) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_AUTH_SIGNUP, payload)
        .then((res) => {
            sendEmail(res.data.access_token)
            setAccess_token(res.data.access_token)
        })
        .catch((err) => {
        })
}

export const sendEmail = async (Authorization) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_SEND_EMAIL, {
        "from": "noreply@mg1.gmillion.io>",
        "subject": "RetoHub verification",
        "text": "Link verification mail https://retohub-api-dev.vekin.co.th/auth/verificated-email/"
    }, {
        headers: {
            Authorization: `Bearer ${Authorization}`
        }
    })
        .then((res) => {
        })
        .catch((err) => {
        })
}

export const verificated = (JWT) => {
    axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_AUTH_VERIFY_EMAIL + `/${JWT}`)
        .then((res) => {
        })
        .catch((err) => {
        })
}