import axios from "axios";
import Cookies from "js-cookie";

export const getSoldLatest = async (setSoldLatest) => {
  await axios
    .get(
      process.env.REACT_APP_PAYMENT_ENDPOINT +
      process.env.REACT_APP_SOLD_LATEST_ENDPOINT,
      {
        headers: {
          "api-key": process.env.REACT_APP_APIKEY,
        },
      }
    )
    .then((res) => {
      setSoldLatest(res?.data?.data);
    })
    .catch((err) => { });
};

export const getHomeInfo = async (setHomeInfo) => {
  await axios
    .get(
      process.env.REACT_APP_BACKEND_ENDPOINT +
      process.env.REACT_APP_HOME_INFO_ENDPOINT
    )
    .then((res) => {
      setHomeInfo(res.data);
    })
    .catch((err) => { });
};

export const getTrendingItems = async (setTrendingItems, user_id) => {
  let param = user_id ? `?user_id=${user_id}` : "";
  await axios
    .get(
      process.env.REACT_APP_BACKEND_ENDPOINT +
      process.env.REACT_APP_ENDPOINT_GET_TRENDING_ITEM__GAME +
      param
    )
    .then((res) => {
      setTrendingItems(res.data.item)
    })
    .catch((err) => { });
};

export const getItemsTopUp = async (setItemsTopUp, item_per_page, page) => {
  await axios
    .get(
      process.env.REACT_APP_BACKEND_ENDPOINT +
      process.env.REACT_APP_ENDPOINT_ITEM_TOPUP +
      `${item_per_page}/${page}`
    )
    .then((res) => {
      setItemsTopUp(res.data);
    })
    .catch((err) => { });
};

export const getMarketItems = async (setMarketItems) => {
  await axios
    .get(
      process.env.REACT_APP_BACKEND_ENDPOINT +
      process.env.REACT_APP_HOME_MARKET_ENDPOINT
    )
    .then((res) => {
      setMarketItems(res.data.data);
    })
    .catch((err) => { });
};

export const getNotification = async (setNotice) => {
  await axios
    .get(
      process.env.REACT_APP_BACKEND_ENDPOINT +
      process.env.REACT_APP_ENDPOINT_AUTH_NOTIFICATION,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get(
            "access_token"
          )}`,
        },
      }
    )
    .then((res) => {
      setNotice(res.data);
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getUserKYCstatus = async (dispatch, getKYCstatus) => {
  await axios.get(
    process.env.REACT_APP_BACKEND_ENDPOINT +
    process.env.REACT_APP_ENDPOINT_GET_USERPROFILE,
    {
      headers: {
        Authorization: `Bearer ${Cookies.get(
          "access_token"
        )}`,
      },
    }
  )
    .then(res => {
      dispatch(
        getKYCstatus({
          user_email: res.data.account_name,
          kyc_step: res.data.kyc_step,
          kyc_step_buyer: res.data.kyc_step_buyer,
          kyc_step_seller: res.data.kyc_step_seller,
        })
      );
    })
    .catch((error) => {
      console.error(error?.response?.status);
      if (error?.response?.status === 401) {
        localStorage.clear()
      }

    })

}

