import { useEffect, useState } from "react";
// import {rejection} from "../../assets/home/index"
import {
  Home_icon,
  profile_icon,
  unactivity,
  unnotifications,
  unwallet,
  unhome,
  notifications,
  activity,
  wallet,
  time,
  setting,
  setting_g,
} from "../../assets/home/icons/index";
import Notification from "../Notification";
import Home from "../../page/Home";
import Profile from "../../page/Profile";
import Inventory from "../../page/Inventory";
import { Link } from "react-router-dom";
import MyWalletResponseMobile from "./walletPayment/MyWallet/MyWalletResponseMobile";
import TransactionPurchasedMobile from "./TransactionPurchasedMobile";
import Setting from "../../page/setting/Setting";
import { useSelector, useDispatch } from "react-redux";
import {
  getStatusOpenDialogMyWallet,
  setOpenDialogMyWallet,
} from "../../slice/myWalletSlice";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Profile_menu from "./Profile_menu";
import Profile_menu_Mobile from "./Profile_menu_Mobile";

const NavBar = ({
  setFocus,
  setFooter,
  activeNav,
  setActiveNav,
  isProfile,
  setIsProfile,
  isOpen,
  setIsOpen,
}) => {
  const dispatch = useDispatch();
  const [showNavbar, setShowNavbar] = useState(true);
  const Authorization = Cookies.get("access_token");
  const openMyWallet = useSelector(getStatusOpenDialogMyWallet);
  const locationPathName = useLocation();
  const pathnameCurrent = locationPathName.pathname.split("/")[1];

  useEffect(() => {
    if (openMyWallet) {
      setActiveNav("3");
    }
    if (openMyWallet === false && activeNav === "3") {
      setActiveNav("0");
      setFocus(true);
      setFooter(true);
    }
  }, [openMyWallet]);

  useEffect(() => {
    if (activeNav === "3") {
      dispatch(setOpenDialogMyWallet(true));
    } else {
      dispatch(setOpenDialogMyWallet(false));
    }
  }, [activeNav]);

  useEffect(() => {
    if (
      pathnameCurrent === "cartorder" ||
      pathnameCurrent === "payment" ||
      pathnameCurrent === "delivery" ||
      pathnameCurrent === "complete" ||
      pathnameCurrent === "checkoutprogress"
    ) {
      setShowNavbar(false);
    } else setShowNavbar(true)

    if (pathnameCurrent === "setting") {
      setActiveNav("4");
    } 
  }, [pathnameCurrent]);

  return (
    <div className="w-full">
      {showNavbar && (
        <div
          className={`top-18 w-full h-[54px] bg-GRAY111 desktop:hidden ${!Authorization ? "hidden" : "block"
            }`}
        >
          <div className="grid h-full grid-cols-5 mx-auto font-sm border-b-4 border-RED001">
            <Link
              to={""}
              onClick={() => {
                setActiveNav("0");
                setFocus(true);
                setFooter(true);
              }}
              className="inline-flex flex-col items-center justify-center px-5"
            >
              {activeNav === "0" ? (
                <img src={Home_icon} alt="" />
              ) : (
                <img src={unhome} alt="" />
              )}
            </Link>
            <Link
              to={""}
              type="button"
              onClick={() => {
                setActiveNav("1");
                setFocus(false);
                setFooter(false);
              }}
              className="inline-flex flex-col items-center justify-center px-5 "
            >
              {activeNav === "1" ? (
                <img src={notifications} alt="" />
              ) : (
                <img src={unnotifications} alt="" />
              )}
            </Link>
            <Link
              to={""}
              type="button"
              onClick={() => {
                setActiveNav("2");
                setFocus(false);
              }}
              className="inline-flex flex-col items-center justify-center px-5 "
            >
              {activeNav === "2" ? (
                <img src={activity} alt="" />
              ) : (
                <img src={unactivity} alt="" />
              )}
            </Link>
            <Link
              to={""}
              type="button"
              onClick={() => {
                setActiveNav("3");
                setFocus(false);
              }}
              className="inline-flex flex-col items-center justify-center px-5 "
            >
              {activeNav === "3" ? (
                <img src={wallet} alt="" />
              ) : (
                <img src={unwallet} alt="" />
              )}
            </Link>
            <Link
              to={"setting"}
              type="button"
              onClick={() => {
                setActiveNav("4");
                setFocus(true);
                setFooter(true);
              }}
              className="inline-flex flex-col items-center justify-center px-5 "
            >
              {activeNav === "4" ? (
                <img src={setting_g} alt="" />
              ) : (
                <img src={setting} alt="" />
              )}
            </Link>
          </div>
          {activeNav === "0" ? (
            <div className="overflow-auto">
              <Home />
            </div>
          ) : null}
          {activeNav === "1" ? (
            <div className="absolute w-full h-full z-10">
              <Notification />
            </div>
          ) : null}
          {activeNav === "2" ? (
            <div className="absolute w-full h-full z-10">
              <TransactionPurchasedMobile />
            </div>
          ) : null}
          {activeNav === "3" ? (
            <div className="absolute w-full h-full z-10">
              <MyWalletResponseMobile />
            </div>
          ) : null}
          
        </div>
      )}
    </div>
  );
};

export default NavBar;
