import React from "react";
import { Rating } from "@material-tailwind/react";
import RatingIcon from "../../../../assets/images/complete/RatingIcon.png";
import EmtryRatingIcon from "../../../../assets/images/complete/EmtryRatingIcon.png";

const RatingComplete = ({ setRating, rating }) => {
  return (
    <Rating
      value={rating}
      ratedIcon={
        <img
          src={RatingIcon}
          alt="RatingIcon"
          className="desktop:h-auto desktop:w-[3.5vw] mobile:h-auto mobile:w-[8.4vw]"
        />
      }
      unratedIcon={
        <img
          src={EmtryRatingIcon}
          alt="EmtryRatingIcon"
          className="desktop:h-auto desktop:w-[3.5vw] mobile:h-auto mobile:w-[8.4vw]"
        />
      }
      className="flex desktop:justify-around mobile:justify-between desktop:px-[5%] mobile:px-3"
      onChange={(value) => setRating(value)}
    />
  );
};

export default RatingComplete;
