import { useMutation } from "@tanstack/react-query";
import { deliveryApi } from "../../services";

export const useMutationClientDeliveryTransaction = ({
  onSuccess,
  onError,
}) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return deliveryApi.ClientDeliveryTransaction(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationClientDeliveryConfirm = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return deliveryApi.ClientDeliveryConfirm(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationClientChatHistory = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return deliveryApi.ClientChatHistory(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationClientGetCancelReason = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return deliveryApi.GetCancelReason(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationClientCancelOrder = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return deliveryApi.ClientCancelOrder(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};
