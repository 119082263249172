import { useState, useEffect, useRef } from "react";
import { Icon_star, Icon_views, Icon_fullstar } from "../assets/icons";
import profile_pic from "../assets/profile_pic.png";
import { useMutationClientItemCheckout } from "../api/mutation/checkout";
import { useNavigate } from "react-router-dom";
import { createFavorite } from "../api/post/marketplace";
import BaseDialog from "./common/BaseDialog";
import { DialogBody, DialogHeader } from "@material-tailwind/react";
import { SubmitButton } from "./Button";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";

const getDateEndIn = (itemExpireDate) => {
  const newItemExpireDate = new Date(itemExpireDate);
  const now = new Date();
  const timeCount = (newItemExpireDate - now) / 1000;

  const day = timeCount / (60 * 60 * 24);
  const hours = (timeCount % (60 * 60 * 24)) / (60 * 60);
  const minutes = (timeCount % (60 * 60)) / 60;
  const seconds = timeCount % 60;

  return {
    hours: Math.floor(hours),
    minutes: Math.floor(minutes),
    seconds: Math.floor(seconds),
    day: Math.floor(day),
  };
};

export default function ItemCard({
  item,
  textSize,
  heightCard,
  priceSize,
  viewSize,
  mobileHeight,
  mobileText,
  mobileWeight,
  fav,
  addFavorite,
  setAddFavorite,
  setItems,
  tab,
}) {
  const textItemDescriptionRef = useRef(null);
  const textItemNameRef = useRef(null);
  const navigate = useNavigate();
  const itemId = item?.item_id;
  const itemAccountId = item?.account?.id ?? item?.owner_id;
  const market = useSelector((state) => state.marketplace);
  const infoUser = useSelector((state) => state.user);
  const [mouseOn, setMouseOn] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const [profileErr, setProfileErr] = useState(false);
  const [isEllipsisItemDescription, setIsEllipsisItemDescription] =
    useState(false);
  const [isEllipsisItemName, setIsEllipsisItemName] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const expItem = getDateEndIn(item?.item_expire_date);
  const [favorite, setFavorite] = useState();
  const [
    openDialogTheProductIsYourOwnProduct,
    setOpenDialogTheProductIsYourOwnProduct,
  ] = useState(false);
  const [openDialogNoticeKYCstatus, setOpenDialogNoticeKYCstatus] =
    useState(false);
  const accessToken = Cookies.get("access_token");

  const { mutate: mutateItemCheckout, isSuccess: isSuccessItemcheckout } =
    useMutationClientItemCheckout({
      onSuccess: (resp) => {
        window.scrollTo(0, 0);
        navigate({
          pathname: "/cartorder",
        });
      },
      onError: () => {},
    });

  useEffect(() => {
    if (accessToken) {
      const isFavorite = item?.isFavorite;
      setFavorite(isFavorite);
    } else {
      setFavorite(false);
    }
  }, [item?.isFavorite, accessToken]);

  useEffect(() => {
    const textItemDescription = textItemDescriptionRef.current;
    const textItemName = textItemNameRef.current;

    if (textItemDescription) {
      const actualWidth = textItemDescription.clientWidth;
      const scrollWidth = textItemDescription.scrollWidth;

      setIsEllipsisItemDescription(scrollWidth > actualWidth);
    }

    if (textItemName) {
      const actualHeight = textItemName.clientHeight;
      const scrollHeight = textItemName.scrollHeight;

      if (isMobile) {
        setIsEllipsisItemName(scrollHeight >= actualHeight * 1.5);
      } else {
        setIsEllipsisItemName(scrollHeight >= actualHeight * 1.5);
      }
    }
  }, [isMobile]);

  //   WindowResize
  useEffect(() => {
    const handleWindowResize = () => {
      const isMobileScreen = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(isMobileScreen);
    };

    // Initial check when the component mounts
    handleWindowResize();

    // Add event listener to update the state on window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isMobile]);

  const onClickBuyNow = (e) => {
    e.preventDefault();
    if (accessToken) {
      if (
        expItem?.day < 0 &&
        expItem?.hours < 0 &&
        expItem?.minutes < 0 &&
        expItem?.seconds < 0
      ) {
      } else if (market?.user_id === itemAccountId) {
        setOpenDialogTheProductIsYourOwnProduct(
          !openDialogTheProductIsYourOwnProduct
        );
      } else if (!infoUser?.kyc_step_buyer) {
        setOpenDialogNoticeKYCstatus(!openDialogNoticeKYCstatus);
      } else {
        mutateItemCheckout({ item_id: itemId });
      }
    }
  };

  const onClickMyFavorite = () => {
    if (accessToken) {
      if (!!setItems) setItems([]);
      createFavorite(itemId, addFavorite, setAddFavorite);
      setFavorite(!favorite);
    }
  };

  const onClickToItemDetail = () => {
    if (
      expItem?.day < 0 &&
      expItem?.hours < 0 &&
      expItem?.minutes < 0 &&
      expItem?.seconds < 0
    ) {
    } else {
      navigate(`/item-detail/${itemId}`);
      window.scrollTo(0, 0);
    }
  };

  const onClickOpenDialogTheProductIsYourOwnProduct = () => {
    setOpenDialogTheProductIsYourOwnProduct(
      !openDialogTheProductIsYourOwnProduct
    );
  };

  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <section
      className={`inline-block desktop:w-[18.5rem] desktop:h-[${
        heightCard ?? "25rem"
      }] mobile:h-[${mobileHeight ?? "16.81rem"}] mobile:w-[${
        mobileWeight ?? "12.5rem"
      }] rounded-lg bg-GRAY910 [@media(max-width:400px)]:w-[9rem]`}
      onMouseEnter={() => setMouseOn(true)}
      onMouseLeave={() => setMouseOn(false)}
    >
      <div className="desktop:p-3 mobile:p-2 desktop:pb-2 mobile:pb-1 text-white w-full desktop:h-[20.5rem]">
        <div className="relative w-full desktop:h-[14rem] desktop:max-h-[14rem] mobile:max-h-[9.5625rem] mobile:h-[9.5625rem] overflow-hidden bg-gray-800 rounded [@media(max-width:400px)]:max-h-[8rem] [@media(max-width:400px)]:min-h-[8rem] mobile:aspect-square">
          <img
            src={!imageErr ? `${item?.item_media?.media_url1}` : ""}
            alt=""
            className={`object-cover w-full h-full bg-gray-800' cursor-pointer ${
              mouseOn ? "scale-110" : "scale-100"
            }`}
            onError={() => setImageErr(true)}
            onClick={onClickToItemDetail}
          />
        </div>
        <div className="flex items-center justify-between w-full desktop:mt-3 mobile:mt-2">
          <div
            className={`${
              isEllipsisItemName ? "group" : ""
            } flex relative w-[84%] [@media(max-width:400px)]:w-[80%]`}
          >
            <p
              ref={textItemNameRef}
              className={`desktop:text-[${textSize ?? "1rem"}] mobile:text-[${
                mobileText ?? ".625rem"
              }] font-bold cursor-default line-clamp-2 break-all w-[84%]`}
            >
              {item?.item_name}
            </p>
            <div className="absolute bottom-full w-full bg-[#363636] p-2 rounded transition-opacity opacity-0 group-hover:opacity-100 group-hover:flex">
              <p
                className={`desktop:text-[16px] mobile:text-[${mobileText}] text-white font-normal normal-case whitespace-wrap cursor-default break-all`}
              >
                {item?.item_name}
              </p>
            </div>
          </div>
          <img
            src={
              item?.game_picture_icon
                ? item?.game_picture_icon
                : item?.game?.game_picture_icon
                ? item?.game?.game_picture_icon
                : profile_pic
            }
            alt="game_picture_icon"
            className="desktop:w-9 desktop:h-9 mobile:w-6 mobile:h-6 desktop:min-w-9 desktop:min-h-9 mobile:min-w-6 mobile:min-h-6 rounded-full object-center"
            onError={() => setProfileErr(true)}
          />
        </div>
        <div
          className={`${
            isEllipsisItemDescription ? "group" : ""
          } flex relative w-full`}
        >
          <h3
            ref={textItemDescriptionRef}
            className={`overflow-hidden desktop:text-base mobile:text-[${mobileText}] ${
              item?.item_description ? "text-[#939393]" : "text-transparent"
            } font-normal normal-case whitespace-nowrap text-ellipsis cursor-default`}
          >
            {item?.item_description ? item?.item_description : "-"}
          </h3>
          <div className="absolute top-full w-full bg-[#363636] p-2 rounded transition-opacity hidden opacity-0 group-hover:opacity-100 group-hover:flex z-20">
            <p
              className={`desktop:text-[16px] mobile:text-[${mobileText}] text-white font-normal normal-case whitespace-wrap cursor-default  break-all `}
            >
              {item?.item_description}
            </p>
          </div>
        </div>
      </div>
      <div className="desktop:px-3 mobile:px-2 py-[0.625rem] border-t-[1px] border-[#111111] w-full desktop:h-[4.5rem] mobile:h-[4rem]">
        {mouseOn ? (
          <div className="flex w-full desktop:h-[3.25rem] mobile:h-full rounded ">
            <button
              className="flex desktop:w-[13.44rem] mobile:grow items-center justify-center text-base font-bold text-white bg-RED001 hover:bg-RED001/[0.8] rounded-s-[4px]"
              onClick={onClickBuyNow}
            >
              BUY NOW
            </button>
            <button
              className="flex items-center justify-center w-[3.5rem] border-l border-black bg-RED001 hover:bg-RED001/[0.8] rounded-e-[4px]"
              onClick={onClickMyFavorite}
            >
              {fav || tab === "favorite" || favorite === true ? (
                <Icon_fullstar />
              ) : (
                <Icon_star className="[&>svg]:desktop:w-8 [&>svg]:desktop:h-8 [&>svg]:mobile:w-7 [&>svg]:mobile:h-7  [&>svg>path]:fill-white" />
              )}
            </button>
          </div>
        ) : (
          <div className="flex flex-col justify-between h-full">
            <div className="flex items-center justify-between text-GRAY920">
              <div className="flex items-center">
                <div className="mobile:w-4 desktop:w-6 pb-[0.125rem]">
                  <Icon_views className="opacity-75 mobile:w-4 desktop:w-6 " />
                </div>
                <p
                  className={`desktop:ml-2 mobile:ml-1 desktop:text-[${viewSize}] mobile:text-[${mobileText}]`}
                >
                  {item?.item_view_count}
                </p>
              </div>

              <p
                className={`mobile:text-[${mobileText}] desktop:text-xs font-[400] [@media(max-width:400px)]:text-[8px]`}
              >
                {item?.item_approve_date === null || undefined
                  ? "Pending"
                  : expItem?.day <= 0 &&
                    expItem?.hours <= 0 &&
                    expItem?.minutes <= 0 &&
                    expItem?.seconds <= 0
                  ? "Expire"
                  : expItem?.day >= 2
                  ? `End in ${expItem?.day} days ${
                      expItem?.hours === 1
                        ? `${expItem?.hours} hr`
                        : `${expItem?.hours} hrs`
                    }`
                  : expItem?.day === 1
                  ? `End in ${expItem?.day} day ${
                      expItem?.hours === 1
                        ? `${expItem?.hours} hr`
                        : `${expItem?.hours} hrs`
                    }`
                  : expItem?.day < 1
                  ? `End in ${expItem?.hours} hrs ${
                      expItem?.minutes === 1
                        ? `${expItem?.minutes} min`
                        : `${expItem?.minutes} mins`
                    }`
                  : expItem?.day < 1 && expItem?.hours === 1
                  ? `End in ${expItem?.hours} hr ${
                      expItem?.minutes === 1
                        ? `${expItem?.minutes} min`
                        : `${expItem?.minutes} mins`
                    }`
                  : expItem?.day < 1 && expItem?.hours < 1
                  ? `End in ${
                      expItem?.minutes === 1
                        ? `${expItem?.minutes} min`
                        : `${expItem?.minutes} mins`
                    }`
                  : expItem?.day < 1 &&
                    expItem?.hours < 1 &&
                    expItem?.minutes === 1
                  ? `End in ${expItem?.minutes} min`
                  : expItem?.day < 1 &&
                    expItem?.hours < 1 &&
                    expItem?.minutes < 1
                  ? `End in 1 min`
                  : ""}
              </p>
            </div>
            <div className="flex justify-between gap-1 text-white items-end">
              <p
                className={`desktop:text-base font-normal mobile:text-[${viewSize}] self-end !leading-5`}
              >
                By{" "}
                <b>
                  {item?.account?.display_name
                    ? item?.account?.display_name
                    : item?.display_name}
                </b>
              </p>
              <p
                className={`desktop:text-[${priceSize}] mobile:text-[${viewSize}] font-bold self-end leading-5 text-end`}
              >
                {numberWithCommas(item?.item_price)} THB
              </p>
            </div>
          </div>
        )}
      </div>

      {/* Dialog when The product is your own product. */}
      <BaseDialog
        open={openDialogTheProductIsYourOwnProduct}
        stylesDialog="desktop:min-w-[45%]"
      >
        <div className="w-[100%] flex flex-col desktop:gap-6 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            UNABLE TO PURCHASE THIS ITEM!
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
            <div className="flex justify-center">
              <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                The product is your own product.
              </p>
            </div>
            <div className="flex justify-center desktop:gap-5">
              <div className="desktop:w-[60%] mobile:w-[100%] ">
                <SubmitButton
                  text="OK"
                  styleBtn="mobile:rounded-none mobile:rounded-br desktop:rounded"
                  onClick={onClickOpenDialogTheProductIsYourOwnProduct}
                />
              </div>
            </div>
          </DialogBody>
        </div>
      </BaseDialog>

      {/* Dialog when user is not buyer */}
      <BaseDialog
        open={openDialogNoticeKYCstatus}
        stylesDialog="desktop:min-w-[45%]"
      >
        <div className="w-[100%] flex flex-col desktop:gap-6 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            Permission was denied
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
            <div className="flex justify-center">
              <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                Please complete your verification KYC 3 step
              </p>
            </div>
            <div className="flex justify-center desktop:gap-5">
              <div className="desktop:w-[60%] mobile:w-[100%] ">
                <SubmitButton
                  text="OK"
                  styleBtn="mobile:rounded-none mobile:rounded-br desktop:rounded"
                  onClick={() => navigate("/setting/kyc")}
                />
              </div>
            </div>
          </DialogBody>
        </div>
      </BaseDialog>
    </section>
  );
}
