import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { icon_dropdown_createorder } from "../../assets/createOrder"
import DownArrow from "../../assets/DownArrow"



export const Checkbox = ({ filter, value, setValue }) => {

    const handleOnchange = (val) => {
        let newVal = value
        let findVal = newVal.findIndex(x => x === val)
        if (findVal >= 0) {
            newVal.splice(findVal, 1)
            setValue([...newVal])
        } else setValue([...newVal, val])
    }

    return (
        <div className="flex flex-col gap-2 text-white">
            <div className="w-full desktop:h-[12.5rem] mobile:h-fit flex flex-col gap-4   ">
                <div className="flex justify-between pr-[14px] mobile:bg-gray-900 desktop:bg-transparent mobile:px-3 mobile:py-2 desktop:p-0">
                    <p className="text-[20px] font-bold uppercase">{filter.filter_name}</p>
                    <img src={icon_dropdown_createorder} alt="icon_dropdown_createorder" className="desktop:flex mobile:hidden" />
                </div>
                <div className="px-[18px] gap-4 flex flex-col desktop:overflow-auto">
                    {filter?.filter_value.map((filter_value, index) => (
                        <div className="flex justify-between items-center cursor-pointer" key={index} onClick={() => handleOnchange(filter_value)}>
                            <p className="text-base">{filter_value}</p>
                            <div className="w-[17.5px] h-[17.5px] rounded border-[2.5px] border-[#BB213D] flex justify-center items-center" >
                                {value.includes(filter_value) && (
                                    <div className="w-[7.5px] h-[7.5px] bg-[#BB213D]"></div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export const Server = ({ filter, item_server, setItem_server }) => {
    const handleOnchange = (val) => {
        // setValue([...value, val])
        if (item_server === val) {
            setItem_server(null)
        } else setItem_server(val)
    }
    return (
        <div className="w-full desktop:h-[12.5rem] flex flex-col gap-4   desktop:overflow-auto text-white ">
            <div className="flex justify-between pr-[14px] mobile:bg-gray-900 desktop:bg-transparent mobile:px-3 mobile:py-2 desktop:p-0">
                <p className="text-[20px] font-bold uppercase">{filter.filter_name}</p>
                <img src={icon_dropdown_createorder} alt="icon_dropdown_createorder" className="desktop:flex mobile:hidden" />
            </div>
            <div className="px-[18px] gap-4 flex flex-col">
                {filter?.filter_value.map((filter_value, index) => (
                    <div className="flex justify-between items-center cursor-pointer" key={index}>
                        <p className="text-base">{filter_value}</p>
                        <div className="w-[17.5px] h-[17.5px] rounded border-[2.5px] border-[#BB213D] flex justify-center items-center" onClick={() => handleOnchange(filter_value)}>
                            {item_server === filter_value && (
                                <div className="w-[7.5px] h-[7.5px] bg-[#BB213D]"></div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const AnotherFilter = ({ filter }) => {
    return (
        <div className="flex flex-col gap-2">
            <div className="w-full desktop:h-[12.5rem] flex flex-col gap-4   overflow-auto">
                <div className="flex justify-between pr-[14px]">
                    <p className="text-[20px] font-bold uppercase">{filter.filter_name}</p>
                    <img src={icon_dropdown_createorder} alt="icon_dropdown_createorder" />
                </div>
                <div className="px-[18px] gap-4 flex flex-col">
                    {filter?.filter_value.map((filter_value, index) => (
                        <div className="flex justify-between items-center" key={index}>
                            <p className="text-base">{filter_value}</p>
                            <div className="w-[17.5px] h-[17.5px] rounded border-[2.5px] border-[#BB213D] flex justify-center items-center">
                                <div className="w-[7.5px] h-[7.5px] bg-[#BB213D]"></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}

export const Price = ({
    getItemFilter_Favorite,
    page,
    itemPerPage,
    items,
    setItems,
    setFavorite_item,
    setMaxPage,
    setItemQuantity,
    payload,
    market,
    item_name,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    value,
    item_server,
    setHitWord,
    countFilterActive
}) => {

    useEffect(() => {

    }, [items])


    return (
        <div className="gap-4 font-IBMPlex flex flex-col">
            <div className="flex gap-4 flex-col px-[15px] ">
                <p className="text-[20px] font-bold text-white">PRICE</p>
                <div className="desktop:max-w-full desktop:w-full flex items-center desktop:max-h-[2.5rem] desktop:h-[2.5rem] justify-between">
                    <div className="desktop:max-w-[4.5rem] desktop:w-[4.5rem] desktop:max-h-[2.5rem] h-[2.5rem] rounded bg-[#282828] flex items-center justify-between p-3">
                        <p className="uppercase font-bold text-base">THB</p>
                        <DownArrow />
                    </div>
                    <input
                        value={minPrice ?? ""}
                        type="number"
                        className="focus:outline-none desktop:max-w-[5.125] desktop:w-[5.125rem] desktop:max-h-[2.5rem] desktop:h-[2.5rem] bg-[#282828] flex justify-center items-center text-center text-white placeholder:text-[#939393]"
                        placeholder="Min"
                        onChange={(e) => setMinPrice(e.target.value.slice(0, 16))}
                        onKeyDown={(e) =>
                            (e.key === "e" && e.preventDefault()) ||
                            (e.key === "-" && e.preventDefault()) ||
                            (e.key === "+" && e.preventDefault()) ||
                            (e.key === "." && e.preventDefault())
                        }
                    />
                    <p className="text-white ">To</p>
                    <input
                        value={maxPrice ?? ''}
                        type="number"
                        className="focus:outline-none desktop:max-w-[5.125] desktop:w-[5.125rem] desktop:max-h-[2.5rem] desktop:h-[2.5rem] bg-[#282828] flex justify-center items-center text-center text-white placeholder:text-[#939393]"
                        placeholder="Max"
                        onChange={(e) => setMaxPrice(e.target.value.slice(0, 16))}
                        onKeyDown={(e) =>
                            (e.key === "e" && e.preventDefault()) ||
                            (e.key === "-" && e.preventDefault()) ||
                            (e.key === "+" && e.preventDefault()) ||
                            (e.key === "." && e.preventDefault())
                        }
                    />
                </div>
            </div>
            <button
                className="rounded text-white uppercase font-bold text-base bg-[#282828]  hover:bg-GRAY666/[0.2] desktop:max-h-[3rem] desktop:h-[3rem] desktop:max-w-full desktop:w-full"
                onClick={() => {
                    const timer = setTimeout(() => {
                        countFilterActive()
                        getItemFilter_Favorite(page, itemPerPage, [], setItems, setFavorite_item, setMaxPage, setItemQuantity, payload, market, minPrice, maxPrice, item_name, value, item_server)
                        setHitWord([item_name, item_server, ...value])
                    }, 300)
                    return () => clearTimeout(timer)
                }}
            >
                apply
            </button>
        </div>
    )
}