import { useState } from "react";
import Footer from "./Footer";
import {
  profile_icon,
  inventory,
  logout,
  payment,
  setting,
  support,
  left_arrow,
} from "../../assets/home/icons/index";
import {
  Grid,
  Box,
  TextField,
  FormControl,
  SwipeableDrawer,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import Profile from "../../page/Profile";
import { Link, useNavigate } from "react-router-dom";
import { signout } from "../../api/post/logout";
import { useDispatch } from "react-redux";
import { setLogout, deleteKYCstatus } from "../../slice/userslice";
import Cookies from "js-cookie";
import { GRAY111, GRAY700 } from "../../theme/color";
import { changePxToRem } from "../../utils/changePixeltoRem";
import Retohub from "../../assets/RetoHub.png";

function Profile_menu_Mobile({
  isProfile,
  setIsProfile,
  setActiveNav,
  setIsOpen,
  setFocus,
  openMenuIconAvatar,
  setOpenMenuIconAvatar,
}) {
  const anchor = "right";
  const navigate = useNavigate();
  const [clickMenu, setClickMenu] = useState(false);
  const dispatch = useDispatch();
  const onLogOut = (event) => {
    setOpenMenuIconAvatar(false);
    signout();
    window.sessionStorage.clear();
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    dispatch(setLogout());
    dispatch(deleteKYCstatus());
    if (Cookies.get("access_token") == null) navigate("/");
    // dispatch(setLogout())
  };

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={openMenuIconAvatar}
      onOpen={() => setOpenMenuIconAvatar(false)}
      onClose={() => setOpenMenuIconAvatar(false)}
      sx={{
        zIndex: 15,
        "& .MuiDrawer-paper": {
          bgcolor: GRAY111,
          boxSizing: "border-box",
          height: "100%",
          width: "100%",
        },
      }}
      className="mobile:block desktop:hidden"
    >
      <Grid
        sx={{
          display: "flex",
          minHeight: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Grid>
          <Grid
            sx={{
              height: changePxToRem(72),
              padding: "8px 12px 8px 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "black",
            }}
          >
            {/* Icon go back */}
            <div className="desktop:hidden flex items-center">
              <button
                onClick={() => {
                  setOpenMenuIconAvatar(false);
                }}
                className={`pl-2`}
              >
                {/* <i className="ml-0 text-3xl ri-arrow-left-s-line"></i> */}
                <img src={left_arrow} alt="" width="10" height="20" />
              </button>
            </div>

            {/* reto hub logo */}
            <div
              onClick={() => {
                navigate("/");
                setActiveNav("0");
                setFocus(true);
                setOpenMenuIconAvatar(false);
              }}
            >
              <img
                src={Retohub}
                alt="PictureRetoHub"
                className="desktop:static desktop:w-[156px] desktop:min-w-[156px] desktop:max-h-10 mobile:flex mobile:items-center"
              />
            </div>
            <div className=""></div>
          </Grid>

          {/* menu on mobile */}
          <Grid>
            <Link
              to={"/profile"}
              onClick={() => {
                setOpenMenuIconAvatar(false);
                setActiveNav("");
              }}
              className="menu-btn text-GRAY920 hover:text-white"
            >
              <span className=" desktop:text-[24px] font-bold font-IBMPlex uppercase md:uppercase lg:capitalize">
                Profile
              </span>
            </Link>

            <Link
              onClick={() => {}}
              to={"/comingsoon"}
              className="menu-btn text-GRAY920 hover:text-white"
            >
              <span className=" desktop:text-[24px] font-bold font-IBMPlex uppercase md:uppercase lg:capitalize">
                Inventory
              </span>
            </Link>

            <Link
              onClick={() => {}}
              to={"/comingsoon"}
              className="menu-btn text-GRAY920 hover:text-white"
            >
              <span className=" desktop:text-[24px] font-bold font-IBMPlex uppercase md:uppercase lg:capitalize">
                Payment
              </span>
            </Link>

            <Link
              onClick={() => {}}
              to={"/comingsoon"}
              className="menu-btn text-GRAY920 hover:text-white"
            >
              <span className=" desktop:text-[24px] font-bold font-IBMPlex uppercase md:uppercase lg:capitalize">
                Support
              </span>
            </Link>

            <Link
              to={"setting"}
              onClick={() => {
                // setIsProfile(false);
                // setFocus(true);
                setOpenMenuIconAvatar(false);
              }}
              className="menu-btn text-GRAY920 hover:text-white"
            >
              <span className=" desktop:text-[24px] font-bold font-IBMPlex uppercase md:uppercase lg:capitalize">
                Setting
              </span>
            </Link>

            <div className="menu-btn text-[#939393] hover:text-white desktop:bg-GRAY111">
              <button onClick={onLogOut} className="menu-icon">
                <span className=" desktop:text-[24px] font-bold font-IBMPlex uppercase md:uppercase lg:capitalize ">
                  Logout
                </span>
              </button>
            </div>
          </Grid>
        </Grid>

        <footer className={`flex flex-col gap-2 pb-10`}>
          <ul className="flex flex-wrap items-center justify-around mt-2 mx-6 text-[10px] font-medium text-white desktop:hidden">
            <li>Terms of Conditions</li>
            <li>Privacy Policy</li>
            <li>Copyright Policy</li>
            <li>Cookie Policy</li>
          </ul>
          <div className="flex justify-center">
            <p className="font-bold text-white mobile:text-[12px]">
              @2023 RETO HUB. All rights reserved.
            </p>
          </div>
        </footer>
      </Grid>
    </SwipeableDrawer>
  );
}

export default Profile_menu_Mobile;
