import axios from "axios";
import Cookies from "js-cookie";

export const createFavorite = async (item_id, addFavorite, setAddFavorite) => {
  await axios
    .post(
      process.env.REACT_APP_BACKEND_ENDPOINT +
      process.env.REACT_APP_ENDPOINT_CREATE_FAVORITE_ITEM,
      {
        item_id: item_id,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get(
            "access_token"
          )}`,
        },
      }
    )
    .then((res) => {
      setAddFavorite(!addFavorite);
    })
    .catch((err) => { });
};

export const getViewGame = async (game_id) => {
  let game_id_num = "";
  let status = false;

  for (let i = 0; i < game_id?.length; i++) {
    if (!status) {
      if (Number(game_id[i]) > 0) {
        status = true;
        game_id_num += game_id[i];
      }
    } else {
      game_id_num += game_id[i];
    }
  }
  await axios
    .post(
      process.env.REACT_APP_BACKEND_ENDPOINT +
      process.env.REACT_APP_ENDPOINT_POST_VIEW,
      {
        gameId: Number(game_id_num),
      }
    )
    .then((res) => { })
    .catch((err) => { });
};
