import { useState, useEffect, useRef } from "react";
import {
    Dialog,
    DialogBody,
    ThemeProvider,
} from "@material-tailwind/react";
import { CloseButton } from "../setting/kyc_component";
import { Link, Navigate } from "react-router-dom";
import Searchbar_marketplace from "../../component/Searchbar_marketplace";
import { icon_marketplace } from "../../assets/marketplace";
import { Server, Checkbox } from "./filter";


const Filtermobile = ({
    open,
    setOpen,
    handleChangeValue,
    mobileFilter,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    value,
    setValue,
    item_server,
    setItem_server,
    getItemFilter_Favorite,
    page,
    itemPerPage,
    setItems,
    setFavorite_item,
    setMaxPage,
    setItemQuantity,
    payload,
    market,
    item_name,
    keyEnterSearchbar,
    setHitWord,
    countFilterActive

}) => {
    const handleOpen = () => setOpen(false);
    const theme = {
        dialog: {
            styles: {
                sizes: {
                    sm: {
                        width: "w-[944px]",
                        minWidth: "min-w-[944px]",
                        maxWidth: "max-w-[944px]",
                    },
                    xs: {
                        width: "w-full",
                        minWidth: "min-w-full",
                        maxWidth: "max-w-full",
                    }
                },
            },
        },
    };
    return (
        <ThemeProvider
            value={theme}
        >
            <Dialog
                open={open}
                size={"xs"}
                className="bg-[#1A1A1A] relative rounded overflow-hidden button-0"
            >
                <DialogBody
                    className="relative desktop:hidden  mobile:flex flex-col gap-5  items-start mobile:h-[90vh] overflow-auto font-IBMPlex"
                >
                    {/*Close Button  */}
                    <CloseButton handleOpen={handleOpen} />
                    <p className="text-[24px] font-bold text-white">FILTER</p>
                    <Searchbar_marketplace
                        handleChange={handleChangeValue}
                        keyEnterSearchbar={keyEnterSearchbar}
                    />
                    <Link to="/marketplace" className="flex w-full h-[3rem] bg-[#282828] justify-center relative uppercase text-[14px] font-bold text-white items-center">
                        marketplace
                        <img src={icon_marketplace} alt="icon_marketplace" className=" absolute left-3" />
                    </Link>
                    <div className="flex flex-col gap-3 w-full">
                        {/* price */}
                        <div className="w-full h-[2.625rem] flex items-center justify-start p-3 text-white text-base font-bold bg-gray-900">
                            price
                        </div>
                        <div className="flex justify-between items-center w-full h-[40px]">

                            <input
                                type="number"
                                value={minPrice ?? ""}
                                className="max-w-[42.7%] min-h-0 w-full bg-[#282828] h-full focus:outline-none text-white text-center placeholder:text-[#939393]"
                                placeholder="Min"
                                onChange={(e) => setMinPrice(e.target.value)}
                                onKeyDown={(e) =>
                                    (e.key === "e" && e.preventDefault()) ||
                                    (e.key === "-" && e.preventDefault()) ||
                                    (e.key === "+" && e.preventDefault()) ||
                                    (e.key === "." && e.preventDefault())
                                }
                            />
                            <div className="text-white ">To</div>
                            <input
                                type="number"
                                value={maxPrice ?? ''}
                                className="max-w-[42.7%] min-h-0 w-full bg-[#282828] h-full focus:outline-none text-white text-center placeholder:text-[#939393]"
                                placeholder="Max"
                                onChange={(e) => setMaxPrice(e.target.value)}
                                onKeyDown={(e) =>
                                    (e.key === "e" && e.preventDefault()) ||
                                    (e.key === "-" && e.preventDefault()) ||
                                    (e.key === "+" && e.preventDefault()) ||
                                    (e.key === "." && e.preventDefault())
                                }
                            />
                        </div>
                        {mobileFilter.map((filter, index) => (
                            // <Filter filter={filter} key={index} />
                            <>
                                {filter?.filter_name?.toLowerCase() === "server" && (
                                    <Server filter={filter} item_server={item_server} setItem_server={setItem_server} />
                                )}
                                {filter?.filter_type === "Checkbox" && filter?.filter_name?.toLowerCase() !== "server" && (
                                    <Checkbox filter={filter} value={value} setValue={setValue} />
                                )}

                            </>
                        ))}
                        <button
                            className="rounded text-white uppercase font-bold text-base bg-[#BB213D] mobile:max-h-[3rem] mobile:h-[3rem] mobile:max-w-full mobile:w-full"
                            onClick={() => {
                                handleOpen()
                                const timer = setTimeout(() => {
                                    countFilterActive()
                                    getItemFilter_Favorite(page, itemPerPage, [], setItems, setFavorite_item, setMaxPage, setItemQuantity, payload, market, minPrice, maxPrice, item_name, value, item_server)
                                    setHitWord([item_name, item_server, ...value])
                                }, 300)
                                return () => clearTimeout(timer)

                            }}
                        >
                            apply
                        </button>
                        <button
                            className="rounded text-white border border-[#BB213D] font-bold text-base w-full h-[44px]"
                            onClick={() => {
                                setMinPrice()
                                setMaxPrice()
                                setValue([])
                                setItem_server()
                            }}
                        >
                            CLEAR ALL
                        </button>
                    </div>
                </DialogBody>
            </Dialog>
        </ThemeProvider>
    )
}

export default Filtermobile
