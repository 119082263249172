import { useMutation } from "@tanstack/react-query";
import { paymentApi } from "../../services";

export const useMutationClientCheckoutProgress = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return paymentApi.ClientCheckoutProgress(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationClientCheckoutProgressItemQC = ({
  onSuccess,
  onError,
}) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return paymentApi.ClientCheckoutProgressItemQC(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};
