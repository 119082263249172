import { useState } from "react";
import { left_arrow } from "../../assets/home/icons/index";
import { Grid, Box, SwipeableDrawer, Tabs, Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GRAY111 } from "../../theme/color";
import { changePxToRem } from "../../utils/changePixeltoRem";
import Retohub from "../../assets/RetoHub.png";
import IconRatingStar from "../rating/IconRatingStar";
import InformationUserRating from "../rating/InformationUserRating";
import BtnRatingStar from "../rating/BtnRatingStar";
import PaginationCheckoutProgress from "./checkoutProgressPage/PaginationCheckoutProgress";

function ProfileReviewRatingMobile({
  isProfile,
  openProfileReviewRatingMobile,
  setOpenProfileReviewRatingMobile,
  selectFilterRating,
  onClickSelectFilterRating,
  ratingBtn,
  dataGetReviewRating,
  pageGetReviewRating,
  setPageGetReviewRating,
  //   setIsProfile,
  //   setActiveNav,
  //   setIsOpen,
  //   setFocus,
}) {
  const anchor = "right";
  const navigate = useNavigate();

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={openProfileReviewRatingMobile}
      onOpen={() => setOpenProfileReviewRatingMobile(false)}
      onClose={() => setOpenProfileReviewRatingMobile(false)}
      sx={{
        zIndex: 15,
        "& .MuiDrawer-paper": {
          bgcolor: GRAY111,
          boxSizing: "border-box",
          height: "100%",
          width: "100%",
        },
      }}
      className="mobile:block desktop:hidden"
    >
      <Grid
        sx={{
          display: "flex",
          minHeight: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Grid>
          <Grid
            sx={{
              height: changePxToRem(72),
              padding: "8px 12px 8px 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "black",
            }}
          >
            {/* Icon go back */}
            <div className="desktop:hidden flex items-center">
              <button
                onClick={() => {
                  setOpenProfileReviewRatingMobile(false);
                }}
                className={`pl-2`}
              >
                {/* <i className="ml-0 text-3xl ri-arrow-left-s-line"></i> */}
                <img src={left_arrow} alt="" width="10" height="20" />
              </button>
            </div>

            {/* reto hub logo */}
            <div
              onClick={() => {
                navigate("/");
                // setActiveNav("0");
                // setFocus(true);
                setOpenProfileReviewRatingMobile(false);
              }}
            >
              <img
                src={Retohub}
                alt="PictureRetoHub"
                className="desktop:static desktop:w-[156px] desktop:min-w-[156px] desktop:max-h-10 mobile:flex mobile:items-center"
              />
            </div>

            {/* don't remove */}
            <div></div>
          </Grid>

          {/* Review Rating on mobile */}
          <Grid>
            <div className="p-4 pb-3 flex flex-col gap-3">
              <p className="text-2xl text-white font-bold">
                {isProfile?.display_name
                  ? `${isProfile?.display_name} RATING`
                  : ""}
              </p>

              <div className="flex items-center flex-wrap gap-2">
                {/* Rating */}
                <div className="flex gap-2">
                  {/* Icon Rating */}
                  <IconRatingStar rating={isProfile?.rating} />
                  <span className="text-xs text-[#FFD02A] font-bold pt-1">
                    {isProfile?.rating ? `${isProfile?.rating}` : "0"}
                  </span>
                </div>
                <p className="text-xs text-white text-end font-bold pl-2 pr-3 border-l border-white/[0.2] pt-1">
                  {isProfile?.rated_count
                    ? `${isProfile?.rated_count} ratings`
                    : "0 ratings"}
                </p>
                {/* <p className="mobile:text-[12px] desktop:text-[20px] text-white font-bold font-Kanit pl-5 border-l-[1px] border-white/[0.2]">
                    386 sold out
                  </p> */}
              </div>
            </div>

            {/* button select filter rating */}
            <div className="mx-0 w-full pt-0 px-3">
              <Box
                sx={{
                  maxWidth: "100%",
                  bgcolor: "transparent",
                  color: "white",
                  minHeight: "0px",
                  "& .MuiTabs-root": {
                    minHeight: changePxToRem(4),
                  },
                  "& .MuiTabs-scroller": {
                    "& .MuiTabs-indicator": {
                      backgroundColor: "transparent",
                      // height: changePxToRem(2),
                    },
                  },
                }}
              >
                <Tabs
                  value={0}
                  onChange={() => {}}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs"
                >
                  <Tab
                    label={
                      <div className="flex flex-col gap-1 pt-1 items-center justify-center border border-white rounded min-w-[7.5rem] w-[7.5rem] h-[3.125rem]">
                        <p className="text-[0.625rem] text-white font-bold !leading-4">
                          Total
                        </p>
                        <p className="text-[0.625rem] text-white font-normal !leading-4">
                          {`(${
                            dataGetReviewRating?.rating_count["rating total"]
                              ? dataGetReviewRating?.rating_count[
                                  "rating total"
                                ]
                              : 0
                          })`}
                        </p>
                      </div>
                    }
                    sx={{
                      padding: `${changePxToRem(0)} ${changePxToRem(0)}`,
                      textTransform: "none",
                      minHeight: "0px",
                      minWidth: "0px",
                      "&.Mui-selected": {
                        color: "white",
                      },
                    }}
                  />

                  {ratingBtn?.map(({ rating, quantityRating }) => {
                    return (
                      <Tab
                        label={
                          <div className="ml-4">
                            <BtnRatingStar
                              rating={rating}
                              selectFilterRating={selectFilterRating}
                              onClick={() => {
                                onClickSelectFilterRating(rating);
                                setPageGetReviewRating(1);
                              }}
                              quantityRating={quantityRating}
                            />
                          </div>
                        }
                        sx={{
                          padding: `${changePxToRem(0)} ${changePxToRem(0)}`,
                          textTransform: "none",
                          minHeight: "0px",
                          minWidth: "0px",
                          "&.Mui-selected": {
                            color: "white",
                          },
                        }}
                      />
                    );
                  })}
                </Tabs>
              </Box>
            </div>

            {/* information user Rating */}
            <div className="flex flex-col gap-4 pt-6 px-4">
              {dataGetReviewRating?.data?.map((data) => {
                return <InformationUserRating data={data} />;
              })}
            </div>
          </Grid>
        </Grid>

        <div>
          {dataGetReviewRating?.totalPage > 1 && (
            <PaginationCheckoutProgress
              pageForPagination={
                dataGetReviewRating?.totalPage
                  ? Math.ceil(dataGetReviewRating?.totalPage)
                  : 0
              }
              page={pageGetReviewRating}
              setPage={setPageGetReviewRating}
            />
          )}
          {/* footer bar */}
          <footer className={`flex flex-col gap-2 py-5`}>
            <ul className="flex flex-wrap items-center justify-around mt-2 mx-6 text-[10px] font-medium text-white desktop:hidden">
              <li>Terms of Conditions</li>
              <li>Privacy Policy</li>
              <li>Copyright Policy</li>
              <li>Cookie Policy</li>
            </ul>
            <div className="flex justify-center">
              <p className="font-bold text-white mobile:text-[12px]">
                @2023 RETO HUB. All rights reserved.
              </p>
            </div>
          </footer>
        </div>
      </Grid>
    </SwipeableDrawer>
  );
}

export default ProfileReviewRatingMobile;

const mockupDataRating = [
  {
    rating: 5,
    name: "Brad Pitts",
    date: "12-12-2022",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    rating: 4.5,
    name: "Brad Pitts 222",
    date: "12-12-2022",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    rating: 3.5,
    name: "Brad Pitts 333",
    date: "12-12-2022",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    rating: 2,
    name: "Brad Pitts 444",
    date: "12-12-2022",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    rating: 1,
    name: "Brad Pitts 555",
    date: "12-12-2022",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];
