/* eslint-disable no-undef */
import {
	icon_hidePassword,
	icon_showPassword,
	retohub_icon,
} from "../../assets/login";
import { signin } from "../../api/post/login";
import { useState, useEffect, useRef } from "react";
import Header from "../../component/layout/Header";
import { bg_main, bg_shadow_1, bg_shadow_2 } from "../../assets/register";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { setDisplay } from "../../slice/goBackBtnSlice";
import FooterLRF from "../../component/layout/FooterLRF";
import { setLogin_success } from "../../slice/userslice";

const Login = () => {
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm({});
	const [showPassword, setShowPassword] = useState(false);
	const [showError, setShowError] = useState("");
	const [checkbox, setChecbox] = useState(false);
	const [validateEmail, setValidateEmail] = useState(true);

	const lock = useRef(false);
	const goBack = useDispatch();
	const dispatch = useDispatch()

	const keepVerifiedStatus = (displayName, email_verification_status) => {
		dispatch(setLogin_success(
			{
				display_Name: displayName,
				email_verification_status: email_verification_status
			}
		))
	}
	useEffect(() => {
		if (errors.email) {
			setShowError("email");
		} else if (errors.password) {
			setShowError("password");
		}
	}, [errors.email, errors.password]);

	useEffect(() => {
		if (lock.current) {
			goBack(setDisplay(false));
		}

		return () => (lock.current = true);
	}, []);

	const onSubmit = (formValues) => {
		signin(formValues, setError, keepVerifiedStatus);
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			handleSubmit(onSubmit)();
		}
	};



	//   if (!validateEmail) return <Navigate to="/register?stage=verify_email" />;
	//   // else if (!displayName && !!Cookies.get("access_token")) return <Navigate to="/register/create_displayname" />
	//   else if (Cookies.get("access_token")) {
	//     window.location.href = "/";
	//   } else
	return (
		<div className="desktop:w-full desktop:h-full  desktop:relative desktop:min-h-[710px] ">
			<div
				className="desktop:w-full desktop:h-screen desktop:absolute bg-black desktop:min-h-[710px]"
				style={{
					backgroundImage: `url(${bg_main})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "cover",
				}}
			></div>
			<div
				className="desktop:w-full desktop:h-screen desktop:absolute desktop:min-h-[710px]"
				style={{
					backgroundImage: `url(${bg_shadow_1})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "cover",
				}}
			></div>
			<div
				className="desktop:w-full desktop:h-screen desktop:absolute desktop:min-h-[710px]"
				style={{
					backgroundImage: `url(${bg_shadow_2})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "cover",
				}}
			></div>
			<div className="flex flex-col w-full desktop:h-full desktop:max-h-screen desktop:min-h-[710px]  pb-10 mobile:h-screen">
				<div className="flex w-full desktop:max-h-full desktop:min-h-[550px] desktop:h-screen mobile:h-full  z-10 justify-between">
					<div className="desktop:flex mobile:hidden desktop:max-w-[50%] desktop:w-[50%] desktop:min-w-0 justify-center items-center">
						<div className="desktop:flex mobile:hidden max-h-[20rem] max-w-[20rem] z-10">
							{/* absolute left-[21.5vw] -translate-y-[50%] top-[50%] */}
							<img
								src={retohub_icon}
								alt="retohub_icon"
								className="w-full h-full object-contain"
							/>
						</div>
					</div>

					<div className="desktop:max-w-[35.27vw] mobile:w-full mobile:h-screen desktop:w-[35.27vw] desktop:min-w-0 desktop:min-h-[650px] desktop:h-screen mobile:min-w-0 mobile:min-h-[650px]  z-10 flex desktop:justify-start desktop:items-center mobile:items-start mobile:justify-start">
						<div className="flex flex-col  mobile:bg-[#1E1E1E] desktop:bg-transparent mobile:w-full  mobile:h-full desktop:h-screen desktop:max-w-[400px]  desktop:w-[400px] desktop:min-w-0 desktop:justify-center mobile:justify-start">
							<div className="mobile:flex desktop:hidden mobile:w-full">
								<Header />
							</div>

							<div className=" flex flex-col items-start mobile:px-3 mobile:bg-[#1E1E1E] desktop:bg-transparent gap-4 mobile:w-full desktop:max-w-[400px] mt-[16px]">
								<p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold">
									LOGIN
								</p>
								<p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-normal">
									New user?{" "}
									<Link
										to="/register"
										className="text-[#FFD262] cursor-pointer"
									>
										Create account
									</Link>{" "}
									here.
								</p>
								<div className="w-full h-[78px] flex flex-col items-start justify-between relative">
									<p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">
										Email
									</p>
									<input
										{...register("email", {
											required: `Please enter your email.`,
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: `Invalid email Please try again`,
											},
										})}
										type="text"
										className={`w-full h-[48px]  focus:outline-none px-[12px] py-[11px] rounded-[4px] bg-[#282828] text-white text-[16px] ${errors.email
											? "border border-[#AE3C33] "
											: "border-none"
											} placeholder:text-[#939393]`}
										onClick={() =>
											showError === "email"
												? setShowError("")
												: setShowError("email")
										}
										placeholder="Enter email address"
										onKeyDown={handleKeyDown}
									/>
									{errors.email && showError === "email" && (
										<div className="mobile:hidden desktop:flex absolute desktop:p-2 top-[calc(100%-24px)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33]  min-w-[15rem] max-w-[25rem]  w-[20.83vw] items-center justify-center  rounded-[4px]">
											<div className="flex justify-center items-center relative  w-full h-full ">
												<p className=" text-white font-IBMPlex">
													{errors.email.message}
												</p>
											</div>
											{/* <p className=" text-white font-IBMPlex">{errors.email.message}</p> */}
											<div className="w-0 h-0 absolute left-[calc(100%-8px)] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"></div>
										</div>
									)}
								</div>
								{errors.email && (
									<p className="mobile:flex desktop:hidden text-xs italic text-red-500">
										{errors.email.message}
									</p>
								)}
								<div className="w-full h-[78px] flex flex-col items-start justify-between relative">
									<div className="flex justify-between w-full">
										<p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">
											Password
										</p>
										<Link
											to="/forgotpassword"
											className="font-IBMPlex text-[#FFFFFF] text-[16px] font-normal underline underline-offset-2 cursor-pointer"
										>
											Forgot password?
										</Link>
									</div>
									<input
										{...register("password", {
											required: "Please enter a password.",
										})}
										type={showPassword ? "text" : "password"}
										className={`w-full h-[48px]  focus:outline-none px-[12px] py-[11px] rounded-[4px] bg-[#282828] text-white text-[16px] ${errors.password
											? "border border-[#AE3C33] "
											: "border-none"
											} placeholder:text-[#939393]`}
										onClick={() =>
											showError === "password"
												? setShowError("")
												: setShowError("password")
										}
										placeholder="Enter password"
										onKeyDown={handleKeyDown}
									/>
									<div className="absolute  right-[12px] flex  bottom-[12px]">
										{showPassword ? (
											<div
												className="w-6 h-6 cursor-pointer"
												onClick={() => setShowPassword(!showPassword)}
											>
												<img
													src={icon_showPassword}
													alt="icon_showPassword"
													className="w-full"
												/>
											</div>
										) : (
											<div
												className="w-6 h-6 cursor-pointer"
												onClick={() => setShowPassword(!showPassword)}
											>
												<img
													src={icon_hidePassword}
													alt="icon_showPassword"
													className="w-full"
												/>
											</div>
										)}
									</div>
									{errors.password && showError === "password" && (
										<div className="mobile:hidden desktop:flex absolute p-2 top-[calc(100%-24px)] -translate-y-[50%]  right-[calc(100%+12px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
											<div className="flex justify-center items-center relative w-full h-full ">
												<p className=" text-white font-IBMPlex">
													{errors.password.message}
												</p>
												<div className="w-0 h-0 absolute left-[calc(100%)] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"></div>
											</div>
										</div>
									)}
								</div>

								<div className="flex justifyy-start items-center gap-[11px] relative">
									<input
										{...register("checkbox")}
										type="checkbox"
										className={`translate-x-1 appearance-none w-5 h-5 bg-[#282828]  checked:bg-[#BB213D] border-[2px] checked:border-[#BB213D]  border-white rounded after:content-[''] after:w-full after:h-full after:absolute after:left-0 after:top-0 after:bg-no-repeat after:bg-center after:bg-[length:40px] checked:after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')]`}
										onChange={() => setChecbox(!checkbox)}
									/>
									<p className="font-IBMPlex text-[#FFFFFF] text-[16px] font-normal h-[26px] flex items-center">
										Keep me logged in
									</p>
								</div>
								<button
									className="w-full h-[52px] bg-[#BB213D] hover:bg-RED001/[0.8] flex justify-center items-center rounded-[4px] font-IBMPlex text-[#FFFFFF] text-[16px] font-bold"
									onClick={handleSubmit((formValues) => {
										signin(formValues, setError, keepVerifiedStatus);
									})}
								>
									LOGIN
								</button>
							</div>
						</div>
					</div>
				</div>
				<FooterLRF />
			</div>
		</div>
	);
};

export default Login;
