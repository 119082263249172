/* eslint-disable no-undef */
import { retohub_icon } from "../../assets/login";
import { useState, useEffect } from "react";
import Header from "../../component/layout/Header";
import { bg_main, bg_shadow_1, bg_shadow_2 } from "../../assets/register";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDisplay } from "../../slice/goBackBtnSlice";
import FooterLRF from "../../component/layout/FooterLRF";
import Cookies from "js-cookie";

const ComingSoonPage = () => {
  const show = true;
  const navigate = useNavigate();
  const auth = Cookies.get("access_token");

  const goBack = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    goBack(setDisplay(false));
  }, [goBack]);

  useEffect(() => {
    if (auth === undefined) {
      navigate("/");
    }
  }, [auth]);

  return (
    <div className="desktop:w-full desktop:h-screen  desktop:relative">
      <div
        className="desktop:w-full desktop:h-screen desktop:absolute bg-black"
        style={{
          backgroundImage: `url(${bg_main})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <div
        className="desktop:w-full desktop:h-screen desktop:absolute"
        style={{
          backgroundImage: `url(${bg_shadow_1})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <div
        className="desktop:w-full desktop:h-screen desktop:absolute"
        style={{
          backgroundImage: `url(${bg_shadow_2})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <div className="desktop:absolute desktop:top-[30%] desktop:flex w-full  items-center bg-transparent ">
        <div className="w-[60%]">
          <div className="desktop:flex mobile:hidden max-h-[20rem] max-w-[20rem] mx-auto">
            <img
              src={retohub_icon}
              alt="retohub_icon"
              className="w-[20rem] h-full object-cover "
            />
          </div>
        </div>

        <div className="flex flex-col mobile:bg-GRAY111 desktop:bg-transparent desktop:pb-5 desktop:ml-[2vw]">
          <div className="mobile:flex desktop:hidden mobile:w-full">
            <Header show={show} />
          </div>
          <div className=" flex flex-col items-start mobile:p-4 mobile:h-screen desktop:h-full desktop:bg-transparent mobile:bg-GRAY111 desktop:gap-5">
            <div className="flex flex-col gap-3">
              <p className="font-IBMPlex text-[#FFFFFF] mobile:text-2xl desktop:text-[32px] font-[700]">
                COMING SOON
              </p>
              <p className="font-IBMPlex text-[#FFFFFF] mobile:text-sm desktop:text-base font-[400]">
                These features are currently planned in development process.
              </p>
            </div>
            <Link to={"/"} className="w-full desktop:block mobile:hidden">
              <button className="w-[85%] h-[3.5rem] min-w-[14.375rem] bg-[#BB213D] flex justify-center items-center rounded-[4px] font-IBMPlex text-[#FFFFFF] text-base font-bold">
                GO BACK HOMEPAGE
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="absolute mobile:bottom-2 mobile:left-4 desktop:bottom-4">
        <FooterLRF />
      </div>
    </div>
  );
};

export default ComingSoonPage;
