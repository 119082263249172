import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "remixicon/fonts/remixicon.css";
import {
  BL,
  dropdown,
  dropdown_gold,
  menu,
  menu_gold,
  normal_active,
  normal_notification,
  normal_wallet,
  Profile1,
  left_arrow,
} from "../../assets/home/icons/index";
import {
  default_Profile_cover,
  default_Profile_picture,
} from "../../assets/home";
import Retohub from "../../assets/RetoHub.png";
import SideBar from "./SideBar";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import Notification from "../Notification";
import Profile_menu from "./Profile_menu";
import OtherIcon from "../../assets/images/OtherIcon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import GameCard from "../../component/GameCard";
import { next_home, prev_home } from "../../assets/home/icons";
import { getMarketItems } from "../../api/get/Homepage";
import MyWallet from "./walletPayment/MyWallet/MyWallet";
import TransactionPurchased from "./TransactionPurchased";
import { SearchBar } from "../search/SearchBar";
import TopUp from "../../assets/images/mockTopUp/TopUp.png";
import TopUp1 from "../../assets/images/mockTopUp/TopUp1.png";
import TopUp2 from "../../assets/images/mockTopUp/TopUp2.png";
import TopUp3 from "../../assets/images/mockTopUp/TopUp3.png";
import TopUp4 from "../../assets/images/mockTopUp/TopUp4.png";
import { setOpenDialogMyWallet } from "../../slice/myWalletSlice";
import { getUserProfile } from "../../api/get/setting/account";
import { getGames } from "../../api";
import Cookies from "js-cookie";
import { getProfileIamge } from "../../slice/userslice";
import Profile_menu_Mobile from "./Profile_menu_Mobile";

const Header = ({
  setFocus,
  show,
  setActiveNav,
  isProfile,
  setIsProfile,
  isOpen,
  setIsOpen,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(0);
  const [openMenuIconAvatar, setOpenMenuIconAvatar] = useState(false);
  const [isDropDown, setIsDropDown] = useState(false);
  const [selectId, setSelectId] = useState("");
  const [searchField, setSearchField] = useState("");
  const [searchParams] = useSearchParams();
  const [swipeForMarket, setSwipeForMarket] = useState();
  const [swipeForTopUP, setSwipeForTopUP] = useState();
  const goBack = useSelector((state) => state.goBack);
  // const path = useSelector(state => state.goBackBtn.path)
  const [openLang, setOpenLang] = useState(false); // for Language list
  const [flag, setFlag] = useState("us"); // for change flag display
  const [openMobile, setOpenMobile] = useState("");
  const Authorization = Cookies.get("access_token");
  const [isProfileImg, setIsProfileImg] = useState();
  const [marketItems, setMarketItems] = useState();
  const [results, setResults] = useState([]);
  const [input, setInput] = useState("");
  const [showHeader, setShowHeader] = useState(true);
  const [otherPage, setOtherPage] = useState(false);
  const trackId = searchParams.get("trackId");
  const [searchName, setSearchName] = useState();
  const locationPathName = useLocation();
  const pathnameCurrent = locationPathName.pathname.split("/")[1];
  const pathnameOther = locationPathName.pathname.split("/")[2];
  const user = useSelector((state) => state.user);
  const marketLength = marketItems?.length || 0;
  const topUpLength = mockupDataTopUp?.length || 0;
  const isLessThanLoopedSlidesForMarket =
    marketLength >= swipeForMarket?.loopedSlides;
  const isLessThanLoopedSlidesForTopUp =
    topUpLength >= swipeForTopUP?.loopedSlides;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    getMarketItems(setMarketItems);
    getUserProfile(setIsProfileImg);
    getGames(setSearchName);
  }, []);

  useEffect(() => {
    if (!!isProfileImg?.profile_picture) {
      dispatch(
        getProfileIamge({
          profile_image: isProfileImg?.profile_picture,
        })
      );
    }
  }, [isProfileImg]);
  useEffect(() => {
    if (
      pathnameCurrent === "cartorder" ||
      pathnameCurrent === "payment" ||
      pathnameCurrent === "checkoutprogress" ||
      pathnameCurrent === "comingsoon"
    ) {
      setShowHeader(false);
      setOtherPage(false);
    } else if (
      pathnameCurrent === "delivery" ||
      pathnameCurrent === "complete"
    ) {
      if (pathnameOther === "other") {
        setOtherPage(false);
        setShowHeader(false);
      } else {
        setOtherPage(true);
        setShowHeader(false);
      }
    } else {
      setShowHeader(true);
      setOtherPage(false);
    }
  }, [pathnameCurrent, pathnameOther]);

  useEffect(() => {
    const handleWindowResize = () => {
      const isMobileScreen = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(isMobileScreen);
    };

    // Initial check when the component mounts
    handleWindowResize();

    // When MobileScreen setOpenMenu
    if (isMobile === true) {
      setOpenMenu(0);
    }

    // Add event listener to update the state on window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isMobile]);

  const mock_menu = [
    { id: 1, title: "marketplace", menu: [] },
    { id: 2, title: "top-up", menu: [] },
    {
      id: 3,
      title: "RETOHUB",
      menu: ["About Us", "Careers", "Reto News", "Reto Blog", "Contact"],
    },
    {
      id: 4,
      title: "BUY",
      menu: [
        "How to Buy",
        "Safety Guidelines",
        "Purchase & Refund",
        "Reto Points",
        "Buy Ranking",
      ],
    },
    {
      id: 5,
      title: "SELL",
      menu: [
        "How to Sell",
        "Rules & Regulations",
        "Request Payment",
        "Commission Fee",
        "Seller Ranking",
      ],
    },
    {
      id: 6,
      title: "SUPPORT",
      menu: [
        "Hell Center",
        "Gamer Protect",
        "Affiliate Program",
        "Reto Account",
        "Q&A",
      ],
    },
  ];

  const MENU_SELECT_HEADER = [
    {
      label: "RETOHUB",
      list: [
        { label: "About us", url: "/comingsoon" },
        { label: "Careers", url: "/comingsoon" },
        { label: "Reto News", url: "/comingsoon" },
        { label: "Reto Blog", url: "/comingsoon" },
      ],
    },
    {
      label: "BUY",
      list: [
        { label: "How to Buy", url: "/comingsoon" },
        { label: "Buyer Ranking", url: "/comingsoon" },
        { label: "Pyaments & Refunds", url: "/comingsoon" },
        { label: "Reto Points", url: "/comingsoon" },
      ],
    },
    {
      label: "SELL",
      list: [
        { label: "How to Sell", url: "/comingsoon" },
        { label: "Seller Ranking", url: "/comingsoon" },
        { label: "Disbursements", url: "/comingsoon" },
        { label: "Retohub Fee", url: "/comingsoon" },
      ],
    },
    {
      label: "SUPPORT",
      list: [
        { label: "Help Center", url: "/comingsoon" },
        { label: "Rules & Regulations", url: "/comingsoon" },
        { label: "Reto Account", url: "/comingsoon" },
        { label: "Contact Us", url: "/comingsoon" },
      ],
    },
    {
      label: "LEGAL",
      list: [
        { label: "Terms & Conditions", url: "/term_policy" },
        { label: "Privacy Policy", url: "/privacy_policy" },
        { label: "Copyright Policy", url: "/comingsoon" },
        { label: "Cookie Policy", url: "/comingsoon" },
      ],
    },
  ];

  const LANGUAGE_LIST = [
    { name: "thailand", label: "ภาษาไทย" },
    { name: "us", label: "English" },
    { name: "japan", label: "日本語" },
    { name: "china", label: "繁体中文" },
    { name: "vietnam", label: "Tiếng Việt" },
    { name: "spain", label: "Español" },
  ];

  const handleClick = (isClick, id) => {
    setIsDropDown(!isClick);
    setSelectId(id);
  };

  const handleOpen = () => {
    navigate("/");
    setIsOpen(!isOpen);
  };

  const handleProfile = () => {
    setIsProfile(!isProfile);
  };

  const handleLang = (langName) => {
    setFlag(langName);
    setOpenLang(false);
  };

  const onClickToOtherPage = (e) => {
    e.preventDefault();
    if (trackId !== undefined && trackId !== null) {
      navigate({
        pathname: `${pathnameCurrent}/other`,
        search: `${createSearchParams({
          trackId: trackId,
        })}`,
      });
    }
    setOtherPage(false);
  };

  return (
    <header className="w-full desktop:h-[5.5rem] mobile:h-[4.5rem] flex items-center desktop:block desktop:bg-GRAY111 mobile:bg-black mobile:px-3 mobile:py-2">
      {/* <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=7acb18cd-8e9c-4d36-b444-434b06bdb2af"
      >
        {" "}
      </script> */}
      <nav className="desktop:bg-GRAY111 mobile:bg-black w-full border-gray-200 desktop:h-[80px] desktop:px-6 desktop:py-2.5 desktop:flex desktop:items-center">
        <div
          className={`flex items-center max-w-[1597px] desktop:w-[1597px] m-auto mobile:flex desktop:flex-nowrap justify-between desktop:gap-7 mobile:items-center `}
        >
          {/* menu on mobile */}
          <div className="desktop:hidden flex items-center">
            <button
              type="button"
              onClick={
                goBack.displayMenu
                  ? () => {
                      handleOpen();
                      setFocus(false);
                      setActiveNav("0");
                    }
                  : pathnameCurrent === "comingsoon" ||
                    pathnameCurrent === "cartorder" ||
                    pathnameOther === "other"
                  ? () => {
                      navigate(-1);
                    }
                  : pathnameCurrent === "login"
                  ? () => {
                      navigate("/");
                    }
                  : pathnameCurrent === "register" ||
                    pathnameCurrent === "forgotpassword"
                  ? () => {
                      navigate(-1);
                    }
                  : goBack.path === "/" || goBack.path === ""
                  ? () => {
                      setFocus(true);
                      navigate(goBack.path);
                    }
                  : () => {
                      navigate(goBack.path);
                    }
              }
              className={`inline-flex items-center text-sm text-gray-500 rounded-lg ${
                isOpen ? "hidden" : "block"
              } ${isProfile ? "hidden" : "block"}`}
            >
              {goBack.displayMenu ? (
                <div className="px-1">
                  <img src={menu} alt="" className="w-[36px] h-[36px]" />
                </div>
              ) : (
                <div className="pl-2">
                  <img src={left_arrow} alt="" width="10" height="20" />
                </div>
              )}
            </button>
            <button
              onClick={() => {
                handleOpen(false);
                setFocus(true);
                setActiveNav("0");
              }}
              className={`text-white ${isOpen ? "block text-white" : "hidden"}`}
            >
              <i className="text-3xl ri-arrow-left-s-line"></i>
            </button>

            <button
              onClick={() => {
                handleProfile(false);
                setFocus(true);
                setActiveNav("0");
              }}
              className={`text-white ${
                isProfile ? "block text-white" : "hidden"
              }`}
            >
              <i className="ml-0 mr-2 text-3xl ri-arrow-left-s-line"></i>
            </button>
          </div>

          {/* reto hub logo */}
          <div
            onClick={
              pathnameCurrent === ""
                ? () => {}
                : pathnameCurrent === "comingsoon"
                ? () => navigate("/")
                : () => {
                    setFocus(true);
                    navigate("/");
                    setActiveNav("0");
                  }
            }
            className={` ${isOpen ? "" : "block"} cursor-pointer`}
          >
            <img
              src={Retohub}
              alt="PictureRetoHub"
              className="desktop:static desktop:w-[156px] desktop:min-w-[156px] desktop:max-h-10 mobile:flex mobile:items-center"
            />
          </div>

          {/* menu on desktop */}
          <button
            onMouseOver={() => setOpenMenu(1)}
            type="button"
            className={`inline-flex desktop:flex desktop:gap-2 items-center text-sm text-gray-500 rounded-lg md:block mobile:hidden`}
          >
            {openMenu === 1 ? (
              <img
                src={menu_gold}
                alt=""
                width="36"
                height="36"
                className="min-w-[2.25rem] min-h-[2.25rem]"
              />
            ) : (
              <img
                src={menu}
                alt=""
                width="36"
                height="36"
                className="min-w-[2.25rem] min-h-[2.25rem]"
              />
            )}
            {openMenu === 1 ? (
              <img src={dropdown_gold} alt="" width="12" height="6" />
            ) : (
              <img src={dropdown} alt="" width="12" height="6" />
            )}
          </button>

          {/* menu left side */}
          <div className="desktop:block [@media(max-width:1065px)]:hidden">
            <div className="flex items-center gap-[1.875rem]">
              <div
                onMouseOver={() => setOpenMenu(2)}
                // className="flex items-center gap-2"
                className="items-center gap-2 cursor-pointer hidden [@media(min-width:1065px)]:flex"
              >
                <p
                  className={`font-[400] text-base text-white ${
                    openMenu === 2 ? "text_style" : ""
                  }`}
                >
                  Market
                </p>
                {openMenu === 2 ? (
                  <img src={dropdown_gold} alt="" width="12" height="6" />
                ) : (
                  <img src={dropdown} alt="" width="12" height="6" />
                )}
              </div>

              <div
                onMouseOver={() => setOpenMenu(3)}
                className="items-center gap-2 cursor-pointer hidden [@media(min-width:1154px)]:flex"
              >
                <p
                  className={`font-[400] text-base text-white whitespace-nowrap ${
                    openMenu === 3 ? "text_style" : ""
                  }`}
                >
                  Top Up
                </p>
                {openMenu === 3 ? (
                  <img
                    src={dropdown_gold}
                    alt=""
                    width="12"
                    height="6"
                    className="md:hidden lg:hidden xl:block"
                  />
                ) : (
                  <img
                    src={dropdown}
                    alt=""
                    width="12"
                    height="6"
                    className="md:hidden lg:hidden xl:block"
                  />
                )}
              </div>
            </div>
          </div>

          {/* Search bar */}
          <div className="mobile:hidden desktop:flex grow z-50">
            <SearchBar
              setResults={setResults}
              results={results}
              input={input}
              setInput={setInput}
            />
          </div>
          {/* </div> */}

          {/* translate */}
          <div className="mobile:hidden desktop:flex desktop:items-center desktop:w-auto desktop:gap-7 border-r desktop:pr-[1.5vw] border-white/[0.2] [@media(max-width:1350px)]:hidden ">
            <div
              onMouseOver={() => setOpenMenu(4)}
              className={`h-full items-center cursor-pointer hidden [@media(min-width:1450px)]:flex relative`}
            >
              <img
                src={`/language_icons/${flag}.svg`}
                alt=""
                width={36}
                height={36}
                className="min-h-[36px] min-w-[36px]"
              />

              {openMenu === 4 ? (
                <div
                  className="mobile:hidden desktop:block"
                  onMouseOver={() => setOpenMenu(4)}
                  onMouseLeave={() => setOpenMenu(0)}
                >
                  <div className="absolute w-[200px] h-[384px] -right-1 top-[3.575rem] bg-GRAY700 rounded-md z-50 py-5 pr-2">
                    <div className="h-full rounded-md overflow-y-auto pl-6 flex flex-col gap-4">
                      {LANGUAGE_LIST.map(({ name, label }, index) => (
                        <button
                          key={index}
                          onClick={() => handleLang(name)}
                          disabled={label !== "English"}
                          className={`flex gap-2 items-center p-2 cursor-pointer text-[20px] text-white font-Kanit disabled:opacity-25`}
                        >
                          <img
                            src={`/language_icons/${name}.svg`}
                            alt=""
                            width="24"
                            height="24"
                          />
                          {label}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div
              className={`h-full items-center cursor-pointer hidden [@media(min-width:1350px)]:flex`}
            >
              <img src={BL} alt="" className="min-h-[36px] min-w-[36px]" />
            </div>
          </div>

          {!Authorization ? (
            <div className="desktop:flex gap-[1.875rem] mobile:hidden items-center">
              <div className="flex gap-5">
                <Link
                  to={"register"}
                  className="w-[8vw] max-w-[8.75rem] min-w-[6.875rem] py-2 flex justify-center items-center border-[3px] border-RED001 rounded hover:bg-GRAY666/[0.2] text-white font-bold font-IBMPlex cursor-pointer"
                >
                  REGISTER
                </Link>
                <Link
                  to={"/login"}
                  className="w-[8vw] max-w-[8.75rem] min-w-[6.875rem] py-2 bg-RED001 flex justify-center items-center rounded  hover:bg-RED001/[0.8] text-white font-bold font-IBMPlex cursor-pointer"
                >
                  LOGIN
                </Link>
              </div>
            </div>
          ) : (
            <>
              {/* menu right side */}
              <div
                className={`mobile:hidden desktop:flex desktop:items-center desktop:gap-[1.7vw] desktop:w-auto`}
              >
                {/* icon notification */}
                <button
                  onMouseOver={() => setOpenMenu(6)}
                  className="desktop:ml-0 flex justify-center cursor-pointer relative"
                >
                  <img
                    alt="notification"
                    src={normal_notification}
                    className="min-w-[2.25rem] min-h-[2.25rem]"
                  />
                  {openMenu === 6 ? (
                    <div
                      className="mobile:hidden desktop:block"
                      onMouseOver={() => setOpenMenu(6)}
                      onMouseLeave={() => setOpenMenu(0)}
                    >
                      <Notification />
                    </div>
                  ) : null}
                </button>

                {/* icon TransactionPurchased */}
                <button
                  onMouseOver={() => setOpenMenu(7)}
                  className="desktop:ml-0 flex justify-center cursor-pointer relative"
                >
                  <img
                    alt="activity"
                    src={normal_active}
                    className="min-w-[2.25rem] min-h-[2.25rem]"
                  />
                  {openMenu === 7 ? (
                    <div
                      className="mobile:hidden desktop:block absolute "
                      onMouseOver={() => setOpenMenu(7)}
                      onMouseLeave={() => setOpenMenu(0)}
                    >
                      <TransactionPurchased
                        isOpen={openMenu}
                        setIsOpen={setOpenMenu}
                      />
                    </div>
                  ) : null}
                </button>

                {/* icon myWallet */}
                <button
                  onMouseOver={() => {
                    setOpenMenu(0);
                    dispatch(setOpenDialogMyWallet(true));
                  }}
                  className="desktop:ml-0 flex justify-center cursor-pointer "
                >
                  <img
                    alt="wallet"
                    src={normal_wallet}
                    className="min-w-[2.25rem] min-h-[2.25rem]"
                  />
                </button>
              </div>
            </>
          )}

          {/* Profile desktop */}
          {!!Authorization && (
            <div className="desktop:block mobile:hidden">
              <div
                onClick={() => {
                  handleProfile(true);
                  setFocus(false);
                  setIsProfile(!isProfile);
                }}
                onMouseOver={() => setOpenMenu(5)}
                className={`flex items-center cursor-pointer desktop:gap-3  ${
                  isOpen ? "hidden" : "block"
                } ${!Authorization ? "desktop:hidden mobile:block" : "block"}`}
              >
                <div
                  className={`rounded-full relative w-[60px] h-[60px] ${
                    isProfile ? "mobile:bg-GRAY111" : ""
                  }`}
                >
                  <img
                    src={
                      !!isProfileImg?.frame_profile?.frame_profile_url
                        ? isProfileImg?.frame_profile?.frame_profile_url
                        : default_Profile_cover
                    }
                    alt=""
                    className={`${
                      isProfile ? "mobile:hidden desktop:block" : ""
                    } absolute w-[60px] h-[60px] z-50`}
                  />

                  <img
                    className={`${
                      isProfile ? "mobile:hidden desktop:block" : ""
                    } absolute top-[0.59rem] right-[0.6rem] rounded-full !w-10 h-10 object-cover`}
                    alt="tempProfileIcon"
                    src={
                      !!user?.profile_image
                        ? user?.profile_image
                        : default_Profile_picture
                    }
                  />
                </div>
                <div className="mobile:hidden desktop:block ml-8 desktop:ml-0">
                  <img alt="..." src={dropdown} className="min-w-[11.99px]" />
                </div>
              </div>
            </div>
          )}

          {/* Profile Mobile */}
          <div className="mobile:block desktop:hidden">
            {!Authorization ? (
              <Link
                to={"/login"}
                className={`desktop:hidden ${isOpen ? "hidden" : ""} ${
                  show ? "hidden" : ""
                }`}
              >
                <img
                  alt="tempProfileIcon"
                  width={36}
                  height={36}
                  src={Profile1}
                />
              </Link>
            ) : (
              <div>
                {otherPage ? (
                  <div
                    className="w-5 flex justify-center cursor-pointer"
                    onClick={onClickToOtherPage}
                  >
                    <img
                      alt="OtherIcon"
                      width={5}
                      height={10}
                      src={OtherIcon}
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setOpenMenuIconAvatar(true);
                      setFocus(true);
                    }}
                    className={`flex items-center cursor-pointer  ${
                      isOpen ? "hidden" : "block"
                    } ${
                      !Authorization ? "desktop:hidden mobile:block" : "block"
                    } ${showHeader ? "block" : "hidden"}`}
                  >
                    <div
                      className={`rounded-full relative ${
                        isProfile ? "mobile:bg-GRAY111" : ""
                      }`}
                    >
                      <img
                        src={
                          !!isProfileImg?.frame_profile?.frame_profile_url
                            ? isProfileImg?.frame_profile?.frame_profile_url
                            : default_Profile_cover
                        }
                        alt="frameProfile"
                        // onError={() => setImageErr(true)}
                        className={`${
                          isProfile ? "mobile:hidden desktop:block" : ""
                        } w-[56px] h-[56px]`}
                      />
                      <img
                        className={`${
                          isProfile ? "mobile:hidden desktop:block" : ""
                        } absolute top-[0.625rem] left-[0.625rem] rounded-full w-9 h-9 object-cover`}
                        alt="tempProfileIcon"
                        src={
                          !!user?.profile_image
                            ? user?.profile_image
                            : default_Profile_picture
                        }
                      />
                    </div>
                    <div className="mobile:hidden desktop:block ml-8">
                      <img alt="..." src={dropdown} />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <SideBar
          isOpen={isOpen}
          setSearchField={setSearchField}
          searchField={searchField}
          mock_menu={mock_menu}
          handleClick={handleClick}
          isDropDown={isDropDown}
          selectId={selectId}
          setOpenMobile={setOpenMobile}
          openMobile={openMobile}
          setIsOpen={setIsOpen}
          input={input}
          setInput={setInput}
          setResults={setResults}
          results={results}
        />
      </nav>

      {openMenu === 1 ? (
        <div
          className="flex justify-between items-center bg_menu top-[88px] px-9"
          onMouseOver={() => setOpenMenu(1)}
          onMouseLeave={() => setOpenMenu(0)}
        >
          <div className="flex justify-between items-center max-w-[1576px] w-[100vw] mx-auto">
            {MENU_SELECT_HEADER.map(({ label, list }) => {
              return (
                <div key={`menu-${label}`} className="flex flex-col gap-10">
                  <p className="text-[20px] font-bold text-white">{label}</p>
                  <div className="flex flex-col gap-6">
                    {list.map((data, index) => {
                      return (
                        <Link
                          to={`${data.url}`}
                          key={index}
                          className="text-white"
                        >
                          {data.label}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {openMenu === 2 ? (
        <div
          onMouseOver={() => setOpenMenu(2)}
          onMouseLeave={() => setOpenMenu(0)}
        >
          <div className={`bg_market h-[376px] w-full top-[88px] `}>
            <div className="pt-10 px-[6rem] [@media(min-width:1250px)]:px-[8vw] relative max-w-[1920px] mx-auto">
              <button
                onClick={() => {
                  if (isLessThanLoopedSlidesForMarket) {
                    swipeForMarket?.slidePrev();
                  }
                }}
                className="absolute top-36 left-[3vw] z-10"
              >
                <img alt="" src={prev_home} />
              </button>

              <button
                onClick={() => swipeForMarket?.slideNext()}
                className="absolute top-36 right-[3vw] z-10"
              >
                <img alt="" src={next_home} />
              </button>

              <Swiper
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                  },
                  950: {
                    slidesPerView: 3,
                  },
                  1205: {
                    slidesPerView: 4,
                  },
                  1505: {
                    slidesPerView: 5,
                  },
                  1850: {
                    slidesPerView: 6,
                  },
                }}
                loop={true}
                onBeforeInit={(swiper) => setSwipeForMarket(swiper)}
                modules={[Navigation]}
                className="mySwiper swiper-container max-w-[1597px] mx-auto"
              >
                {marketItems?.map((game, index) => (
                  <SwiperSlide key={`marketplace-${index}`}>
                    <GameCard
                      key={index}
                      pic={game.game_picture}
                      label={game.game_name}
                      views={game.game_view_count}
                      game_id={game.game_id}
                    />
                  </SwiperSlide>
                ))}

                {isLessThanLoopedSlidesForMarket &&
                  marketItems?.map((game, index) => (
                    <SwiperSlide key={`gameIndex-${index}`}>
                      <GameCard
                        key={index}
                        pic={game.game_picture}
                        label={game.game_name}
                        views={game.game_view_count}
                        game_id={game.game_id}
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
            <div className="flex justify-center pt-2">
              <Link to={"marketplace"} className="text_style font-bold">
                All Game Index
              </Link>
            </div>
          </div>
        </div>
      ) : null}

      {openMenu === 3 ? (
        <div
          onMouseOver={() => setOpenMenu(3)}
          onMouseLeave={() => setOpenMenu(0)}
        >
          <div className="bg_market h-[376px] w-full">
            <div className="max-w-[1920px] mx-auto relative">
              <button
                className="absolute top-36 left-[3vw] z-10"
                onClick={() => {
                  if (isLessThanLoopedSlidesForTopUp) {
                    swipeForTopUP?.slidePrev();
                  }
                }}
              >
                <img alt="" src={prev_home} />
              </button>
              <button
                className="absolute top-36 right-[3vw] z-10"
                onClick={() => swipeForTopUP?.slideNext()}
              >
                <img alt="" src={next_home} />
              </button>
              <div className="pt-[3.5rem] px-[6rem] [@media(min-width:1250px)]:px-[10.625rem]">
                <Swiper
                  breakpoints={{
                    0: {
                      slidesPerView: 2,
                      // spaceBetween: 30,
                    },
                    //   431: {
                    //     slidesPerView: 1.5,
                    //   },
                    1250: {
                      slidesPerView: 3,
                      // spaceBetween: 40,
                    },
                    1550: {
                      slidesPerView: 4,
                      // spaceBetween: 50,
                    },
                    1860: {
                      slidesPerView: 5,
                    },
                  }}
                  loop={true}
                  onBeforeInit={(swiper) => setSwipeForTopUP(swiper)}
                  modules={[Navigation]}
                  className="mySwiper swiper-container max-w-[99.8125rem] min-w-0"
                  spaceBetween="20"
                >
                  {mockupDataTopUp.map((topUp, index) => (
                    <SwiperSlide key={`topUp-${index}`}>
                      <div className="max-w-[18.5rem] max-h-[15.375rem] min-w-[18.5rem] min-h-[15.375rem] mx-auto">
                        <img
                          key={index}
                          src={topUp.picture}
                          alt="PictureTopUp"
                          className="w-full h-full"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                  {isLessThanLoopedSlidesForTopUp &&
                    mockupDataTopUp.map((topUp, index) => (
                      <SwiperSlide key={`topUp-${index}`}>
                        <div className="max-w-[18.5rem] max-h-[15.375rem] min-w-[18.5rem] min-h-[15.375rem] mx-auto">
                          <img
                            key={index}
                            src={topUp.picture}
                            alt="PictureTopUp"
                            className="w-full h-full"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
              <div className="flex justify-center pt-9">
                <Link
                  to={"/comingsoon"}
                  className="bottom-6 text_style font-bold"
                >
                  All Top up
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {openMenu === 5 ? (
        <div
          onMouseOver={() => setOpenMenu(5)}
          onMouseLeave={() => setOpenMenu(0)}
          //   onClick={() => setOpenMenu(5)}
          className="mobile:hidden desktop:block desktop:absolute mobile:w-full desktop:w-[381px] desktop:h-[calc(100%-88px)] right-0 overflow-auto desktop:z-[9999px]"
        >
          <Profile_menu isProfile={isProfile} setIsProfile={setIsProfile} />
        </div>
      ) : null}

      {/*When click icon avatar profile is show SwipeableDrawer Profile_menu_Mobile */}
      <div>
        <Profile_menu_Mobile
          openMenuIconAvatar={openMenuIconAvatar}
          setOpenMenuIconAvatar={setOpenMenuIconAvatar}
          setActiveNav={setActiveNav}
          setFocus={setFocus}
        />
      </div>

      {/*When hover icon wallet show Dialog MyWallet */}
      <div>
        <MyWallet
          onClickOpenMyWallet={() => dispatch(setOpenDialogMyWallet(false))}
          onClickCloseMyWallet={() => dispatch(setOpenDialogMyWallet(true))}
        />
      </div>
    </header>
  );
};

export default Header;

const mockupDataTopUp = [
  { label: "Netflix Gift Card [TH]", picture: TopUp },
  { label: "Netflix Gift Card [TH]", picture: TopUp1 },
  { label: "Netflix Gift Card [TH]", picture: TopUp2 },
  { label: "Netflix Gift Card [TH]", picture: TopUp3 },
  { label: "Netflix Gift Card [TH]", picture: TopUp4 },
];
