import {
  requestClientChatHistory,
  requestClientPaymentAuth,
  requestClientPaymentAPIkey,
} from "./client";

export const ClientDeliveryTransaction = async (data) => {
  const res = await requestClientPaymentAuth.post(
    process.env.REACT_APP_DELIVERY_TRANSACTION_ENDPOINT,
    data
  );
  return res;
};

export const GetCancelReason = async (data) => {
  const res = await requestClientPaymentAPIkey.post(
    process.env.REACT_APP_PAYMENTTRANSACTION_CANCELREASON_ENDPOINT,
    data
  );
  return res;
};

export const ClientCancelOrder = async (data) => {
  const res = await requestClientPaymentAPIkey.post(
    process.env.REACT_APP_PAYMENTTRANSACTION_CANCELORDER_ENDPOINT,
    data
  );
  return res;
};

export const ClientDeliveryConfirm = async (data) => {
  const res = await requestClientPaymentAuth.post(
    process.env.REACT_APP_DELIVERY_CONFIRM_ENDPOINT,
    data
  );
  return res;
};

export const ClientChatHistory = async (data) => {
  const res = await requestClientChatHistory.post(
    process.env.REACT_APP_DELIVERY_CHATHISTORY_ENDPOINT,
    data
  );
  return res;
};
