import axios from "axios"
import Cookies from "js-cookie"

export const getProfileKYC = async (setStatus_KYC) => {
    await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_GET_PROFIILE_KYC, {
        headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`
        }
    })
        .then((res) => {
            setStatus_KYC([
                res.data.status_list.step_1_otp,
                res.data.status_list.step_2_id_card,
                res.data.status_list.step_3_image_match,
                res.data.status_list.step_4_bank_book
            ])
        })
        .catch(err => {
        })
}