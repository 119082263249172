import React from "react";
import IconStarRating from "../../assets/images/IconRating/IconStarRating.svg";
import IconStarRated from "../../assets/images/IconRating/IconStarRated.svg";
import IconStarHalfRating from "../../assets/images/IconRating/IconStarHalfRating.svg";

const IconRatingStarII = ({ rating }) => {
  return (
    <div className="flex gap-2">
      {rating >= 1 && (
        <img
          src={
            rating >= 1
              ? IconStarRated
              : rating < 1 && rating >= 0.5
              ? IconStarHalfRating
              : IconStarRating
          }
          alt="IconRating"
          className="w-[0.875rem]"
        />
      )}
      {rating >= 2 && (
        <img
          src={
            rating >= 2
              ? IconStarRated
              : rating < 2 && rating >= 1.5
              ? IconStarHalfRating
              : IconStarRating
          }
          alt="IconRating"
          className="w-[0.875rem]"
        />
      )}
      {rating >= 3 && (
        <img
          src={
            rating >= 3
              ? IconStarRated
              : rating < 3 && rating >= 2.5
              ? IconStarHalfRating
              : IconStarRating
          }
          alt="IconRating"
          className="w-[0.875rem]"
        />
      )}
      {rating >= 4 && (
        <img
          src={
            rating >= 4
              ? IconStarRated
              : rating < 4 && rating >= 3.5
              ? IconStarHalfRating
              : IconStarRating
          }
          alt="IconRating"
          className="w-[0.875rem]"
        />
      )}
      {rating >= 5 && (
        <img
          src={
            rating >= 5
              ? IconStarRated
              : rating < 5 && rating >= 4.5
              ? IconStarHalfRating
              : IconStarRating
          }
          alt="IconRating"
          className="w-[0.875rem]"
        />
      )}
    </div>
  );
};

export default IconRatingStarII;
