import axios from "axios";
import Cookies from "js-cookie";



export const UploadMultiple = async (pictureArray, setPictureUpload) => {

    let formdata = new FormData();
    pictureArray.map(({ file }) => {
        return formdata.append("files", file);
    });



    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_UPLOAD_MULTIPLE, formdata, {
        headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`
        }
    })
        .then((res) => {
            setPictureUpload(res.data.upload)
        })
        .catch((err) => {
            console.error(err);
        })
}

export const createOderGold = async (payload, setCreateStatus) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_CREATE_ORDER_GOLD, payload, {
        headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`
        }
    })
        .then((res) => {
            setCreateStatus({
                status:res.data.status,
                item_game_id:res.data.item_game_id,
                item_product_id:res.data.item_product_id
            })
        })
        .catch((err) => {
            console.error(err);
        })
}

export const createOderAccount = async (accountPayload, setCreateStatus) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_CREATE_ORDER_ACCOUNT, accountPayload, {
        headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`
        }
    })
        .then((res) => {
            setCreateStatus({
                status:res.data.status,
                item_game_id:res.data.item_game_id,
                item_product_id:res.data.item_product_id
            })
        })
        .catch((err) => {
            console.error(err);
        })
}


export const createOderItem = async (payload, setCreateStatus) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_CREATE_ORDER_ITEM, payload, {
        headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`
        }
    })
        .then((res) => {
            setCreateStatus({
                status:res.data.status,
                item_game_id:res.data.item_game_id,
                item_product_id:res.data.item_product_id
            })
        })
        .catch((err) => {
            console.error(err);
        })
}