import {
    icon_helmet, icon_gold, icon_sword, icon_growth,
    icon_helmet_activated, icon_gold_activated, icon_sword_activated, icon_growth_activated,
    icon_filter, icon_sort,
} from '../../assets/icons'
import { useState, useEffect } from 'react'


const CATEGORY = [
    { name: 'account', label: 'Account', inactive: icon_helmet, icon: icon_helmet_activated, id: 2, active: true },
    { name: 'gold', label: 'Gold', inactive: icon_gold, icon: icon_gold_activated, id: 3, active: true },
    { name: 'item', label: 'Item', inactive: icon_sword, icon: icon_sword_activated, id: 1, active: true },
    { name: 'service', label: 'service', inactive: icon_growth, icon: icon_growth_activated, id: 4, active: false },
]

const Category = ({ cateSelect, setCateSelect }) => {
    return (
        <div className='desktop:w-[33rem] desktop:max-w-full desktop:min-w-0 mobile:max-w-[25.875rem] mobile:w-full overflow-auto gap-[1px] desktop:h-[9.5rem] mobile:h-[6rem] flex justify-between '>
            {CATEGORY?.map(({ name, label, inactive, icon, id, active }, index) => (
                <button
                    type='button'
                    key={index}
                    className={`desktop:w-[7.5rem] desktop:max-w-full desktop:min-w-0 h-full mobile:w-[6rem] flex flex-col items-center justify-between bg-[#282828] rounded  ${!active ? "grayscale cursor-default" : "cursor-pointer"}`}
                    onClick={() => setCateSelect(id)}
                    disabled={!active}
                >
                    <div className='gap-2 flex flex-col justify-center items-center max-w-full min-w-0 w-full desktop:h-[7.5rem] mobile:h-[6rem]'>
                        <img src={icon} alt={name} className={`w-10 h-10 object-contain ${cateSelect === id ? "grayscale-0" : "grayscale"}`} />
                        <p className='text-white text-base font-bold '>{label}</p>
                    </div>
                    <div className='w-full max-w-full min-w-0 desktop:h-[2rem] desktop:flex justify-center items-center mobile:hidden'>
                        <div className='border-[3px] border-[#BB213D] rounded-full  w-5 h-5 flex justify-center items-center'>
                            {cateSelect === id && (
                                <div className=' bg-[#BB213D] flex rounded-full w-2 h-2'>
                                </div>
                            )}
                        </div>
                    </div>
                </button>

            ))}
        </div>
    )
}

export default Category