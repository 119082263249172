import { Dialog, DialogBody, ThemeProvider } from "@material-tailwind/react";
import CloseButton from "./closeButton";
import { useState } from "react";

const NotifyStepFacerecognition = ({ theme, openNotifyStep2, windowSize, handleOpenNotifyStep2, errorMessage }) => {
    return (
        <ThemeProvider
            value={theme}
        >
            <Dialog
                open={openNotifyStep2}
                size={windowSize[0] > 768 ? "sm" : "xs"}
                className="bg-[#1A1A1A] relative rounded overflow-hidden button-0"
            >
                <DialogBody
                    className="desktop:relative  flex flex-col items-center h-[16.375rem]  overflow-auto"
                >
                    {/*Close Button  */}
                    <div className="w-full h-full desktop:p-4 flex flex-col justify-evenly items-center gap-5 font-IBMPlex">
                        <p className="text-white font-bold text-[32px]">FACE RECOGNITION ERROR!</p>
                        <p className="text-base font-normal text-white">You failed to pass verification, Please recheck{errorMessage}.</p>
                        <button
                            className="w-[25rem] h-[3.5rem] bg-[#BB213D] hover:bg-RED001/[0.8] rounded text-white font-IBMPlex uppercase font-bold"
                            onClick={handleOpenNotifyStep2}
                        >
                            ok
                        </button>
                    </div>
                </DialogBody>
            </Dialog>
        </ThemeProvider>
    )
}

export default NotifyStepFacerecognition