import React, { useState, useEffect } from "react";
import TableCheckProgress from "./TableCheckProgress";
// import { Select, Option } from "@material-tailwind/react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { GRAY910, GRAY920 } from "../../../theme/color";
import Filter2 from "../../../assets/images/filter/filter2.svg";
import ItemDetailMobileCheckoutProgress from "./MobileCheckoutProgress/ItemDetailMobileCheckoutProgress";
import { BorderButton, SubmitButton } from "../../Button";
import PaginationCheckoutProgress from "./PaginationCheckoutProgress";
import { changePxToRem } from "../../../utils/changePixeltoRem";

const DetailCheckoutProgress = ({
  filter,
  setFilter,
  selectProgress,
  setSelectProgress,
  progressStatus,
  page,
  setPage,
  pageForPagination,
  dataCheckoutProgress,
  dataCheckoutProgressItemQC,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTransactionPurchasePage, setIsTransactionPurchasePage] =
    useState(true);
  const [selectTransactionPurchaseMobile, setSelectTransactionPurchaseMobile] =
    useState("payment");

  useEffect(() => {
    const handleWindowResize = () => {
      const isMobileScreen = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(isMobileScreen);
    };

    // Initial check when the component mounts
    handleWindowResize();

    // When isn't MobileScreen setIsTransactionPurchasePage
    if (isMobile === false) {
      setIsTransactionPurchasePage(true);
    }

    // Add event listener to update the state on window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isMobile]);

  const onClickSelectTransactionPurchaseMobile = (e) => {
    e.preventDefault();
    setFilter(selectTransactionPurchaseMobile);
    setIsTransactionPurchasePage(true);
  };

  const onClickNameSelectionProgress = (buttonName) => {
    setSelectProgress(buttonName);
    setPage(1);
  };

  const openIsTransactionPurchasePage = (e) => {
    e.preventDefault();
    setSelectTransactionPurchaseMobile(filter);
    setIsTransactionPurchasePage(false);
  };
  return (
    <div className="w-full">
      {isTransactionPurchasePage ? (
        <div className="w-full flex flex-col desktop:gap-8 mobile:gap-3">
          <div className="mobile:flex items-center justify-between">
            <p className="font-[700] desktop:text-[32px] mobile:text-2xl text-white uppercase">
              {filter === "payment"
                ? "Transaction Purchase"
                : "Products qualification"}
            </p>
            <div
              className="py-2 px-[0.625rem] bg-GRAY910 hover:bg-white/[0.2] rounded mobile:block desktop:hidden"
              onClick={openIsTransactionPurchasePage}
            >
              <img src={Filter2} alt="filter" className="min-w-6 h-auto" />
            </div>
          </div>

          <div className="flex justify-between  w-full">
            <div className="flex flex-wrap mobile:flex-col desktop:flex-row desktop:items-center desktop:gap-3 mobile:gap-3">
              <div className="flex flex-wrap desktop:gap-3 mobile:gap-[0.375rem] items-center">
                {filter === "payment"
                  ? nameSelectionProgressPayment.map(({ name }) => {
                      return (
                        <div>
                          <ButtonSelectProgress
                            buttonName={name}
                            key={name}
                            setSelectProgress={setSelectProgress}
                            selectProgress={selectProgress}
                            setPage={setPage}
                            onClick={() => onClickNameSelectionProgress(name)}
                          />
                        </div>
                      );
                    })
                  : nameSelectionProgressItemsQc.map(({ name }) => {
                      return (
                        <ButtonSelectProgress
                          buttonName={name}
                          key={name}
                          setSelectProgress={setSelectProgress}
                          selectProgress={selectProgress}
                          setPage={setPage}
                          onClick={() => onClickNameSelectionProgress(name)}
                        />
                      );
                    })}
              </div>
              <div>
                <p className="font-[700] desktop:text-base mobile:text-xs text-white">
                  {filter === "payment"
                    ? dataCheckoutProgress?.count
                      ? dataCheckoutProgress?.count
                      : 0
                    : dataCheckoutProgressItemQC?.totalItem}
                  <span className="desktop:font-[400] mobile:font-[700] desktop:text-base mobile:text-xs text-white">
                    {` Results`}
                  </span>
                </p>
              </div>
            </div>
            <div className="min-w-[150px] font-[400] text-base text-white desktop:block mobile:hidden">
              <SelectTransactionPurchase
                selectFrom="desktop"
                filter={filter}
                setFilter={setFilter}
                setSelectTransactionPurchaseMobile={
                  setSelectTransactionPurchaseMobile
                }
                setSelectProgress={setSelectProgress}
              />
            </div>
          </div>

          {/* Table cell desktop*/}
          <div className="desktop:block mobile:hidden min-h-[59vh]">
            <TableCheckProgress
              dataPayment={dataCheckoutProgress}
              dataItemQC={dataCheckoutProgressItemQC}
              filter={filter}
            />
          </div>

          {/* ItemDetailMobileCheckoutProgress */}
          <div className="desktop:hidden mobile:block">
            <ItemDetailMobileCheckoutProgress
              dataPayment={dataCheckoutProgress}
              dataItemQC={dataCheckoutProgressItemQC}
              filter={filter}
            />
          </div>

          {/* Pagination */}
          {pageForPagination > 1 && (
            <div className="desktop:block mobile:block">
              {/* <PaginationCheckoutProgressVersionTwo
                itemsPerPage={5}
                setPage={setPage}
                pageForPagination={pageForPagination}
              /> */}
              <PaginationCheckoutProgress
                pageForPagination={pageForPagination}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="w-full h-[90vh] desktop:hidden mobile:flex flex-col justify-between desktop:gap-8 mobile:gap-3">
          <div className="mobile:flex flex-col gap-4">
            <p className="font-[700]  mobile:text-2xl text-white uppercase">
              SORT
            </p>
            <div className="w-full">
              <SelectTransactionPurchaseMobile
                selectTransactionPurchaseMobile={
                  selectTransactionPurchaseMobile
                }
                setSelectTransactionPurchaseMobile={
                  setSelectTransactionPurchaseMobile
                }
                setSelectProgress={setSelectProgress}
                selectFrom="mobile"
                filter={filter}
              />
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <SubmitButton
              text="Apply"
              onClick={onClickSelectTransactionPurchaseMobile}
            />

            <BorderButton
              text="DEFAULT"
              styleBtn=""
              onClick={() => setIsTransactionPurchasePage(true)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailCheckoutProgress;

const nameSelectionProgressPayment = [
  {
    name: "All",
  },
  {
    name: "Pending",
  },
  {
    name: "Delivery",
  },
  {
    name: "Success",
  },
  {
    name: "Canceled",
  },
];

const nameSelectionProgressItemsQc = [
  {
    name: "All",
  },
  {
    name: "Product QC",
  },
  {
    name: "Expired",
  },
];

const ButtonSelectProgress = ({
  buttonName,
  setSelectProgress,
  selectProgress,
  setPage,
  onClick,
}) => {
  return (
    <div
      className={`border ${
        selectProgress === buttonName ? "border-RED001" : "border-GRAY920"
      } rounded cursor-pointer`}
      onClick={onClick}
    >
      <p
        className={`font-[500] desktop:text-base mobile:text-xs ${
          selectProgress === buttonName ? "text-RED001" : "text-GRAY920"
        } px-4 py-[0.375rem]`}
      >
        {buttonName}
      </p>
    </div>
  );
};

const SelectTransactionPurchase = ({
  selectFrom,
  filter,
  setFilter,
  setSelectProgress,
}) => {
  const handleSelectTransactionPurchase = (e) => {
    e.preventDefault();
    setFilter(e.target.value);
    setSelectProgress("All");
  };
  return (
    // <select
    //   className="peer h-full w-full rounded bg-GRAY910 px-3 py-2.5 text-base font-[700] text-center text-white outline outline-0  transition-all  empty:!bg-red-500 disabled:border-0 disabled:bg-blue-gray-50"
    //   onChange={handleSelectTransactionPurchase}
    // >
    //   <option value="payment" className="text-base font-[700]">
    //     PAYMENT
    //   </option>
    //   <option value="itemsQc" className="text-base font-[700]">
    //     ITEMS QC
    //   </option>
    // </select>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: changePxToRem(20),
        minWidth: selectFrom === "desktop" ? 0 : 0,
        borderRadius: changePxToRem(4),
        // bgcolor: "red",
      }}
    >
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          //   value={filter}
          displayEmpty
          renderValue={() => (
            <p className="desktop:text-base mobile:text-base font-[700] text-white uppercase text-center pt-[2px]">
              {filter}
            </p>
          )}
          onChange={handleSelectTransactionPurchase}
          sx={{
            bgcolor: GRAY910,
            color: GRAY920,

            "& .MuiOutlinedInput-input": {
              padding: `${changePxToRem(11)}`,
            },
            "& .MuiSelect-icon": {
              color: "white",
              fontSize: { xs: "38px", sm: "30px" },
            },
          }}
          MenuProps={{
            sx: {
              marginTop: changePxToRem(5),
              zIndex: 999,
              "& .MuiMenu-paper": {
                backgroundColor: "transparent",
              },
              "& .MuiList-root": {
                backgroundColor: GRAY910,
              },
            },
          }}
        >
          <MenuItem value={"payment"} key={"payment"}>
            <p className="desktop:text-base mobile:text-base font-[700] text-white uppercase">
              payment
            </p>
          </MenuItem>
          <MenuItem value={"Items QC"} key={"Items QC"}>
            <p className="desktop:text-base mobile:text-base font-[700] text-white uppercase">
              Items QC
            </p>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const SelectTransactionPurchaseMobile = ({
  selectTransactionPurchaseMobile,
  setSelectTransactionPurchaseMobile,
  selectFrom,
  setSelectProgress,
}) => {
  const handleSelectTransactionPurchase = (e) => {
    e.preventDefault();
    setSelectTransactionPurchaseMobile(e.target.value);
    setSelectProgress("All");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: changePxToRem(20),
        minWidth: selectFrom === "desktop" ? 0 : 0,
        borderRadius: changePxToRem(4),
        // bgcolor: "red",
      }}
    >
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          //   value={filter}
          displayEmpty
          renderValue={() => (
            <p className="desktop:text-base mobile:text-base font-[700] text-white uppercase text-center pt-[2px]">
              {selectTransactionPurchaseMobile}
            </p>
          )}
          onChange={handleSelectTransactionPurchase}
          sx={{
            bgcolor: GRAY910,
            color: GRAY920,
            "& .MuiOutlinedInput-input": {
              padding: `${changePxToRem(11)}`,
            },
            "& .MuiSelect-icon": {
              color: "white",
              fontSize: { xs: "38px", sm: "30px" },
            },
          }}
          MenuProps={{
            sx: {
              marginTop: changePxToRem(5),
              zIndex: 999,
              "& .MuiMenu-paper": {
                backgroundColor: "transparent",
              },
              "& .MuiList-root": {
                backgroundColor: GRAY910,
              },
            },
          }}
        >
          <MenuItem value={"payment"} key={"payment"}>
            <p className="desktop:text-base mobile:text-base font-[700] text-white uppercase">
              payment
            </p>
          </MenuItem>
          <MenuItem value={"Items QC"} key={"Items QC"}>
            <p className="desktop:text-base mobile:text-base font-[700] text-white uppercase">
              Items QC
            </p>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
