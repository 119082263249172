import {
  Outlet,
  Link,
  useLocation,
  Navigate,
  redirect,
} from "react-router-dom";
import {
  Account,
  Darkmode,
  Dbsync,
  Kyc,
  Lang,
  Legal,
  Manual,
  Payment,
  Rank,
  Security,
} from "../../assets/setting/menubar_icons/select";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BaseDialog from "../../component/common/BaseDialog";
import { DialogBody, DialogHeader } from "@material-tailwind/react";
import { SubmitButton } from "../../component/Button";
import Cookies from "js-cookie";

const Setting = () => {
  const pathName = useLocation().pathname.split("/");
  const topic_setting = [
    { icon: <Account />, label: "Account", path: "account" },
    { icon: <Kyc />, label: "KYC", path: "kyc" },
    { icon: <Security />, label: "Security", path: "security" },
    { icon: <Payment />, label: "Payment", path: "/comingsoon" },
    { icon: <Lang />, label: "Language", path: "/comingsoon" },
    { icon: <Darkmode />, label: "L & B Mode", path: "/comingsoon" },
    { icon: <Legal />, label: "Legal Docs", path: "/comingsoon" },
    { icon: <Manual />, label: "Instruction Manual", path: "/comingsoon" },
    { icon: <Rank />, label: "Ranking", path: "/comingsoon" },
    { icon: <Dbsync />, label: "DB Sync", path: "/comingsoon" },
  ];
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [isShow, setIsShow] = useState(pathName[2]);
  const user = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const path = location?.pathname?.split("/");
  useEffect(() => {
    window.scrollTo(0, 0);

    if (path[2] === "security" && !user?.kyc_step_seller) {
      setIsOpen(true);
    }
  }, [path]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  if (windowSize[0] > 768 && pathName?.length < 3) {
    window.location.replace("/setting/account");
  }

  return (
    <div className="flex desktop:w-[1576px] desktop:max-w-[1576px] desktop:min-w-0  mobile:w-full  justify-center bg-[#1E1E1E] mobile:pt-4 desktop:pt-[60px] pb-[74px] px-3 min-h-screen h-fit">
      <div className="desktop:flex desktop:w-full desktop:justify-between  mobile:flex mobile:w-full">
        <div
          className={`desktop:w-[310px]  desktop:flex flex-col gap-3 ${
            pathName[2] ? "mobile:hidden" : "mobile:w-full"
          }`}
        >
          <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[20px] font-bold mobile:hidden desktop:flex">
            Settings
          </p>
          <div className="desktop:w-full flex flex-col desktop:border-l desktop:border-l-[#414141] desktop:px-0  desktop:h-[480px] desktop:justify-between  mobile:gap-3 mobile:px-3 mobile:w-full">
            {topic_setting.map(({ icon, label, path }, index) => {
              if (label === "Security" && !user?.kyc_step_seller) {
                return (
                  <div
                    key={index}
                    className={`flex desktop:w-full gap-[10px] desktop:h-9 desktop:rounded-none items-center px-9  ${
                      isShow === path
                        ? " desktop:border-l-[#BB213D] desktop:border-l-4 desktop:opacity-100"
                        : "desktop:opacity-60"
                    } desktop:hover:opacity-95 desktop:cursor-pointer mobile:h-[52px] mobile:w-full mobile:opacity-100 mobile:cursor-pointer mobile:px-3 mobile:bg-[#282828] mobile:rounded-[4px]`}
                    onClick={() => setIsOpen(true)}
                  >
                    <div className="w-[22px] h-[22px]">{icon}</div>
                    <div className="pt-1">
                      <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold mobile:uppercase desktop:normal-case">
                        {label}
                      </p>
                    </div>
                  </div>
                );
              } else {
                return (
                  <Link
                    to={path}
                    key={index}
                    className={`flex desktop:w-full gap-[10px] desktop:h-9 desktop:rounded-none items-center px-9  ${
                      isShow === path
                        ? " desktop:border-l-[#BB213D] desktop:border-l-4 desktop:opacity-100"
                        : "desktop:opacity-60"
                    } desktop:hover:opacity-95 desktop:cursor-pointer mobile:h-[52px] mobile:w-full mobile:opacity-100 mobile:cursor-pointer mobile:px-3 mobile:bg-[#282828] mobile:rounded-[4px]`}
                    onClick={() => setIsShow(path)}
                  >
                    <div className="w-[22px] h-[22px]">{icon}</div>
                    <div className="pt-1">
                      <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold mobile:uppercase desktop:normal-case">
                        {label}
                      </p>
                    </div>
                  </Link>
                );
              }
            })}
          </div>
        </div>
        <div
          className={`desktop:max-w-[68.625rem] desktop:min-w-0 desktop:w-[68.625rem]    ${
            pathName[2] ? "mobile:w-full" : "mobile:hidden"
          }`}
        >
          <Outlet />
        </div>
      </div>
      <BaseDialog open={isOpen} stylesDialog="desktop:min-w-[45%]">
        <div className="w-[100%] flex flex-col desktop:gap-6 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            Permission was denied
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
            <div className="flex justify-center">
              <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                Please complete your verification KYC 4 step
              </p>
            </div>
            <div className="flex justify-center desktop:gap-5">
              <div className="desktop:w-[60%] mobile:w-[100%] ">
                {/* <Link to="/setting/kyc"> */}
                <SubmitButton
                  text="OK"
                  styleBtn="mobile:rounded-none mobile:rounded-br desktop:rounded"
                  onClick={() => {
                    setIsOpen(false);
                    return (window.location.href = "/setting/kyc");
                  }}
                />
                {/* </Link> */}
              </div>
            </div>
          </DialogBody>
        </div>
      </BaseDialog>
    </div>
  );
};

export default Setting;
