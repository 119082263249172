import { useState, useEffect } from "react"
import {iconClose } from "../../../assets/setting/KYC";
import icon_arrow_left from "../../../assets/setting/icon_arrow_left.svg"


const CloseButton = ({ handleOpen }) => {
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight])
        }

        window.addEventListener('resize', handleWindowResize)
        return () => window.removeEventListener('resize', handleWindowResize)
    }, []);
    return (
        <div
            className="desktop:w-[60px] desktop:h-[60px] desktop:absolute desktop:top-0 desktop:right-0 desktop:flex desktop:justify-center desktop:items-center desktop:bg-[#282828] desktop:hover:bg-[#303030] desktop:rounded-bl-[50px] desktop:cursor-pointer"
            onClick={handleOpen}
        >
            {windowSize[0] > 768
                ? <img src={iconClose} alt="iconClose" className="desktop:w-15 desktop:h-15 desktop:ml-2 desktop:mb-2" />
                : <img src={icon_arrow_left} alt="icon_arrow_left" className="desktop:w-15 desktop:h-15 desktop:ml-2 desktop:mb-2 mobile:mb-4" />
            }
        </div>
    )
}

export default CloseButton