import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDisplay, setUrlPath } from "../../slice/goBackBtnSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { next_home, prev_home } from "../../assets/home/icons";
import Searchbar_Index from "../../component/Searchbar_Index";
import GameGrouping from "../../component/GameGrouping";
import GameCard from "../../component/GameCard";
import { getGames } from "../../api";
import { getMarketItems } from "../../api/get/Homepage";
import { viewGame } from "../../api/post/marketplace";
import rigthArrow from "../../assets/rigth_arrow.svg";
import { useSearchParams, useLocation } from "react-router-dom";
import { Icon_views, Icon_dropdown } from "../../assets/icons";
import NoResult_pic from "../../assets/NoResult_pic";
import { getTotalItem } from "../../api/get/marketplace";

export default function Marketplace() {
  const lock = useRef(false);
  const goBack = useDispatch();
  const [games, setGames] = useState({});
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [marketItems, setMarketItems] = useState();
  const swipe = useRef();
  //   const [swipe, setSwipe] = useState();
  const [value, setValue] = useState(null);
  const [searchValue, setSearchValue] = useState();
  const [alphas, setAlphas] = useState([]);
  const [btnHold, setBtnHold] = useState("ALL");
  const [searchName, setSearchName] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const name = searchParams.get("name");
  const [isOpen, setIsOpen] = useState(false);
  const [gameId] = useState(1);
  const [isViewGame, setIsViewGame] = useState();
  const [selectedGame, setSelectedGame] = useState();
  const [totalItem, setTotalItem] = useState();
  const marketLength = marketItems?.length || 0;
  const isLessThanLoopedSlides = marketLength >= swipe.current?.loopedSlides;

  const prepareAlphabets = () => {
    for (let i = 65; i <= 90; i++) {
      setAlphas((prevs) => [...prevs, String.fromCharCode(i)]);
    }
    setAlphas((prevs) => [...prevs, "#"]);
  };

  const breadcrumbsLink = [{ label: "Home", path: "/" }];

  useEffect(() => {
    getGames(setGames, setSearchName, searchParams.get("name"));
    getMarketItems(setMarketItems);
    if (!!name) {
      setValue(name)
      setSearchValue(name)
    };
    prepareAlphabets();
    goBack(setDisplay(false));
    goBack(setUrlPath("/"));
    getTotalItem(setTotalItem);
  }, []);


  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // useEffect(() => {
  //   if (windowSize[0] <= 768 && !!name) {
  //     window.location.href = `marketplace`
  //   }
  // }, [windowSize])

  return (
    <main className="desktop:w-[111.3125rem] desktop:max-w-[111.3125] desktop:min-w-0 mobile:w-full text-white bg-GRAY650 desktop:pt-10 desktop:pb-[3.75rem] mobile:px-4 mobile:pt-4 mobile:pb-5 flex  flex-col items-center">
      <section className="flex-col desktop:flex gap-9 mobile:hidden desktop:max-w-[99.8125rem] desktop:w-full desktop:min-w-0">
        <div className="flex items-center text-white">
          {breadcrumbsLink?.map((link, index) => (
            <div key={index} className="inline-block">
              <Link to={link.path}>
                <h3 className="inline text-base font-bold underline">
                  {link.label}
                </h3>
              </Link>
              <img
                src={rigthArrow}
                alt=""
                width="8"
                height="12"
                className="inline-block mx-3"
              />
            </div>
          ))}
          <h3 className="inline text-base font-bold text-[#CB8E44]">
            Game Index
          </h3>
        </div>
        <hr className="border-b opacity-25 border-b-white" />
      </section>
      <section className="flex flex-col desktop:max-w-[99.8125rem] desktop:w-full desktop:min-w-0">
        <div className="flex items-center justify-between desktop:mt-5 mobile:w-full ">
          <h1 className="desktop:text-[2rem] desktop:leading-[3.3rem] font-bold mobile:text-2xl">
            Game Index
          </h1>
          <p className="text-base font-bold text-white desktop:block mobile:hidden">
            {totalItem} Items
          </p>
        </div>
      </section>
      <section className=" desktop:flex desktop:w-full desktop:max-w-[111.3125rem] desktop:min-w-0  justify-between items-center mt-5 gap-x-[1.56rem] mobile:hidden">
        <button
          className="w-[3.25rem] h-[3.25rem] "
          onClick={() => {
            if (isLessThanLoopedSlides) {
              swipe.current?.slidePrev();
            }
          }}
        >
          <img alt="" src={prev_home} />
        </button>

        <Swiper
          className="mySwiper swiper-container flex gap-x-6 mySwiper w-full max-w-[99.8125rem]  min-w-0"
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            902: {
              slidesPerView: 3,
            },
            1150: {
              slidesPerView: 4,
            },
            1410: {
              slidesPerView: 5,
            },
            1660: {
              slidesPerView: 6,
            },
          }}
          spaceBetween="24"
          loop={true}
          onSwiper={(swiper) => (swipe.current = swiper)}
          modules={[Navigation]}
        >
          {marketItems?.map((game, index) => (
            <SwiperSlide key={`gameIndex-${index}`}>
              <GameCard
                key={index}
                pic={game.game_picture}
                label={game.game_name}
                views={game.game_view_count}
                game_id={game.game_id}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
            </SwiperSlide>
          ))}

          {isLessThanLoopedSlides &&
            marketItems?.map((game, index) => (
              <SwiperSlide key={`gameIndex-${index}`}>
                <GameCard
                  key={index}
                  pic={game.game_picture}
                  label={game.game_name}
                  views={game.game_view_count}
                  game_id={game.game_id}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                />
              </SwiperSlide>
            ))}
        </Swiper>
        <button
          className="w-[3.25rem] h-[3.25rem]"
          onClick={() => {
            console.log(swipe.current);
            swipe.current?.slideNext();
          }}
        >
          <img alt="" src={next_home} />
        </button>
      </section>
      <section className="flex flex-col desktop:max-w-[99.8125rem] desktop:w-full desktop:min-w-0 mobile:w-full">
        <Searchbar_Index
          handleChange={(event) => setSearchValue(event.target.value)}
          onClick={() =>
            (window.location.href = `marketplace?name=${searchValue}`)
          }
          name={name}
          value={searchValue}
        />
        <div className="w-full mt-6 desktop:flex gap-x-6 mobile:hidden">
          <div className="flex flex-col gap-y-6">
            {["ALL", "NEW"].map((btn, index) => (
              <button
                type="button"
                key={index}
                value={btn}
                onClick={(event) => {
                  setValue(null);
                  setBtnHold(event.target.value);
                }}
                className={`flex items-center justify-center w-[3.81rem] h-12 text-base font-bold rounded ${btnHold === btn ? "bg-RED001" : "bg-GRAY910"
                  }`}
              >
                {btn}
              </button>
            ))}
          </div>
          <div className="w-full flex flex-wrap gap-6">
            {alphas?.map((btn, index) => (
              <button
                type="button"
                key={index}
                value={btn}
                onClick={(event) => {
                  setValue(null);
                  setBtnHold(event.target.value);
                }}
                className={`flex items-center justify-center w-12 h-12 text-base font-bold rounded ${btnHold === btn ? "bg-RED001" : "bg-GRAY910"
                  }`}
              >
                {btn}
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-full desktop:mt-10 mobile:mt-0 desktop:h-auto mobile:min-h-screen mobile:h-auto">
          {value === null ? (
            btnHold === "ALL" ? (
              Object.keys(games).map((i) => (
                <GameGrouping cate={i} hold={btnHold} value={games} />
              ))
            ) : (
              <>
                {windowSize[0] <= 768 ? (
                  Object.keys(games).map((i) => (
                    <GameGrouping cate={i} hold={btnHold} value={games} />
                  ))
                ) : (
                  <GameGrouping cate={btnHold} hold={btnHold} value={games} />
                )}
              </>
            )
          ) : (
            <div>
              <section
                className={`relative flex w-full desktop:justify-start desktop:h-auto desktop:gap-x-[6.44rem] desktop:mt-10 desktop:items-start desktop:bg-transparent mobile:rounded mobile:justify-center mobile:mt-4 mobile:items-center mobile:h-[3.25rem] ${isOpen ? "mobile:bg-black" : "mobile:bg-GRAY910"
                  }`}
                onClick={() => setIsOpen(!isOpen)}
              >
                <h1 className="desktop:mt-[-.3rem] w-[3.5rem] desktop:text-2xl font-bold  mobile:text-base mobile:mt-0 mobile:text-center">
                  {name?.charAt(0).toUpperCase() + name.slice(1)}
                </h1>
                <div
                  className={
                    " desktop:flex flex-wrap gap-[10px] justify-center w-full h-full mobile:hidden"
                  }
                >
                  {searchName
                    ?.filter((game) =>
                      game.game_name
                        .toLowerCase()
                      // .includes(name.toLocaleLowerCase())
                    )
                    ?.map((game, index) => (
                      <div
                        key={`game=${index}`}
                        className="flex w-[24.2rem] h-[13.75rem]"
                      >
                        <Link
                          to={`/marketplace/${game?.game_name}?gameID=${game?.game_id}&cate=All`}
                          className="w-[10rem] h-[13.75rem]"
                        >
                          <img
                            src={game.game_cover_picture}
                            className="cursor-pointer w-full h-full object-cover"
                            alt=""
                          />
                        </Link>
                        <div className="flex flex-col flex-grow-1 ml-5 gap-y-2.5 text-GRAY920">
                          <Link
                            to={`/marketplace/${game?.game_name}?gameID=${game?.game_id}&cate=All`}
                            className="mt-[-.3rem] text-2xl font-bold text-white cursor-pointer"
                            onClick={() => {
                              // push(`/marketplace/${data.game_name}`)
                              // getView(data?.id)
                            }}
                          >
                            {game.game_name}
                          </Link>
                          <div className="w-full">
                            <Icon_views className="inline-block mb-0.5 [&>svg>path]:fill-GRAY920" />
                            <span className="text-base font-medium ml-1.5 cursor-default">
                              {game.game_view_count} views
                            </span>
                          </div>
                          <div className="flex flex-col gap-y-1">
                            {game.item_category.map((cate, index) => (
                              <Link
                                to={`/marketplace/${game?.game_name}?gameID=${game?.game_id}&cate=${cate}`}
                                key={index}
                                className="text-sm font-normal cursor-pointer flex justify-between w-full"
                              >
                                {cate}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    )) ?? (
                      <div className="flex-col items-center justify-center w-full text-white desktop:flex">
                        <NoResult_pic className="w-[25.5rem] h-[17rem]" />
                        <h1 className="text-[2rem] font-bold mt-5">
                          No Result Found
                        </h1>
                        <span className="mt-5 text-base font-normal">
                          Please try selecting different filters or try another
                        </span>
                        <span className="text-base font-normal">
                          keywords for better results.
                        </span>
                      </div>
                    )}
                </div>
                <Icon_dropdown
                  className={`absolute my-auto right-3 desktop:hidden ${isOpen ? "rotate-180" : ""
                    }`}
                  onClick={() => setIsOpen(!isOpen)}
                />
              </section>
              {isOpen && (
                <div className=" mt-4 w-full">
                  <div className={"grid grid-cols-1 mt-4 gap-y-4"}>
                    {searchName
                      ?.filter((game) =>
                        game.game_name
                          .toLowerCase()
                        // .includes(name.toLocaleLowerCase())
                      )
                      .map((game, index) => (
                        <div
                          key={`game=${index}`}
                          className="flex w-full h-[10.75rem]"
                        >
                          <Link
                            to={`/marketplace/${game?.game_name}?gameID=${game?.game_id}&cate=All`}
                            className="w-[10rem] h-[10.75rem]"
                          >
                            <img
                              src={game.game_cover_picture}
                              className="cursor-pointer w-full h-full object-contain"
                              alt=""
                            />
                          </Link>
                          <div className="flex flex-col flex-grow-1 ml-5 gap-y-2.5 text-GRAY920">
                            <Link
                              to={`/marketplace/${game?.game_name}?gameID=${game?.game_id}&cate=All`}
                              className="mt-[-.3rem] text-2xl font-bold text-white cursor-pointer"
                              onClick={() => {
                                // push(`/marketplace/${data.game_name}`)
                                // getView(data?.id)
                              }}
                            >
                              {game.game_name}
                            </Link>
                            <div className="w-full">
                              <Icon_views className="inline-block mb-0.5 [&>svg>path]:fill-GRAY920" />
                              <span className="text-base font-medium ml-1.5 cursor-default">
                                {game.game_view_count} views
                              </span>
                            </div>
                            <div className="flex flex-col gap-y-1">
                              {game.item_category.map((cate, index) => (
                                <Link
                                  to={`/marketplace/${game?.game_name}?gameID=${game?.game_id}&cate=${cate}`}
                                  key={index}
                                  className="text-sm font-normal cursor-pointer flex justify-between w-full"
                                >
                                  {cate}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      )) ?? (
                        <div className="flex-col items-center justify-center w-full text-white desktop:flex">
                          <NoResult_pic className="w-[25.5rem] h-[17rem]" />
                          <h1 className="text-[2rem] font-bold mt-5">
                            No Result Found
                          </h1>
                          <span className="mt-5 text-base font-normal">
                            Please try selecting different filters or try another
                          </span>
                          <span className="text-base font-normal">
                            keywords for better results.
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </main>
  );
}
