import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/free-mode"
import App from "./App";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Login from "./page/login/Signin";
import Register from "./page/register/Register";
import VerifyEmail from "./page/register/VerifyEmail";
import KYC from "./page/setting/KYC";
import Security from "./page/setting/Security";
import QRCode_Authenticator from "./page/setting/QRCode";
import Home from "./page/Home";
import DeliveryPage from "./page/delivery/DeliveryPage";
import Forgotpassword from "./page/Forgetpassword";
import Setting from "./page/setting/Setting";
import { ThemeProvider } from "@material-tailwind/react";
import PaymentPage from "./page/payment/PaymentPage";
import { GamesIndex, ItemsIndex, ItemDetail } from "./page";
import DeliveryOtherPage from "./component/layout/walletPayment/delivery/deliveryResponseMobile/DeliveryOtherPage";
import DeliveryGuideBookPage from "./component/layout/walletPayment/delivery/deliveryResponseMobile/DeliveryGuideBookPage";
import DeliveryInformationPage from "./component/layout/walletPayment/delivery/deliveryResponseMobile/DeliveryInformationPage";
import CompletePage from "./page/complete/CompletePage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CartOrderPage } from "./page/cartOrder";
import Account from "./page/setting/account/Account";
import { CheckoutProgressPage } from "./page/checkoutprogress";
import Profile from "./page/Profile";
import { ComingSoon } from "./page/comingSoon";
import Inventory from "./page/Inventory";
import SupportPage from "./page/SupportPage";
import TopUpPage from "./page/TopUpPage";
import { Privacy_Policy, Term_Policy, Secure_Seller_Agreement } from "./page/legal";
import MyWallet from "./component/layout/walletPayment/MyWallet/MyWallet";

import CreateOrder from "./page/createOrder/CreateOrder";
import Create from "./page/createOrder/Create";
// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <Routes>
                <Route path="" element={<App />}>
                  <Route path="" element={<Home />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="top-up" element={<Outlet />}>
                    <Route index element={<TopUpPage />} />
                  </Route>
                  <Route path="inventory" element={<Outlet />}>
                    <Route index element={<Inventory />} />
                  </Route>
                  <Route path="support" element={<Outlet />}>
                    <Route index element={<SupportPage />} />
                  </Route>
                  <Route path="profile" element={<Profile />} />

                  <Route path="setting" element={<Setting />}>
                    {/* <Route index element={<Setting />} /> */}
                    <Route path="account" element={<Account />} />
                    <Route path="kyc" element={<KYC />} />
                    <Route path="security" element={<Outlet />}>
                      <Route index element={<Security />} />
                      <Route
                        path="qrcode-authenticator"
                        element={<QRCode_Authenticator />}
                      />
                    </Route>
                  </Route>
                  <Route path="create_order" element={<Create />} />
                  <Route path="marketplace" element={<Outlet />}>
                    <Route index element={<GamesIndex />} />
                    <Route path=":game_name" element={<ItemsIndex />} />
                  </Route>
                  <Route path="item-detail" element={<Outlet />}>
                    <Route path=":id" element={<ItemDetail />} />
                  </Route>
                  <Route path="cartorder" element={<CartOrderPage />} />
                  <Route path="payment" element={<PaymentPage />} />
                  <Route path="delivery" element={<DeliveryPage />} />
                  <Route
                    path="delivery/other"
                    element={<DeliveryOtherPage />}
                  />
                  <Route
                    path="delivery/other/guidebook"
                    element={<DeliveryGuideBookPage />}
                  />
                  <Route
                    path="delivery/other/information"
                    element={<DeliveryInformationPage />}
                  />
                  <Route path="complete" element={<CompletePage />} />
                  <Route
                    path="complete/other"
                    element={<DeliveryOtherPage />}
                  />
                  <Route
                    path="complete/other/guidebook"
                    element={<DeliveryGuideBookPage />}
                  />
                  <Route
                    path="complete/other/information"
                    element={<DeliveryInformationPage />}
                  />
                  <Route path="mywallet" element={<MyWallet />} />
                  <Route
                    path="checkoutprogress"
                    element={<CheckoutProgressPage />}
                  />
                  <Route path="privacy_policy" element={<Privacy_Policy />} />
                  <Route path="term_policy" element={<Term_Policy />} />
                  <Route path="secure_seller_sgreement"  element={<Secure_Seller_Agreement/>}/>
                </Route>
                <Route path="login" element={<Login />} />
                <Route path="comingsoon" element={<ComingSoon />}></Route>
                <Route path="register" element={<Register />}>
                  <Route path=":action" element={<VerifyEmail />} />
                </Route>
                <Route path="forgotpassword" element={<Forgotpassword />}>
                  <Route path=":action" element={<Forgotpassword />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </PersistGate>
        </QueryClientProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
