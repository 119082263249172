import { createSlice } from "@reduxjs/toolkit";

export const myWalletSlice = createSlice({
  name: "myWallet",
  initialState: {
    openDialogMyWallet: false,
  },
  reducers: {
    setOpenDialogMyWallet: (state, action) => {
      state.openDialogMyWallet = action.payload;
    },
  },
});

export const { setOpenDialogMyWallet } = myWalletSlice.actions;

export const getStatusOpenDialogMyWallet = (state) => {
  return state.myWallet.openDialogMyWallet;
};

export default myWalletSlice.reducer;
