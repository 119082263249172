import React, { useState, useEffect } from "react";
import StepProgress from "../../component/layout/walletPayment/StepProgress";
import GameLogo from "../../assets/images/Icon/game_logo.png";
import SelectGatewayPayment from "../../component/layout/walletPayment/cartOrder/SelectGatewayPayment";
import DetailItem from "../../component/layout/walletPayment/cartOrder/DetailItem";
import StickyDetailAboutAmount from "../../component/layout/walletPayment/cartOrder/StickyDetailAboutAmount";
import { useQueryGetCheckout } from "../../api/query/checkout";
import { useDispatch, useSelector } from "react-redux";
import { setDisplay } from "../../slice/goBackBtnSlice";
import BaseDialog from "../../component/common/BaseDialog";
import { DialogBody, DialogHeader } from "@material-tailwind/react";
import { SubmitButton } from "../../component/Button";
import { createSearchParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useMutationCreateOrderNumber } from "../../api/mutation/checkout";

const CartOrderPage = () => {
  const goBack = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [amountPrice, setAmountPrice] = useState();
  const [nameGateway, setNameGateway] = useState("QR");
  const [openDialogOrderNotBeCompleted, setOpenDialogOrderNotBeCompleted] =
    useState(false);
  const stepProgressName = "checkout";
  const gatewayFee = 0;
  const { data: CheckoutData } = useQueryGetCheckout();
  const itemPrice = Number(
    CheckoutData?.data?.checkout_item["0"]?.item_game?.item_price
  );
  const data = CheckoutData?.data?.checkout_item[0];

  const navigate = useNavigate();
  const accessToken = Cookies.get("access_token");

  const {
    mutate: mutateCreateOrderNumber,
    isSuccess: isSuccessCreateOrderNumber,
  } = useMutationCreateOrderNumber({
    onSuccess: (resp) => {
      navigate(
        {
          pathname: "/payment",
          search: `${createSearchParams({
            orderNo: resp?.data?.orderIds,
          })}`,
        },
        { replace: true }
      );
    },
    onError: () => {
      setOpenDialogOrderNotBeCompleted(!openDialogOrderNotBeCompleted);
    },
  });

  useEffect(() => {
    if (accessToken) {
      const amount = itemPrice * quantity;
      setAmountPrice(amount);
    } else {
      navigate("/login", { replace: true });
    }
  }, [quantity, itemPrice]);

  useEffect(() => {
    goBack(setDisplay(false));
  }, []);

  const onClickOpenDialogOrderNotBeCompleted = () => {
    setOpenDialogOrderNotBeCompleted(!openDialogOrderNotBeCompleted);
  };

  const onClickCheckoutItem = (e) => {
    e.preventDefault();
    const dataCheckout = {
      paymentMethod: nameGateway,
      items: [
        {
          item_id: data?.item_game?.item_id,
          seller_id: data?.item_game?.account?.id,
          product_count: quantity,
          amount: amountPrice,
        },
      ],
    };
    mutateCreateOrderNumber(dataCheckout);
  };

  return (
    <div className=" bg-GRAY111 desktop:bg-GRAY710 w-[100%]  mobile:py-4 mobile:px-3 desktop:py-0 desktop:px-0 flex flex-col justify-between gap-4 desktop:gap-5">
      <div className="flex gap-4 items-center ">
        <StepProgress stepProgressName={stepProgressName} />
      </div>
      <div className="desktop:flex desktop:px-[17.605%] gap-6 desktop:pb-8 mobile:pb-0">
        <div className="mobile:hidden desktop:block  desktop:w-[50%]">
          <DetailItem
            data={CheckoutData?.data?.checkout_item[0]}
            quantity={quantity}
            setQuantity={setQuantity}
            setAmountPrice={setAmountPrice}
            gameLogo={GameLogo}
          />
        </div>
        <div className=" desktop:w-[50%] ">
          <SelectGatewayPayment
            amountPrice={amountPrice}
            gatewayFee={gatewayFee}
            setNameGateway={setNameGateway}
            onClickCheckoutItem={onClickCheckoutItem}
          />
        </div>
      </div>
      <div className="mobile:hidden desktop:flex fixed bottom-0 desktop:px-[17.605%] bg-GRAY700 pt-5 pb-10 w-[100vw]">
        <StickyDetailAboutAmount
          amountPrice={amountPrice}
          gatewayFee={gatewayFee}
          onClickCheckoutItem={onClickCheckoutItem}
        />
      </div>

      {/* Dialog when This order could not be completed!, Try again later. */}
      <BaseDialog
        open={openDialogOrderNotBeCompleted}
        stylesDialog="desktop:min-w-[45%]"
      >
        <div className="w-[100%] flex flex-col desktop:gap-6 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            SOMETHING WENT WRONG!
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
            <div className="flex justify-center">
              <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                This order could not be completed!, Try again later.
              </p>
            </div>
            <div className="flex justify-center desktop:gap-5">
              <div className="desktop:w-[60%] mobile:w-[100%] ">
                <SubmitButton
                  text="OK"
                  styleBtn="mobile:rounded-none mobile:rounded-br desktop:rounded"
                  onClick={onClickOpenDialogOrderNotBeCompleted}
                />
              </div>
            </div>
          </DialogBody>
        </div>
      </BaseDialog>
    </div>
  );
};

export default CartOrderPage;
