
export default function icon_dropdown(props) {
    return (
        <div {...props}>
            <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" 
                    d="M7.6446 8.5326C8.39188 9.26671 9.60922 9.26123 10.3506 8.5326L16.7483 2.24903C17.4956 1.51492 17.1984 0.919922 16.0833 0.919922H1.9123C0.797777 0.919922 0.505614 1.52067 1.24729 2.24903L7.6446 8.5326Z" 
                    fill="white"
                />
            </svg>
        </div>
    )
}