import { useState } from "react";
import { Icon_views } from "../assets/icons";
import { Link } from "react-router-dom";

export default function GameCard({
  key,
  pic,
  label,
  views,
  game_id,
  setIsOpen,
  isOpen,
}) {
  return (
    <main key={key} className="inline-block text-white w-full">
      <Link
        to={`/marketplace/${label}?gameID=${game_id}&cate=All`}
        onClick={() => setIsOpen(!isOpen)}
        className={`relative `}
      >
        <div className={`rounded h-[18.5rem] w-[15.125rem] relative mx-auto`}>
          <img src={pic} alt="" className={`rounded h-full w-full`} />
          <div
            className="absolute -bottom-[.5px] w-[15.13rem] h-[18.5rem] 
                    bg-gradient-to-t from-black to-[70%] opacity-90 rounded"
          />
          <div className="absolute bottom-0 w-full pb-3 text-center font-IBMPlex">
            <h3 className="text-[1.37rem] font-bold mx-auto">{label}</h3>
            <div className="mt-1">
              <Icon_views className="inline-block mb-0.5 [&>svg>path]:fill-GRAY920" />
              <span className="ml-2 text-base font-bold">{views} Views</span>
            </div>
          </div>
        </div>
      </Link>
    </main>
  );
}
