import axios from 'axios'
import Cookies from 'js-cookie'

export default async function qr_googleAuth(setQRCode, setTextCode) {
    await axios
        .post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_SECURITY_QRGOOGLEAUTH_ENDPOINT,
            {}, {
            headers: {
                Authorization: `Bearer ${Cookies.get("access_token")}`,
            }
        })
        .then(res => {
            setQRCode(res.data?.qrlink)
            setTextCode(res.data?.base32)
        })
        .catch(err => console.log(err))
}