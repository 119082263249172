import { useMutation } from "@tanstack/react-query";
import { transactionPurchasedApi } from "../../services";

export const useMutationClientDeliveryTransactionHistory = ({
  onSuccess,
  onError,
}) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return transactionPurchasedApi.ClientDeliveryTransactionHistory(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};
