export default function Icon_views(props) {
    return (
        <div {...props}>
            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M15.75 9C15.75 9.99456 15.3549 10.9484 14.6517 11.6517C13.9484 12.3549 12.9946 12.75 12 12.75C11.0054 12.75 10.0516 12.3549 9.34835 11.6517C8.64509 10.9484 8.25 9.99456 8.25 9C8.25 8.00544 8.64509 7.05161 9.34835 6.34835C10.0516 5.64509 11.0054 5.25 12 5.25C12.9946 5.25 13.9484 5.64509 14.6517 6.34835C15.3549 7.05161 15.75 8.00544 15.75 9Z" 
                    fill="white"
                />
                <path d="M0 9C0 9 4.5 0.75 12 0.75C19.5 0.75 24 9 24 9C24 9 19.5 17.25 12 17.25C4.5 17.25 0 9 0 9ZM12 14.25C13.3924 14.25 14.7277 13.6969 15.7123 12.7123C16.6969 11.7277 17.25 10.3924 17.25 9C17.25 7.60761 16.6969 6.27226 15.7123 5.28769C14.7277 4.30312 13.3924 3.75 12 3.75C10.6076 3.75 9.27226 4.30312 8.28769 5.28769C7.30312 6.27226 6.75 7.60761 6.75 9C6.75 10.3924 7.30312 11.7277 8.28769 12.7123C9.27226 13.6969 10.6076 14.25 12 14.25Z" 
                    fill="white"
                />
            </svg>
        </div>
    )
}