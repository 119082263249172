import { styled, Divider } from "@mui/material";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const [showFooter, SetShowFooter] = useState(true);
  const locationPathName = useLocation();
  const pathnameCurrent = locationPathName.pathname.split("/")[1];

  useEffect(() => {
    if (
      pathnameCurrent === "cartorder" ||
      pathnameCurrent === "payment" ||
      pathnameCurrent === "delivery" ||
      pathnameCurrent === "complete" ||
      pathnameCurrent === "checkoutprogress"
    ) {
      SetShowFooter(false);
    }
  }, [pathnameCurrent]);

  const PAYMENT_LIST = [
    {
      name: "mastercard",
      size: [40, 24],
    },
    {
      name: "visa",
      size: [38, 12],
    },
    {
      name: "scb",
      size: [49, 20],
    },
    {
      name: "kb",
      size: [55, 19],
    },
    {
      name: "tm",
      size: [63, 19],
    },
  ];

  const SOCIAL_LIST = [
    { name: "ig" },
    { name: "twitter" },
    { name: "fb" },
    { name: "youtube" },
    { name: "discord" },
  ];

  const FOOTER_LIST = [
    {
      label: "RETOHUB",
      list: [
        { label: "About us", url: "/comingsoon" },
        { label: "Careers", url: "/comingsoon" },
        { label: "Reto News", url: "/comingsoon" },
        { label: "Reto Blog", url: "/comingsoon" },
      ],
    },
    {
      label: "BUY",
      list: [
        { label: "How to Buy", url: "/comingsoon" },
        { label: "Buyer Ranking", url: "/comingsoon" },
        { label: "Pyaments & Refunds", url: "/comingsoon" },
        { label: "Reto Points", url: "/comingsoon" },
      ],
    },
    {
      label: "SELL",
      list: [
        { label: "How to Sell", url: "/comingsoon" },
        { label: "Seller Ranking", url: "/comingsoon" },
        { label: "Disbursements", url: "/comingsoon" },
        { label: "Retohub Fee", url: "/comingsoon" },
      ],
    },
    {
      label: "SUPPORT",
      list: [
        { label: "Help Center", url: "/comingsoon" },
        { label: "Rules & Regulations", url: "/comingsoon" },
        { label: "Reto Account", url: "/comingsoon" },
        { label: "Contact Us", url: "/comingsoon" },
      ],
    },
    {
      label: "LEGAL",
      list: [
        { label: "Terms & Conditions", url: "/term_policy" },
        { label: "Privacy Policy", url: "privacy_policy" },
        { label: "Copyright Policy", url: "/comingsoon" },
        { label: "Cookie Policy", url: "/comingsoon" },
      ],
    },
  ];

  return (
    <div>
      {/* Footer desktop */}
      <div className="desktop:block mobile:hidden">
        <footer
          className={`desktop:w-full desktop:bg-GRAY111 mobile:bg-GRAY650 mobile:w-full z-10`}
        >
          <div className={`flex justify-center bg-[#070707] gap-4 p-4`}>
            <div className="flex gap-4 mobile:h-[24px] desktop:h-[36px] items-center">
              {PAYMENT_LIST.map(({ name, size }, index) => {
                return (
                  <img
                    className="mobile:w-10"
                    src={`/footer_payment/${name}.svg`}
                    alt=""
                    key={index}
                  />
                );
              })}
            </div>
          </div>
          <div className="desktop:flex justify-center py-8 mobile:hidden">
            <div className="flex justify-between w-full mx-[10%] [@media(min-width:1919px)]:w-[1578px] font-IBMPlex">
              {FOOTER_LIST.map(({ label, list }) => {
                return (
                  <div
                    key={`label-${label}`}
                    className="flex flex-col gap-6 p-4"
                  >
                    <div className="text-[20px] font-bold text-white">
                      {label}
                    </div>
                    <div>
                      {list.map((data, index) => {
                        return (
                          <Link
                            to={`${data.url}`}
                            key={index}
                            className="text-white py-2 flex flex-col"
                          >
                            {data.label}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="desktop:flex desktop:justify-center gap-6 py-8 mobile:hidden">
            {/* {SOCIAL_LIST.map(({name},index) => {
                return (
                  <img src={`/footer_social_icons/${name}.svg`} alt="" width="54" height="54" key={index} />
                )
              })} */}
            <Link to={"/comingsoon"}>
              <img
                src={`/footer_social_icons/ig.svg`}
                alt=""
                width="54"
                height="54"
              />
            </Link>
            <Link to={"/comingsoon"}>
              <img
                src={`/footer_social_icons/twitter.svg`}
                alt=""
                width="54"
                height="54"
              />
            </Link>
            <Link to={"/comingsoon"}>
              <img
                src={`/footer_social_icons/fb.svg`}
                alt=""
                width="54"
                height="54"
              />
            </Link>
            <Link to={"/comingsoon"}>
              <img
                src={`/footer_social_icons/youtube.svg`}
                alt=""
                width="54"
                height="54"
              />
            </Link>
            <Link to={"/comingsoon"}>
              <img
                src={`/footer_social_icons/discord.svg`}
                alt=""
                width="54"
                height="54"
              />
            </Link>
          </div>
          <ul className="flex flex-wrap items-center justify-around mt-4 mx-6 mb-2 text-[10px] font-medium text-white desktop:hidden">
            <li>Terms of Conditions</li>
            <li>Privacy Policy</li>
            <li>Copyright Policy</li>
            <li>Cookie Policy</li>
          </ul>
          <DividerStyled
            className="mobile:hidden desktop:block"
            orientation="horizontal"
          />
          <div className="flex justify-center py-4 mb-2">
            <p className="font-bold text-white mobile:text-[12px]">
              @2023 RETO HUB. All rights reserved.
            </p>
          </div>
        </footer>
      </div>

      {/* Footer Mobile */}
      <div className="desktop:hidden mobile:block">
        {showFooter && (
          <footer
            className={`desktop:w-full desktop:bg-GRAY111 mobile:bg-GRAY650 mobile:w-full z-10`}
          >
            <div className={`flex justify-center bg-[#070707] gap-4 p-4`}>
              <div className="flex gap-4 mobile:h-[24px] desktop:h-[36px] items-center">
                {PAYMENT_LIST.map(({ name, size }, index) => {
                  return (
                    <img
                      className="mobile:w-10"
                      src={`/footer_payment/${name}.svg`}
                      alt=""
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
            <div className="desktop:flex justify-center py-8 mobile:hidden">
              <div className="flex justify-between w-full [@media(min-width:1919px)]:w-[1578px] font-IBMPlex">
                {FOOTER_LIST.map(({ label, list }) => {
                  return (
                    <div
                      key={`label-${label}`}
                      className="flex flex-col gap-6 p-4"
                    >
                      <div className="text-[20px] font-bold text-white">
                        {label}
                      </div>
                      <div>
                        {list.map((data, index) => {
                          return (
                            <div key={index} className="text-white py-2">
                              {data.label}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="desktop:flex desktop:justify-center gap-6 py-8 mobile:hidden">
              {/* {SOCIAL_LIST.map(({name},index) => {
                return (
                  <img src={`/footer_social_icons/${name}.svg`} alt="" width="54" height="54" key={index} />
                )
              })} */}
              <Link to={"/comingsoon"}>
                <img
                  src={`/footer_social_icons/ig.svg`}
                  alt=""
                  width="54"
                  height="54"
                />
              </Link>
              <Link to={"/comingsoon"}>
                <img
                  src={`/footer_social_icons/twitter.svg`}
                  alt=""
                  width="54"
                  height="54"
                />
              </Link>
              <Link to={"/comingsoon"}>
                <img
                  src={`/footer_social_icons/fb.svg`}
                  alt=""
                  width="54"
                  height="54"
                />
              </Link>
              <Link to={"/comingsoon"}>
                <img
                  src={`/footer_social_icons/youtube.svg`}
                  alt=""
                  width="54"
                  height="54"
                />
              </Link>
              <Link to={"/comingsoon"}>
                <img
                  src={`/footer_social_icons/discord.svg`}
                  alt=""
                  width="54"
                  height="54"
                />
              </Link>
            </div>
            <ul className="flex flex-wrap items-center justify-around mt-4 mx-6 mb-2 text-[10px] font-medium text-white desktop:hidden">
              <Link to={"/term_policy"}>
                <li>Terms of Conditions</li>
              </Link>
              <Link to={"privacy_policy"}>
                <li>Privacy Policy</li>
              </Link>
              <Link to={"/comingsoon"}>
                <li>Copyright Policy</li>
              </Link>
              <Link to={"/comingsoon"}>
                <li>Cookie Policy</li>
              </Link>
            </ul>
            <DividerStyled
              className="mobile:hidden desktop:block"
              orientation="horizontal"
            />
            <div className="flex justify-center py-4 mb-2">
              <p className="font-bold text-white mobile:text-[12px]">
                @2023 RETO HUB. All rights reserved.
              </p>
            </div>
          </footer>
        )}
      </div>
    </div>
  );
};

export default Footer;

const DividerStyled = styled(Divider)(({ theme }) => ({
  background: "#D9D9D9",
  height: "0.5px",
}));
