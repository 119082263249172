import { createSlice } from '@reduxjs/toolkit'

export const goBackBtnSlice = createSlice({
    name: 'goBack',
    initialState: {
        displayMenu: true,
        path: '/',
    },
    reducers: {
        setDisplay: (state, action) => {
            state.displayMenu = action.payload
        },
        setUrlPath: (state, action) => {
            state.path = action.payload
        },
    }
})

export const {setDisplay, setUrlPath} = goBackBtnSlice.actions
export default goBackBtnSlice.reducer