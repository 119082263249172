import { useState, useEffect } from "react";
import {
  Profile_B,
  no_result,
  cover_retohub,
  RE_IRON,
} from "../assets/profile/index";
import { ic_1, ic_2, Vector_verify } from "../assets/profile/icons/index";
import { ProfilePage } from "../api/post/ProfilePage";
import ItemCard from "../component/ItemCard";
import moment from "moment";
import IconStarRating from "../assets/images/IconRating/IconStarRating.svg";
import IconStarRated from "../assets/images/IconRating/IconStarRated.svg";
import IconStarHalfRating from "../assets/images/IconRating/IconStarHalfRating.svg";
import { Link, useNavigate } from "react-router-dom";
import { Tabs, Tab, Box } from "@mui/material";
import { changePxToRem } from "../utils/changePixeltoRem";
import BaseDialog from "../component/common/BaseDialog";
import IconClose from "../assets/images/Icon/IconClose.svg";
import { DialogHeader, DialogBody } from "@material-tailwind/react";
import ProfileReviewRatingMobile from "../component/layout/ProfileReviewRatingMobile";
import IconRatingStar from "../component/rating/IconRatingStar";
import BtnRatingStar from "../component/rating/BtnRatingStar";
import InformationUserRating from "../component/rating/InformationUserRating";
import { useQueryGetReviewRating } from "../api/query/reviewRating";
import PaginationCheckoutProgress from "../component/layout/checkoutProgressPage/PaginationCheckoutProgress";

const Profile = () => {
  const navigate = useNavigate();
  const [page] = useState(1);
  const [pageSize] = useState(30);
  const [tab, setTab] = useState("created");
  const [valueMenuSelect, setValueMenuSelect] = useState(0);
  const [isProfile, setIsProfile] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const [addFavorite, setAddFavorite] = useState(false);
  const [openModalReviewRating, setOpenModalReviewRating] = useState(false);
  const [openProfileReviewRatingMobile, setOpenProfileReviewRatingMobile] =
    useState(false);
  const [selectFilterRating, setSelectFilterRating] = useState("all");

  //   get review rating
  const rating = isProfile?.rating ? isProfile?.rating : 0;
  const userId = isProfile?.user_id ? isProfile?.user_id : 0;
  const [pageGetReviewRating, setPageGetReviewRating] = useState(1);
  const [pageSizeGetReviewRating, setPageSizeGetReviewRating] = useState(10);

  useEffect(() => {
    ProfilePage(page, pageSize, tab, setIsProfile);
  }, [tab, addFavorite]);

  const { data: dataGetReviewRating, refetch: refetchDataGetReviewRating } =
    useQueryGetReviewRating(
      userId,
      selectFilterRating,
      pageGetReviewRating,
      pageSizeGetReviewRating
    );

  useEffect(() => {
    refetchDataGetReviewRating();
  }, [
    refetchDataGetReviewRating,
    userId,
    selectFilterRating,
    pageGetReviewRating,
  ]);

  const SOCIAL_LIST = [
    { name: "ig", path: "/comingsoon" },
    { name: "twitter", path: "/comingsoon" },
    { name: "fb", path: "/comingsoon" },
    { name: "youtube", path: "/comingsoon" },
  ];

  const NameMenuSelect = [
    {
      name: "Created",
      count: isProfile?.created_count ? isProfile?.created_count : 0,
    },
    {
      name: "Pending",
      count: isProfile?.pending_count ? isProfile?.pending_count : 0,
    },
    {
      name: "Favorited",
      count: isProfile?.favorite_count ? isProfile?.favorite_count : 0,
    },
    // {
    //   name: "Activity",
    //   count: isProfile?.activity_count ? isProfile?.activity_count : 0,
    // },
    // {
    //   name: "Review",
    //   count: isProfile?.review_count ? isProfile?.review_count : 0,
    // },
  ];

  // information ratingBtn
  const ratingBtn = [
    {
      rating: 5,
      quantityRating: dataGetReviewRating?.rating_count["rating 5"],
    },
    {
      rating: 4,
      quantityRating: dataGetReviewRating?.rating_count["rating 4"],
    },
    {
      rating: 3,
      quantityRating: dataGetReviewRating?.rating_count["rating 3"],
    },
    {
      rating: 2,
      quantityRating: dataGetReviewRating?.rating_count["rating 2"],
    },
    {
      rating: 1,
      quantityRating: dataGetReviewRating?.rating_count["rating 1"],
    },
  ];

  //   WindowResize
  useEffect(() => {
    const handleWindowResize = () => {
      const isMobileScreen = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(isMobileScreen);

      if (openModalReviewRating && isMobileScreen) {
        setOpenModalReviewRating(false);
        setOpenProfileReviewRatingMobile(true);
      }
      if (openProfileReviewRatingMobile && !isMobileScreen) {
        setOpenModalReviewRating(true);
        setOpenProfileReviewRatingMobile(false);
      }
    };

    // Initial check when the component mountsƒ
    handleWindowResize();

    // Add event listener to update the state on window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isMobile, openModalReviewRating, openProfileReviewRatingMobile]);

  const handleChangeValueMenuSelect = (event, newValue) => {
    const value =
      newValue === 0
        ? "created"
        : newValue === 1
        ? "pending"
        : newValue === 2
        ? "favorite"
        : newValue === 3
        ? "activity"
        : newValue === 4
        ? "review"
        : "more";
    setValueMenuSelect(newValue);
    setTab(value);
  };

  const onClickShareSocial = (pathLink) => {
    navigate(pathLink);
  };
  const onClickOpenReviewRating = () => {
    if (!isMobile) {
      setOpenModalReviewRating(!openModalReviewRating);
    } else {
      setOpenProfileReviewRatingMobile(!openProfileReviewRatingMobile);
    }
  };

  const onClickSelectFilterRating = (rating) => {
    if (
      typeof selectFilterRating === "number" &&
      rating === selectFilterRating
    ) {
      setSelectFilterRating("all");
    } else {
      setSelectFilterRating(rating);
    }
  };

  return (
    <div className="mobile:bg-GRAY111 desktop:bg-GRAY700 w-full">
      {/* cover Picture */}
      <div>
        <div className="relative mobile:bg-GRAY111 desktop:bg-GRAY700">
          <div className="relative desktop:max-w-[1920px] w-full desktop:h-[17vw] mobile:h-[27.885vw] desktop:max-h-[19.6875rem] mx-auto">
            <img
              alt="coverPicture"
              src={
                isProfile?.cover_picture
                  ? isProfile?.cover_picture
                  : cover_retohub
              }
              // className={`w-full ${
              //   isProfile?.cover_picture ? "h-[27.885vw]" : "h-[27.885vw]"
              // } desktop:max-h-[19.6875rem] desktop:max-w-[1920px] object-center mx-auto`}
              className={`absolute w-full h-full object-cover aspect-video`}
            />
          </div>

          {/*  Picture Profile Desktop */}
          <div className="mobile:hidden desktop:block absolute -bottom-[3vw] left-[8vw] h-[13.025vw] w-[13.025vw]">
            <div className="relative">
              <div
                className={`absolute bg-GRAY700 rounded-full ${
                  isProfile?.profile_frame
                    ? "top-[2.11vw] left-[2.1vw]"
                    : "top-[2.2vw] left-[2.2vw]"
                }`}
              >
                <img
                  alt="profilePicture"
                  src={
                    isProfile?.profile_picture
                      ? isProfile?.profile_picture
                      : Profile_B
                  }
                  className={`${
                    isProfile?.profile_frame
                      ? "h-[8.8vw] w-[8.8vw]"
                      : "h-[8.8vw] w-[8.8vw] "
                  } !rounded-full object-cover`}
                />
              </div>

              <div className="absolute top-0 left-0">
                <img
                  src={
                    isProfile?.profile_frame
                      ? isProfile?.profile_frame
                      : RE_IRON
                  }
                  alt="profileFrame"
                  className="h-[13.025vw] w-[13.025vw] object-center"
                />
              </div>
            </div>
          </div>

          {/*  Picture Profile Mobile */}
          <div className="mobile:block desktop:hidden absolute -bottom-[5.5vw] left-[2vw] h-[27.885vw] w-[27.885vw] ">
            <div className="relative">
              <div
                className={`absolute bg-GRAY700 rounded-full ${"top-[4.6vw] left-[4.6vw]"}`}
              >
                <img
                  alt="profilePicture"
                  src={
                    isProfile?.profile_picture
                      ? isProfile?.profile_picture
                      : Profile_B
                  }
                  className={`${"h-[18.6vw] w-[18.6vw] "} !rounded-full object-cover`}
                />
              </div>

              <div className="absolute top-0 left-0">
                <img
                  src={
                    isProfile?.profile_frame
                      ? isProfile?.profile_frame
                      : RE_IRON
                  }
                  alt="profileFrame"
                  className="h-[27.885vw] w-[27.885vw]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile:bg-GRAY111 desktop:bg-GRAY700 desktop:px-[8.91vw] mobile:px-3 pt-[3.5vw]">
        <div className="mobile:flex mobile:flex-col mobile:items-start mobile:justify-start mobile:w-full mobile:px-1 desktop:pl-0">
          <div className="flex flex-col space-y-2 w-full">
            <div className="flex items-center justify-between mx-0">
              <div className="lg:flex lg:items-center lg:gap-4">
                <div className="mobile:text-[24px] text-white font-bold font-IBMPlex desktop:text-5xl">
                  {isProfile?.display_name}
                </div>
                <div className="flex items-center flex-wrap gap-2">
                  {/* Rating */}
                  <div className="flex items-center gap-5 pr-3">
                    {/* Icon Rating */}
                    <div
                      className="flex gap-2 cursor-pointer"
                      onClick={onClickOpenReviewRating}
                    >
                      <img
                        src={
                          rating >= 1
                            ? IconStarRated
                            : rating < 1 && rating >= 0.5
                            ? IconStarHalfRating
                            : IconStarRating
                        }
                        alt="IconRating"
                        className="desktop:w-6 mobile:w-4"
                      />
                      <img
                        src={
                          rating >= 2
                            ? IconStarRated
                            : rating < 2 && rating >= 1.5
                            ? IconStarHalfRating
                            : IconStarRating
                        }
                        alt="IconRating"
                        className="desktop:w-6 mobile:w-4"
                      />
                      <img
                        src={
                          rating >= 3
                            ? IconStarRated
                            : rating < 3 && rating >= 2.5
                            ? IconStarHalfRating
                            : IconStarRating
                        }
                        alt="IconRating"
                        className="desktop:w-6 mobile:w-4"
                      />
                      <img
                        src={
                          rating >= 4
                            ? IconStarRated
                            : rating < 4 && rating >= 3.5
                            ? IconStarHalfRating
                            : IconStarRating
                        }
                        alt="IconRating"
                        className="desktop:w-6 mobile:w-4"
                      />
                      <img
                        src={
                          rating >= 5
                            ? IconStarRated
                            : rating < 5 && rating >= 4.5
                            ? IconStarHalfRating
                            : IconStarRating
                        }
                        alt="IconRating"
                        className="desktop:w-6 mobile:w-4"
                      />
                    </div>
                    <span className="mobile:text-[12px] desktop:text-[20px] desktop:text-white mobile:text-[#FFD02A] font-bold font-Kanit">
                      {isProfile?.rating ? `${isProfile?.rating}` : "0"}
                    </span>
                  </div>
                  <p className="mobile:text-[12px] desktop:text-xl text-white font-bold font-Kanit pl-5 pr-3 border-l-[1px] border-white/[0.2]">
                    {isProfile?.rated_count
                      ? `${isProfile?.rated_count} ratings`
                      : "0 ratings"}
                  </p>
                  {/* <p className="mobile:text-[12px] desktop:text-[20px] text-white font-bold font-Kanit pl-5 border-l-[1px] border-white/[0.2]">
                    386 sold out
                  </p> */}
                </div>
              </div>

              <div className="desktop:flex divide-x divide-gray space-x-4 mobile:hidden">
                <div className="desktop:flex mobile:hidden">
                  {SOCIAL_LIST.map(({ name, path }, index) => {
                    return (
                      <img
                        key={`${name}${index}`}
                        src={`/profile_icons/${name}.svg`}
                        alt=""
                        width="45"
                        height="45"
                        className="cursor-pointer"
                        onClick={() => onClickShareSocial(path)}
                      />
                    );
                  })}
                </div>
                <div className="flex items-center">
                  <img
                    src={ic_1}
                    alt="iconShare"
                    className="ml-4 cursor-pointer"
                    onClick={() => navigate("/comingsoon")}
                  />
                  <img
                    src={ic_2}
                    alt="iconOther"
                    className="cursor-pointer"
                    onClick={() => navigate("/comingsoon")}
                  />
                </div>
              </div>
            </div>

            <div className="desktop:flex desktop:justify-between mx-0 desktop:mt-6">
              <div>
                <div className="flex items-center gap-4 ">
                  <span className="mobile:text-[12px] desktop:text-xl text-white font-IBMPlex mobile:font-[700] desktop:font-[500]">
                    {isProfile?.follower_count
                      ? `${isProfile?.follower_count} Followers`
                      : "0 Followers"}
                  </span>
                  <span className="mobile:text-[12px] desktop:text-xl font-[400] text-white font-IBMPlex ">
                    Joined {moment(isProfile?.created_date).format("MMMM YYYY")}
                  </span>

                  {isProfile?.verify_status === 1 && (
                    <img
                      alt="Vector_verify"
                      src={Vector_verify}
                      className="desktop:w-6 desktop:h-6 mobile:w-5 mobile:h-5"
                    />
                  )}
                </div>

                <p className="mobile:text-[10px] desktop:text-base font-normal text-white font-IBMPlex desktop:mt-4 mobile:mt-2">
                  {isProfile?.profile_bio}
                </p>
              </div>

              <Link to="/setting/account">
                <button className="mobile:hidden w-[160px] h-[55px] py-2 desktop:flex justify-center items-center border-[3px] border-RED001 rounded hover:bg-GRAY666/[0.2] text-white font-bold font-IBMPlex cursor-pointer">
                  EDIT PROFILE
                </button>
              </Link>
            </div>

            {/* Button Responsive Mobile*/}
            <div className="mobile:flex desktop:hidden flex-wrap w-full gap-2">
              <Link to="/create_order" className="min-w-[8.375rem] grow">
                <button className="w-full py-2 h-9 bg-RED001 flex justify-center items-center rounded  hover:bg-RED001/[0.8] text-xs text-white font-bold font-IBMPlex cursor-pointer">
                  CREATE ORDER
                </button>
              </Link>
              <Link to="/setting/account" className="min-w-[8.375rem] grow">
                <button className="w-full py-2 h-9 flex justify-center items-center border-[3px] border-RED001 rounded hover:bg-GRAY666/[0.2] text-xs text-white font-bold font-IBMPlex cursor-pointer">
                  EDIT PROFILE
                </button>
              </Link>
            </div>

            <div className="desktop:flex desktop:justify-between mx-0 desktop:border-b-[1px] desktop:border-[#63636363]/[0.38] desktop:!mt-0 w-full mobile:pt-0">
              <Box
                sx={{
                  maxWidth: "100%",
                  bgcolor: "transparent",
                  color: "white",
                  minHeight: "0px",
                  "& .MuiTabs-root": {
                    minHeight: changePxToRem(4),
                  },
                  "& .MuiTabs-scroller": {
                    "& .MuiTabs-indicator": {
                      backgroundColor: isMobile ? "#BB213D" : "white",
                      height: isMobile ? changePxToRem(2) : changePxToRem(4),
                    },
                  },
                }}
              >
                <Tabs
                  value={valueMenuSelect}
                  onChange={handleChangeValueMenuSelect}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs"
                >
                  {NameMenuSelect?.map(({ name, count }) => {
                    return (
                      <Tab
                        label={`${name} (${count})`}
                        className="text-8xl"
                        key={`${name}${count}`}
                        sx={{
                          color: "gray",
                          fontWeight: "bold",
                          fontSize: isMobile
                            ? changePxToRem(12)
                            : changePxToRem(22),
                          fontFamily: "IBM Plex Sans Thai",
                          padding: isMobile
                            ? `${changePxToRem(6)} ${changePxToRem(8)}`
                            : `${changePxToRem(20)} ${changePxToRem(20)}`,
                          marginRight: isMobile
                            ? changePxToRem(10)
                            : changePxToRem(10),
                          textTransform: "none",
                          minHeight: "0px",
                          minWidth: "0px",
                          "&.Mui-selected": {
                            color: "white",
                          },
                        }}
                      />
                    );
                  })}
                  {/* <Tab
                    label="More"
                    className="text-8xl"
                    icon={
                      <img
                        src={
                          tab === "more" ? IconDropDown : IconDropDownProfile
                        }
                        alt="IconArrowShowMore"
                        className={`desktop:w-4 desktop:h-2 mobile:w-2 ${tab === "more" && "rotate-180"
                          }`}
                      />
                    }
                    iconPosition="end"
                    sx={{
                      color: "gray",
                      fontWeight: "bold",
                      fontSize: isMobile
                        ? changePxToRem(12)
                        : changePxToRem(22),
                      fontFamily: "IBM Plex Sans Thai Looped",
                      padding: isMobile
                        ? `${changePxToRem(6)} ${changePxToRem(8)}`
                        : `${changePxToRem(20)} ${changePxToRem(20)}`,
                      textTransform: "none",
                      minHeight: "0px",
                      minWidth: "0px",
                      "&.Mui-selected": {
                        color: "white",
                      },
                    }}
                  /> */}
                </Tabs>
              </Box>
            </div>
          </div>
        </div>

        {isProfile?.item?.length === 0 ||
        tab === "activity" ||
        tab === "review" ||
        tab === "more" ? (
          <div className="mobile:py-20 desktop:top-0 flex flex-col items-center justify-center desktop:py-36">
            <img src={no_result} alt="" width={270} height={180} />
            <span className="font-bold text-white font-IBMPlex desktop:hidden mobile:block">
              No Result Found
            </span>
            <span className="text-white text-[32px] font-bold font-IBMPlex mobile:hidden desktop:block">
              No Order Found
            </span>
            <p className="text-white font-IBMPlex text-center mobile:hidden desktop:block">
              Run to create order for great offers.
            </p>
            <p className="mobile:text-[12px] text-GRAY920 font-IBMPlex text-center desktop:hidden mobile:block">
              Please try selecting different filters or try <br></br> another
              keywords for better results.
            </p>
            <Link to="/create_order">
              <button className="mobile:hidden w-[300px] py-2 mt-4 bg-RED001 desktop:flex justify-center items-center rounded  hover:bg-RED001/[0.8] text-white font-bold font-IBMPlex cursor-pointer">
                START CREATE
              </button>
            </Link>
          </div>
        ) : (
          <div className="md:mx-auto desktop:flex desktop:flex-col desktop:py-4 mobile:py-2">
            <div className="flex flex-wrap justify-around  desktop:gap-x-[1.3125rem] desktop:gap-y-4 mobile:gap-[0.375rem]">
              {isProfile?.item?.map((item) => (
                <div key={item.item_id}>
                  <ItemCard
                    item={item}
                    textSize={"16px"}
                    mobileText={"10px"}
                    viewSize={"12px"}
                    priceSize={"20px"}
                    heightCard={"400px"}
                    mobileHeight={"269px"}
                    mobileWeight={"200px"}
                    tab={tab}
                    addFavorite={addFavorite}
                    setAddFavorite={setAddFavorite}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Dialog Rating Desktop */}
      <BaseDialog
        open={openModalReviewRating}
        handler={onClickOpenReviewRating}
        stylesDialog="desktop:px-[4.375rem] !max-w-[59rem] !w-[59vw]"
      >
        <div className="w-[100%] flex flex-col gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-xl text-white text-center">
            {isProfile?.display_name ? `${isProfile?.display_name} RATING` : ""}
          </DialogHeader>
          <DialogBody className="flex flex-col w-[100%] desktop:gap-3 mobile:gap-3 mobile:p-0 mobile:px-4 mobile:pb-5 desktop:p-0">
            <div className="flex justify-center flex-wrap gap-2">
              {/* Rating */}
              <div className="flex gap-5 pr-3">
                {/* Icon Rating */}
                <IconRatingStar rating={rating} />
                <span className="text-base text-[#FFD02A] font-bold pt-1">
                  {isProfile?.rating ? `${isProfile?.rating}` : "0"}
                </span>
              </div>
              <p className="text-base text-white text-end font-bold pl-5 pr-3 border-l border-white/[0.2] pt-1">
                {isProfile?.rated_count
                  ? `${isProfile?.rated_count} ratings`
                  : "0 ratings"}
              </p>
              {/* <p className="mobile:text-[12px] desktop:text-[20px] text-white font-bold font-Kanit pl-5 border-l-[1px] border-white/[0.2]">
                    386 sold out
                  </p> */}
            </div>

            {/* Total Rating */}
            <div className="flex flex-wrap justify-center gap-4">
              <div className="flex flex-col items-center justify-center border border-white rounded min-w-[7.5rem] w-[7.5rem] h-[3.125rem]">
                <p className="text-base text-white font-bold">Total</p>
                <p className="text-[0.625rem] text-white font-normal">
                  {`(${
                    dataGetReviewRating?.rating_count["rating total"]
                      ? dataGetReviewRating?.rating_count["rating total"]
                      : 0
                  })`}
                </p>
              </div>

              {ratingBtn?.map(({ rating, quantityRating }) => {
                return (
                  <div>
                    <BtnRatingStar
                      rating={rating}
                      selectFilterRating={selectFilterRating}
                      onClick={() => {
                        onClickSelectFilterRating(rating);
                        setPageGetReviewRating(1);
                      }}
                      quantityRating={quantityRating}
                    />
                  </div>
                );
              })}
            </div>

            {/* information user Rating */}
            <div className="flex flex-col gap-5 pt-2 overflow-auto h-[59vh] pr-1">
              {dataGetReviewRating?.data?.map((data) => {
                return <InformationUserRating data={data} />;
              })}
            </div>

            <div className="min-h-[2.75rem]">
              {dataGetReviewRating?.totalPage > 1 && (
                <PaginationCheckoutProgress
                  pageForPagination={
                    dataGetReviewRating?.totalPage
                      ? Math.ceil(dataGetReviewRating?.totalPage)
                      : 0
                  }
                  page={pageGetReviewRating}
                  setPage={setPageGetReviewRating}
                />
              )}
            </div>
          </DialogBody>
        </div>
        <div
          className="absolute desktop:block  mobile:hidden top-5 right-5 cursor-pointer p-2"
          onClick={onClickOpenReviewRating}
        >
          <img src={IconClose} alt="closeIcon" className="w-4 h-4 " />
        </div>
      </BaseDialog>

      {/*When click icon rating profile is show SwipeableDrawer ProfileReviewRatingMobile */}
      <div>
        <ProfileReviewRatingMobile
          isProfile={isProfile}
          openProfileReviewRatingMobile={openProfileReviewRatingMobile}
          setOpenProfileReviewRatingMobile={setOpenProfileReviewRatingMobile}
          onClickSelectFilterRating={onClickSelectFilterRating}
          selectFilterRating={selectFilterRating}
          ratingBtn={ratingBtn}
          dataGetReviewRating={dataGetReviewRating}
          pageGetReviewRating={pageGetReviewRating}
          setPageGetReviewRating={setPageGetReviewRating}
          //   setActiveNav={setActiveNav}
          //   setFocus={setFocus}
        />
      </div>
    </div>
  );
};

export default Profile;
