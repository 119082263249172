import { useEffect } from "react"

const Secure_Seller_Agreement = () => {
    useEffect(()=>{
        window.scroll(0,0)
    },[])
    return (
        <div className="w-full desktop:py-[60px] h-screen mobile:py-[33px] mobile:px-[16px] desktop:px-0 flex justify-center">

        </div>
    )
}

export default Secure_Seller_Agreement