import React, { useState, useRef, useEffect } from "react";
import {
  styled,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import {
  RED001,
  RED002,
  RED005,
  GRAY700,
  GRAY750,
  GRAY760,
  GRAY910,
  GRAY920,
  gray5757,
  GREEN050,
  YELLOW100,
} from "../../theme/color";
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs from "dayjs";
import { useMutationClientCheckoutProgress } from "../../api/mutation/checkoutProgress";
import { Link } from "react-router-dom";
import { changePxToRem } from "../../utils/changePixeltoRem";
import IconClock from "../../assets/images/transaction purchased/IconClock.svg";
import ArrowDown from "../../assets/images/transaction purchased/ArrowDown.svg";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useMutationClientDeliveryTransactionHistory } from "../../api/mutation/transactionPurchased";

const TransactionPurchased = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const [dataTransactionPurchased, setDataTransactionPurchased] = useState([]);
  const [dataDeliveryTransactionHistory, setDataDeliveryTransactionHistory] =
    useState();
  const [expanded, setExpanded] = useState(false);

  const [getDataMore, setGetDataMore] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [countOrder, setCountOrder] = useState();

  const {
    mutate: mutateCheckoutProgress,
    isSuccess: isSuccessCheckoutProgress,
  } = useMutationClientCheckoutProgress({
    onSuccess: (resp) => {
      setDataTransactionPurchased((old) => [...old, ...resp?.data?.data]);
      setTotalPages(resp?.data.totalPages);
      setCountOrder(resp?.data.count);
    },
    onError: () => {
      setDataTransactionPurchased("");
    },
  });

  const {
    mutate: mutateDeliveryTransaction,
    isSuccess: isSuccessDeliveryTransaction,
  } = useMutationClientDeliveryTransactionHistory({
    onSuccess: (resp) => {
      setDataDeliveryTransactionHistory(resp?.data?.data);
    },
    onError: () => {},
  });

  useEffect(() => {
    if (isOpen === 7) {
      mutateCheckoutProgress({
        pageSize: 20,
        page: page,
        progress: null,
        noti: true,
      });
    }
  }, [isOpen, page]);

  const fetchData = () => {
    const nextPage = page + 1;
    if (nextPage <= totalPages) {
      setPage(nextPage);
    } else {
      setGetDataMore(false);
    }
  };

  const onChangeExpanded =
    (index, transactionTrackId) => (event, isExpanded) => {
      const dataTransaction = {
        transactionTrackId: transactionTrackId,
      };
      setExpanded(isExpanded ? index : false);
      mutateDeliveryTransaction(dataTransaction);
    };

  return (
    <MailboxContainer>
      <Box>
        <Box
          sx={{
            display: "flex",
            height: "60px",
            padding: "0 16px",
            background: "#1A1A1A",
            borderRadius: "4px 4px 0 0",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p className="text-xl font-[700] text-white">Transaction Purchased</p>
          <Link to="/checkoutprogress">
            <p
              className="text-base font-[500] text-white hover:text-RED001 underline"
              onClick={() => setIsOpen(0)}
            >
              See All ({countOrder ? countOrder : 0})
            </p>
          </Link>
        </Box>

        <InfiniteScroll
          dataLength={dataTransactionPurchased?.length} //This is important field to render the next data
          next={fetchData}
          hasMore={getDataMore}
          height={720}
          // loader={<h4>Loading...</h4>}
          // endMessage={
          //   <p style={{ textAlign: "center" }}>
          //     <b>Yay! You have seen it all</b>
          //   </p>
          // }
        >
          {dataTransactionPurchased?.length > 0 &&
            dataTransactionPurchased?.map(
              (
                {
                  itemName,
                  itemPic,
                  progress,
                  transactionTrackId,
                  type,
                  txnDatetime,
                  buyerId,
                  gameName,
                  grandTotal,
                  itemDesc,
                  itemId,
                  namePayment,
                  paymentGatewayFee,
                  price,
                  productCount,
                  productTransactionId,
                  sellerId,
                },
                index
              ) => {
                const date = dayjs(txnDatetime).format("DD-MM-YYYY HH:mm");
                return (
                  <Accordion
                    key={index}
                    expanded={expanded === index}
                    onChange={onChangeExpanded(index, transactionTrackId)}
                    disableGutters
                    sx={{
                      background: "#1A1A1A",
                      boxShadow: "none",
                      "& .MuiAccordionSummary-root": {
                        display: "flex",
                        padding: "8px 30px 8px 16px",
                        height: "120px",
                        background: index % 2 === 0 ? "#202020" : "#282828",
                      },
                      "& .MuiAccordionSummary-root > .MuiAccordionSummary-content":
                        {
                          gap: "12px",
                        },
                      "& .MuiAccordionDetails-root": {
                        padding: "4px 10px 15px 30px",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <img src={ArrowDown} alt="" width="18" height="26" />
                      }
                    >
                      <div>
                        <img
                          src={itemPic["media_url1"]}
                          alt="gameName"
                          className="w-100 h-100 rounded"
                        />
                      </div>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "6px 0",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid sx={{ display: "flex", gap: changePxToRem(3) }}>
                          <p className="text-xl font-[700] text-white">
                            {itemName}
                          </p>
                          <p
                            className={`text-xl font-[500] ${
                              type === "Buy" ? "text-GREEN050" : "text-RED001"
                            }`}
                          >
                            {type === "Buy" ? "(B)" : "(S)"}
                          </p>
                        </Grid>

                        <Grid
                          sx={{
                            display: "flex",
                            gap: changePxToRem(4),
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p className="text-sm font-[500] text-white">
                            {progress === "Success"
                              ? "Your order has been successfully processed."
                              : "Request received, You’re on delivery processing."}
                          </p>

                          <p
                            className="text-sm font-[400] text-RED001 underline"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (progress === "Success") {
                                navigate({
                                  pathname: "/complete",
                                  search: `${createSearchParams({
                                    itemName: itemName,
                                    trackId: transactionTrackId,
                                  })}`,
                                });
                              }
                              if (progress === "Delivery") {
                                navigate({
                                  pathname: "/delivery",
                                  search: `${createSearchParams({
                                    itemName: itemName,
                                    trackId: transactionTrackId,
                                  })}`,
                                });
                              }
                            }}
                          >
                            {transactionTrackId}
                          </p>
                        </Grid>

                        <div className="flex gap-[0.625rem] items-center">
                          <img
                            src={IconClock}
                            alt="iconClock"
                            className="w-[1.125rem] h-[1.125rem]"
                          />
                          <p className="text-sm font-[400] text-GRAY920">
                            {date}
                          </p>
                        </div>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TimelineWrapper>
                        <TimelineContainer
                          confirmed={
                            dataDeliveryTransactionHistory?.seller_confirm ===
                              null || undefined
                              ? dataDeliveryTransactionHistory?.seller_available
                              : dataDeliveryTransactionHistory?.seller_confirm
                          }
                        >
                          <div className="flex flex-col gap-1 items-start mt-[0.375rem]">
                            <p
                              className={`text-base font-[400] ${
                                dataDeliveryTransactionHistory?.seller_available ||
                                dataDeliveryTransactionHistory?.seller_confirm
                                  ? "text-GREEN050"
                                  : "text-RED001"
                              }`}
                            >
                              Awaiting seller response
                            </p>
                            <p className="text-base font-[400] text-white text-start">
                              You can integrate a live chat messaging system
                              into your marketplace. Buyer Seller Chat
                              Marketplace Add-on will allow a customer to send
                              and receive instant messages from the vendors.
                            </p>
                            <p className="text-sm font-[400] text-GRAY920 mt-1">
                              {date}
                            </p>
                          </div>
                        </TimelineContainer>
                      </TimelineWrapper>
                      <TimelineWrapper>
                        <TimelineContainerII
                          sellerAvailable={
                            dataDeliveryTransactionHistory?.seller_available
                          }
                          sellerConfirmed={
                            dataDeliveryTransactionHistory?.seller_confirm
                          }
                        >
                          <div className="flex flex-col gap-1 items-start mt-[0.375rem]">
                            <p
                              className={`text-base font-[400] ${
                                dataDeliveryTransactionHistory?.seller_available ||
                                dataDeliveryTransactionHistory?.seller_confirm
                                  ? dataDeliveryTransactionHistory?.seller_confirm
                                    ? "text-GREEN050"
                                    : "text-RED001"
                                  : "text-white"
                              }`}
                            >
                              Seller confirm delivery
                            </p>
                            <p className="text-base font-[400] text-white text-start">
                              Right information helps them understand your
                              products and shape accurate expectations, reducing
                              time, returns and refunds from buyer decision.
                            </p>
                            <p className="text-sm font-[400] text-GRAY920 mt-1">
                              {dataDeliveryTransactionHistory?.seller_confirm
                                ? dayjs(
                                    dataDeliveryTransactionHistory?.seller_confirm
                                  ).format("DD-MM-YYYY HH:mm")
                                : "-"}
                            </p>
                          </div>
                        </TimelineContainerII>
                      </TimelineWrapper>
                      <TimelineWrapper>
                        <TimelineContainerIII
                          sellerConfirmed={
                            dataDeliveryTransactionHistory?.seller_confirm
                          }
                          buyerConfirmed={
                            dataDeliveryTransactionHistory?.buyer_confirm
                          }
                        >
                          <div className="flex flex-col gap-1 items-start mt-[0.375rem]">
                            <p
                              className={`text-base font-[400]  ${
                                dataDeliveryTransactionHistory?.seller_confirm
                                  ? dataDeliveryTransactionHistory?.buyer_confirm
                                    ? "text-GREEN050"
                                    : "text-RED001"
                                  : "text-white"
                              }`}
                            >
                              Buyer confirm delivery
                            </p>
                            <p className="text-base font-[400] text-white text-start">
                              Please check that all items for your order has
                              been delivered. If you are satisfied with your
                              order, click confirm to release payment to the
                              seller. Rate the products.
                            </p>
                            <p className="text-sm font-[400] text-GRAY920 mt-1">
                              {dataDeliveryTransactionHistory?.buyer_confirm
                                ? dayjs(
                                    dataDeliveryTransactionHistory?.buyer_confirm
                                  ).format("DD-MM-YYYY HH:mm")
                                : "-"}
                            </p>
                          </div>
                        </TimelineContainerIII>
                      </TimelineWrapper>
                    </AccordionDetails>
                  </Accordion>
                );
              }
            )}
        </InfiniteScroll>
        {/* </Box> */}
      </Box>
    </MailboxContainer>
  );
};

export default TransactionPurchased;

const TimelineWrapper = styled(Box)({
  widht: "100%",
  paddingLeft: "12px 77px 12px 37px",
  "&::after": {
    content: '""',
    position: "absolute",
    borderLeft: "2px solid #ffffff",
    top: "120px",
    left: "29.9px",
    bottom: "0",
  },
});

const MailboxContainer = styled(Box)({
  position: "absolute",
  width: "632px",
  height: "780px",
  top: "3.625rem",
  right: "-20px",
  background: "#202020",
  zIndex: 99999,
});

const TimelineContainer = styled(Box)(({ confirmed }) => ({
  position: "relative",
  padding: "11px 29px 0px 29px",
  background: "inherit",
  "&::after": {
    content: '""',
    position: "absolute",
    width: "12px",
    height: "12px",
    left: "-5px",
    background: confirmed ? GREEN050 : RED001,
    top: "21.3px",
    borderRadius: "50%",
    zIndex: 1,
  },
}));
const TimelineContainerII = styled(Box)(
  ({ sellerAvailable, sellerConfirmed }) => ({
    position: "relative",
    padding: "11px 29px 0px 29px",
    background: "inherit",
    "&::after": {
      content: '""',
      position: "absolute",
      width: "12px",
      height: "12px",
      left: "-5px",
      background:
        sellerAvailable || sellerConfirmed
          ? sellerConfirmed
            ? GREEN050
            : RED001
          : "white",
      top: "21.3px",
      borderRadius: "50%",
      zIndex: 1,
    },
  })
);
const TimelineContainerIII = styled(Box)(
  ({ buyerConfirmed, sellerConfirmed }) => ({
    position: "relative",
    padding: "11px 29px 0px 29px",
    background: "inherit",
    "&::after": {
      content: '""',
      position: "absolute",
      width: "12px",
      height: "12px",
      left: "-5px",
      background: sellerConfirmed
        ? buyerConfirmed
          ? GREEN050
          : RED001
        : "white",
      top: "21.3px",
      borderRadius: "50%",
      zIndex: 1,
    },
  })
);
