import { SearchResult } from "./SearchResult";
import { useState } from "react";

export const SearchResultsList = ({
  results,
  setInput,
  setIsOpen,
  setResults,
}) => {
  return (
    <div className="bg-[#282828] desktop:py-3 rounded mobile:py-2 desktop:w-full">
      <div
        className={`w-full mobile:h-[15.375rem] overflow-x-hidden desktop:px-4 mo text-base text-white rounded-md bg-[#282828] z-50 ${
          results?.length > 5 ? "desktop:h-56" : "desktop:h-fit"
        }`}
      >
        <div>
          {results && results?.length > 0 ? (
            <div>
              {results?.map((result, id) => {
                return (
                  <SearchResult
                    result={result.game_name}
                    key={id}
                    setInput={setInput}
                    setIsOpen={setIsOpen}
                    setResults={setResults}
                  />
                );
              })}
            </div>
          ) : (
            <div
              className={`text-white cursor-default text-center my-20 text-[20px] font-IBMPlex `}
            >
              No matching results
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
