
import { useState, useEffect, useRef } from "react";
import {
    Dialog,
    DialogBody,
    ThemeProvider,
} from "@material-tailwind/react";
import CloseButton from "./closeButton";
import Webcam from "react-webcam";
import { uploadPicture } from "../../../api/post/setting/kyc";
import { iconUpload_1 } from "../../../assets/setting/KYC";
import { useForm } from "react-hook-form"
import { iconDragonCardID } from "../../../assets/setting/KYC";
import { IconKBANK, IconKTB, IconSCB } from "../../../assets/setting/KYC/eKYC_CardID";
import icon_arrow_down from "../../../assets/setting/icon_arrow_down.svg"
import ImageUploading from "react-images-uploading";
import { uploadBookBank } from "../../../api/post/setting/kyc";


const BANK_NAME = [
    { pic: IconSCB, val: "Siam Commercial Bank (ธนาคารไทยพาณิชย์)" },
    { pic: IconKTB, val: "Krungthai Bank (ธนาคารกรุงไทย)" },
    { pic: IconKBANK, val: "Kasikorn Bank (ธนาคารกสิกร)" },
]


const Step_Bookbank = ({ theme, open, windowSize, handleOpen, setOpen, setSuccess4Step }) => {
    const { register, handleSubmit, setError, formState: { errors } } = useForm({})
    const [bookBank, setBookBank] = useState()
    const [bankIndex, setBankIndex] = useState(-1)
    const [isOpen, setIsOpen] = useState(false)

    const onChangeBookBank = (imageList, addUpdateIndex) => {
        setBookBank(imageList);
    };


    return (
        <ThemeProvider
            value={theme}
        >
            <Dialog
                open={open === "bookbank"}
                size={windowSize[0] > 768 ? "sm" : "xs"}
                className="bg-[#1A1A1A] relative rounded overflow-hidden button-0"
            >
                <DialogBody
                    className="desktop:relative  flex flex-col desktop:items-center desktop:h-[761px] mobile:h-[90vh] overflow-auto"
                >
                    {/*Close Button  */}
                    <CloseButton handleOpen={handleOpen} />
                    <div className="desktop:relative  flex flex-col desktop:items-center desktop:h-[761px] mobile:h-[90vh] mobile:w-full  py-5">
                        <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold">BOOK BANK IDENTITY</p>
                        <p className="desktop:w-[500px] mobile:w-full h-[52px] font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-normal text-center">Make sure that the book bank is completed, the image is clear, and the light is even when taking pictures.</p>
                        <div className="desktop:w-[580px] flex flex-col items-center gap-5 py-5">
                            <ImageUploading
                                value={bookBank}
                                onChange={onChangeBookBank}
                                dataURLKey="data_url"
                                maxNumber={1}
                            >
                                {({ onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                                    <div className="w-full h-full  flex flex-col items-center gap-5 ">
                                        {!bookBank?.[0]?.data_url
                                            ? (
                                                <div
                                                    {...dragProps}
                                                    className="w-[280px] h-[156px] border border-dashed border-[#BB213D] rounded-[4px] cursor-pointer relative bg-gray-900"
                                                    onClick={onImageUpload}
                                                >
                                                    <img src={iconDragonCardID} alt="dragon" className=" object-contain w-full h-full opacity-20 " />
                                                    <div className="absolute top-0 flex justify-center items-center flex-col w-full h-full gap-2">
                                                        <img src={iconUpload_1} alt="iconUpload_1" className="w-[64px] h-[64px] " />
                                                        <p className="text-white font-IBMPlex font-bold text-[16px]">Book Bank</p>
                                                    </div>
                                                </div>
                                            )
                                            : (
                                                <div
                                                    className="mobile:w-full mobile:h-[220px] border-dashed desktop:w-[280px] desktop:h-[156px]border-[#BB213D] border rounded-[4px] object-contain"
                                                    onClick={onImageUpload}
                                                >
                                                    <img src={bookBank?.[0]?.data_url} alt="frontcard" className="w-full h-full " />
                                                </div>
                                            )
                                        }
                                    </div>

                                )}
                            </ImageUploading>
                            <div className="w-full gap-1">
                                <p className="text-white font-IBMPlex font-normal text-[16px]">Bank Name</p>
                                <div
                                    className="w-full h-12 rounded-[4px] bg-[#282828] focus:outline-none placeholder:text-[#7C7C7C] text-white relative cursor-pointer flex items-center px-3"
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    {bankIndex >= 0
                                        ? <div className="flex items-center gap-3 ">
                                            <img src={BANK_NAME[bankIndex]?.pic} alt={`val` + bankIndex} />
                                            <p className="text-white font-IBMPlex font-normal text-[16px] mt-1">{BANK_NAME[bankIndex]?.val}</p>
                                        </div>
                                        : <p className="text-[#939393] font-IBMPlex font-normal text-[16px] ">Select banking chanel</p>
                                    }
                                    <img
                                        src={icon_arrow_down}
                                        alt="arrow_down"
                                        className={`absolute right-[18px] top-[21px]  h-[6px] desktop:cursor-pointer ${isOpen ? "rotate-180" : ""}`}
                                        onClick={() => setIsOpen(!isOpen)}
                                    />

                                    {isOpen && (
                                        <div className=" overflow-auto w-full absolute py-3 left-0 top-[105%] rounded-[4px] gap-1 flex flex-col bg-[#282828]">
                                            {BANK_NAME?.map(({ pic, val }, index) => (
                                                <div
                                                    key={index}
                                                    className="h-8 w-full bg-[#282828] flex items-center gap-5 px-3 cursor-pointer hover:bg-[#242424]"
                                                    onClick={() => setBankIndex(index)}
                                                >
                                                    <img src={pic} alt={`val` + index} />
                                                    <p className="text-white font-IBMPlex font-normal text-[16px] mt-1">{val}</p>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="w-full gap-1">
                                <p className="text-white font-IBMPlex font-normal text-[16px]">Account number</p>
                                <input
                                    {...register("account_number")}
                                    type="text"
                                    className="w-full h-12 rounded-[4px] bg-[#282828] focus:outline-none placeholder:text-[#7C7C7C] p-[10px] text-white"
                                    placeholder="Write account number"
                                    onKeyDown={(e) =>
                                        (e?.key?.length === 1 && e.key.match(/[^0-9]/g)) && e.preventDefault()
                                    }
                                    maxLength='16'
                                />
                            </div>
                            <div className="w-full flex desktop:flex-row mobile:flex-col gap-5 ">
                                <div className=" gap-1 desktop:w-[280px] mobile:w-full">
                                    <p className="text-white font-IBMPlex font-normal text-[16px]">First name</p>
                                    <input
                                        {...register("first_name")}
                                        type="text"
                                        className="w-full h-12 rounded-[4px] bg-[#282828] focus:outline-none placeholder:text-[#7C7C7C] p-[10px] text-white"
                                        placeholder="Write first name"
                                        maxLength="100"
                                    />
                                </div>
                                <div className=" gap-1 desktop:w-[280px] mobile:w-full">
                                    <p className="text-white font-IBMPlex font-normal text-[16px]">Last name</p>
                                    <input
                                        {...register("last_name")}
                                        type="text"
                                        className="w-full h-12 rounded-[4px] bg-[#282828] focus:outline-none placeholder:text-[#7C7C7C] p-[10px] text-white"
                                        placeholder="Write last name"
                                        maxLength="100"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex desktop:mt-[30px] mobile:mt-4 desktop:w-[420px] desktop:justify-between mobile:w-full">
                            <button
                                className="desktop:w-[200px] desktop:flex  desktop:justify-center desktop:items-center desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold desktop:border-[3px] desktop:border-[#BB213D] desktop:bg-[#1A1A1A] hover:bg-GRAY666/[0.2] desktop:text-white active:bg-[#242424] mobile:hidden"
                                onClick={() => handleOpen()}
                            >
                                Cancel
                            </button>
                            <button
                                className={`desktop:w-[200px] desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold bg-[#BB213D] text-white ${"active:bg-[#BB213D]/80 hover:bg-RED001/[0.8]"} mobile:h-[52px] mobile:w-full`}
                                onClick={handleSubmit((formValue) => {
                                    uploadBookBank(bookBank, formValue, BANK_NAME[bankIndex].val, setOpen, setSuccess4Step)
                                })}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </DialogBody>
            </Dialog>
        </ThemeProvider>
    )
}

export default Step_Bookbank