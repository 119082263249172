import React from "react";
import { IconButton } from "../../../Button";
import IconFacebookDesktopShare from "../../../../assets/images/social/facebook.png";
import IconInstagramDesktopShare from "../../../../assets/images/social/instagram.png";
import IconLineDesktopShare from "../../../../assets/images/social/line.png";
import IconPinterestDesktopShare from "../../../../assets/images/social/pinterest.png";
import IconTwitterDesktopShare from "../../../../assets/images/social/twitter.png";
import IconGenlinksDesktopShare from "../../../../assets/images/social/genlinks.png";
import IconInstagramMobileShare from "../../../../assets/images/social/IconSocialMobile/instagram.svg";
import IconFacebookMobileShare from "../../../../assets/images/social/IconSocialMobile/facebook.svg";
import IconTwitterMobileShare from "../../../../assets/images/social/IconSocialMobile/twitter.svg";
import IconYoutubeMobileShare from "../../../../assets/images/social/IconSocialMobile/youtube.svg";
import IconGenlinksMobileShare from "../../../../assets/images/social/IconSocialMobile/genlink.svg";
import { useNavigate } from "react-router-dom";

const SocialShare = () => {
  const navigate = useNavigate();

  const onClickSocialShare = (e) => {
    e.preventDefault();
    navigate("/comingsoon");
  };
  return (
    <div className="w-full flex flex-col desktop:gap-10 mobile:gap-4">
      <div className="flex flex-col desktop:gap-4 mobile:gap-2">
        <p className="font-[700] desktop:text-3xl mobile:text-xl text-white desktop:text-center">
          Share your offer & Sell it faster
        </p>
        <p className="desktop:font-[700] mobile:font-[400] desktop:text-xl mobile:text-sm text-white desktop:text-center">
          Share it now on your social media and instantly enchance the exposure
          ofyour offer
        </p>
      </div>

      {/* dataSocialDesktopShare */}
      <div className="desktop:flex mobile:hidden flex-wrap 2xl:gap-10 xl:gap-7 gap-5">
        {dataSocialDesktopShare?.map(({ name, icon }, index) => {
          return (
            <div
              key={index + 1}
              className="w-[31.2%] grow"
              onClick={onClickSocialShare}
            >
              <IconButton
                iconButton={icon}
                text={name}
                styleImage={`${
                  name === "Gen Links"
                    ? "!w-7 !h-5"
                    : name === "Twitter"
                    ? "!w-7"
                    : "!w-6"
                }`}
              />
            </div>
          );
        })}
      </div>

      {/* dataSocialMobileShare */}
      <div className="desktop:hidden mobile:flex items-center gap-3">
        {dataSocialMobileShare?.map(({ icon, name }, index) => {
          return (
            <div
              key={index + 1}
              className="hover:bg-white/[0.1] hover:rounded"
              onClick={onClickSocialShare}
            >
              <img src={icon} alt={name} className={`rounded w-11 h-11`} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SocialShare;

const dataSocialDesktopShare = [
  {
    name: "Facebook",
    icon: IconFacebookDesktopShare,
  },
  {
    name: "Instagram",
    icon: IconInstagramDesktopShare,
  },
  {
    name: "Line",
    icon: IconLineDesktopShare,
  },
  {
    name: "Pinterest",
    icon: IconPinterestDesktopShare,
  },
  {
    name: "Twitter",
    icon: IconTwitterDesktopShare,
  },
  {
    name: "Gen Links",
    icon: IconGenlinksDesktopShare,
  },
];

const dataSocialMobileShare = [
  {
    name: "IconInstagramShare",
    icon: IconInstagramMobileShare,
  },
  {
    name: "IconFacebookShare",
    icon: IconFacebookMobileShare,
  },
  {
    name: "IconTwitterShare",
    icon: IconTwitterMobileShare,
  },
  {
    name: "IconYoutubeShare",
    icon: IconYoutubeMobileShare,
  },
  {
    name: "IconGenlinkShare",
    icon: IconGenlinksMobileShare,
  },
];
