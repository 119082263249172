import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setDisplay, setUrlPath } from '../../slice/goBackBtnSlice'
import { useNavigate, Link } from 'react-router-dom'
import { Icon_authenticator, Icon_close, Icon_duplicate } from '../../assets/icons'
import googlePlay from '../../assets/google_play.png'
import appStore from '../../assets/app_store.png'
import qr_googleAuth from '../../api/post/setting/qr_googleAuth'
import {
    Dialog,
    DialogHeader,
    DialogBody,
    Alert,
} from "@material-tailwind/react";
import { icon_hidePassword, icon_showPassword } from '../../assets/login'
import { check_2fa_status, activate_2fa } from '../../api/post/security'

export default function Security() {
    const goBack = useDispatch()
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])
    const navigate = useNavigate()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [qrCode, setQRCode] = useState()
    const [textCode, setTextCode] = useState()
    const [isCopied, setIsCopied] = useState(false)
    const [inputCode, setInputCode] = useState()
    const [showCode, setShowCode] = useState(false)
    const [status_2fa, setStatus_2fa] = useState(null)


    const handleOpenDialog = () => {
        if (dialogOpen === false) {
            qr_googleAuth(setQRCode, setTextCode)
        }
        setDialogOpen(prev => !prev)
    }

    const handleGoQRCodePage = () => {
        navigate('qrcode-authenticator')
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(textCode);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1000);
    }

    useEffect(() => {
        goBack(setDisplay(false))
        goBack(setUrlPath('/setting'))
    }, [])

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight])
        }

        window.addEventListener('resize', handleWindowResize)
        return () => window.removeEventListener('resize', handleWindowResize)
    }, [])

    useEffect(() => {
        check_2fa_status(setStatus_2fa)
    }, [])

    console.log(inputCode);
    return (
        <main className='w-full relative flex flex-col desktop:px-3 text-white mobile:p-4'>
            <Alert open={isCopied} className='absolute z-[10000] top-[80%] left-[-69%] w-56 rounded
                bg-GRAY910 text-GREEN100 border-b-2 border-GREEN100 !opacity-70'
            >
                Copied
            </Alert>
            <h1 className='desktop:text-[2rem] font-bold mobile:text-2xl'>Setting Security</h1>
            <h3 className='font-bold desktop:mt-5 desktop:text-2xl mobile:text-base mobile:mt-3'>Choose the right security system for your wallet.</h3>
            <p className='text-base font-normal desktop:mt-5 mobile:mt-4'>
                Do not delete the 2FA app after successful activation, as the 2FA code will be
                lost and you will need to ask staff to remove 2FA. and set up a new 2FA app, The
                Company shall not be liable for any damages arising from unauthorized access to
                your 2FA code.
            </p>
            <div className='flex justify-between items-center w-full desktop:mt-10 mobile:mt-[1.12rem]'>
                <div className='grow'>
                    <Icon_authenticator className='inline-block align-middle mb-1 desktop:[&>svg]:w-[3.375rem] desktop:[&>svg]:h-[3.375rem] 
                        mobile:[&>svg]:w-[2.25rem] mobile:[&>svg]:h-[2.25rem] mobile:'
                    />
                    <span className='desktop:text-[1.375rem] font-normal desktop:ml-[4.13rem] whitespace-nowrap mobile:text-[0.87rem] mobile:ml-4'>
                        Google Authenticator
                    </span>
                </div>
                {!!status_2fa
                    ? (
                        <button className='uppercase rounded bg-black font-bold 
                        desktop:w-[9.37rem] desktop:h-[3rem] desktop:text-base desktop:mr-0
                        mobile:w-[4.69rem] mobile:h-[1.5rem] mobile:text-[.5rem] mobile:mr-2'

                        >
                            activated
                        </button>)
                    : (<button disabled={typeof status_2fa !== 'boolean'} className='uppercase rounded bg-RED001 font-bold 
                    desktop:w-[9.37rem] desktop:h-[3rem] desktop:text-base desktop:mr-0
                    mobile:w-[4.69rem] mobile:h-[1.5rem] mobile:text-[.5rem] mobile:mr-2'
                        onClick={windowSize[0] <= 768 ? handleGoQRCodePage : handleOpenDialog
                        }
                    >
                        getstarted
                    </button>)}

            </div>
            <div className='flex flex-col desktop:mt-10 gap-y-3 mobile:mt-[1.12rem]'>
                <p className='text-base font-normal'>
                    If you have any questions you can reply to this email to contact support :
                    <a href='https://business@gmillion.io' className='underline ml-1'>business@gmillion.io</a>
                </p>
                <a href='#' className='mt-2 text-base font-normal underline'>Unlock or Change 2FA</a>
                <a href='#' className='mt-2 text-base font-normal underline'>FAQ Support</a>
            </div>
            <Dialog open={dialogOpen && windowSize[0] > 768} handler={handleOpenDialog}
                className='desktop:block rounded bg-GRAY700 2xl:w-full 2xl:max-w-[59rem] py-10 
                overflow-hidden mobile:hidden '
            >
                <DialogHeader className='relative flex justify-center p-0'>
                    <h1 className='text-3xl font-bold text-white'>Google Authenticator (2FA)</h1>
                    <button className='absolute top-[-5.25rem] right-[-2.5rem] w-[6.125rem] h-[6.125rem] 
                        rounded-[100%] bg-GRAY910 text-white'
                        onClick={handleOpenDialog}
                    >
                        <Icon_close className='[&>svg]:w-[1.125rem] [&>svg]:h-[1.125rem] mt-9 ml-6' />
                    </button>
                </DialogHeader>
                <DialogBody className='flex flex-col items-center pt-5 pb-0  '>
                    <div className='max-w-[690px] min-w-0 w-full flex flex-col items-center gap-y-5 '>
                        <p className='w-[27.31rem] text-white text-base font-normal text-center'>
                            Please scan this QR code or type the out authenticator code
                            into your authenticator application
                        </p>
                        <img src={qrCode} alt='' width='272' height='272' className='' />
                        <div className='relative w-full'>
                            <input
                                type={showCode ? "text" : "password"}
                                name="inputCode"
                                className={`w-full h-[48px]  focus:outline-none px-[12px] py-[11px] rounded-[4px] bg-[#282828] text-white text-[16px] placeholder:text-[#939393]`}
                                placeholder='Enter 2FA code'
                                maxLength="6"
                                onKeyDown={(e) =>
                                    (e?.key?.length === 1 && e.key.match(/[^0-9]/g)) && e.preventDefault()
                                }
                                onChange={(e) => setInputCode(e?.target?.value)}
                            />
                            <div className="absolute  right-[12px] flex  bottom-[12px]">
                                {showCode
                                    ? <div className="w-6 h-6 cursor-pointer" onClick={() => setShowCode(prev => !prev)}>
                                        <img src={icon_showPassword} alt="icon_showPassword" className="w-full" />
                                    </div>
                                    : <div className="w-6 h-6 cursor-pointer" onClick={() => setShowCode(prev => !prev)}>
                                        <img src={icon_hidePassword} alt="icon_showPassword" className="w-full" />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='text-base font-normal text-white rounded w-full py-14 px-3 text-center gap-2 flex justify-center '>
                            <p className='whitespace-normal break-words'>Do you need help? &nbsp;
                                <Link to="/comingsoon" className=' underline underline-offset-4'>
                                    What’s Google Authenticator (2FA)
                                </Link></p>
                        </div>

                        <div className="flex  mobile:mt-4 max-w-[420px] w-full min-w-0 justify-between gap-4">
                            <button
                                className="desktop:max-w-[200px] desktop:min-w-0 desktop:w-full  desktop:flex  desktop:justify-center desktop:items-center desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold desktop:border-[3px] desktop:border-[#BB213D] hover:bg-GRAY666/[0.2] desktop:bg-[#1A1A1A] desktop:text-white active:bg-[#242424] mobile:hidden"
                                onClick={handleOpenDialog}
                            >
                                Cancel
                            </button>
                            <button
                                className="desktop:max-w-[200px] desktop:min-w-0 desktop:w-full desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold bg-[#BB213D] text-white hover:bg-RED001/[0.9] active:bg-[#BB213D]/80 "
                                onClick={() => activate_2fa(inputCode, setStatus_2fa, setDialogOpen)}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>

                </DialogBody>
            </Dialog>
        </main>
    )
}