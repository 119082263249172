import React from "react";
import dayjs from "dayjs";
import { useNavigate, createSearchParams } from "react-router-dom";
import NoResult from "../../common/NoResult";

const TableCheckProgress = ({ dataPayment, dataItemQC, filter }) => {
  const navigate = useNavigate();

  const onClickViewProgress = (
    progress,
    orderId,
    itemName,
    transactionTrackId
  ) => {
    if (progress === "Success") {
      navigate({
        pathname: "/complete",
        search: `${createSearchParams({
          itemName: itemName,
          trackId: transactionTrackId,
        })}`,
      });
    }
    if (progress === "Pending") {
      navigate({
        pathname: "/payment",
        search: `${createSearchParams({
          orderNo: orderId,
        })}`,
      });
    }
    if (progress === "Delivery") {
      navigate({
        pathname: "/delivery",
        search: `${createSearchParams({
          itemName: itemName,
          trackId: transactionTrackId,
        })}`,
      });
    }
  };

  return (
    <div>
      <div className="w-full bg-GRAY710">
        <table className="w-full table-auto">
          <thead>
            <tr>
              {headerName.map(({ name }, index) => (
                <th
                  key={index + 1}
                  className={`border-y border-GRAY910 py-3 bg-GRAY710 ${
                    name === "OFFER TITLE" ? "w-[30%]" : "w-[15%]"
                  }`}
                >
                  <p
                    className={`font-[400] text-base text-${
                      name === "VIEW" ? "transparent" : "white"
                    } ${name === "OFFER TITLE" ? "text-left" : "text-center"}`}
                  >
                    {name}
                  </p>
                </th>
              ))}
            </tr>
          </thead>
          {filter === "payment" ? (
            <tbody className="[&>*:last-child]:border-none">
              {dataPayment?.data?.map(
                (
                  {
                    gameName,
                    grandTotal,
                    itemDesc,
                    itemName,
                    itemPic,
                    namePayment,
                    productCount,
                    progress,
                    transactionTrackId,
                    txnDatetime,
                    type,
                    orderId,
                  },
                  index
                ) => {
                  return (
                    <tr key={index + 1} className="border-b border-GRAY910">
                      <td>
                        <div className="py-5 flex gap-5 ">
                          <div className="relative">
                            <img
                              src={
                                itemPic["media_url1"]
                                  ? itemPic["media_url1"]
                                  : ""
                              }
                              alt="pictureItem"
                              className="w-[10vw] h-[10vw] max-w-[7.5rem] max-h-[7.5rem] rounded flex object-cover"
                            />
                            {progress === "Canceled" && (
                              <div className="absolute top-0 w-[10vw] h-[10vw] max-w-[7.5rem] max-h-[7.5rem] bg-black/[0.6] rounded"></div>
                            )}
                          </div>
                          <div className="flex py-3 pt-2 justify-between w-[16vw]">
                            <div className="flex flex-col justify-around w-[16vw]">
                              <p
                                className={`text-xl font-[700] ${
                                  progress === "Canceled"
                                    ? "text-GRAY920"
                                    : "text-white"
                                } truncate`}
                              >
                                {itemName}
                              </p>
                              <div className="flex gap-1 items-center">
                                <p
                                  className={`text-xs font-[400] ${
                                    progress === "Canceled"
                                      ? "text-GRAY920"
                                      : "text-GRAY920"
                                  } truncate`}
                                >
                                  {itemDesc}
                                </p>
                              </div>
                              <p
                                className={`text-xs font-[700] ${
                                  progress === "Canceled"
                                    ? "text-RED003"
                                    : "text-RED001"
                                } underline`}
                              >
                                {gameName}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p
                          className={`font-[700] text-base ${
                            progress === "Canceled"
                              ? "text-GRAY920"
                              : "text-white"
                          } text-center`}
                        >
                          {productCount}
                        </p>
                      </td>
                      <td>
                        <p
                          className={`font-[700] text-base ${
                            progress === "Canceled"
                              ? "text-GRAY920"
                              : "text-white"
                          } text-center`}
                        >
                          {grandTotal} THB
                          <span
                            className={`${
                              progress === "Canceled"
                                ? "text-GRAY920"
                                : type === "Buy"
                                ? "text-GREEN050"
                                : "text-RED001"
                            }`}
                          >
                            {" "}
                            {`(${type === "Buy" ? "B" : "S"})`}
                          </span>
                        </p>
                      </td>
                      <td>
                        <p
                          className={`font-[700] text-base ${
                            progress === "Success"
                              ? "text-GREEN050"
                              : progress === "Pending"
                              ? "text-YELLOW200"
                              : progress === "Canceled"
                              ? "text-GRAY920"
                              : "text-RED005"
                          } text-center`}
                        >
                          {progress}
                        </p>
                      </td>
                      <td>
                        <p
                          className={`font-[400] text-sm ${
                            progress === "Canceled"
                              ? "text-GRAY920"
                              : "text-white"
                          } text-center`}
                        >
                          {txnDatetime
                            ? dayjs(txnDatetime).format("DD-MM-YYYY")
                            : ""}
                        </p>
                      </td>
                      <td>
                        {progress === "Canceled" ? (
                          <div className="font-[700] text-base text-RED004 text-center cursor-default">
                            <p>REFUND</p>
                          </div>
                        ) : (
                          <div
                            className={`w-full min-w-[5.625rem] bg-RED001 flex justify-center items-center rounded  hover:bg-RED001/[0.8] desktop:h-[3.5rem] mobile:h-[3.25rem]  cursor-pointer `}
                            onClick={() =>
                              onClickViewProgress(
                                progress,
                                orderId,
                                itemName,
                                transactionTrackId
                              )
                            }
                          >
                            <p
                              className={`uppercase   font-[700] text-base text-white`}
                            >
                              View
                            </p>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          ) : (
            <tbody className="[&>*:last-child]:border-none">
              {dataItemQC?.data?.map(
                (
                  {
                    game_name,
                    item_price,
                    item_description,
                    item_name,
                    item_media,
                    namePayment,
                    item_quantity,
                    status,
                    // transactionTrackId,
                    create_date,
                    // type,
                    // orderId,
                  },
                  index
                ) => {
                  return (
                    <tr key={index + 1} className="border-b border-GRAY910">
                      <td>
                        <div className="py-5 flex gap-5">
                          <div className="relative">
                            <img
                              src={
                                item_media["media_url1"]
                                  ? item_media["media_url1"]
                                  : ""
                              }
                              alt="pictureItem"
                              className="w-[10vw] h-[10vw] max-w-[7.5rem] max-h-[7.5rem] rounded flex object-cover"
                            />
                            {status === "Canceled" && (
                              <div className="absolute top-0 w-120 h-120 bg-black/[0.6] rounded"></div>
                            )}
                          </div>
                          <div className="flex py-3 pt-2 justify-between w-[16vw]">
                            <div className="flex flex-col justify-around w-[16vw]">
                              <p
                                className={`text-xl font-[700] ${
                                  status === "Canceled"
                                    ? "text-GRAY920"
                                    : "text-white"
                                } truncate`}
                              >
                                {item_name}
                              </p>
                              <div className="flex gap-1 items-center">
                                <p
                                  className={`text-xs font-[400] ${
                                    status === "Canceled"
                                      ? "text-GRAY920"
                                      : "text-white"
                                  } truncate`}
                                >
                                  {item_description}
                                </p>
                              </div>
                              <p
                                className={`text-xs font-[700] ${
                                  status === "Canceled"
                                    ? "text-RED003"
                                    : "text-RED001"
                                } underline`}
                              >
                                {game_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p
                          className={`font-[700] text-base ${
                            status === "Canceled"
                              ? "text-GRAY920"
                              : "text-white"
                          } text-center`}
                        >
                          {item_quantity}
                        </p>
                      </td>
                      <td>
                        <p
                          className={`font-[700] text-base ${
                            status === "Canceled"
                              ? "text-GRAY920"
                              : "text-white"
                          } text-center`}
                        >
                          {Number(item_price).toFixed()} THB
                          <span className={`text-RED001`}>{` (S)`}</span>
                        </p>
                      </td>
                      <td>
                        <p
                          className={`font-[700] text-base ${
                            status === "Expired"
                              ? "text-GRAY920"
                              : "text-YELLOW200"
                          } text-center`}
                        >
                          {status}
                        </p>
                      </td>
                      <td>
                        <p
                          className={`font-[400] text-sm ${
                            status === "Canceled"
                              ? "text-GRAY920"
                              : "text-white"
                          } text-center`}
                        >
                          {create_date
                            ? dayjs(create_date).format("DD-MM-YYYY")
                            : ""}
                        </p>
                      </td>
                      <td>
                        {status === "Canceled" ? (
                          <div className="font-[700] text-base text-RED004 text-center cursor-default">
                            <p>REFUND</p>
                          </div>
                        ) : (
                          <div
                            className={`w-full min-w-[5.625rem] bg-RED003 flex justify-center items-center rounded  hover:bg-RED003 desktop:h-[3.5rem] mobile:h-[3.25rem]  cursor-default`}
                            // onClick={() =>
                            //   onClickViewProgress(
                            //     status,
                            //     orderId,
                            //     item_name,
                            //     transactionTrackId
                            //   )
                            // }
                          >
                            <p
                              className={`uppercase   font-[700] text-base text-white`}
                            >
                              View
                            </p>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          )}
        </table>
        {filter === "payment" ? (
          dataPayment?.data ? (
            ""
          ) : (
            <div className="flex justify-center w-full pt-8">
              <NoResult />
            </div>
          )
        ) : (
          dataItemQC?.totalItem === 0 && (
            <div className="flex justify-center w-full pt-8">
              <NoResult />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default TableCheckProgress;

const headerName = [
  {
    name: "OFFER TITLE",
  },
  {
    name: "QUANTITY",
  },
  {
    name: "PRICE",
  },
  {
    name: "STATUS",
  },
  {
    name: "TIME",
  },
  {
    name: "VIEW",
  },
];
