import { useState, useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import Header from "../../component/layout/Header"
import { checkEmail, sendEmail } from "../../api/post/register";
import { bg_main, bg_shadow_1, bg_shadow_2 } from "../../assets/register";
import { icon_hidePassword, icon_showPassword, retohub_icon } from "../../assets/login";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import { verificated } from "../../api/post/register";
import FooterLRF from "../../component/layout/FooterLRF";
import Cookies from "js-cookie";

const Register = () => {
	const pathName = useLocation().pathname.split('/')
	const [checkbox, setChecbox] = useState(false)
	const [currentStage, setCurrentStage] = useState("reg")
	const [showPassword, setShowPassword] = useState(false)
	const [showConfirmPassword, setConfirmShowPassword] = useState(false)
	const [showError, setShowError] = useState("")
	const { register, handleSubmit, setError, formState: { errors }, getValues } = useForm({});
	const [access_token, setAccess_token] = useState()
	const [searchParams] = useSearchParams()
	const [countTime, setCountTime] = useState(0)


	useEffect(() => {
		if (errors.email) {
			setShowError('email')
		} else if (errors.password) {
			setShowError("password")
		} else if (errors.confirmpassword) {
			setShowError("confirmpassword")
		} else if (errors.display_name) {
			setShowError("display_name")
		}

	}, [errors.email, errors.password, errors.confirmpassword, errors.display_name])

	useEffect(() => {
		if (searchParams.get('stage') === "verify_email") {
			setCurrentStage("verify_email")
			sendEmail(Cookies.get("access_token"))
		}
		verificated(searchParams.get("token"))
	}, [])

	const onSubmit = (formValues) => {
		if (!formValues.checkbox) {
			setShowError("checkbox")
		} else {
			checkEmail(formValues, setCurrentStage, setError, setAccess_token)
		}
		// // checkEmail(formValues, setCurrentStage, setError, setAccess_token)
	}
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			handleSubmit(onSubmit)()
		}
	}

	useEffect(() => {
		countTime > 0 && setTimeout(() => {
			setCountTime(prev => prev - 1)
		}, 3000000)

	}, [countTime])
	return (
		<div className="desktop:w-full desktop:h-full  desktop:relative desktop:min-h-[710px] "  >
			<div
				className="desktop:w-full desktop:h-screen desktop:absolute bg-black desktop:min-h-[710px]"
				style={{
					backgroundImage: `url(${bg_main})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "cover"
				}}></div>
			<div
				className="desktop:w-full desktop:h-screen desktop:absolute desktop:min-h-[710px]"
				style={{
					backgroundImage: `url(${bg_shadow_1})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "cover"
				}}></div>
			<div
				className="desktop:w-full desktop:h-screen desktop:absolute desktop:min-h-[710px]"
				style={{
					backgroundImage: `url(${bg_shadow_2})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "cover"
				}}></div>
			<div className="flex flex-col w-full  desktop:max-h-screen desktop:min-h-[710px]  py-10 mobile:h-screen" >
				<div className="flex w-full desktop:max-h-full desktop:min-h-[550px] desktop:h-full mobile:h-full  z-10 justify-between items-center">
					<div className="desktop:flex mobile:hidden desktop:max-w-[50%] desktop:w-[50%] desktop:min-w-0 justify-center items-center">
						<div className="desktop:flex mobile:hidden max-h-[20rem] max-w-[20rem] z-10">
							{/* absolute left-[21.5vw] -translate-y-[50%] top-[50%] */}
							<img src={retohub_icon} alt="retohub_icon" className="w-full h-full object-contain" />
						</div>
					</div>

					<div className="desktop:max-w-[35.27vw] mobile:w-full mobile:h-screen desktop:w-[35.27vw] desktop:min-w-0 desktop:min-h-[710px] desktop:h-full mobile:min-w-0 mobile:min-h-[650px]  z-10 flex desktop:justify-start desktop:items-center mobile:items-start">
						{pathName?.length === 2
							? <div className="flex flex-col  mobile:bg-[#1E1E1E] desktop:bg-transparent mobile:w-full  mobile:h-full desktop:h-screen desktop:max-w-[400px]  desktop:w-[400px] desktop:min-w-0 desktop:justify-center mobile:justify-start">
								<div className="mobile:flex desktop:hidden mobile:w-full">
									<Header />
								</div>
								{currentStage === "reg" && (
									<div className=" z-10  flex flex-col items-start mobile:px-3 mobile:bg-[#1E1E1E] desktop:bg-transparent gap-4 mobile:w-full w-full pt-[16px] desktop:h-[550px] ">
										<p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold">REGISTER</p>
										<p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-normal">Already have an account?   <Link to="/login" className="text-[#FFD262] cursor-pointer">Login</Link> here.</p>
										{/* email */}
										<div className="w-full h-[78px] flex flex-col items-start justify-between ">
											<p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">Email</p>
											<div className="relative w-full">
												<input
													{...register("email", {
														required: `Please enter your email`,
														pattern: {
															value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
															// 	message: `This will be displayed on your profile and can be seen by other users. Special characters such as _or- are allowed to be used or number 0-9.
															// -*/\|&^%$#`,
															message: `Invalid email Please try again`,
														}
													})}
													type="email"
													className={`w-full h-[48px] focus:outline-none px-[12px] py-[11px] rounded-[4px] bg-[#282828] text-white text-[16px] ${errors.email ? "border border-[#AE3C33] " : "border-none"} placeholder:text-[#939393]`}
													placeholder="Enter email address"
													onClick={() => showError === "email" ? setShowError("") : setShowError("email")}
													onKeyDown={handleKeyDown}
												/>
												{errors.email && showError === "email" && (
													<div className="mobile:hidden desktop:flex absolute desktop:p-2 top-[calc(50%)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
														<div className="flex justify-center items-center relative w-full h-full ">
															<p className=" text-white font-IBMPlex">{errors.email.message}</p>
															<div
																className="w-0 h-0 absolute left-[100%] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"
															></div>
														</div>
													</div>
												)}
											</div>
										</div>
										{errors.email && <p className="mobile:flex desktop:hidden text-xs italic text-red-500">{errors.email.message}</p>}
										{/* password */}
										<div className="w-full h-[78px] flex flex-col items-start justify-between relative" >
											<p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">Password</p>
											<input
												{...register("password", {
													required: 'Please enter your password',
													// pattern: {
													// 	value: /^(?=.*[a-z])(?=.*[A-Z]).+$/,
													// 	message: ''
													// },
													validate: {
														containNumber: (value) => /\d/.test(value) || "Password must contain at least 1 number", isUpperCase: (value) => /[A-Z]/.test(value) || "Password must contain at least 1 uppercase character",
														checkSpecialChar: (value) => !/[(/\|&^%$#)]/.test(value) || "Please do not use these special character (/\|&^%$#)"
													},
													minLength: {
														value: 5,
														message: "Your password is too short (must longer than 4 characters)"
													},
													maxLength: {
														value: 16,
														message: "Your password is too long (not longer than 16 characters)"
													}
												})}
												type={showPassword ? "text" : "password"}
												className={`w-full h-[48px]  focus:outline-none px-[12px] py-[11px] rounded-[4px] bg-[#282828] text-white text-[16px] ${errors.password ? "border border-[#AE3C33] " : "border-none"} placeholder:text-[#939393]`}
												placeholder="Enter password"
												onClick={() => showError === "password" ? setShowError("") : setShowError("password")}
												onKeyDown={handleKeyDown}
											/>
											<div className="absolute  right-[12px] flex  bottom-[12px]">
												{showPassword
													? <div className="w-6 h-6 cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
														<img src={icon_showPassword} alt="icon_showPassword" className="w-full" />
													</div>
													: <div className="w-6 h-6 cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
														<img src={icon_hidePassword} alt="icon_showPassword" className="w-full" />
													</div>
												}
											</div>
											{errors.password && showError === "password" && (
												<div className="mobile:hidden desktop:flex absolute p-2 top-[calc(50%+14px)] right-[calc(100%+12px)] -translate-y-[50%] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
													<div className="flex justify-center items-center relative w-full h-full ">
														<p className=" text-white font-IBMPlex">{errors.password.message}</p>
														<div
															className="w-0 h-0 absolute left-[calc(100%)] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"
														></div>
													</div>
												</div>
											)}
										</div>
										{errors.password && (
											<p className="mobile:flex desktop:hidden text-xs italic text-red-500">{errors.password.message}</p>
										)}
										{/* confirm password */}
										<div className="w-full h-[78px] flex flex-col items-start justify-between relative">
											<p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">Confirm password</p>
											<input
												{...register("confirmpassword", {
													required: {
														value: true,
														message: "Please make sure your password match",
													},
													validate: (value) => {
														if (value === getValues("password")) {
															return true;
														}
														if (value === getValues("new_password")) {
															return true;
														}

														return "Please make sure your password match";
													},
												})}
												type={showConfirmPassword ? "text" : "password"}
												className={`w-full h-[48px]  focus:outline-none px-[12px] py-[11px] rounded-[4px] bg-[#282828] text-white text-[16px] ${errors.confirmpassword ? "border border-[#AE3C33] " : "border-none"} placeholder:text-[#939393]`}
												placeholder="Re-confirm password"
												onClick={() => showError === "confirmpassword" ? setShowError("") : setShowError("confirmpassword")}
												onKeyDown={handleKeyDown}
											/>
											<div className="absolute  right-[12px] flex  bottom-[12px]">
												{showConfirmPassword
													? <div className="w-6 h-6 cursor-pointer" onClick={() => setConfirmShowPassword(!showConfirmPassword)}>
														<img src={icon_showPassword} alt="icon_showPassword" className="w-full" />
													</div>
													: <div className="w-6 h-6 cursor-pointer" onClick={() => setConfirmShowPassword(!showConfirmPassword)}>
														<img src={icon_hidePassword} alt="icon_showPassword" className="w-full" />
													</div>
												}
											</div>
											{errors.confirmpassword && showError === "confirmpassword" && (
												<div className="mobile:hidden desktop:flex absolute p-2 top-[calc(50%+14px)] right-[calc(100%+12px)] -translate-y-[50%] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
													<div className="flex justify-center items-center relative w-full h-full ">
														<p className=" text-white font-IBMPlex">{errors.confirmpassword.message}</p>
														<div
															className="w-0 h-0 absolute left-[calc(100%)] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"
														></div>
													</div>
												</div>
											)}
										</div>
										{errors.confirmpassword && (
											<p className="mobile:flex desktop:hidden text-xs italic text-red-500">{errors.confirmpassword.message}</p>
										)}
										{/* checkbox */}
										<div className="flex flex-col w-full">
											<div className="flex flex-col ">
												<div className="flex justify-start items-start gap-[20px] max-h-fit h-full  py-2 relative">
													<div className="  translate-y-1 ">
														<input
															{...register("checkbox")}
															type="checkbox"
															value={checkbox}
															onChange={() => setChecbox(!checkbox)}
															className={` translate-x-1 appearance-none w-5 h-5 bg-[#282828]  checked:bg-[#BB213D] border-[2px] checked:border-[#BB213D]  border-white rounded after:content-[''] after:w-full after:h-full after:absolute after:left-0 after:top-0 after:bg-no-repeat after:bg-center after:bg-[length:40px] checked:after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')]`}
														/>
													</div>
													<p className="font-IBMPlex text-[#FFFFFF] text-[16px] font-normal   items-center whitespace-normal break-words">I have read and agree to RetoHub’s &nbsp; <Link to="/term_policy" className="text-[#FFFFFF] text-[16px] font-bold underline underline-offset-2">Terms of Use</Link>
														&nbsp;and <Link to="/privacy_policy" className="text-[#FFFFFF] text-[16px] font-bold underline underline-offset-2">Privacy Policy</Link>
													</p>
													{showError === "checkbox" && (
														<div className="mobile:hidden desktop:flex  absolute p-2 top-[calc(50%-8px)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33]  min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px] px-3">
															<div className="flex justify-center items-center relative w-full h-full ">
																<p className=" text-white font-IBMPlex">Please accept our terms and conditions of service.</p>
																<div
																	className="w-0 h-0 absolute left-[calc(100%+6px)] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"
																></div>
															</div>
														</div>
													)}
												</div>
												{showError === "checkbox" && (
													<p className="mobile:flex desktop:hidden text-xs italic text-red-500">Please accept our terms and conditions of service.</p>
												)}
											</div>
											<button
												className="w-full h-[3.25rem] mt-6 bg-[#BB213D] flex justify-center items-center rounded-[4px] font-IBMPlex text-[#FFFFFF] text-[16px] font-bold hover:bg-RED001/[0.8]"
												onClick={handleSubmit((formValues) => {
													if (!formValues.checkbox) {
														setShowError("checkbox")
													} else checkEmail(formValues, setCurrentStage, setError, setAccess_token)
												})}
											>
												CREATE ACCOUNT
											</button>
										</div>
									</div>
								)}


								{currentStage === "verify_email" && (
									<div className="  z-10  flex flex-col items-start justify-center mobile:px-3 mobile:bg-[#1E1E1E] desktop:bg-transparent gap-4 mobile:w-full w-full pt-[16px] desktop:h-[550px] ">
										<p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold uppercase">Email verification</p>
										<p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-normal">Don’t receive email. <span className="text-[#FFD262] cursor-pointer" onClick={() => {
											if (countTime <= 0) {
												sendEmail(access_token ? access_token : Cookies.get("access_token"))
												setCountTime(5)
											}
										}}>Resend</span> again.</p>
									</div>
								)}

							</div>
							: <div className="flex flex-col  mobile:bg-[#1E1E1E] desktop:bg-transparent mobile:w-full  mobile:h-full desktop:h-screen desktop:max-w-[400px]  desktop:w-[400px] desktop:min-w-0 desktop:justify-center mobile:justify-start ">
								<Outlet />
							</div>}
					</div>
				</div>
				<FooterLRF />
			</div>
		</div >
	)
}

export default Register