import React, { useState } from "react";
import { gem, growth, roman_helmet, sword } from "../assets/home/icons/index";
import Select, { components } from "react-select";

const Categories = [
  { value: "All Categories", label: "All Categories", icon: "" },
  { value: "Account", label: "Account", icon: roman_helmet },
  { value: "Gold", label: "Gold", icon: gem },
  { value: "Item", label: "Item", icon: sword },
  { value: "Service", label: "Service", icon: growth },
];

const Option = (props) => (
  <components.Option {...props} className="space-x-4">
    {props.data.icon && <img src={props.data.icon} alt="logo" className="" />}
    <div>{props.data.label}</div>
  </components.Option>
);

const Dropdown = () => {
  const [selectedCategories, setSelectedCategories] = useState(Categories[0]);

  const handleChange = (value) => {
    setSelectedCategories(value);
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>{children}</components.SingleValue>
  );

  return (
    <Select
      value={selectedCategories}
      options={Categories}
      onChange={handleChange}
      placeholder="All"
      styles={{
        singleValue: (base) => ({
          ...base,
          paddingTop: "-0.1rem",
          display: "flex",
          alignItems: "center",
          fontSize: "16px",
          height: "44px",
          color: "#939393",
          justifyContent: "end",
          marginLeft: "0px",
        }),
        control: (base, { isFocused }) => ({
          ...base,
          border: 0,
          backgroundColor: "#414141",
          width: "150px",
          boxShadow: "none",
          borderRadius: "0px",
          height: "100%",
        }),
        menu: (base) => ({
          ...base,
          backgroundColor: "#1A1A1A",
          color: "white",
          padding: "8px",
          marginTop: "20px",
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: "none",
        }),
        dropdownIndicator: (base) => ({
          ...base,
          padding: "0px",
          paddingRight: "0.75rem",
          paddingBottom: "0.1rem",
        }),
        option: (base, { isFocused }) => ({
          ...base,
          backgroundColor: isFocused ? "#000000" : "#1A1A1A",
          display: "flex",
          width: "fit-container",
          padding: "10px",
          borderRadius: "6px",
        }),
      }}
      components={{
        Option,
        SingleValue,
      }}
    />
  );
};

export default Dropdown;
