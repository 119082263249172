import { useQuery } from "@tanstack/react-query";
import { completeApi } from "../../services";

export const useQueryGetReviewRating = (userId, review, page, pageSize) => {
  const query = useQuery({
    queryKey: ["getReviewRating"],
    queryFn: async () => {
      const data = await completeApi.ClientGetReviewRating(
        userId,
        review,
        page,
        pageSize
      );
      return data?.data;
    },
  });
  return query;
};
