export default function Icon_star(props) {
    return (
        <div {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.6498 9.04L14.8098 8.62L12.9198 4.17C12.5798 3.36 11.4198 3.36 11.0798 4.17L9.18983 8.63L4.35983 9.04C3.47983 9.11 3.11983 10.21 3.78983 10.79L7.45983 13.97L6.35983 18.69C6.15983 19.55 7.08983 20.23 7.84983 19.77L11.9998 17.27L16.1498 19.78C16.9098 20.24 17.8398 19.56 17.6398 18.7L16.5398 13.97L20.2098 10.79C20.8798 10.21 20.5298 9.11 19.6498 9.04ZM11.9998 15.4L8.23983 17.67L9.23983 13.39L5.91983 10.51L10.2998 10.13L11.9998 6.1L13.7098 10.14L18.0898 10.52L14.7698 13.4L15.7698 17.68L11.9998 15.4Z" 
                    fill="#939393"
                />
            </svg>
        </div>
        
    )
}