import { useMutation } from "@tanstack/react-query";
import { paymentApi } from "../../services";

export const useMutationClientItemCheckout = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return paymentApi.ClientItemCheckout(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationCreateOrderNumber = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return paymentApi.ClientCreateOrderNumber(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationGetDetailOrderNumber = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return paymentApi.ClientGetDetailOrderNumber(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationGenerateQRPayment = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return paymentApi.ClientGenerateQRPayment(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationPaymentConfirm = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return paymentApi.ClientPaymentConfirm(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationPaymentChangeOrder = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return paymentApi.ClientPaymentChangeOrder(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};
