import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDisplay } from "../slice/goBackBtnSlice";
import {
  bg_explore_topup,
  bg_explore_topup_mobile,
  marketImage,
  market_desktop,
  bg_circle,
  bg_items,
} from "../assets/home/index";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/grid";
import { Navigation, Pagination, Grid } from "swiper";
import { announcement_sold, next_home, prev_home } from "../assets/home/icons";
import Slider from "react-slick";
import {
  getSoldLatest,
  getHomeInfo,
  getTrendingItems,
  getItemsTopUp,
  getUserKYCstatus,
} from "../api/get/Homepage";
import ItemCard from "../component/ItemCard";
import { Link, useOutletContext, Navigate } from "react-router-dom";
import { getUserid } from "../api/get/getItemById";
import { getUserID } from "../slice/marketplace";
import { getKYCstatus } from "../slice/userslice";
import Cookies from "js-cookie";

const Home = () => {
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState("0");
  const [isHover, setIsHover] = useState(false);
  const [timeAutoplay, setTimeAutoplay] = useState(1000);
  const [soldLatest, setSoldLatest] = useState(null);
  const [homeInfo, setHomeInfo] = useState([]);
  const [trendingItems, setTrendingItems] = useState([]);
  const [itemsTopUp, setItemsTopUp] = useState([]);
  const [itemsPerPage] = useState(20);
  const [page] = useState(1);
  const [swipeForItemsTopUp, setSwipeForItemsTopUp] = useState();
  const [swipeForTrendingItems, setSwipeForTrendingItems] = useState();
  const open = useOutletContext();
  // const screenSize = (window.matchMedia("(max-width: 430px)").matches)
  const imagePerRow = 6;
  const [next, setNext] = useState(imagePerRow);
  const user = useSelector((state) => state.user);
  const market = useSelector((state) => state.marketplace);
  const [mobileOpen, setMobileOpen] = useState(null);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const trendingItemsLength = trendingItems?.length || 0;
  const itemsTopUpLength = itemsTopUp?.item_topup?.length || 0;
  const isLessThanLoopedSlidesForTrendingItems =
    trendingItemsLength >= swipeForTrendingItems?.loopedSlides;
  const isLessThanLoopedSlidesForItemsTopUp =
    itemsTopUpLength >= swipeForItemsTopUp?.loopedSlides;

  useEffect(() => {
    getSoldLatest(setSoldLatest);
    getHomeInfo(setHomeInfo);
    getTrendingItems(setTrendingItems, market?.user_id);
    getItemsTopUp(setItemsTopUp, itemsPerPage, page);
    dispatch(setDisplay(true));
    getUserKYCstatus(dispatch, getKYCstatus);
  }, [market?.user_id]);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setMobileOpen(false);
    } else {
      setMobileOpen(true);
    }
    const handleResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!!Cookies.get("access_token")) {
      getUserid(dispatch, getUserID);
    }
  }, []);

  setTimeout(() => {
    setTimeAutoplay(3000);
  }, 1000);

  const GAME_TITLE_1 = [
    { id: 1, name: "counter_strike2" },
    { id: 2, name: "dota2" },
    { id: 3, name: "ragnarok" },
    { id: 4, name: "apex" },
    { id: 5, name: "valo" },
    { id: 6, name: "gtav" },
    { id: 7, name: "freefire" },
    { id: 8, name: "lol" },
    { id: 9, name: "overwatch" },
    { id: 10, name: "deadbyDaylight" },
    { id: 11, name: "diablo" },
    { id: 12, name: "one_piece" },
  ];
  const GAME_TITLE_2 = [
    { id: 1, name: "identity" },
    { id: 2, name: "callOfDuty" },
    { id: 3, name: "fortnite" },
    { id: 4, name: "fifa" },
    { id: 5, name: "Genshin" },
    { id: 6, name: "rov" },
    { id: 7, name: "pubg" },
    { id: 8, name: "OnePunchMan" },
    { id: 9, name: "roblox" },
    { id: 10, name: "blackDesert" },
    { id: 11, name: "heartStone" },
    { id: 12, name: "football" },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 11.9,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    draggable: false,
    swipe: false,
    pauseOnHover: false,
  };

  const settings1 = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 11.9,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: timeAutoplay,
    draggable: true,
    swipe: false,
    pauseOnHover: false,
  };

  const formatCompactNumber = (number) => {
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    return formatter.format(number);
  };

  // if() return 

  // if (!user?.display_Name && Cookies.get("access_token"))
  //   return <Navigate to="/register/create_displayname" />;

    return (
    <div
      className={`${
        open ? "" : "hidden"
      } desktop:flex desktop:flex-col [@media(min-width:1919px)]:items-center w-full mobile:bg-GRAY111 desktop:bg-GRAY700 mobile:h-full desktop:h-full overflow-x-hidden desktop:relative`}
    >
      {/* get start part */}
      <section className="desktop:hidden mobile:block">
        <div>
          <div className="child_div mobile:w-full sm:h-[240px] md:h-[300px]">
            <div className="text-white font-bold ml-4 pt-16">
              <p className="py-1 text-xl">RETOHUB PROJECT</p>
              <p className="py-1 text-base">Digital Marketplace Platform</p>
              <Link
                to={"marketplace"}
                className="bg-RED001 w-[50%] max-w-[12.5rem] py-3 mt-3 flex justify-center rounded-md text-xs hover:bg-RED001/[0.8] cursor-pointer"
              >
                GET STARTED
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="mobile:hidden desktop:bg-[#1A1A1A] desktop:block">
        {isHover ? null : (
          <div className="bg_banner [@media(min-width:1919px)]:left-[0%]">
            <div className="w-full">
              <h1 className="text-white text-[32px] font-bold">
                Retohub Project
              </h1>
              <h2 className="text-white text-[24px] font-bold">
                Digital Marketplace Platform
              </h2>
              <Link
                to={"marketplace"}
                className="bg-RED001 text-white w-[200px] font-bold px-4 py-2 my-4 flex justify-center rounded-md hover:bg-RED001/[0.8] cursor-pointer"
              >
                GET STARTED
              </Link>
            </div>
          </div>
        )}
        <div
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className="flex flex-col gap-4 w-[4200px]"
        >
          <Slider {...settings}>
            {GAME_TITLE_1.map(({ name }, { id }) => (
              <img src={`/game_title/${name}.png`} key={id} alt="" />
            ))}
          </Slider>
          <Slider {...settings1} className="ml-[200px]">
            {GAME_TITLE_2.map(({ name }, { id }) => (
              <img src={`/game_title/${name}.png`} key={id} alt="" />
            ))}
          </Slider>
        </div>
      </section>

      {/* announcement desktop part */}
      <section className="w-full desktop:px-9 mobile:px-0">
        <div className="desktop:mt-10 w-full  desktop:max-w-[1597px] desktop:mx-auto  mobile:px-4 mobile:py-4 desktop:p-0">
          <div
            className="desktop:p-4 mobile:py-2 mobile:px-3 desktop:bg-GRAY111 mobile:bg-GRAY700 w-full items-center desktop:justify-between text-white leading-none desktop:rounded-md desktop:flex"
            role="alert"
          >
            <div className="flex items-center w-full desktop:gap-5">
              <div className="mobile:hidden desktop:flex rounded-md bg-GREEN050 uppercase px-4 py-2 text-xs font-bold  items-center">
                SOLD
                <img
                  alt="announcement_sold"
                  src={announcement_sold}
                  className="ml-2"
                />
              </div>
              {soldLatest === null || soldLatest === undefined ? (
                ""
              ) : (
                <div className="flex items-center w-full justify-between ">
                  <div className="flex items-center desktop:gap-5 mobile:gap-3">
                    <div className="desktop:w-10 desktop:h-10 mobile:w-[30px] mobile:h-[30px] mobile:min-w-[30px] mobile:min-h-[30px]">
                      <img
                        alt="announcement_item"
                        src={soldLatest?.item_picture["media_url1"]}
                        height={40}
                        width={40}
                        className="w-full h-full object-cover desktop:rounded-full mobile:rounded"
                      />
                    </div>
                    <div className="mobile:text-[10px] mobile:leading-4 desktop:text-base">
                      <span className="font-semibold">
                        {soldLatest?.item_name} {soldLatest?.game_name}{" "}
                      </span>
                      <span className="text-GRAY920">was sold to </span>
                      <span className="font-semibold">
                        {soldLatest?.buyer_name}{" "}
                      </span>
                      <span className="text-GRAY920">for </span>
                      <span className="font-semibold">
                        {Number(soldLatest?.price).toFixed(0)}{" "}
                        {soldLatest?.currency} by {soldLatest?.seller_name}
                      </span>
                    </div>
                  </div>
                  <span className="mobile:hidden mobile:text-[10px] desktop:text-base desktop:block pr-4 whitespace-nowrap pl-2">
                    {soldLatest?.time}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* market mobile part */}
      <div className="w-full mobile:px-4 mobile:block desktop:hidden">
        <section className="relative desktop:hidden">
          <Swiper
            pagination={true}
            modules={[Navigation, Pagination]}
            className="mySwiper w-full"
          >
            <SwiperSlide className="">
              <div>
                <div className="w-full ">
                  <img src={marketImage} alt="" className="w-full " />
                </div>
                <div className="absolute top-[5vw] left-[6vw] sm:top-[9vw] flex flex-col gap-2">
                  <div className="flex flex-col gap-1">
                    <p className="font-bold text-sm text-white">
                      There are over
                    </p>
                    <p className="font-bold text-xl text-white ">
                      {homeInfo?.data?.totalItem
                        ? formatCompactNumber(homeInfo?.data?.totalItem)
                        : 0}{" "}
                      Items
                    </p>
                    <p className="font-normal text-xs text-white pt-1">
                      from{" "}
                      {homeInfo?.data?.totalGame
                        ? formatCompactNumber(homeInfo?.data?.totalGame)
                        : 0}{" "}
                      different games
                    </p>
                  </div>

                  <div className="flex flex-row gap-4">
                    <div className="w-[3.125rem] flex flex-col gap-2">
                      <div className="w-[3.125rem] h-[3.125rem] relative border-2 border-RED001 rounded-full bg-black">
                        <p className="text-white text-xs font-bold absolute top-[1rem] left-0 w-full text-center">
                          {homeInfo?.data?.totalGameItem
                            ? formatCompactNumber(homeInfo?.data?.totalGameItem)
                            : 0}
                        </p>
                      </div>
                      <p className="text-white font-bold text-[0.625rem] w-full text-center">
                        Items
                      </p>
                    </div>

                    <div className="w-[3.125rem] flex flex-col gap-2">
                      <div className="w-[3.125rem] h-[3.125rem] relative border-2 border-RED001 rounded-full bg-black">
                        <p className="text-white text-xs font-bold absolute top-[1rem] left-0 w-full text-center">
                          {homeInfo?.data?.totalServiceItem
                            ? formatCompactNumber(
                                homeInfo?.data?.totalServiceItem
                              )
                            : 0}
                        </p>
                      </div>
                      <p className="text-white font-bold text-[0.625rem] w-full text-center">
                        Skins
                      </p>
                    </div>

                    <div className="w-[3.125rem] flex flex-col gap-2">
                      <div className="w-[3.125rem] h-[3.125rem] relative border-2 border-RED001 rounded-full bg-black">
                        <p className="text-white text-xs font-bold absolute top-[1rem] left-0 w-full text-center">
                          {homeInfo?.data?.totalCoinItem
                            ? formatCompactNumber(homeInfo?.data?.totalCoinItem)
                            : 0}
                        </p>
                      </div>
                      <p className="text-white font-bold text-[0.625rem] w-full text-center">
                        Coins
                      </p>
                    </div>

                    <div className="w-[3.125rem] flex flex-col gap-2">
                      <div className="w-[3.125rem] h-[3.125rem] relative border-2 border-RED001 rounded-full bg-black">
                        <p className="text-white text-xs font-bold absolute top-[1rem] left-0 w-full text-center">
                          {homeInfo?.data?.totalAccountItem
                            ? formatCompactNumber(
                                homeInfo?.data?.totalAccountItem
                              )
                            : 0}
                        </p>
                      </div>
                      <p className="text-white font-bold text-[0.625rem] w-full text-center">
                        Accounts
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={bg_explore_topup_mobile} alt="" className="w-full" />
              <div className="absolute top-[20%] left-[5%] translate-y-1">
                <p className="font-bold font-IBMPlex text-[14px] text-white my-2">
                  There are over
                </p>
                <p className="font-bold font-Kanit text-[20px] text-white my-2">
                  N/A Gift Cards
                </p>
                <p className="font-IBMPlex text-[12px] text-white my-2">
                  Discount 10% per units,<br></br>Reality license quality
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </section>
      </div>

      {/* market desktop part */}
      <div className="w-full desktop:px-9 mobile:hidden desktop:block">
        <section className="relative w-full mx-auto max-w-[1597px] pt-10 mobile:hidden desktop:block">
          <Swiper
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            className="mySwiper w-full"
          >
            <SwiperSlide>
              <div className="imageSummary">
                <img src={market_desktop} alt="" className="w-full" />
                <div className="position">
                  <p className="text-white font-bold md:text-[24px] md:text-lg lg:text-2xl xl:text-3xl 2xl:text-4xl [@media(min-width:1919px)]:text-[30px]">
                    There are over
                  </p>
                  <p className="font-bold [@media(min-width:1919px)]:text-3xl lg:text-2xl md:text-xl text-lg text-white my-2">
                    {homeInfo?.data?.totalItem
                      ? formatCompactNumber(homeInfo?.data?.totalItem)
                      : 0}{" "}
                    Items
                  </p>
                  <p className="text-white [@media(min-width:1919px)]:text-xl lg:text-lg md:text-base text-sm ">
                    from{" "}
                    {homeInfo?.data?.totalGame
                      ? formatCompactNumber(homeInfo?.data?.totalGame)
                      : 0}{" "}
                    different games
                  </p>
                  <Link
                    to={"marketplace"}
                    className="bg-RED001 text-white px-4 py-2 text-sm md:px-2 md:py-1 lg:px-8 lg:py-2 [@media(min-width:1919px)]:px-16 [@media(min-width:1919px)]:py-4 mt-4 flex justify-center rounded hover:bg-RED001/[0.8] cursor-pointer"
                  >
                    EXPLORE MARKETPLACE
                  </Link>
                  <div className="flex flex-wrap absolute column-2 w-[250px] h-[150px] lg:w-[264px] lg:h-[200px] [@media(min-width:1919px)]:w-[264px] [@media(min-width:1919px)]:h-[261px] [@media(min-width:1919px)]:gap-8 positionItem">
                    <div className="image">
                      <img
                        src={bg_items}
                        alt=""
                        className="lg:w-[70%] w-[60%] xl:w-[80%] [@media(min-width:1920px)]:w-[100%]"
                      />
                      <span className="font-IBMPlex text-white font-bold text-sm lg:text-sm xl:pl-2 [@media(min-width:1919px)]:text-xl">
                        Account
                      </span>
                      <figcaption className="text-white font-bold text-lg flex flex-col items-center lg:text-lg [@media(min-width:1919px)]:text-2xl">
                        {homeInfo?.data?.totalAccountItem
                          ? formatCompactNumber(
                              homeInfo?.data?.totalAccountItem
                            )
                          : 0}
                      </figcaption>
                    </div>
                    <div className="image">
                      <img
                        src={bg_items}
                        alt=""
                        className="lg:w-[70%] w-[60%] xl:w-[80%] [@media(min-width:1920px)]:w-[100%]"
                      />
                      <span className="font-IBMPlex text-white font-bold text-sm pl-2 xl:pl-4 [@media(min-width:1919px)]:pl-4 lg:text-sm [@media(min-width:1919px)]:text-xl">
                        Gold
                      </span>
                      <figcaption className="text-white font-bold text-lg flex flex-col items-center lg:text-lg [@media(min-width:1919px)]:text-2xl">
                        {homeInfo?.data?.totalCoinItem
                          ? formatCompactNumber(homeInfo?.data?.totalCoinItem)
                          : 0}
                      </figcaption>
                    </div>
                    <div className="image">
                      <img
                        src={bg_items}
                        alt=""
                        className="lg:w-[70%] w-[60%] xl:w-[80%] [@media(min-width:1920px)]:w-[100%]"
                      />
                      <span className="font-IBMPlex text-white font-bold text-sm pl-2.5 xl:pl-4 [@media(min-width:1919px)]:pl-4 lg:text-sm [@media(min-width:1919px)]:text-xl">
                        Item
                      </span>
                      <figcaption className="text-white font-bold text-lg flex flex-col items-center lg:text-lg [@media(min-width:1919px)]:text-2xl">
                        {homeInfo?.data?.totalGameItem
                          ? formatCompactNumber(homeInfo?.data?.totalGameItem)
                          : 0}
                      </figcaption>
                    </div>
                    <div className="image">
                      <img
                        src={bg_items}
                        alt=""
                        className="lg:w-[70%] w-[60%] xl:w-[80%] [@media(min-width:1920px)]:w-[100%]"
                      />
                      <span className="font-IBMPlex text-white font-bold pl-1 xl:pl-2 [@media(min-width:1919px)]:pl-1 text-sm lg:text-sm [@media(min-width:1919px)]:text-xl">
                        Service
                      </span>
                      <figcaption className="text-white font-bold text-lg flex flex-col items-center lg:text-lg [@media(min-width:1919px)]:text-2xl">
                        {homeInfo?.data?.totalServiceItem
                          ? formatCompactNumber(
                              homeInfo?.data?.totalServiceItem
                            )
                          : 0}
                      </figcaption>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img alt="" src={bg_explore_topup} />
              <div className="absolute top-[80px] left-[90px]">
                <p className="font-bold font-IBMPlex text-white my-2">
                  There are over
                </p>
                <p className="font-bold font-Kanit text-[32px] text-white my-2">
                  100,000 Gift Cards
                </p>
                <p className="font-IBMPlex text-white my-2">
                  Discount 10% per units, Reality license quality
                </p>
                <button className="bg-RED001 text-white px-16 py-4 mt-4 flex justify-center rounded hover:bg-RED001/[0.8] cursor-pointer">
                  EXPLORE TOP UP
                </button>
              </div>
            </SwiperSlide>
          </Swiper>
        </section>
      </div>

      {/* trending part */}
      <section className="relative mt-[20px] desktop:mx-[10%] [@media(min-width:1919px)]:mt-[40px] [@media(min-width:1919px)]:w-[1597px]">
        <div className="flex mx-2 mb-6">
          <div className="text-lg font-bold text-center flex">
            <ul className="flex flex-wrap -mb-px">
              <li className="mr-2">
                <button
                  className={`inline-block p-4 text-lg [@media(min-width:1919px)]:text-[32px] font-IBMPlex ${
                    activeMenu === "0"
                      ? "border-b-4 border-RED001 text-white"
                      : "text-gray-400"
                  }`}
                  onClick={() => setActiveMenu("0")}
                >
                  Trending
                </button>
              </li>
              <li className="mr-2">
                <button
                  className={`inline-block p-4 text-lg [@media(min-width:1919px)]:text-[32px] font-IBMPlex ${
                    activeMenu === "2"
                      ? "border-b-4 border-RED001 text-white"
                      : "text-gray-400"
                  }`}
                  // onClick={() => setActiveMenu('2')}
                >
                  Premium
                </button>
              </li>
            </ul>

            <Link
              to={"marketplace"}
              className="text-[#939393] mobile:text-[10px] desktop:text-[24px] absolute desktop:right-[10px] mobile:right-4 mobile:mt-4 desktop:mt-6"
            >
              SEE ALL
            </Link>
          </div>
        </div>

        <button
          onClick={() => {
            if (isLessThanLoopedSlidesForTrendingItems) {
              swipeForTrendingItems?.slidePrev();
            }
          }}
          className="absolute -left-[calc(4vw+30px)] top-[50%] mobile:hidden desktop:block"
        >
          <img alt="" src={prev_home} />
        </button>
        <button
          onClick={() => swipeForTrendingItems?.slideNext()}
          className="absolute  -right-[calc(4vw+30px)] top-[50%] mobile:hidden desktop:block"
        >
          <img alt="" src={next_home} />
        </button>

        {windowSize[0] > 768 ? (
          <Swiper
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              1130: {
                slidesPerView: 3,
              },
              1520: {
                slidesPerView: 4,
              },
              1905: {
                slidesPerView: 5,
              },
            }}
            direction="horizontal"
            loop={true}
            centeredSlides={false}
            onBeforeInit={(swiper) => setSwipeForTrendingItems(swiper)}
            modules={[Navigation, Grid]}
            className="mySwiper"
            spaceBetween={"20"}
          >
            {trendingItems?.map((item) => (
              <SwiperSlide key={item?.item_id}>
                <div className="mx-auto desktop:w-[18.5rem]">
                  <ItemCard
                    item={item}
                    textSize={"16px"}
                    mobileText={"10px"}
                    viewSize={"12px"}
                    priceSize={"20px"}
                    heightCard={"399px"}
                    mobileHeight={"269px"}
                    mobileWeight={"200px"}
                  />
                </div>
              </SwiperSlide>
            ))}

            {isLessThanLoopedSlidesForTrendingItems &&
              trendingItems?.map((item) => (
                <SwiperSlide key={item?.item_id}>
                  <ItemCard
                    item={item}
                    textSize={"16px"}
                    mobileText={"10px"}
                    viewSize={"12px"}
                    priceSize={"20px"}
                    heightCard={"399px"}
                    mobileHeight={"269px"}
                    mobileWeight={"200px"}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        ) : (
          <div className="flex flex-wrap justify-center gap-4 sm:container sm:mx-auto md:container md:mx-auto md:w-[540px] [@media(max-width:304px)]:grid [@media(max-width:304px)]:grid-cols-2">
            {trendingItems?.slice(0, next)?.map((item, index) => (
              <div key={`trending-${index}`} className="">
                <ItemCard
                  item={item}
                  textSize={"16px"}
                  mobileText={"10px"}
                  viewSize={"12px"}
                  priceSize={"20px"}
                  heightCard={"399px"}
                  mobileHeight={"269px"}
                  mobileWeight={"200px"}
                  setItems={setTrendingItems}
                />
              </div>
            ))}
          </div>
        )}
      </section>

      {/* coming soon part */}
      <section className="relative mt-[40px] desktop:mx-[10%] [@media(min-width:1919px)]:w-[1597px] mobile:px-4 desktop:px-0">
        <div className=" flex justify-between items-center mx-4 my-[20px]">
          <p className="text-[32px] font-IBMPlex text-white font-bold">
            Coming Soon
          </p>
        </div>

        <button
          onClick={() => {
            if (isLessThanLoopedSlidesForItemsTopUp) {
              swipeForItemsTopUp?.slidePrev();
            }
          }}
          className="absolute -left-[calc(4vw+30px)] top-[55%] mobile:hidden desktop:block z-10"
        >
          <img alt="" src={prev_home} />
        </button>
        <button
          onClick={() => swipeForItemsTopUp?.slideNext()}
          className="absolute  -right-[calc(4vw+30px)] top-[55%] mobile:hidden desktop:block z-10"
        >
          <img alt="" src={next_home} />
        </button>

        <Swiper
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            950: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
            1280: {
              slidesPerView: 4,
            },
            1536: {
              slidesPerView: 5,
            },
          }}
          loop={true}
          spaceBetween={10}
          onBeforeInit={(swiper) => setSwipeForItemsTopUp(swiper)}
          modules={[Navigation]}
          className="mySwiper "
        >
          {itemsTopUp?.item_topup?.map((topup) => (
            <SwiperSlide
              key={topup?.item_id}
              className="mobile:mx-auto xl:mx-auto"
            >
              <img src={topup?.item_picture} alt="" />
              <p className="text-white font-bold font-IBMPlex mt-4 ">
                {topup?.item_name}
              </p>
            </SwiperSlide>
          ))}

          {isLessThanLoopedSlidesForItemsTopUp &&
            itemsTopUp?.item_topup?.map((topup) => (
              <SwiperSlide
                key={topup?.item_id}
                className="mobile:mx-auto xl:mx-auto"
              >
                <img src={topup?.item_picture} alt="" />
                <p className="text-white font-bold font-IBMPlex mt-4 ">
                  {topup?.item_name}
                </p>
              </SwiperSlide>
            ))}
        </Swiper>
      </section>

      {/* about part */}
      <section
        className={`desktop:w-full about_bg mt-4 desktop:flex desktop:flex-col [@media(min-width:1919px)]:items-center`}
      >
        <div className="desktop:mx-[10%] [@media(min-width:1919px)]:w-[1597px] desktop:h-[400px] mobile:h-[230px] text-white desktop:grid desktop:grid-cols-6 mobile:mt-4">
          <div className="desktop:pt-[88px] my-4 desktop:col-span-3 mobile:pt-4 mobile:mx-2">
            <p className="font-bold desktop:text-[32px] desktop:mb-[24px] mobile:text-lg">
              About RetoHub
            </p>
            <p className="desktop:text-[16px] mt-2 desktop:mb-[40px] mobile:text-[10px]">
              We provide digital marketplace platform for gamers trading with a
              wide range of goods: in game currency, items/skins, accounts, gift
              cards and top-up.
            </p>
            <div className="mobile:flex mobile:justify-center mobile:my-4">
              <button className="bg-RED001 px-4 py-2 my-2 mobile:w-[200px] desktop:w-[300px] desktop:h-[56px] font-bold mobile:text-[10px] desktop:text-[16px] flex justify-center items-center rounded-md hover:bg-RED001/[0.8] cursor-pointer">
                GET TO KNOW US
              </button>
            </div>
          </div>
          <div className="mobile:grid mobile:grid-cols-3 mobile:justify-items-center desktop:col-span-3 desktop:flex desktop:justify-evenly">
            <div className="desktop:grid desktop:justify-items-center desktop:content-center">
              <p className="font-bold desktop:text-[32px] desktop:py-2 mobile:text-[16px] mobile:pl-4">
                {formatCompactNumber(homeInfo?.data?.totalItem)}
              </p>
              <p className="desktop:text-[22px] mobile:text-[12px]">
                Product Orders
              </p>
            </div>
            <div className="desktop:grid desktop:justify-items-center desktop:content-center">
              <p className="font-bold desktop:text-[32px] desktop:py-2 mobile:text-[16px] mobile:pl-4">
                {formatCompactNumber(homeInfo?.data?.monthlyVolume)}
              </p>
              <p className="desktop:text-[22px] mobile:text-[12px]">
                Monthly Volume
              </p>
            </div>
            <div className="desktop:grid desktop:justify-items-center desktop:content-center">
              <p className="font-bold desktop:text-[32px] desktop:py-2 mobile:text-[16px] mobile:pl-2">
                {formatCompactNumber(homeInfo?.data?.totalUser)}
              </p>
              <p className="desktop:text-[22px] mobile:text-[12px]">ID Login</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
