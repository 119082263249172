import moment from 'moment'
export default function expFormat(exp) {
    const now = moment()
    const expiration = moment(exp)
    const duration = moment.duration(expiration.diff(now))

    const inDay = Math.floor(duration.asDays())
    const inHour = Math.floor(duration.asHours())
    const inMinute = Math.floor(duration.asMinutes())

    if (exp == null){ return ' ...' }
    
    if(inDay > 0) {
        return inDay + ` day${inDay > 1 ? 's' : ''}`
    } else if (inHour > 0) {
        return inHour + ` hour${inHour > 1 ? 's' : ''}`
    } else {
        return inMinute + 'minutes'
    }

}