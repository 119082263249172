import { useForm } from "react-hook-form"
import { useState, useEffect, useRef } from "react"
import ImageUploading from "react-images-uploading";
import { getGamelist } from "../../api/get/createOrder";
import { icon_dropdown_createorder, icon_final_total, icon_info, icon_store_price } from "../../assets/createOrder";
import GameName from "./GameName";
import ServerName from "./ServerName";
import Category from "./Category";
import UploadItempicture from "./UploadItempicture";
import Promocode from "./Promocode";
import { UploadMultiple, createOderAccount, createOderGold, createOderItem } from "../../api/post/createOrder";
import { useSelector } from "react-redux";
import { Navigate, useSearchParams, Link } from "react-router-dom";
import NoticeKYCstatus from "../../component/NoticeKYCstatus";

const ACTIVE_TIME = [
    { label: "30 mins", val: "30 mins" },
    { label: "60 mins", val: "60 mins" },
    { label: "90 mins", val: "90 mins" },
    { label: "120 mins", val: "120 mins" },
];


const OFFER_TIME = [
    { label: "1 days", val: 1 },
    { label: "3 days", val: 3 },
    { label: "5 days", val: 5 },
    { label: "7 days", val: 7 },
    { label: "14 days", val: 14 },
];

const CreateOrder = ({ setCreateStatus, pictureUpload, setPictureUpload }) => {
    const [searchParams] = useSearchParams()
    const [gameIndex, setGameIndex] = useState()
    const [cateSelect, setCateSelect] = useState(1)
    const [pictureArray, setPictureArray] = useState([])
    const [item_server, setItem_server] = useState()
    const [serverSelect, setServerSelect] = useState(-1)
    const [showList, setShowList] = useState('')
    const [gameList, setGameList] = useState()
    const [gameSelect, setGameSelect] = useState(-1)
    const [activeSelect, setActiveSelect] = useState(0)
    const [offerSelect, setOfferSelect] = useState(0)
    const [showActive, setShowActive] = useState(false)
    const [showOffer, setShowOffer] = useState(false)
    const [isError, setIsError] = useState([])
    const [formValue, setFormValue] = useState()
    const [showError, setShowError] = useState(null)
    const [price, setPrice] = useState({
        store_price: null,
        final_total: null
    })
    const [showPrice, setShowPrice] = useState({
        store_price: null,
        final_total: null
    })
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])
    const [open, setOpen] = useState(false)
    const [noticeOpen, setNoticeOpen] = useState(false)
    const user = useSelector((state) => state.user)
    useEffect(() => {
        let id = searchParams.get("gameId")
        getGamelist(setGameList, setGameIndex, id)
        if (!user?.kyc_step_seller) {
            setNoticeOpen(true)
        } else setNoticeOpen(false)
    }, [])


    const { register, handleSubmit, setError, formState: { errors }, getValues } = useForm({});

    const numberWithCommas = (x) => {
        return x?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    }


    const deleteCommas = (e) => {
        let newStr = ''
        for (let i = 0; i < e?.length; i++) {
            if (e[i] !== ',' && Number(e[i]) >= 0) {
                newStr += e[i]
            }
        }
        return newStr
    }

    const setMinFee = (numStr) => {
        const num = Number(numStr)
        if (num < 100) {
            return (num + 10).toFixed(2)
        } else if (num < 1000) {
            return (num * 1.1).toFixed(2)
        } else return (num + 100).toFixed(2)
    }

    const fineStorePrice = (numStr) => {
        const num = Number(numStr)
        if (num >= 1100) {
            return (num - 100).toFixed(2)
        } else if (num >= 110) {
            return (num / 1.1).toFixed(2)
        } else if (num < 110 && num > 10) {
            return (num - 10).toFixed(2)
        } else return 0
    }

    const getPriceItem = (e) => {
        let strNumer = deleteCommas(e.target.value)
        setPrice({
            store_price: Number(strNumer),
            final_total: Math.ceil(setMinFee(strNumer))
        })
        setShowPrice({
            store_price: numberWithCommas(Number(strNumer)),
            final_total: numberWithCommas(Math.ceil(setMinFee(strNumer))),
        })
    }

    const getFilnalPrice = (e) => {
        let strNumer = deleteCommas(e.target.value)
        setPrice({
            store_price: Math.floor(fineStorePrice(strNumer)),
            final_total: Math.floor(Number(strNumer))
        })
        setShowPrice({
            store_price: numberWithCommas(Math.floor(fineStorePrice(strNumer))),
            final_total: numberWithCommas(Math.floor(Number(strNumer)))
        })
    }

    const checkPayload = (formValues) => {
        let newError = []
        let newError2 = []
        const payload = {
            "game_id": gameSelect >= 0 ? Number(gameSelect) : newError.push("game_id"),
            "item_server": item_server ? item_server : newError.push("item_server"),
            "category_id": cateSelect,
            "item_name": !!formValues?.product_name ? formValues?.product_name : newError.push("product_name"),
            "item_description": !!formValues?.product_description ? formValues?.product_description : newError.push("product_description"),
            "currency": 1,
            // "currency": !!formValues?.currency ? Number(formValues?.currency) : newError.push("currency"),
            "item_per_unit": formValues?.item_per_unit > 0 ? Number(formValues?.item_per_unit) : newError.push("item_per_unit"),
            "item_min_unit": formValues?.min_units_perorder > 0 && Number(formValues?.stock_total_unit) >= Number(formValues?.min_units_perorder) ? Number(formValues?.min_units_perorder) : newError.push("min_units_perorder"),
            "item_quantity": formValues?.stock_total_unit > 0 && Number(formValues?.stock_total_unit) >= Number(formValues?.min_units_perorder) ? Number(formValues?.stock_total_unit) : newError.push("stock_total_unit"),
            "item_offer_duration": OFFER_TIME[offerSelect]?.val,
            "item_active_deliver": ACTIVE_TIME[activeSelect]?.val,
            "item_media": {
                "media_url1": pictureUpload?.img1 ? pictureUpload?.img1 : newError.push("upload_Image"),
                "media_url2": pictureUpload?.img2 ? pictureUpload?.img2 : "",
                "media_url3": pictureUpload?.img3 ? pictureUpload?.img3 : "",
                "media_url4": pictureUpload?.img4 ? pictureUpload?.img4 : ""
            },
            "item_price": price?.store_price > 1 ? Number(price?.final_total) : newError.push("final_total"),
            "discount": false
        }

        const accountPayload = {
            "game_id": gameSelect >= 0 ? Number(gameSelect) : newError2.push("game_id"),
            "item_server": item_server ? item_server : newError2.push("item_server"),
            "category_id": cateSelect,
            "item_name": !!formValues?.product_name ? formValues?.product_name : newError2.push("product_name"),

            "currency": 1,
            // "currency": !!formValues?.currency ? Number(formValues?.currency) : newError2.push("currency"),
            "character_name": !!formValues?.charactor_name ? formValues?.charactor_name : newError2.push("charactor_name"),
            "game_level": !!formValues?.game_level ? formValues?.game_level : newError2.push("game_level"),
            "item_description": !!formValues?.product_description ? formValues?.product_description : newError2.push("product_description"),
            "item_per_unit": !!formValues?.item_per_unit > 0 ? Number(formValues?.item_per_unit) : newError2.push("item_per_unit"),
            "item_offer_duration": OFFER_TIME[offerSelect]?.val,
            "item_active_deliver": ACTIVE_TIME[activeSelect]?.val,
            "item_media": {
                "media_url1": pictureUpload?.img1 ? pictureUpload?.img1 : newError2.push("upload_Image"),
                "media_url2": pictureUpload?.img2 ? pictureUpload?.img2 : "",
                "media_url3": pictureUpload?.img3 ? pictureUpload?.img3 : "",
                "media_url4": pictureUpload?.img4 ? pictureUpload?.img4 : ""
            },
            "item_price": price?.store_price > 1 ? Number(price?.final_total) : newError2.push("final_total"),
            "discount": false
        }




        if (formValues?.checkbox === true) {
            if (cateSelect === 2) {
                if (newError2?.length === 0) {
                    switch (cateSelect) {
                        case 2: {
                            createOderAccount(accountPayload, setCreateStatus)
                            break
                        }
                    }
                } else {
                    setIsError([...newError2])
                }
            } else {
                if (newError?.length === 0) {
                    switch (cateSelect) {
                        case 1: {
                            createOderItem(payload, setCreateStatus)
                            break
                        }
                        case 3: {
                            createOderGold(payload, setCreateStatus)
                            break
                        }
                    }
                } else {
                    setIsError([...newError])
                }
            }


        } else {
            if (cateSelect === 2) {
                setIsError([...newError2, "checkbox"])
                setShowError(newError2[0])
            } else {
                setIsError([...newError, "checkbox"])
                setShowError(newError[0])
            }
        }

    }

    useEffect(() => {
        if (isError?.length > 0) {
            document.getElementById(`${isError[0]}`)?.scrollIntoView({ behavior: "smooth", block: "center" })
        }
    }, [isError])

    useEffect(() => {
        if (!!pictureUpload) {
            checkPayload(formValue)
        }
    }, [pictureUpload])

    return (
        <main className="desktop:w-[99.8125rem] desktop:max-w-[99.8125rem] desktop:min-w-0 mobile:w-full flex flex-col items-center py-[3.75rem] gap-5 font-IBMPlex mobile:mx-4">
            <div className="flex justify-start w-full">
                <p className="text-white font-bold desktop:text-[32px] mobile:text-[24px]">CREATE ORDER</p>
            </div>
            <div className="flex max-w-full min-w-0 w-full  desktop:flex-row mobile:flex-col">
                {/* left */}
                <div className="flex flex-col desktop:gap-5 mobile:gap-3 desktop:max-w-[49rem] desktop:min-w-0 desktop:w-[49rem] mobile:w-full">
                    <div className="flex w-full desktop:h-[2.8125rem] mobile:h-[2.1875rem] border-b-[1px] border-[#636363]">
                        <p className="text-white font-bold desktop:text-[20px] mobile:text-base">ORDER DETAIL</p>
                    </div>
                    <GameName
                        gameList={gameList}
                        gameSelect={gameSelect}
                        setGameSelect={setGameSelect}
                        showList={showList}
                        setShowList={setShowList}
                        isError={isError}
                        showError={showError}
                        setShowError={setShowError}
                        gameIndex={gameIndex}

                    />
                    <ServerName
                        serverSelect={serverSelect}
                        setServerSelect={setServerSelect}
                        showList={showList}
                        setShowList={setShowList}
                        item_server={item_server}
                        setItem_server={setItem_server}
                        isError={isError}
                        showError={showError}
                        setShowError={setShowError}
                    />
                    <Category
                        cateSelect={cateSelect}
                        setCateSelect={setCateSelect}
                    />
                    <div className="flex desktop:max-w-full desktop:min-w-0 w-full desktop:h-[2.8125rem] mobile:h-[2.1875rem] border-b-[1px] border-[#636363]">
                        <p className="text-white font-bold desktop:text-[20px] mobile:text-base">PRODUCT TITLE</p>
                    </div>
                    <div className="desktop:max-w-full desktop:min-w-0  w-full flex flex-col gap-5">
                        <div className="w-full desktop:h-[4.875rem] mobile:h-[4.6875rem] flex flex-col justify-between" id="product_name">
                            <div className="w-full flex justify-between">
                                <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Product Name</p>
                                {isError.includes("product_name") && showError === "product_name" && (
                                    <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill product name</p>
                                )}
                            </div>
                            <input
                                {...register("product_name", {
                                    maxLength: 255
                                })}
                                type="text"
                                className={`w-full h-12 rounded text-white relative bg-[#282828] focus:outline-none p-3 placeholder:text-[#939393] ${isError.includes("product_name") ? "border-[#bb213d] border" : ''}`}
                                placeholder="Write product name"
                                onClick={() => setShowError("product_name")}
                                maxLength='100'
                            />
                        </div>
                        {/* Account */}
                        {cateSelect === 2 && (
                            <div className="flex desktop:flex-row mobile:flex-col gap-5">
                                <div className="desktop:max-w-[23.875rem] desktop:min-w-0 desktop:w-[23.875rem] mobile:w-full desktop:h-[4.875rem] mobile:h-[4.6875rem] flex flex-col justify-between" id="charactor_name">
                                    <div className="w-full flex justify-between">
                                        <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Character Name</p>
                                        {isError.includes("charactor_name") && showError === "charactor_name" && (
                                            <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill character name</p>
                                        )}
                                    </div>
                                    <input
                                        {...register("charactor_name", {
                                            maxLength: 255
                                        })}
                                        type="text"
                                        className={`w-full h-12 rounded text-white relative bg-[#282828] focus:outline-none p-3 placeholder:text-[#939393] ${isError.includes("charactor_name") ? "border border-[#bb213d]" : ""}`}
                                        placeholder="Write character name"
                                        onClick={() => setShowError("charactor_name")}
                                        maxLength='100'
                                    />
                                </div>
                                <div className="desktop:max-w-[23.875rem] desktop:min-w-0 desktop:w-[23.875rem] mobile:w-full desktop:h-[4.875rem] mobile:h-[4.6875rem] flex flex-col justify-between" id="game_level">
                                    <div className="w-full flex justify-between">
                                        <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Game Level</p>

                                        {isError.includes("game_level") && showError === "game_level" && (
                                            <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill game level</p>
                                        )}
                                    </div>
                                    <input
                                        {...register("game_level")}
                                        type="text"
                                        className={`w-full h-12 rounded text-white relative bg-[#282828] focus:outline-none p-3 placeholder:text-[#939393] ${isError.includes("game_level") ? "border border-[#bb213d]" : ""}`}
                                        placeholder="Write game level"
                                        onClick={() => setShowError("game_level")}
                                        maxLength='100'
                                    />
                                </div>
                            </div>
                        )}
                        <div className="desktop:max-w-full desktop:min-w-0  w-full desktop:h-[10.75rem] mobile:h-[4.6875rem] flex flex-col justify-between" id="product_description">
                            <div className="w-full flex justify-between">
                                <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Product Description</p>
                                {/* <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill product description</p> */}
                                {isError.includes("product_description") && showError === "product_description" && (
                                    <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill product description</p>
                                )}
                            </div>
                            <textarea
                                {...register("product_description")}
                                type="text"
                                rows={5}
                                className={`w-full h-[8.875rem] rounded text-white relative bg-[#282828] focus:outline-none p-3 placeholder:text-[#939393] resize-none ${isError.includes("product_description") ? "border border-[#bb213d]" : ""}`}
                                placeholder="Write product description"
                                onClick={() => setShowError("product_description")}
                                maxLength='500'
                            />
                        </div>
                        <div className="cursor-pointer h-6">
                            {/* <p className="desktop:text-[#BB213D] mobile:text-[#FF002E] text-base font-normal">+ add keyword</p> */}
                        </div>
                        <div className="flex desktop:max-w-full desktop:min-w-0  w-full desktop:h-[2.8125rem] mobile:h-[2.1875rem] border-b-[1px] border-[#636363]">
                            <p className="text-white font-bold desktop:text-[20px] mobile:text-base">CUSTOM UNIT</p>
                        </div>
                        <div className="desktop:max-w-full desktop:min-w-0  flex desktop:flex-row mobile:flex-col gap-5">
                            <div className="desktop:max-w-[23.875rem] desktop:min-w-0 desktop:w-[23.875rem] mobile:w-full desktop:h-[4.875rem] mobile:h-[4.6875rem] flex flex-col justify-between">
                                <div className="w-full flex justify-between">
                                    <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Item Per Unit</p>
                                    {/* <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill item per unit</p> */}
                                    {isError?.includes("item_per_unit") && showError === "item_per_unit" && (
                                        <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill item per unit</p>
                                    )}
                                </div>
                                <input
                                    {...register("item_per_unit")}
                                    type="text"
                                    className={`w-full h-12 rounded text-white relative bg-[#282828] focus:outline-none p-3 placeholder:text-[#939393] ${isError?.includes("item_per_unit") ? "border border-[#bb213d]" : ""}`}
                                    placeholder="0"
                                    onClick={() => setShowError("item_per_unit")}
                                    onKeyDown={(e) =>
                                        (e?.key?.length === 1 && e.key.match(/[^0-9]/g)) && e.preventDefault()
                                    }
                                    maxLength='16'
                                    min="0"
                                    id="item_per_unit"
                                />
                            </div>
                            <div className="desktop:max-w-[23.875rem] desktop:min-w-0 desktop:w-[23.875rem] mobile:w-full desktop:h-[4.875rem] mobile:h-[4.6875rem] flex flex-col justify-between">
                                <div className="w-full flex justify-between">
                                    <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Currency</p>
                                    {/* <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill currency</p> */}
                                    {isError.includes("currency") && showError === "currency" && (
                                        <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill currency</p>
                                    )}
                                </div>
                                <input
                                    {...register("currency")}
                                    type="text"
                                    disabled
                                    className={`w-full h-12 rounded text-white relative bg-[#282828] focus:outline-none p-3 placeholder:text-[#939393] ${isError.includes("currency") ? "border border-[#bb213d]" : ""}`}
                                    placeholder="THB    "
                                    onClick={() => setShowError("currency")}
                                    id="currency"
                                />
                            </div>
                        </div>
                        {cateSelect !== 2 && (
                            <div className="flex desktop:flex-row mobile:flex-col gap-5">
                                <div className="desktop:max-w-[23.875rem] desktop:min-w-0 desktop:w-[23.875rem] mobile:w-full desktop:h-[4.875rem] mobile:h-[4.6875rem] flex flex-col justify-between">
                                    <div className="w-full flex justify-between">
                                        <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Stock (Total Units)</p>
                                        {/* <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill stock total uinit</p> */}
                                        {isError.includes("stock_total_unit") && showError === "stock_total_unit" && (
                                            <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill stock total unit</p>
                                        )}
                                    </div>
                                    <input
                                        {...register("stock_total_unit")}
                                        type="text"
                                        className={`w-full h-12 rounded text-white relative bg-[#282828] focus:outline-none p-3 placeholder:text-[#939393] ${isError.includes("stock_total_unit") ? "border border-[#bb213d]" : ""}`}
                                        placeholder="0"
                                        onClick={() => setShowError("stock_total_unit")}
                                        onKeyDown={(e) =>
                                            (e?.key?.length === 1 && e.key.match(/[^0-9]/g)) && e.preventDefault()
                                        }
                                        maxLength='16'
                                        min="0"
                                        id="stock_total_unit"
                                    />
                                </div>
                                <div className="desktop:max-w-[23.875rem] desktop:min-w-0 desktop:w-[23.875rem] mobile:w-full desktop:h-[4.875rem] mobile:h-[4.6875rem] flex flex-col justify-between">
                                    <div className="w-full flex justify-between">
                                        <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Min Units Per Order</p>

                                        {isError?.includes("min_units_perorder") && showError === "min_units_perorder" && (
                                            <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">{
                                                Number(getValues('stock_total_unit') < Number(getValues('min_units_perorder'))) ? "*Must not over total stock" : "*Please choose min per unit"
                                            }</p>
                                        )}
                                    </div>
                                    <input
                                        {...register("min_units_perorder")}
                                        type="text"
                                        className={`w-full h-12 rounded text-white relative bg-[#282828] focus:outline-none p-3 placeholder:text-[#939393] ${isError?.includes("min_units_perorder") ? "border border-[#bb213d]" : ""}`}
                                        placeholder="0"
                                        onClick={() => setShowError("min_units_perorder")}
                                        onKeyDown={(e) =>
                                            (e?.key?.length === 1 && e.key.match(/[^0-9]/g)) && e.preventDefault()
                                        }
                                        maxLength='16'
                                        min="0"
                                        id="min_units_perorder"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="desktop:w-[7.0625rem] desktop:max-w-[7.0625rem] desktop:min-w-0 mobile:hidden desktop:flex"></div>
                {/* right */}
                <div className="flex flex-col justify-between desktop:gap-5 mobile:gap-3  desktop:w-[43.75rem] desktop:max-w-[43.75rem] desktop:min-w-0 mobile:w-full">
                    <div id="upload_Image" className="flex w-full justify-between desktop:h-[2.8125rem] mobile:h-[2.1875rem] border-b-[1px] border-[#636363]">
                        <p className="text-white font-bold desktop:text-[20px] mobile:text-base">UPLOAD PHOTO</p>
                        {isError.includes("upload_Image") && (
                            <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please upload photo</p>
                        )}
                    </div>
                    <UploadItempicture
                        pictureArray={pictureArray}
                        setPictureArray={setPictureArray}

                    />
                    <div className="flex max-w-full min-w-0 w-full desktop:h-[2.8125rem] mobile:h-[2.1875rem] border-b-[1px] border-[#636363]">
                        <p className="text-white font-bold desktop:text-[20px] mobile:text-base">PERIOD</p>
                    </div>
                    <div className="flex max-w-full min-w-0 w-full desktop:flex-row mobile:flex-col gap-5">
                        <div className="desktop:max-w-[23.875rem] desktop:min-w-0 desktop:w-[23.875rem] mobile:w-full desktop:h-[4.875rem] mobile:h-[4.6875rem] flex flex-col justify-between">
                            <div className="w-full flex justify-between">
                                <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Active Delivery</p>
                                {/* <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill active delivery</p> */}
                            </div>

                            <div className="w-full h-12 rounded  relative bg-[#282828]   flex  items-center p-3" onClick={() => setShowActive(!showActive)}>
                                <p className="text-white">{ACTIVE_TIME[activeSelect]?.label}</p>
                                <img src={icon_dropdown_createorder} alt="icon_dropdown_createorder" className="absolute right-3 top-3" />
                                {showActive && (
                                    <div className="w-full absolute overflow-auto top-[102%] left-0 bg-[#282828] rounded z-10">
                                        {ACTIVE_TIME?.map(({ label, val }, index) => (
                                            <div
                                                className="flex items-center p-3 cursor-pointer hover:bg-black/40"
                                                key={index}
                                                onClick={() => {
                                                    setActiveSelect(index)
                                                    setShowActive(false)
                                                }}
                                            >
                                                <p className="text-white text-base">{label}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="desktop:max-w-[23.875rem] desktop:min-w-0 desktop:w-[23.875rem] mobile:w-full desktop:h-[4.875rem] mobile:h-[4.6875rem] flex flex-col justify-between">
                            <div className="w-full flex justify-between">
                                <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Offer Duration</p>
                                {/* <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill active delivery</p> */}
                            </div>

                            <div className="w-full h-12 rounded  relative bg-[#282828]   flex  items-center p-3" onClick={() => setShowOffer(!showOffer)}>
                                <p className="text-white">{OFFER_TIME[offerSelect]?.label}</p>
                                <img src={icon_dropdown_createorder} alt="icon_dropdown_createorder" className="absolute right-3 top-3" />
                                {showOffer && (
                                    <div className="w-full absolute overflow-auto top-[102%] left-0 bg-[#282828] rounded z-10">
                                        {OFFER_TIME?.map(({ label, val }, index) => (
                                            <div
                                                className="flex items-center p-3 cursor-pointer hover:bg-black/40"
                                                key={index}
                                                onClick={() => {
                                                    setOfferSelect(index)
                                                    setShowOffer(false)
                                                }}
                                            >
                                                <p className="text-white text-base">{label}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full max-w-full min-w-0  desktop:h-[2.8125rem] mobile:h-[2.1875rem] border-b-[1px] border-[#636363]">
                        <p className="text-white font-bold desktop:text-[20px] mobile:text-base">PRICE</p>
                    </div>
                    <div className="desktop:h-[4.25rem] mobile:h-[4.125rem]  max-w-full min-w-0  w-full rounded bg-[#282828] px-5 flex justify-between items-center">
                        <div className="desktop:h-[3.125rem] desktop:w-full flex justify-center items-center cursor-pointer" onClick={() => setOpen(!open)}>
                            <p className="text-[#FEE362]">Add promo code</p>
                        </div>
                        <div className="w-[1px] h-[3.125rem] bg-[#636363]"></div>
                        <div className="desktop:h-[3.125rem] desktop:w-full flex justify-center items-center">
                            <input
                                type="text"
                                className="focus:outline-none placeholder:text-[#939393] text-white bg-transparent w-[8.0625rem]"
                                placeholder="Enter promo code "
                                maxLength="100"
                            />
                        </div>
                    </div>
                    <div className="flex w-full max-w-full min-w-0  desktop:flex-row mobile:flex-col desktop:justify-between">
                        <div className="desktop:w-[17.5rem] mobile:w-full desktop:h-[4.875rem] mobile:h-[4.6875rem] flex flex-col justify-between" id="final_total">
                            <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Store Price</p>
                            <div className={`flex w-full items-center justify-between h-12 rounded text-white relative bg-[#282828] px-5 ${isError.includes("final_total") ? "border border-[#bb213d]" : ""}`}>
                                <input
                                    {...register("store_price")}
                                    type="text"
                                    className={`w-full h-full bg-transparent rounded text-white relative  focus:outline-none p-3 placeholder:text-[#939393] text-center`}
                                    placeholder="0 THB"
                                    onClick={() => setShowError("final_total")}
                                    value={showPrice?.store_price}
                                    onChange={(e) => getPriceItem(e)}
                                    onKeyDown={(e) =>
                                        (e.key === "e" && e.preventDefault()) ||
                                        (e.key === "-" && e.preventDefault()) ||
                                        (e.key === "+" && e.preventDefault()) ||
                                        (e.key === "." && e.preventDefault())
                                    }
                                    maxLength='16'
                                />
                                <img
                                    src={icon_store_price}
                                    alt="icon_store_price"
                                    className=" object-contain w-6 h-6"
                                />
                            </div>
                        </div>
                        <div className="desktop:w-[6rem] flex justify-center items-end py-3 gap-2">
                            <img
                                src={icon_info}
                                alt="icon_info"
                                className=" object-contain w-6 h-6"
                            />
                            <p className="text-[#00B67A]">Fee 10%</p>
                        </div>
                        <div className="desktop:w-[17.5rem] mobile:w-full desktop:h-[4.875rem] mobile:h-[4.6875rem] flex flex-col justify-between">
                            <div className="w-full flex justify-between">
                                <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Final Total</p>
                                {/* <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill price</p> */}
                                {isError.includes("final_total") && showError === "final_total" && (
                                    <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill price</p>
                                )}
                            </div>
                            <div className={`flex w-full items-center justify-between h-12 rounded text-white relative bg-[#282828] px-5 ${isError.includes("final_total") ? "border border-[#bb213d]" : ""}`}>
                                <input
                                    {...register("final_total")}
                                    type="text"
                                    className={`w-full h-full bg-transparent rounded text-white relative  focus:outline-none p-3 placeholder:text-[#939393] text-center `}
                                    placeholder="0 THB"
                                    onClick={() => setShowError("final_total")}
                                    value={showPrice?.final_total}
                                    onChange={(e) => getFilnalPrice(e)}
                                    onKeyDown={(e) =>
                                        (e.key === "e" && e.preventDefault()) ||
                                        (e.key === "-" && e.preventDefault()) ||
                                        (e.key === "+" && e.preventDefault()) ||
                                        (e.key === "." && e.preventDefault())
                                    }
                                    maxLength='16'
                                />
                                <img
                                    src={icon_final_total}
                                    alt="icon_final_total"
                                    className=" object-contain w-6 h-6"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="flex mt-10 gap-3 mobile:w-full desktop:w-auto cursor-default items-center mobile:justify-start relative">
                <div className=" relative translate-y-1">
                    <input
                        {...register("checkbox")}
                        type="checkbox"
                        className={`appearance-none w-5 h-5 bg-[#282828]  checked:bg-[#BB213D] border-[2px] checked:border-[#BB213D] border-white rounded after:content-[''] after:w-full after:h-full after:bg-center after:absolute after:left-0 after:-top-1 after:bg-no-repeat  after:bg-[length:40px] checked:after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')]`}
                    />
                </div>
                <p className="text-white font-medium desktop:text-[20px] mobile:text-[14px]">I have read and agree the <Link to="/secure_seller_sgreement" className="underline underline-offset-4 cursor-pointer">Secure Seller Agreement</Link></p>
                {isError?.length === 1 && isError.includes("checkbox") && (
                    <div className="mobile:hidden desktop:flex absolute p-2 -top-[4px] right-[calc(100%+16px)] bg-[#AE3C33] w-[468px]  rounded-[4px]">
                        <div className="flex justify-center items-center relative w-full h-full ">
                            <p className=" text-white font-IBMPlex text-[14px]">This will be displayed on your profile and can be seen by other users.</p>
                            <div
                                className="w-0 h-0 absolute left-[calc(100%+2px)] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"
                            ></div>
                        </div>
                    </div>
                )}
            </div>
            <button
                className="desktop:w-[25rem] mobile:w-full h-[3.5rem] rounded bg-[#BB213D] hover:bg-RED001/[0.8] uppercase text-white text-base font-bold "
                onClick={handleSubmit((formValues) => {
                    if (pictureArray?.length > 0) {
                        setFormValue(formValues)
                        UploadMultiple(pictureArray, setPictureUpload)
                    } else {
                        checkPayload(formValues)
                    }
                })}
            >
                CREATE NEW OFFER
            </button>
            {isError?.length === 1 && isError.includes("checkbox") && (
                <p className="  desktop:hidden mobile:flex text-xs italic text-red-500">This will be displayed on your profile and can be seen by other users.</p>
            )}

            <Promocode open={open} setOpen={setOpen} />
            <NoticeKYCstatus open={noticeOpen} setOpen={setNoticeOpen} />
        </main>
    )
}

export default CreateOrder