import { useState, useEffect } from "react"
import { icon_dropdown_createorder } from "../../assets/createOrder";
import { getGamelist } from "../../api/get/createOrder";

const GameName = ({ gameList, gameSelect, setGameSelect, showList, setShowList, isError, showError, setShowError ,gameIndex}) => {
    const [selectGamename, setSelectGamename] = useState()
    
    useEffect(()=>{
        if(gameIndex){
            setSelectGamename(gameIndex?.game_name)
            setGameSelect(gameIndex?.id)
        }else setSelectGamename("")
    },[gameIndex])
    return (
        <div 
        className="max-w-full min-w-0 w-full desktop:h-[4.875rem] mobile:h-[4.6875rem] flex flex-col justify-between cursor-default" 
        onClick={() => setShowError("game_id")}
        id="game_id"
        >
            <div className="w-full flex justify-between">
                <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Game Name</p>
                {isError?.includes("game_id") && showError === "game_id" && (
                    <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please choose game name</p>
                )}
            </div>
            <div className={`w-full h-12 rounded text-[#939393] relative bg-[#282828] `}>
                <input
                    type="text"
                    className={`w-full h-full flex items-center p-3 text-white text-base placeholder:text-[#939393] bg-[#282828] rounded focus:outline-none ${isError.includes("game_id") ? "border border-[#bb213d]" : ""}`}
                    placeholder="Enter or choose your game"
                    value={selectGamename}
                    onChange={(e) => setSelectGamename(e.target.value.slice(0, 100))}
                    onClick={() => {
                        setShowError('game_id')
                        setShowList('game_name')
                    }}
                    onKeyDown={(e)=>{
                        if(e.key === "Enter"){
                            setGameSelect(gameList?.filter((x) => x.game_name.toLowerCase().includes(selectGamename.toLocaleLowerCase()))[0].id)
                            setSelectGamename(gameList?.filter((x) => x.game_name.toLowerCase().includes(selectGamename.toLocaleLowerCase()))[0].game_name)
                            setShowList('')
                        }
                    }}
                    maxLength="100"
                />
                <img src={icon_dropdown_createorder} alt="icon_dropdown_createorder" className="absolute right-3 top-3" onClick={() => showList === 'game_name' ? setShowList('') : setShowList('game_name')} />
                {(showList === "game_name" ) && (
                    <div className="w-full desktop:min-h-fit desktop:h-fit desktop:max-h-[22.5rem] mobile:h-[6.25rem] absolute top-[102%] left-0 bg-[#282828] rounded overflow-auto z-10">
                        {gameList?.filter((x) => x.game_name.toLowerCase().includes(selectGamename.toLocaleLowerCase())).map(({ id, game_name }, index) => {
                            return (
                                <div
                                    className=" flex items-center p-3 cursor-pointer hover:bg-black/40"
                                    key={index}
                                    onClick={() => {
                                        setGameSelect(id)
                                        setShowList('')
                                        setSelectGamename(game_name)
                                    }}
                                >
                                    <p className="text-white text-base ">
                                        {game_name}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default GameName