import React from "react";
import { changePxToRem } from "../../../../utils/changePixeltoRem";
import IconWebSite from "../../../../assets/images/Icon/IconWebSite.svg";
import { BorderButton } from "../../../Button";
import CountDownQR from "./CountDownQR";
import { Link } from "react-router-dom";

const DetailItemOrderPayment = ({
  data,
  handleOpenDialogCancelOrder,
  transactionId,
  transactionRef,
  timer,
  endTime,
  onClickRecode,
  QRCode,
  paymentSuccess,
}) => {
  const totalPrice = data?.subtotalAmount;
  const minutes = timer.getTimeValues().minutes;
  const recode = minutes < 5;

  return (
    <div>
      <div className="py-18 px-5 flex flex-col gap-2 items-center border-b-[1px] border-GRAY750">
        <p className="text-xl font-[700] text-white">
          Order No. PD{transactionId ? transactionId : ""}
        </p>
        <p className="text-xs font-[500] text-GRAY920">
          Purchasing with QR Code, Please check the details carefully.
        </p>
      </div>

      <div className="py-5 px-9 flex flex-wrap justify-between items-center  border-b-[1px] border-GRAY750 gap-2">
        <div className="flex flex-col gap-2 grow w-[50%]">
          <p className="text-xl font-[700] text-white">
            G Million Company (Thailand)
          </p>
          <p className="text-base font-[500] text-white">
            AIA Capital Center building located in <br />
            89 Ratchadapisek Road, Dindaeng <br /> Bangkok 10400, Thailand
          </p>
        </div>
        <div className="flex justify-end grow">
          <Link
            className="flex gap-[0.625rem] items-center py-3 px-6 bg-GRAY910 hover:bg-GRAY900/[0.5] rounded cursor-pointer w-[9.375rem]"
            to={"https://www.gmillion.io"}
            target="_blank"
          >
            <img
              src={IconWebSite}
              alt="WebSite"
              style={{
                width: changePxToRem(20),
                height: changePxToRem(20),
              }}
            />
            <p className="text-base font-[700] text-white uppercase">Website</p>
          </Link>
        </div>
      </div>

      <div className=" py-5 px-9 flex flex-col gap-5 ">
        <div className="flex justify-between">
          <p className="text-base font-[500] text-GRAY920">Account Bank</p>
          <p className="text-base font-[400] text-GRAY920 text-end">
            Retohub Banking
          </p>
        </div>

        <div className="flex flex-wrap justify-between ">
          <p className="text-base font-[500] text-GRAY920">Confirmation code</p>
          <p className="text-base font-[400] text-GRAY920 grow text-end">
            {transactionRef ? transactionRef : ""}
          </p>
        </div>

        <div className="flex justify-between">
          <p className="text-base font-[500] text-GRAY920">
            Payment gateway Fee
          </p>
          <p className="text-base font-[400] text-GRAY920">
            {data?.gatewayFee
              ? `${Number(data?.gatewayFee).toLocaleString()}`
              : ""}
          </p>
        </div>
      </div>

      <div className=" py-5 px-9 flex flex-col gap-5  border-y-2 border-GRAY750">
        <div className="flex justify-between items-center">
          <p className="text-base font-[500] text-white">Total</p>
          <p className="text-xl font-[700] text-GREEN050">
            {totalPrice && transactionRef
              ? `${Number(totalPrice).toLocaleString()} THB`
              : "THB"}
          </p>
        </div>
      </div>

      <div className=" py-5 px-9 flex flex-col gap-5">
        <div
          className={`w-full ${
            recode && QRCode ? "bg-RED001" : "bg-RED001/[0.8]"
          } flex justify-center items-center rounded  hover:bg-RED001/[0.8] desktop:h-[3.5rem] mobile:h-[3.25rem]  cursor-pointer`}
          onClick={recode ? onClickRecode : () => ""}
        >
          <p
            className={`uppercase font-[700] text-base text-white text-center`}
          >
            <span>
              {endTime || !QRCode ? (
                `Recode in 00 SEC`
              ) : (
                <CountDownQR timeCountdown={timer} />
              )}
            </span>
          </p>
        </div>
        <BorderButton
          text="Cancel order"
          styleBtn={`border-none ${
            paymentSuccess ? "hover:bg-transparent" : ""
          }`}
          onClick={handleOpenDialogCancelOrder}
        />
      </div>
    </div>
  );
};

export default DetailItemOrderPayment;
