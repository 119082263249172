import React, { useState, useEffect } from "react";
import StepProgress from "../../component/layout/walletPayment/StepProgress";
import BaseDialog from "../../component/common/BaseDialog";
import { DialogHeader, DialogBody } from "@material-tailwind/react";
import { BorderButton, SubmitButton } from "../../component/Button";
import IconClose from "../../assets/images/Icon/IconClose.svg";
import IconReviewBox from "../../assets/images/complete/IconReviewBox.svg";
import DetailItemComplete from "../../component/layout/walletPayment/complete/DetailItemComplete";
import DetailOrderComplete from "../../component/layout/walletPayment/complete/DetailOrderComplete";
import SocialShare from "../../component/layout/walletPayment/complete/SocialShare";
import RatingComplete from "../../component/layout/walletPayment/complete/RatingComplete";
import DetailCompleteResponseMobile from "../../component/layout/walletPayment/complete/completeResponseMobile/DetailCompleteResponseMobile";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutationClientDeliveryTransaction } from "../../api/mutation/delivery";
import dayjs from "dayjs";
import {
  useMutationClientMailReceipt,
  useMutationClientReviewRating,
} from "../../api/mutation/complete";
import MyWallet from "../../component/layout/walletPayment/MyWallet/MyWallet";
import { useSelector, useDispatch } from "react-redux";
import {
  getStatusOpenDialogMyWallet,
  setOpenDialogMyWallet,
} from "../../slice/myWalletSlice";
import { setDisplay } from "../../slice/goBackBtnSlice";

const CompletePage = () => {
  const [dataComplete, setDataComplete] = useState();
  const [openDialogReceipt, setOpenDialogReceipt] = useState(false);
  const [openDialogReview, setOpenDialogReview] = useState(false);
  const [openDialogSubscribe, setOpenDialogSubscribe] = useState(false);
  const [openDialogContact, setOpenDialogContact] = useState(false);
  const [openMyWallet, setOpenMyWallet] = useState(false);
  const [rating, setRating] = useState(0);
  const [valueCommentReView, setValueCommentReView] = useState("");
  const [ratedReView, setRatedReView] = useState(false);
  const stepProgressName = "complete";
  const [searchParams, setSearchParams] = useSearchParams();
  const trackId = searchParams.get("trackId");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openMyWalletFromReduce = useSelector(getStatusOpenDialogMyWallet);
  const goBack = useDispatch();

  //   dataComplete
  const {
    mutate: mutateDeliveryTransaction,
    isSuccess: isSuccessDeliveryTransaction,
  } = useMutationClientDeliveryTransaction({
    onSuccess: (resp) => {
      setDataComplete(resp?.data);
      setRatedReView(resp?.data?.reviewed);
    },
    onError: () => {
      navigate("/", { replace: true });
    },
  });

  const { mutate: mutateMailReceipt, isSuccess: isSuccessMailReceipt } =
    useMutationClientMailReceipt({
      onSuccess: (resp) => {
        setOpenDialogReceipt(!openDialogReceipt);
      },
      onError: () => {},
    });

  //   ReviewRating
  const { mutate: mutateReviewRating, isSuccess: isSuccessReviewRating } =
    useMutationClientReviewRating({
      onSuccess: (resp) => {
        setOpenDialogReview(false);
      },
      onError: () => {},
    });

  useEffect(() => {
    if (trackId !== undefined) {
      const dataTransaction = {
        transactionTrackId: trackId || "",
      };
      mutateDeliveryTransaction(dataTransaction);
    }
  }, [trackId]);

  useEffect(() => {
    goBack(setDisplay(false));
  }, []);

  //   OpenDialog  //
  const handleOpenDialogReceipt = () => {
    const totalDiscount =
      dataComplete?.txnGatewayfee.lengnt > 0 ? dataComplete?.txnGatewayfee : 0;
    if (trackId !== undefined) {
      const dataReceipt = {
        item_id: dataComplete?.itemId,
        item_quantity: dataComplete?.itemCount,
        transaction_id: dataComplete?.transactionTrackId,
        order_date: dataComplete?.paidDatetime
          ? dayjs(dataComplete?.paidDatetime).format("DD/MM/YYYY")
          : "",
        order_time: dataComplete?.paidDatetime
          ? dayjs(dataComplete?.paidDatetime).format("HH:mm")
          : "",
        gatewayFee: Number(dataComplete?.txnGatewayfee) || 0,
        discount: totalDiscount,
        total: dataComplete?.grandTotalAmount,
      };
      mutateMailReceipt(dataReceipt);
    }
  };

  const handleOpenDialogReview = () => {
    if (ratedReView === false && trackId !== undefined) {
      setOpenDialogReview(!openDialogReview);
      setRating(0);
    }
  };

  const handleOpenDialogSubscribe = () => {
    setOpenDialogSubscribe(!openDialogSubscribe);
  };

  const handleOpenDialogMyWallet = () => {
    dispatch(setOpenDialogMyWallet(!openMyWalletFromReduce));
  };

  const onClickSubmitRating = () => {
    const dataSubmitReview = {
      transactionTrackId: trackId,
      rating: Number(rating),
      comment: valueCommentReView,
    };
    mutateReviewRating(dataSubmitReview);
    setOpenDialogReview(!openDialogReview);
    setRatedReView(true);
  };

  return (
    <div className=" bg-GRAY111 desktop:bg-GRAY710 w-[100%]  mobile:py-4 mobile:px-3 desktop:py-0 desktop:px-0 flex flex-col justify-between gap-4 desktop:gap-6">
      {/* StepProgress */}
      <div className="flex gap-4 items-center ">
        <StepProgress stepProgressName={stepProgressName} />
      </div>

      {/* DetailItemComplete &  DetailOrderComplete*/}
      <div className="desktop:flex desktop:px-[17.605%]  gap-6 desktop:pb-4">
        <div className=" desktop:w-[50%] mobile:hidden desktop:block desktop:bg-GRAY700 rounded">
          <DetailItemComplete
            data={dataComplete}
            handleOpenDialogSubscribe={handleOpenDialogSubscribe}
            disable={true}
          />
        </div>
        <div className="mobile:hidden desktop:block  desktop:w-[50%] desktop:bg-GRAY700 rounded">
          <DetailOrderComplete
            data={dataComplete}
            ratedReView={ratedReView}
            handleOpenDialogReceipt={handleOpenDialogReceipt}
            handleOpenDialogReview={handleOpenDialogReview}
            handleOpenDialogMyWallet={handleOpenDialogMyWallet}
            openAccordionMyWallet={openMyWallet}
            setOpenAccordionMyWallet={setOpenMyWallet}
          />
        </div>

        {/* DetailDeliveryResponseMobile */}
        <div className="desktop:hidden ">
          <DetailCompleteResponseMobile
            data={dataComplete}
            handleOpenDialogReceipt={handleOpenDialogReceipt}
            handleOpenDialogReview={handleOpenDialogReview}
            ratedReView={ratedReView}
          />
        </div>
      </div>

      {/* SocialShare  desktop*/}
      <div className="desktop:flex desktop:px-[16.702%] mobile:hidden gap-6 desktop:pb-10 ">
        <SocialShare />
      </div>

      {/* Dialog Receipt */}
      <BaseDialog open={openDialogReceipt}>
        <div className="w-[100%] flex flex-col desktop:gap-5 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            PAYMENT RECEIPT
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
            <div className="flex justify-center">
              <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                The receipt has been sent to your email as evidence <br /> that
                payment has been made and received.
              </p>
            </div>
            <div className="flex justify-center desktop:gap-5">
              <div className="desktop:w-[70%] mobile:w-[100%]">
                <SubmitButton
                  text="OK"
                  styleBtn="mobile:rounded-none mobile:rounded-b desktop:rounded"
                  onClick={() => {
                    setOpenDialogReceipt(!openDialogReceipt);
                  }}
                />
              </div>
            </div>
          </DialogBody>
        </div>
        {/* <div
          className="absolute desktop:block  mobile:hidden top-5 right-5 cursor-pointer p-2"
          onClick={handleOpenDialogCancelOrder}
        >
          <img src={IconClose} alt="closeIcon" className="w-4 h-4 " />
        </div> */}
      </BaseDialog>

      {/* Dialog Notification */}
      <BaseDialog open={openDialogSubscribe} stylesDialog="desktop:min-w-[45%]">
        <div className="w-[100%] flex flex-col desktop:gap-6 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            ALLOW TO SEND YOU NOTIFICATION
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
            <div className="flex justify-center">
              <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                Get a notification when deals are assigned to you.
              </p>
            </div>
            <div className="flex justify-center desktop:gap-5">
              <div className="desktop:w-[30%] mobile:w-[50%]">
                <BorderButton
                  text="don’t allow"
                  styleBtn="mobile:rounded-none mobile:rounded-bl desktop:rounded"
                  onClick={handleOpenDialogSubscribe}
                />
              </div>
              <div className="desktop:w-[30%] mobile:w-[50%] ">
                <SubmitButton
                  text="allow"
                  styleBtn="mobile:rounded-none mobile:rounded-br desktop:rounded"
                  onClick={handleOpenDialogSubscribe}
                />
              </div>
            </div>
          </DialogBody>
        </div>
        {/* <div
          className="absolute desktop:block  mobile:hidden top-5 right-5 cursor-pointer p-2"
          onClick={handleOpenDialogCancelOrder}
        >
          <img src={IconClose} alt="closeIcon" className="w-4 h-4 " />
        </div> */}
      </BaseDialog>

      {/* Dialog Review */}
      <BaseDialog
        open={openDialogReview}
        handler={handleOpenDialogReview}
        stylesDialog="desktop:px-6 desktop:min-w-[35%] w-[35%]"
      >
        <div className="w-[100%] flex flex-col desktop:gap-5 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-2xl desktop:leading-9 mobile:text-xl text-white text-center">
            PRODUCT REVIEW
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-3 mobile:p-0 mobile:px-4 mobile:pb-5  desktop:p-0">
            <div className="flex flex-col justify-center items-center gap-3">
              <img
                src={IconReviewBox}
                alt="IconReviewBox"
                className="desktop:w-100 desktop:h-100 mobile:w-20 mobile:h-20 "
              />
              <div className="flex flex-col gap-2">
                <p className="font-[700] desktop:text-2xl mobile:text-sm text-white text-center">
                  Order No. {dataComplete?.transactionId}
                </p>
                <p className="font-[400] desktop:text-base mobile:text-[0.625rem] text-GRAY920 text-center">
                  {dataComplete?.txnDatetime
                    ? dayjs(dataComplete?.txnDatetime).format(
                        "MMMM DD, YYYY HH:mm:ss"
                      )
                    : ""}
                </p>
              </div>
            </div>

            {/* Rating */}
            <div className="desktop:pt-2 desktop:pb-5">
              <RatingComplete setRating={setRating} rating={rating} />
            </div>

            <div className="w-full  rounded mobile:mt-1 desktop:mt-0">
              <textarea
                placeholder="Additional Comments..."
                className="text-white desktop:min-h-[9.375rem]  mobile:min-h-[16vw] w-full border-none p-3 rounded bg-GRAY910 resize-none desktop:font-[500] mobile:font-[400] desktop:text-base mobile:text-xs desktop:placeholder:font-[500] mobile:placeholder:font-[400] desktop:placeholder:text-base mobile:placeholder:text-xs placeholder:text-GRAY920"
                onChange={(e) => setValueCommentReView(e.target.value)}
              />
            </div>

            {/* Button */}
            <div className="flex justify-center desktop:gap-5 mobile:gap-3">
              <div className="desktop:w-[50%] mobile:w-[50%]">
                <BorderButton
                  text="NOT NOW"
                  styleBtn="mobile:rounded  desktop:rounded desktop:border-2"
                  onClick={handleOpenDialogReview}
                />
              </div>
              <div className="desktop:w-[50%] mobile:w-[50%]">
                <SubmitButton
                  text="SUBMIT REVIEW"
                  styleBtn="mobile:rounded  desktop:rounded"
                  onClick={onClickSubmitRating}
                />
              </div>
            </div>
          </DialogBody>
        </div>
        <div
          className="absolute desktop:block  mobile:hidden top-5 right-5 cursor-pointer p-2"
          onClick={handleOpenDialogReview}
        >
          <img src={IconClose} alt="closeIcon" className="w-4 h-4 " />
        </div>
      </BaseDialog>

      <div>
        <MyWallet
          onClickOpenMyWallet={() => dispatch(setOpenDialogMyWallet(false))}
          onClickCloseMyWallet={() => dispatch(setOpenDialogMyWallet(true))}
        />
      </div>
    </div>
  );
};

export default CompletePage;
