import React, { useState, useEffect, useRef } from "react";
import MockPictureItem from "../../assets/images/MyWallet/bg_defaultprofile.jpg";
import IconDropDownGray from "../../assets/images/Icon/IconDropDownGray.svg";
import { SubmitButton, BorderButton } from "../../component/Button";
import StepProgress from "../../component/layout/walletPayment/StepProgress";
import BaseDialog from "../../component/common/BaseDialog";
import {
  DialogHeader,
  DialogBody,
  //   Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import {
  Container,
  Grid,
  styled,
  Box,
  Divider,
  ButtonBase,
  Breadcrumbs,
  Radio,
  Typography,
  InputLabel,
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import {
  RED001,
  RED002,
  RED004,
  GRAY700,
  GRAY750,
  GRAY770,
  GRAY910,
  GRAY920,
  YELLOW100,
  RED005,
  RED003,
} from "../../theme/color";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import IconClose from "../../assets/images/Icon/IconClose.svg";
import DeliveryProgress from "../../component/layout/walletPayment/delivery/DeliveryProgress";
import DetailItemOrderDelivery from "../../component/layout/walletPayment/delivery/DetailItemOrderDelivery";
import DetailDeliveryResponseMobile from "../../component/layout/walletPayment/delivery/deliveryResponseMobile/DetailDeliveryResponseMobile";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  Link,
} from "react-router-dom";
import {
  useMutationClientDeliveryTransaction,
  useMutationClientChatHistory,
  useMutationClientDeliveryConfirm,
  useMutationClientGetCancelReason,
  useMutationClientCancelOrder,
} from "../../api/mutation/delivery";
import { changePxToRem } from "../../utils/changePixeltoRem";
import { useDispatch } from "react-redux";
import { setDisplay } from "../../slice/goBackBtnSlice";

const DeliveryPage = () => {
  const navigate = useNavigate();
  const keepInterval = useRef();
  const goBack = useDispatch();
  const [dataDelivery, setDataDelivery] = useState();
  const [dataChatHistory, setDataChatHistory] = useState();
  const [openDialogCancelOrder, setOpenDialogCancelOrder] = useState(false);
  const [openDialogOrderCancelled, setOpenDialogOrderCancelled] =
    useState(false);
  const [openDialogConfirmOrderForBuyer, setOpenDialogConfirmOrderForBuyer] =
    useState(false);
  const [openDialogConfirmOrderForSeller, setOpenDialogConfirmOrderForSeller] =
    useState(false);
  const [openDialogDoneConfirmDelivery, setOpenDialogDoneConfirmDelivery] =
    useState(false);
  const [openDialogRefundProcess, setOpenDialogRefundProcess] = useState(false);
  const stepProgressName = "delivery";
  const [searchParams, setSearchParams] = useSearchParams();
  const trackId = searchParams.get("trackId");

  const isSeller = dataDelivery?.tokenSeller;
  const progress = dataDelivery?.progress;
  const cancelBy = dataDelivery?.cancelBy;

  // select Reason for Cancel
  const [selectReasonCancel, setSelectReasonCancel] = useState("");
  const [menuSelectCancel, setMenuSelectCancel] = useState();
  const [aboutValueReasonsCancel, setAboutValueReasonsCancel] = useState("");

  //   mutateGetDetailOrderNumber
  const {
    mutate: mutateDeliveryTransaction,
    isSuccess: isSuccessDeliveryTransaction,
  } = useMutationClientDeliveryTransaction({
    onSuccess: (resp) => {
      setDataDelivery(resp?.data);
      if (
        (resp?.data.sellerConfirmed !== null || undefined) &&
        (resp?.data.buyerConfirmed !== null || undefined)
      ) {
        setOpenDialogDoneConfirmDelivery(true);
      }
    },
    onError: () => {
      navigate("/", { replace: true });
    },
  });

  //   mutateChatHistory
  const { mutate: mutateChatHistory, isSuccess: isSuccessChatHistory } =
    useMutationClientChatHistory({
      onSuccess: (resp) => {
        setDataChatHistory(resp?.data);
      },
      onError: () => {},
    });

  //   mutateGetCancelReason
  const { mutate: mutateGetCancelReason, isSuccess: isSuccessGetCancelReason } =
    useMutationClientGetCancelReason({
      onSuccess: (resp) => {
        setMenuSelectCancel(resp?.data);
      },
      onError: () => {},
    });

  //   mutateCancelOrder
  const { mutate: mutateCancelOrder, isSuccess: isSuccessCancelOrder } =
    useMutationClientCancelOrder({
      onSuccess: (resp) => {
        setOpenDialogCancelOrder(false);
        setOpenDialogRefundProcess(!openDialogRefundProcess);
      },
      onError: () => {},
    });

  // mutateDeliveryConfirm
  const { mutate: mutateDeliveryConfirm, isSuccess: isSuccessDeliveryConfirm } =
    useMutationClientDeliveryConfirm({
      onSuccess: (resp) => {
        if (resp?.data?.message === "Success") {
          alert("Confirm Delivery Success");
        }
      },
      onError: () => {},
    });

  useEffect(() => {
    if (trackId !== undefined) {
      const dataTransaction = {
        transactionTrackId: trackId || "",
      };
      const dataChatHistory = {
        transaction_track_id: trackId || "",
      };
      mutateDeliveryTransaction(dataTransaction);
      mutateChatHistory(dataChatHistory);
    }
  }, [trackId, mutateChatHistory, mutateDeliveryTransaction]);

  //---- call sellerConfirmed & buyerConfirmed
  useEffect(() => {
    if (keepInterval.current) {
      clearInterval(keepInterval.current);
    }
    keepInterval.current = setInterval(function () {
      const dataTransaction = {
        transactionTrackId: trackId || "",
      };
      mutateDeliveryTransaction(dataTransaction);
      if (openDialogConfirmOrderForSeller) {
        clearInterval(keepInterval.current);
      }
    }, 3500);
    return () => {
      clearInterval(keepInterval.current);
    };
  }, [trackId, mutateDeliveryTransaction, openDialogConfirmOrderForSeller]);

  useEffect(() => {
    if (progress === "Canceled") {
      if (
        (cancelBy === "buyer" && isSeller === false) ||
        (cancelBy === "seller" && isSeller === true)
      ) {
        setOpenDialogRefundProcess(true);
      } else {
        setOpenDialogOrderCancelled(true);
      }
    }
  }, [cancelBy, isSeller, progress]);

  useEffect(() => {
    goBack(setDisplay(false));
  }, []);

  const onClickConfirmDelivery = () => {
    mutateDeliveryConfirm({
      transactionTrackId: trackId,
    });
    setOpenDialogConfirmOrderForSeller(false);
  };

  // Open Dialog
  const handleOpenDialogCancelOrder = () => {
    setOpenDialogCancelOrder(!openDialogCancelOrder);
    setSelectReasonCancel("");
    setAboutValueReasonsCancel("");
    if (openDialogCancelOrder !== true) {
      mutateGetCancelReason({
        transactionTrackId: trackId,
      });
    }
  };

  const handleOpenDialogConfirmOrderForSeller = (e) => {
    setOpenDialogConfirmOrderForSeller(!openDialogConfirmOrderForSeller);
  };

  const handleOpenDialogConfirmOrderForBuyer = (e) => {
    setOpenDialogConfirmOrderForBuyer(!openDialogConfirmOrderForBuyer);
  };

  const handleOpenDialogDoneConfirmDelivery = (e) => {
    e.preventDefault();
    navigate(
      {
        pathname: "/complete",
        search: `${createSearchParams({
          itemName: dataDelivery?.itemName,
          trackId: trackId,
        })}`,
      },
      { replace: true }
    );
  };

  const handleOpenDialogRefundProcess = () => {
    setOpenDialogCancelOrder(!openDialogCancelOrder);
    setOpenDialogRefundProcess(!openDialogRefundProcess);
  };

  const handleChangeSelectReasonCancel = (event) => {
    setSelectReasonCancel(event.target.value);
  };

  const handleClickSConfirmReasonCancel = () => {
    if (selectReasonCancel !== "") {
      const dataConfirm = {
        reasonId: selectReasonCancel === "" ? 0 : selectReasonCancel,
        comment: aboutValueReasonsCancel,
        transactionTrackId: trackId,
      };
      mutateCancelOrder(dataConfirm);
    }
  };

  return (
    <div className=" bg-GRAY111 desktop:bg-GRAY710 w-[100%]  mobile:py-4 mobile:px-3 desktop:py-0 desktop:px-0 flex flex-col justify-between gap-4 desktop:gap-5">
      <div className="flex gap-4 items-center ">
        <StepProgress stepProgressName={stepProgressName} />
      </div>

      <div className="desktop:flex desktop:px-[17.605%]  gap-6 desktop:pb-60 ">
        <div className="desktop:w-[50%] mobile:hidden desktop:block desktop:bg-GRAY700 rounded h-full">
          <DeliveryProgress
            data={dataDelivery}
            dataChatHistory={dataChatHistory}
          />
        </div>
        <div className="mobile:hidden desktop:block  desktop:w-[50%] desktop:bg-GRAY700 rounded h-full">
          <DetailItemOrderDelivery
            data={dataDelivery}
            handleOpenDialogCancelOrder={handleOpenDialogCancelOrder}
            handleOpenDialogConfirmOrderForSeller={
              handleOpenDialogConfirmOrderForSeller
            }
            handleOpenDialogConfirmOrderForBuyer={
              handleOpenDialogConfirmOrderForBuyer
            }
          />
        </div>

        {/* DetailDeliveryResponseMobile */}
        <div className="desktop:hidden ">
          <DetailDeliveryResponseMobile
            data={dataDelivery}
            handleOpenDialogCancelOrder={handleOpenDialogCancelOrder}
            handleOpenDialogConfirmOrderForSeller={
              handleOpenDialogConfirmOrderForSeller
            }
            handleOpenDialogConfirmOrderForBuyer={
              handleOpenDialogConfirmOrderForBuyer
            }
            onClickConfirmDelivery={onClickConfirmDelivery}
            dataChatHistory={dataChatHistory}
          />
        </div>
      </div>

      {/* Dialog ConfirmOrderForSeller */}
      <BaseDialog
        open={openDialogConfirmOrderForSeller}
        handler={handleOpenDialogConfirmOrderForSeller}
        stylesDialog="desktop:min-w-[50%]"
      >
        <div className="w-[100%] flex flex-col gap-5">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            CONFIRM RECEIVED
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  gap-7 p-0">
            <div className="flex flex-col justify-center gap-5">
              <p className="font-[400] mobile:hidden desktop:block desktop:text-base mobile:text-sm text-white text-center">
                WARNING! If you click confirm without making the delivery,
                <br /> The platform reserve the rights to claim any damage
                caused
              </p>
              <div className="bg-black mobile:mx-2 py-2 rounded">
                <div className="rounded mobile:p-4 desktop:p-5 mobile:pt-2 desktop:pt-3 desktop:pb-2  mobile:pb-1 flex flex-col mobile:max-h-[200px] desktop:max-h-[400px]  overflow-auto desktop:gap-3 mobile:gap-0 ">
                  <div>
                    <p className="font-[400] desktop:text-base mobile:text-xs text-white">
                      To ensure 100% complete product receipt, the buyer should
                      provide the following details:
                    </p>
                    <p className="font-[400] desktop:text-base mobile:text-xs text-white">
                      1. Take photos and videos from the beginning to the
                      completion of the product receipt.
                    </p>
                    <p className="font-[400] desktop:text-base mobile:text-xs text-white">
                      2. Upload product images and videos into the system after
                      receiving the product.
                    </p>
                    <p className="font-[400] desktop:text-base mobile:text-xs text-white">
                      3. In case the buyer receives complaints from RETO HUB,
                      the uploaded{" "}
                      <span className="text-red-600 cursor-pointer">
                        evidence
                      </span>{" "}
                      will be used to confirm the receipt.
                    </p>
                  </div>
                  <div>
                    <ul className="list-disc p-5">
                      <li className="font-[400] desktop:text-base mobile:text-xs text-white">
                        For sellers without{" "}
                        <span className="text-red-600 cursor-pointer">
                          evidence
                        </span>{" "}
                        and in cases of complaints, RETO HUB will not take
                        responsibility in any circumstances. *
                      </li>
                      <li className="font-[400] desktop:text-base mobile:text-xs text-white">
                        Providing additional information to RETO HUB reduces the
                        chances of product returns or refund requests.
                      </li>
                      <li className="font-[400] desktop:text-base mobile:text-xs text-white">
                        To prevent possible errors, we recommend keeping
                        evidence such as video recordings or screenshots of
                        conversations.
                      </li>
                    </ul>
                  </div>
                  <p className="font-[400] desktop:text-base mobile:text-xs text-white">
                    If you want more information can be read:{" "}
                    <span className="text-blue-900 cursor-pointer">
                      How to Sell
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-center desktop:gap-5 ">
              <div className="desktop:w-[45%] mobile:w-[50%]">
                <BorderButton
                  text="cancel"
                  styleBtn="mobile:rounded-none mobile:rounded-bl  desktop:rounded"
                  onClick={handleOpenDialogConfirmOrderForSeller}
                />
              </div>
              <div className="desktop:w-[45%] mobile:w-[50%]">
                <SubmitButton
                  text="Confirm"
                  styleBtn="mobile:rounded-none mobile:rounded-br desktop:rounded"
                  onClick={onClickConfirmDelivery}
                />
              </div>
            </div>
          </DialogBody>
        </div>
        <div
          className="absolute desktop:block  mobile:hidden top-5 right-5 cursor-pointer p-2"
          onClick={handleOpenDialogConfirmOrderForSeller}
        >
          <img src={IconClose} alt="closeIcon" className="w-4 h-4 " />
        </div>
      </BaseDialog>

      {/* Dialog ConfirmOrderForBuyer */}
      <BaseDialog
        open={openDialogConfirmOrderForBuyer}
        handler={handleOpenDialogConfirmOrderForBuyer}
        stylesDialog="desktop:min-w-[50%]"
      >
        <div className="w-[100%] flex flex-col gap-5">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            CONFIRM RECEIVED
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  gap-7 p-0">
            <div className="flex flex-col justify-center gap-5">
              <p className="font-[400] mobile:hidden desktop:block desktop:text-base mobile:text-sm text-white text-center">
                WARNING! If you click confirm without making the delivery,
                <br /> The platform reserve the rights to claim any damage
                caused
              </p>
              <div className="bg-black mobile:mx-2 py-2 rounded">
                <div className="rounded mobile:p-4 desktop:p-5 mobile:pt-2 desktop:pt-3 desktop:pb-2  mobile:pb-1 flex flex-col mobile:max-h-[200px] desktop:max-h-[400px]  overflow-auto desktop:gap-3 mobile:gap-0 ">
                  <div>
                    <p className="font-[400] desktop:text-base mobile:text-xs text-white">
                      To ensure 100% complete product receipt, the buyer should
                      provide the following details:
                    </p>
                    <p className="font-[400] desktop:text-base mobile:text-xs text-white">
                      1. Take photos and videos from the beginning to the
                      completion of the product receipt.
                    </p>
                    <p className="font-[400] desktop:text-base mobile:text-xs text-white">
                      2. Upload product images and videos into the system after
                      receiving the product.
                    </p>
                    <p className="font-[400] desktop:text-base mobile:text-xs text-white">
                      3. In case the buyer receives complaints from RETO HUB,
                      the uploaded{" "}
                      <span className="text-red-600 cursor-pointer">
                        evidence
                      </span>{" "}
                      will be used to confirm the receipt.
                    </p>
                  </div>
                  <div>
                    <ul className="list-disc p-5">
                      <li className="font-[400] desktop:text-base mobile:text-xs text-white">
                        For buyers without{" "}
                        <span className="text-red-600 cursor-pointer">
                          evidence
                        </span>{" "}
                        and in cases of complaints, RETO HUB will not take
                        responsibility in any circumstances. *
                      </li>
                      <li className="font-[400] desktop:text-base mobile:text-xs text-white">
                        Providing additional information to RETO HUB reduces the
                        chances of product returns or refund requests.
                      </li>
                      <li className="font-[400] desktop:text-base mobile:text-xs text-white">
                        To prevent possible errors, we recommend keeping
                        evidence such as video recordings or screenshots of
                        conversations.
                      </li>
                    </ul>
                  </div>
                  <p className="font-[400] desktop:text-base mobile:text-xs text-white">
                    If you want more information can be read:{" "}
                    <span className="text-blue-900 cursor-pointer">
                      How to Buy
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-center desktop:gap-5 ">
              <div className="desktop:w-[45%] mobile:w-[50%]">
                <BorderButton
                  text="cancel"
                  styleBtn="mobile:rounded-none mobile:rounded-bl  desktop:rounded"
                  onClick={handleOpenDialogConfirmOrderForBuyer}
                />
              </div>
              <div className="desktop:w-[45%] mobile:w-[50%]">
                <SubmitButton
                  text="Confirm"
                  styleBtn="mobile:rounded-none mobile:rounded-br desktop:rounded"
                  onClick={onClickConfirmDelivery}
                />
              </div>
            </div>
          </DialogBody>
        </div>
        <div
          className="absolute desktop:block  mobile:hidden top-5 right-5 cursor-pointer p-2"
          onClick={handleOpenDialogConfirmOrderForBuyer}
        >
          <img src={IconClose} alt="closeIcon" className="w-4 h-4 " />
        </div>
      </BaseDialog>

      {/* Dialog DialogDoneConfirmDelivery */}
      <BaseDialog open={openDialogDoneConfirmDelivery}>
        <div className="w-[100%] flex flex-col gap-5">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            Successfully Delivery
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  gap-7 p-0">
            <div className="flex flex-col justify-center gap-5">
              <p className="font-[400] mobile:block desktop:block desktop:text-base mobile:text-sm text-white text-center">
                You will be receiving an invoice email with order details
              </p>
            </div>
            <div className="flex justify-center desktop:gap-5">
              <div className="desktop:w-[60%] mobile:w-[100%]">
                <SubmitButton
                  text="CONTINUE"
                  styleBtn="mobile:rounded-none mobile:rounded-b desktop:rounded"
                  onClick={handleOpenDialogDoneConfirmDelivery}
                />
              </div>
            </div>
          </DialogBody>
        </div>
      </BaseDialog>

      {/* Dialog Cancel Order*/}
      <BaseDialog
        open={openDialogCancelOrder}
        handler={handleOpenDialogCancelOrder}
        stylesDialog="xl:desktop:min-w-[45%]"
      >
        <Grid sx={{ width: "100%" }}>
          <DialogContent
            sx={{
              bgcolor: GRAY700,
              padding: "0",
              display: "flex",
              width: "100%",
            }}
          >
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: { xs: changePxToRem(12), sm: changePxToRem(20) },
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: { xs: changePxToRem(12), sm: changePxToRem(20) },
                  width: "100%",
                }}
              >
                <p className="desktop:text-3xl mobile:text-xl font-[700] text-white text-center">
                  CANCEL ORDER
                </p>

                <Grid sx={{ width: "100%", paddingX: "4%" }}>
                  <p className="desktop:text-base mobile:text-sm font-[700] text-white mb-1">
                    Reason for Cancel
                  </p>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: { xs: changePxToRem(12), sm: changePxToRem(20) },
                      minWidth: 100,
                      borderRadius: changePxToRem(4),
                      // bgcolor: "red",
                    }}
                  >
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectReasonCancel}
                        displayEmpty
                        renderValue={
                          selectReasonCancel !== ""
                            ? undefined
                            : () => (
                                <p className="desktop:text-base mobile:text-xs font-[400] text-GRAY920">
                                  {isSeller
                                    ? "Please select reason ( Seller )"
                                    : "Please select reason ( Buyer )"}
                                </p>
                              )
                        }
                        onChange={handleChangeSelectReasonCancel}
                        sx={{
                          bgcolor: GRAY910,
                          color: GRAY920,
                          fontSize: { xs: "12px", sm: "16px" },
                          maxWidth: "100%",
                          "& .MuiOutlinedInput-input": {
                            padding: `${changePxToRem(11)}`,
                            whiteSpace: "normal",
                          },
                          "& .MuiSelect-icon": {
                            color: GRAY920,
                            fontSize: { xs: "20px", sm: "30px" },
                          },
                        }}
                        MenuProps={{
                          sx: {
                            marginTop: changePxToRem(5),
                            zIndex: 9999999,
                            "& .MuiMenu-paper": {
                              backgroundColor: "transparent",
                            },
                            "& .MuiList-root": {
                              backgroundColor: GRAY910,
                            },
                          },
                        }}
                      >
                        {menuSelectCancel &&
                          menuSelectCancel?.data.map(({ reason, id, type }) => {
                            return (
                              <MenuItem
                                value={id}
                                key={id}
                                sx={{
                                  fontSize: {
                                    xs: changePxToRem(12),
                                    sm: changePxToRem(16),
                                  },
                                  fontWeight: 400,
                                  color: GRAY920,
                                  whiteSpace: "normal",
                                }}
                              >
                                {reason}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": {
                          m: 0,
                          //   width: {
                          //     xs: changePxToRem(355),
                          //     sm: changePxToRem(610),
                          //   },
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <div>
                        <TextField
                          id="outlined-textarea"
                          placeholder="Comment"
                          onChange={(e) =>
                            setAboutValueReasonsCancel(e.target.value)
                          }
                          multiline
                          rows={4}
                          sx={{
                            bgcolor: GRAY910,
                            borderRadius: changePxToRem(4),
                            "& .MuiOutlinedInput-root": {
                              padding: changePxToRem(11),
                              alignItems: "start",
                              color: "white",
                              fontSize: { xs: "12px", sm: "16px" },
                              fontWeight: 400,
                            },
                          }}
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <div className="w-full flex desktop:gap-5 mobile:gap-3 justify-center mobile:px-[4%] mobile:pb-4 desktop:px-0 desktop:pb-0">
                {/* Cancel for desktop */}
                <div className="desktop:w-[35%] mobile:w-[50%] desktop:block mobile:hidden">
                  <BorderButton
                    text="Cancel"
                    styleBtn="border-[3px]"
                    onClick={handleOpenDialogCancelOrder}
                  />
                </div>
                {/* Cancel for mobile */}
                <div className="desktop:w-[35%] mobile:w-[50%] desktop:hidden mobile:block">
                  <BorderButton
                    text="NOT NOW"
                    styleBtn="border-[3px]"
                    onClick={handleOpenDialogCancelOrder}
                  />
                </div>

                {/* Confirm for desktop */}
                <div className="desktop:w-[35%] mobile:w-[50%] desktop:block mobile:hidden">
                  <SubmitButton
                    type="submit"
                    text="Confirm"
                    styleBtn={
                      selectReasonCancel === ""
                        ? "bg-RED003 hover:bg-RED003 !cursor-default"
                        : "bg-RED001"
                    }
                    onClick={handleClickSConfirmReasonCancel}
                  />
                </div>
                {/* submit for desktop */}
                <div className="desktop:w-[35%] mobile:w-[50%] desktop:hidden mobile:block">
                  <SubmitButton
                    type="submit"
                    text="submit"
                    styleBtn={
                      selectReasonCancel === ""
                        ? "bg-RED003 hover:bg-RED003 !cursor-default"
                        : "bg-RED001"
                    }
                    onClick={handleClickSConfirmReasonCancel}
                  />
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <div className="desktop:block mobile:hidden">
            <ClearRoundedIcon
              sx={{
                position: "absolute",
                top: changePxToRem(10),
                right: changePxToRem(12),
                color: "white",
                cursor: "pointer",
                zIndex: 2,
              }}
              onClick={handleOpenDialogCancelOrder}
            />
            <Grid
              sx={{
                position: "absolute",
                top: changePxToRem(0),
                right: changePxToRem(0),
                width: changePxToRem(55),
                height: changePxToRem(50),
                backgroundColor: GRAY910,
                borderBottomLeftRadius: changePxToRem(40),
                borderRight: 0,
                borderTop: 0,
                cursor: "pointer",
              }}
              onClick={handleOpenDialogCancelOrder}
            ></Grid>
          </div>
        </Grid>
      </BaseDialog>

      {/* REFUND PROCESS */}
      <BaseDialog open={openDialogRefundProcess}>
        <div className="w-[100%] flex flex-col desktop:gap-5 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] mobile:text-base text-white text-center">
            REFUND PROCESS
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
            <div className="flex justify-center">
              <p className="font-[400] mobile:hidden desktop:block desktop:text-base mobile:text-sm text-white text-center">
                We have received your refund order and it is being processed,
                <br />
                Please waiting for refund at least 14 days
              </p>
              <p className="font-[400] mobile:block desktop:hidden desktop:text-base mobile:text-sm text-white text-center">
                We have received your refund order and it is being <br />{" "}
                processed, Please waiting for refund at least 14 days
              </p>
            </div>
            <div className="flex justify-center">
              <div className="desktop:w-[65%] mobile:w-[100%]">
                <Link to="/">
                  <SubmitButton
                    text="OK"
                    styleBtn="mobile:rounded-none mobile:rounded-b desktop:rounded"
                  />
                </Link>
              </div>
            </div>
          </DialogBody>
        </div>
      </BaseDialog>

      {/* Popup OrderCancelled */}
      <BaseDialog
        open={openDialogOrderCancelled}
        stylesDialog="desktop:min-w-[50%]"
      >
        <div className="w-[100%] flex flex-col desktop:gap-5 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center uppercase">
            Order Cancelled
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
            <div className="flex justify-center">
              <p className="font-[400] mobile:hidden desktop:block desktop:text-base mobile:text-sm text-white text-center">
                {isSeller
                  ? "We’re sorry to hear that your order was cancelled. We hope you can give us another chance."
                  : "We apologize for the inconvenience that your order was cancelled."}
                <br />
                {isSeller
                  ? "You can learn more about your product by checking out this"
                  : "The system will refund to you. There are products that are similar to yours."}
              </p>
              <p className="font-[400] mobile:block desktop:hidden desktop:text-base mobile:text-sm text-white text-center">
                {isSeller
                  ? "We’re sorry to hear that your order was cancelled. We hope you can give us another chance."
                  : "We apologize for the inconvenience that your order was cancelled."}
                <br />
                {isSeller
                  ? "You can learn more about your product by checking out this"
                  : "The system will refund to you. There are products that are similar to yours."}
              </p>
            </div>
            <div className="flex justify-center">
              <div className="desktop:w-[65%] mobile:w-[100%]">
                <Link to="/">
                  <SubmitButton
                    text="OK"
                    styleBtn="mobile:rounded-none mobile:rounded-b desktop:rounded"
                  />
                </Link>
              </div>
            </div>
          </DialogBody>
        </div>
      </BaseDialog>
    </div>
  );
};

export default DeliveryPage;
