import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        access_token: "",
        refresh_token: "",
        display_Name: "",
        email_verification_status: "",
        user_email: null,
        kyc_step: null,
        kyc_step_buyer: null,
        kyc_step_seller: null,
        profile_image: null
    },
    reducers: {
        setLogin_success: (state, { payload: { access_token, refresh_token, display_Name, email_verification_status } }) => {
            state.access_token = access_token;
            state.refresh_token = refresh_token;
            state.display_Name = display_Name
            state.email_verification_status = email_verification_status
        },
        setLogout: (state) => {
            state.access_token = ""
            state.refresh_token = ""
            state.display_Name = ""
            state.email_verification_status = ""
            state.user_email = null
            state.kyc_step = null
            state.kyc_step_buyer = null
            state.kyc_step_seller = null
            state.profile_image = null
        },
        getKYCstatus: (state, { payload: { kyc_step, kyc_step_buyer, kyc_step_seller, user_email } }) => {
            state.user_email = user_email
            state.kyc_step = kyc_step;
            state.kyc_step_buyer = kyc_step_buyer
            state.kyc_step_seller = kyc_step_seller
        },
        deleteKYCstatus: (state) => {
            state.kyc_step = null;
            state.kyc_step_buyer = null
            state.kyc_step_seller = null
        },
        getProfileIamge: (state, { payload: { profile_image } }) => {
            state.profile_image = profile_image
        },
    }
})

export const { setLogin_success, setLogout, getKYCstatus, deleteKYCstatus, getProfileIamge } = userSlice.actions
export default userSlice.reducer