
import { useState, useEffect } from "react"
import { icon_dropdown_createorder } from "../../assets/createOrder";


const SERVER_LIST_AUTOCOMPLETE = [
    { label: "ENG" },
    { label: "JP" },
    { label: "SG" },
    { label: "TH" },
    
];

const ServerName = ({ serverSelect, setServerSelect, showList, setShowList, item_server, setItem_server, isError, showError, setShowError }) => {

    return (
        <div className="max-w-full min-w-0 w-full desktop:h-[4.875rem] mobile:h-[4.6875rem] flex flex-col justify-between cursor-default" id="item_server">
            <div className="w-full flex justify-between">
                <p className="text-white font-bold desktop:text-base mobile:text-[0.875rem]">Server Name</p>
                {isError.includes("item_server") && showError === "item_server" && (
                    <p className="text-[#BB213D] font-meduim desktop:text-base mobile:text-[0.875rem]">*Please fill the blank of server</p>
                )}
            </div>
            <div className="w-full h-12 rounded text-[#939393] relative bg-[#282828] " onClick={() => showList === 'server_name' ? setShowList('') : setShowList("server_name")} >
                <input
                    type="text"
                    className={`w-full h-full flex items-center p-3 text-white text-base placeholder:text-[#939393] bg-[#282828] rounded focus:outline-none ${isError.includes("item_server") ? "border border-[#bb213d]" : ""}`}
                    placeholder="Write or select your server"
                    value={item_server}
                    onChange={(e) => setItem_server(e.target.value.slice(0, 100))}
                    onClick={() => setShowError("item_server")}
                />
                <img src={icon_dropdown_createorder} alt="icon_dropdown_createorder" className="absolute right-3 top-3" onClick={() => showList === 'server_name' ? setShowList('') : setShowList("server_name")} />
                {showList === 'server_name' && (
                    <div className="w-full  absolute top-[102%] left-0 bg-[#282828] rounded overflow-auto z-10">
                        {SERVER_LIST_AUTOCOMPLETE?.map(({ label }, index) => (
                            <div
                                className=" flex items-center p-3 cursor-pointer hover:bg-black/40"
                                key={index}
                                onClick={() => {
                                    setServerSelect(index)
                                    setShowList(false)
                                    setItem_server(label)
                                    // setShowListGameName(!showListGameName)
                                }}
                            >
                                <p className="text-white text-base ">
                                    {label}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default ServerName