import React from "react";
import { Link, useNavigate } from "react-router-dom";
import IconDropDown from "../../assets/images/Icon/IconDropDown.png";
import { useState } from "react";

const SideBarButton = ({ menu, setIsOpen }) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);

  return (
    <div
      onClick={() => {
        setSelected(!selected);
      }}
    >
      {menu.title === "marketplace" ? (
        <summary
          className={`flex items-center justify-center rounded-md p-[0.875rem] bg-GRAY910`}
        >
          <p
            onClick={() => {
              setIsOpen(false);
              navigate("/marketplace");
            }}
            className={`flex justify-center w-full font-semibold font-IBMPlex uppercase text-white cursor-pointer `}
          >
            {" "}
            Market{" "}
          </p>
        </summary>
      ) : menu.title === "top-up" ? (
        <summary
          className={`flex items-center justify-center rounded-md p-[0.875rem] bg-GRAY910`}
        >
          <Link
            to={"/comingsoon"}
            className={`flex justify-center w-full font-semibold font-IBMPlex uppercase text-white cursor-pointer `}
          >
            {menu.title.replace("-", " ")}{" "}
          </Link>
        </summary>
      ) : (
        <summary
          className={`flex items-center justify-center rounded-md p-[0.875rem]
                  ${selected ? "bg-GRAY111" : "bg-GRAY910"}`}
        >
          <div
            className={`flex relative justify-center w-full font-semibold font-IBMPlex uppercase text-white cursor-pointer `}
          >
            {menu.title}
            <div
              className={`absolute right-1 top-2 ${
                selected ? "rotate-180 " : ""
              }`}
            >
              <img src={IconDropDown} alt="" className="w-4 h-2" />
            </div>
          </div>
        </summary>
      )}

      {selected && (
        <ul>
          {menu?.menu.map((sub_menu, index) => (
            <li key={index}>
              <Link
                to={"/comingsoon"}
                className={`flex justify-center p-3 bg-GRAY700`}
              >
                <div className="text-sm text-white focus:text-white  font-IBMPlex">
                  {sub_menu}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SideBarButton;
