import axios from "axios";

export const getGamelist = async (setGameList, setGameIndex, id) => {
    if (Number(id)) {
        switch (id.toString().length) {
            case 5: id = "G" + id.toString()
                break;
            case 4: id = "G0" + id.toString()
                break;
            case 3: id = "G00" + id.toString()
                break;
            case 2: id = "G000" + id.toString()
                break;
            case 1: id = "G0000" + id.toString()
                break;
        }
    }
    await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_GET_DATAGAMEINDEX)
        .then((res) => {
            setGameList(res.data.sortByName)
            if (!!id) {
                setGameIndex(res.data.sortByName[res?.data?.sortByName?.findIndex((game) => game.game_id === id)]);
            }
        })
        .catch((err) => {
            console.error(err);
        })
}