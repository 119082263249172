import axios from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie";


export const getUserProfile = async (
    setProfileData,
    setBio,
    setFirst_Name,
    setLast_name,
    setAddress,
    setDistrict,
    setCity,
    setZipcode,
    searchGender,
    setDate,
    setIsProfileImg
) => {
    await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_GET_USERPROFILE, {
        headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`
        }
    })
        .then((res) => {
            setProfileData(res?.data)
            setBio(res?.data?.bio)
            setFirst_Name(res?.data?.first_name)
            setLast_name(res?.data?.last_name)
            setAddress(res?.data?.address)
            setDistrict(res?.data?.district)
            setCity(res?.data?.city)
            setZipcode(res?.data?.zipcode)
            searchGender(res?.data?.gender)
            setDate(dayjs(res?.data?.birth_date?.slice(0, 10)))
            setIsProfileImg(res?.data)
        })
        .catch((err) => {
        })
}


export const getFrameProfile = (setFramProfileData) => {
    axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_GET_FRAME_PROFILE, {
        headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`
        }
    })
        .then((res) => {
            setFramProfileData(res.data)
        })
        .catch((err) => {
            console.error(err);
        })
}