import {
  iconbankcard,
  iconface,
  iconIDcard,
  iconPhone,
  iconClose,
  iconCorrect,
  iconDragonCardID,
  iconUpload,
  iconLoading,
} from "../../assets/setting/KYC";
import { useState, useEffect } from "react";
import { getProfileKYC } from "../../api/get/setting/kyc";
import {
  Dialog,
  DialogBody,
  ThemeProvider,
  DialogHeader,
} from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import {
  icon_thailand_lang,
  icon_china_lang,
  icon_france_lang,
  icon_us_lang,
  icon_japan_lang,
} from "../../assets/setting/KYC/language_icons";
import icon_arrow_down from "../../assets/setting/icon_arrow_down.svg";
import {
  sendOTP,
  validateOTP,
  UploadPicMultiple,
  step1_2,
} from "../../api/post/setting/kyc";
import {
  Back_Blurry,
  Back_Icomplete,
  Back_Reflectibe,
  Front_Blurry,
  Front_incomplete,
  Front_Reflective,
  Icon_Correct,
  Icon_Incorrect,
} from "../../assets/setting/KYC/eKYC_CardID";
import ImageUploading from "react-images-uploading";
import { Step_2, Step_Bookbank, CloseButton } from "./kyc_component";
import { IMaskInput } from "react-imask";
import { useSelector } from "react-redux";
import NotifyStepFacerecognition from "./kyc_component/notifyStepFacerecognition";
import { SubmitButton } from "../../component/Button";
import BaseDialog from "../../component/common/BaseDialog";

const KYC_LIST = [
  { pic: iconPhone, label: "Mobile Phone Number", id: "phone" },
  { pic: iconIDcard, label: "Your ID Card/Your Passport", id: "idcard" },
  { pic: iconface, label: "Face Recognition", id: "face" },
  { pic: iconbankcard, label: "Registration of book bank", id: "bookbank" },
];

const COUNTRY_CODE = [
  { pic: icon_thailand_lang, val: "th", code: "+66" },
  { pic: icon_china_lang, val: "chn", code: "+86" },
  { pic: icon_france_lang, val: "fra", code: "+33" },
  { pic: icon_us_lang, val: "us", code: "+1" },
  { pic: icon_japan_lang, val: "jpn", code: "+81" },
];

const KYC = () => {
  const user = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState("");
  const [status_KYC, setStatus_KYC] = useState([]);
  const [codeIndex, setCodeIndex] = useState(0);
  const [open, setOpen] = useState("");
  const [success, setSuccess] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const handleOpen = () => setOpen("");

  // step-otp
  const [phoneNumber, setPhoneNumber] = useState();
  const [sendStatus, setSendStatus] = useState(false);
  const [reCode, setReCode] = useState(false);
  const [reCodeTime, setReCodeTime] = useState(0);
  const [otp_Code, setOTP_Code] = useState();
  const [invalidOTP, setInvalidOTP] = useState(false);

  // step 1-2
  const [card_number, setCard_number] = useState();
  const [first_name, setFirst_name] = useState();
  const [last_name, setLast_name] = useState();
  const [birthday, setBirthday] = useState();
  const [laser_no, setLaser_no] = useState();
  const [err_step1_2, setErr_step1_2] = useState([]);
  const [step1_1_Success, setStep1_1_Success] = useState(null);
  const [step1_2_Success, setStep1_2_Success] = useState(null);
  const [confirmFront, setConfirmFront] = useState(false);
  const [confirmBack, setConfirmBack] = useState(false);
  const [res_Data, setRes_Data] = useState({})
  const [qualityUploadPic, setQuantityUploadPic] = useState({
    upload: 0,
    send: 0,
    status: null
  })
  // qualityUploadPic?.status

  // step2
  const [openNotifyStep2, setOpenNotifyStep2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success3Step, setSuccess3Step] = useState(false);
  const [pendingPicture, setPendingPicture] = useState(false);
  const [success4Step, setSuccess4Step] = useState(false);
  const handleOpenNotifyStep2 = () => {
    setOpenNotifyStep2(false);
  };
  const handleCard_id = (e) => {
    setCard_number(e.target.value);
  };

  const handleKeydownOTP = (event) => {
    (event.key === "e" && event.preventDefault()) ||
      (event.key === "-" && event.preventDefault()) ||
      (event.key === "+" && event.preventDefault()) ||
      (event.key === "." && event.preventDefault());

    let key = event.key;
    if (key === "Enter") {
      if (sendStatus) {
        validateOTP(otp_Code, phoneNumber, success, setSuccess, setOpen);
      } else sendOTP(phoneNumber, setSendStatus, setReCode, setReCodeTime);
    }
  };
  // step 1-1  1-2
  const [openCardIDDialog, setOpenCardIDDialog] = useState("");
  const [frontCard, setFrontCard] = useState();
  const [backCard, setBackCard] = useState();
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const closeFrontUploadDialog = () => {
    setOpenCardIDDialog("");
    setOpen("idcard");
    if (!confirmFront) {
      setFrontCard();
    }
  };

  const closeBackUploadDialog = () => {
    setOpenCardIDDialog("");
    setOpen("idcard");
    if (!confirmBack) {
      setBackCard();
    }
  };

  const onChangeFront = (imageList, addUpdateIndex) => {
    if (frontCard?.length > 1) {
      let newArr = frontCard;
      newArr?.splice(0, 1, imageList);
    } else setFrontCard(imageList);
  };
  const onChangeBack = (imageList, addUpdateIndex) => {
    if (backCard?.length > 1) {
      let newArr = backCard;
      newArr?.splice(0, 1, imageList);
    } else setBackCard(imageList);
  };
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    if (!!frontCard && !!backCard) {
      setQuantityUploadPic((prev) => ({
        ...prev, upload: prev?.upload + 1
      }))
      UploadPicMultiple(
        frontCard,
        backCard,
        setStep1_1_Success,
        setRes_Data,
        setQuantityUploadPic
      );
    }

  }, [frontCard, backCard]);


  useEffect(() => {
    if (qualityUploadPic?.upload === qualityUploadPic?.send && qualityUploadPic?.send > 0 && qualityUploadPic?.upload > 0) {
      setCard_number(res_Data?.card_number)
      setFirst_name(res_Data?.first_name)
      setLast_name(res_Data?.last_name)
      setBirthday(res_Data?.birthday)
      setLaser_no(res_Data?.laser_no)
    }
  }, [qualityUploadPic])

  console.log(res_Data);
  console.log(qualityUploadPic);


  useEffect(() => {
    getProfileKYC(setStatus_KYC);
  }, [success, open]);

  useEffect(() => {
    if (sendStatus) {
      reCodeTime > 0 && setTimeout(() => setReCodeTime(reCodeTime - 1), 1000);
    }
    if (reCodeTime <= 0) {
      setReCode(false);
    }
  }, [reCodeTime, reCode]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (frontCard?.length > 1) {
      frontCard.slice(0, 1);
    }

    if (backCard?.length > 1) {
      backCard.slice(0, 1);
    }
  }, [frontCard, backCard]);

  const theme = {
    dialog: {
      styles: {
        sizes: {
          sm: {
            width: "w-[944px]",
            minWidth: "min-w-[944px]",
            maxWidth: "max-w-[944px]",
          },
          xs: {
            width: "w-full",
            minWidth: "min-w-full",
            maxWidth: "max-w-full",
          },
        },
      },
    },
  };

  navigator.permissions.query({ name: "camera" }).then((res) => {
    if (res.state === "granted") {
    }
  });

  return (
    <div className="px-3 flex flex-col items-start bg-[#1E1E1E] mobile:w-full desktop:max-w-full desktop:min-w-0  desktop:w-full mobile:h-full desktop:h-full mobile:gap-8 desktop:gap-[42px] desktop:mb-4">
      <div className="flex flex-col mobile:gap-3 desktop:gap-5 desktop:w-full">
        <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold">
          KYC Identity
        </p>
        <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[18px] desktop:text-[24px] font-bold">
          Process {status_KYC.lastIndexOf(true) + 2} of 4 steps
        </p>
        <div className="w-full flex mobile:flex-col desktop:flex-row desktop:justify-between mobile:gap-4">
          <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-normal">
            There are all steps in the KYC process to unlock all marketplace
            function.
          </p>
          <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-normal">
            Email : {user.user_email}
          </p>
        </div>
      </div>

      <div className="flex flex-col w-full desktop:h-[550px] mobile:h-full mobile:gap-8 desktop:gap-0">
        {KYC_LIST?.map(({ pic, label, id }, index) => (
          <div className="flex items-start flex-col mobile:w-full" key={index}>
            <div className="flex items-center justify-between w-full ">
              <div className="flex items-center desktop:gap-[48px] mobile:gap-[14px]">
                <img src={pic} alt={pic + "List"} />
                <p className="mobile:text-[14px] desktop:text-[22px] font-IBMPlex font-normal text-[#FFFFFF]">
                  {label}
                </p>
              </div>
              {status_KYC[index] || status_KYC[index] === null ? (
                <div className="mobile:w-[28%] mobile:h-[40px] flex justify-center items-center desktop:w-[150px] desktop:h-[48px] rounded-[4px]">
                  <img src={iconCorrect} alt="correct" />
                </div>
              ) : (
                <button
                  disabled={
                    (index > status_KYC.lastIndexOf(true) + 1 ||
                      status_KYC[index] ||
                      status_KYC[index] === null) &&
                    status_KYC?.length > 0
                  }
                  className={`mobile:w-[28%] mobile:h-[40px] bg-[#BB213D]   desktop:w-[150px] desktop:h-[48px] rounded-[4px] ${index > status_KYC.lastIndexOf(true) + 1 ||
                    status_KYC[index] ||
                    status_KYC[index] === null ||
                    status_KYC?.length === 0
                    ? "opacity-60"
                    : "opacity-100 hover:bg-RED001/[0.8]"
                    } `}
                  onClick={() => setOpen(id)}
                >
                  <p className="mobile:text-[10px] desktop:text-[16px] font-IBMPlex font-bold text-[#FFFFFF]">
                    {index === status_KYC.lastIndexOf(true) + 1 &&
                      status_KYC?.length > 0
                      ? "GET STARTED"
                      : "VERIFY"}
                  </p>
                </button>
              )}
            </div>
            {windowSize[0] > 768 && index < 3 && (
              <div className="w-[1px] h-[56px] bg-white opacity-50 my-6 translate-x-8"></div>
            )}
          </div>
        ))}
      </div>

      {/* Step OTP */}
      <ThemeProvider value={theme}>
        <Dialog
          open={open === "phone"}
          size={windowSize[0] > 768 ? "sm" : "xs"}
          className="bg-[#1A1A1A] relative rounded overflow-hidden button-0"
        >
          <DialogBody className="desktop:relative  flex flex-col desktop:items-center desktop:h-[395px] mobile:h-[90vh]">
            {/*Close Button  */}

            <CloseButton handleOpen={handleOpen} />
            {sendStatus ? (
              // {/* Sended OTP */ }
              <div className="w-full h-full desktop:p-10 flex flex-col desktop:items-center">
                <p className="desktop:text-[32px] text-white font-IBMPlex font-bold mobile:text-[24px]">
                  PHONE NUMBER IDENTITY
                </p>
                <div className="flex  desktop:w-[562px] h-[78px] ">
                  <div className="flex flex-col w-full">
                    <p className="font-IBMPlex text-white text-[16px] font-bold">
                      Confirm Verification
                    </p>
                    <div className="flex w-full justify-between">
                      <input
                        type="number"
                        className={`desktop:w-[398px] mobile:w-[58.8%] focus:outline-none h-12 bg-[#282828] p-[10px] text-white rounded-[4px] placeholder:text-[#7C7C7C] ${invalidOTP ? "border border-[#D80027]" : "border-none"
                          }`}
                        placeholder="Please enter 6 code"
                        value={otp_Code}
                        onChange={(e) => setOTP_Code(e?.target?.value?.slice(0, 6))}
                        onKeyDown={(e) =>
                          (e.key === "e" && e.preventDefault()) ||
                          (e.key === "-" && e.preventDefault()) ||
                          (e.key === "+" && e.preventDefault()) ||
                          (e.key === "." && e.preventDefault())
                        }
                      />
                      <button
                        disabled={reCodeTime > 0}
                        className={`desktop:w-[148px] mobile:w-[37.2%] h-[48px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold bg-[#BB213D] text-white active:bg-[#BB213D]/80  ${reCodeTime > 0
                          ? "opacity-60"
                          : "opacity-100 hover:bg-RED001/[0.8]"
                          } `}
                        onClick={() =>
                          sendOTP(
                            phoneNumber,
                            setSendStatus,
                            setReCode,
                            setReCodeTime
                          )
                        }
                      >
                        recode {reCodeTime > 0 ? `( ${reCodeTime} )` : null}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex desktop:mt-[30px] mobile:mt-4 desktop:w-[420px] justify-between ">
                  <button
                    className="desktop:w-[200px] desktop:flex  desktop:justify-center desktop:items-center desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold desktop:border-[3px] desktop:border-[#BB213D] hover:bg-GRAY666/[0.2] desktop:bg-[#1A1A1A] desktop:text-white active:bg-[#242424] mobile:hidden"
                    onClick={() => {
                      handleOpen()
                      setSendStatus(false)
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="desktop:w-[200px] desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold bg-[#BB213D] text-white hover:bg-RED001/[0.8] active:bg-[#BB213D]/80 mobile:h-[52px] mobile:w-full"
                    onClick={() =>
                      validateOTP(
                        otp_Code,
                        phoneNumber,
                        success,
                        setSuccess,
                        setOpen,
                        setInvalidOTP
                      )
                    }
                    onKeyDown={(e) => handleKeydownOTP(e)}
                  >
                    Confirm
                  </button>
                </div>
                {invalidOTP && windowSize[0] <= 768 && (
                  <p className=" font-IBMPlex text-[#FF002E] text-[16px] font-normal">
                    This will be displayed on your profile and can be seen by
                    other users. Special characters such as _or- are allowed to
                    be used or number 0-9.-*/\|&^%$#@
                  </p>
                )}
              </div>
            ) : (
              // {/* Get OTP */ }
              <div
                div
                className="w-full h-full desktop:p-10 flex flex-col desktop:items-center "
              >
                <p className="desktop:text-[32px] text-white font-IBMPlex font-bold mobile:text-[24px]">
                  PHONE NUMBER IDENTITY
                </p>
                <div className="flex justify-between desktop:w-[526px] h-[78px] w-full">
                  <div className="flex flex-col mobile:w-[26%]">
                    <p className="font-IBMPlex text-white text-[16px] font-bold">
                      Country code
                    </p>
                    {/* Select Country Code */}
                    <div
                      className="desktop:w-[112px] focus:outline-none h-12 bg-[#282828]  text-[#7C7C7C] rounded-[4px] relative cursor-pointer"
                      onClick={() =>
                        isOpen ? setIsOpen("") : setIsOpen("COUNTRY_CODE")
                      }
                    >
                      <div className="w-full h-full flex items-center">
                        <div className="flex desktop:p-2 gap-1 mobile:p-1">
                          <img src={COUNTRY_CODE[codeIndex].pic} alt="" />
                          <p className=" font-IBMPlex text-[16px] font-normal text-white mt-1">
                            {COUNTRY_CODE[codeIndex].code}
                          </p>
                        </div>
                      </div>
                      <img
                        src={icon_arrow_down}
                        alt="arrow_down"
                        className={`absolute right-[18px] top-[21px]  h-[6px] desktop:cursor-pointer ${isOpen === "COUNTRY_CODE" ? "rotate-180" : ""
                          }`}
                        onClick={() =>
                          isOpen ? setIsOpen("") : setIsOpen("COUNTRY_CODE")
                        }
                      />
                      {isOpen === "COUNTRY_CODE" && (
                        <div className="absolute top-[105%] flex flex-col w-[112px] bg-[#282828] text-[#7C7C7C] z-10">
                          {COUNTRY_CODE?.map(({ pic, val, code }, index) => (
                            <div
                              key={index}
                              className="flex px-2 py-1 gap-1 cursor-pointer hover:bg-[#242424]"
                              onClick={() => {
                                setCodeIndex(index);
                                setIsOpen("");
                              }}
                            >
                              <img src={pic} alt={val} />
                              <p className=" font-IBMPlex desktop:text-[16px] desktop:font-normal text-white mt-1">
                                {code}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Enter Phone number */}
                  <div className="flex flex-col mobile:w-[64%] desktop:w-[398px]">
                    <p className="font-IBMPlex text-white text-[16px] font-bold">
                      Mobile phone number
                    </p>
                    <input
                      type="number"
                      className="desktop:w-[398px] focus:outline-none h-12 bg-[#282828] p-[10px] text-[white] rounded-[4px] placeholder:text-[#7C7C7C]"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e?.target?.value)}
                      onKeyDown={(e) => {
                        handleKeydownOTP(e);
                      }}
                    />
                  </div>
                </div>
                <div className="flex desktop:mt-[30px] mobile:mt-4 desktop:w-[420px] desktop:justify-between ">
                  <button
                    className="desktop:w-[200px] desktop:flex  desktop:justify-center desktop:items-center desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold desktop:border-[3px] desktop:border-[#BB213D] desktop:bg-[#1A1A1A] desktop:text-white active:bg-[#242424] hover:bg-GRAY666/[0.2] mobile:hidden"
                    onClick={handleOpen}
                  >
                    Cancel
                  </button>
                  <button
                    className="desktop:w-[200px] desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold bg-[#BB213D] hover:bg-RED001/[0.8] text-white active:bg-[#BB213D]/80 mobile:h-[52px] mobile:w-full"
                    onClick={() =>
                      sendOTP(
                        phoneNumber,
                        setSendStatus,
                        setReCode,
                        setReCodeTime
                      )
                    }
                  >
                    Confirm
                  </button>
                </div>
              </div>
            )}
          </DialogBody>
        </Dialog>
      </ThemeProvider>

      {/* Step 1-1 front*/}
      <ThemeProvider value={theme}>
        <Dialog
          open={openCardIDDialog === "front_card"}
          size={windowSize[0] > 768 ? "sm" : "xs"}
          className="bg-[#1A1A1A] relative rounded overflow-hidden button-0"
        >
          <DialogBody className="desktop:relative  flex flex-col desktop:items-center desktop:h-[761px] mobile:h-[90vh] overflow-auto">
            {/*Close Button  */}
            <CloseButton handleOpen={closeFrontUploadDialog} />
            <div className="w-full h-full desktop:p-10 flex flex-col desktop:items-center gap-5">
              <p className="desktop:text-[32px] text-white font-IBMPlex font-bold mobile:text-[24px]">
                UPLOAD FRONT ID CARD
              </p>
              <ImageUploading
                value={frontCard}
                onChange={onChangeFront}
                dataURLKey="data_url"
                maxNumber={2}
              >
                {({
                  onImageUpload,
                  onImageUpdate,
                  onImageRemove,
                  dragProps,
                }) => (
                  <div className="w-full h-full  flex flex-col desktop:items-center gap-5">
                    {!frontCard?.[0]?.data_url ? (
                      <div
                        {...dragProps}
                        className=" relative desktop:w-[400px] mobile:w-full desktop:h-[230px] mobile:h-[230px]  border-dashed border-[#BB213D] border rounded-[4px] cursor-pointer"
                        onClick={onImageUpload}
                      >
                        <div className=" absolute flex flex-col top-0 w-full h-full items-center justify-center gap-2">
                          <img src={iconUpload} alt="upload" className="" />
                          <p className="text-white font-IBMPlex font-bold text-[16px]">
                            Drag & drop files or Browse
                          </p>
                          <p className=" font-IBMPlex text-[#939393] font-normal text-[12px]">
                            Drag & drop files or Browse
                          </p>
                          <p className=" font-IBMPlex text-[#939393] font-normal text-[12px]">
                            Maximum size 50 mb
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="desktop:w-[400px] mobile:w-full desktop:h-[230px] mobile:h-[230px] border-dashed border-[#BB213D] border rounded-[4px] relative"
                        onClick={onImageUpload}
                      >
                        <img
                          src={frontCard?.[0]?.data_url}
                          alt="frontcard"
                          className="w-full h-full object-contain"
                        />
                        {/* <img src={iconLoading} alt="iconLoading" className="absolute w-8 h-8" /> */}
                      </div>
                    )}

                    {/* Loading */}

                    <p className=" font-IBMPlex text-[16px] font-bold text-white text-center">
                      Please upload a valid photo
                    </p>
                    <div className="desktop:w-[672px] mobile:w-full flex justify-between">
                      <div className="desktop:w-[200px] mobile:w-[120px] flex flex-col gap-3">
                        <div className="w-full desktop:h-[100px] mobile:h-[80px] flex justify-center items-center">
                          <img src={Front_incomplete} alt="Front_incomplete" />
                        </div>
                        <div className="w-full flex flex-col items-center gap-1">
                          <img src={Icon_Incorrect} alt="Icon_Incorrect" />
                          <p className="text-[16px] text-white font-bold font-IBMPlex">
                            Incomplete
                          </p>
                        </div>
                      </div>
                      <div className="desktop:w-[200px] mobile:w-[120px] flex flex-col gap-3">
                        <div className="w-full desktop:h-[100px] mobile:h-[80px] flex justify-center items-center">
                          <img src={Front_Blurry} alt="Front_Blurry" />
                        </div>
                        <div className="w-full flex flex-col items-center gap-1">
                          <img src={Icon_Incorrect} alt="Icon_Incorrect" />
                          <p className="text-[16px] text-white font-bold font-IBMPlex">
                            Incomplete
                          </p>
                        </div>
                      </div>
                      <div className="desktop:w-[200px] mobile:w-[120px] flex flex-col gap-3">
                        <div className="w-full desktop:h-[100px] mobile:h-[80px] flex justify-center items-center">
                          <img src={Front_Reflective} alt="Front_Reflective" />
                        </div>
                        <div className="w-full flex flex-col items-center gap-1">
                          <img src={Icon_Incorrect} alt="Icon_Incorrect" />
                          <p className="text-[16px] text-white font-bold font-IBMPlex">
                            Incomplete
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="text-white font-IBMPlex font-normal text-[12px]">
                      Files must be less than 8 MB and must be jpg, jpeg file
                      types only.
                    </p>
                    <button
                      className={`desktop:w-[400px] desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold bg-[#BB213D] hover:bg-RED001/[0.8] text-white active:bg-[#BB213D]/80 mobile:h-[52px] mobile:w-full`}
                      onClick={() => {
                        setOpenCardIDDialog("");
                        setOpen("idcard");
                        setConfirmFront(true);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                )}
              </ImageUploading>
            </div>
          </DialogBody>
        </Dialog>
      </ThemeProvider>

      {/* Step 1-1 back*/}
      <ThemeProvider value={theme}>
        <Dialog
          open={openCardIDDialog === "back_card"}
          size={windowSize[0] > 768 ? "sm" : "xs"}
          className="bg-[#1A1A1A] relative rounded overflow-hidden button-0"
        >
          <DialogBody className="desktop:relative  flex flex-col desktop:items-center desktop:h-[761px] mobile:h-[90vh] overflow-auto">
            {/*Close Button  */}
            <CloseButton handleOpen={closeBackUploadDialog} />
            <div className="w-full h-full desktop:p-10 flex flex-col desktop:items-center gap-5">
              <p className="desktop:text-[32px] text-white font-IBMPlex font-bold mobile:text-[24px]">
                UPLOAD FRONT ID CARD
              </p>
              <ImageUploading
                value={backCard}
                onChange={onChangeBack}
                dataURLKey="data_url"
                maxNumber={2}
              >
                {({
                  onImageUpload,
                  onImageUpdate,
                  onImageRemove,
                  dragProps,
                }) => (
                  <div className="w-full h-full  flex flex-col desktop:items-center gap-5">
                    {!backCard?.[0]?.data_url ? (
                      <div
                        {...dragProps}
                        className=" relative desktop:w-[400px] mobile:w-full desktop:h-[230px] mobile:h-[230px] border-dashed border-[#BB213D] border rounded-[4px] cursor-pointer"
                        onClick={onImageUpload}
                      >
                        <div className=" absolute flex flex-col top-0 w-full h-full items-center justify-center gap-2">
                          <img src={iconUpload} alt="upload" className="" />
                          <p className="text-white font-IBMPlex font-bold text-[16px]">
                            Drag & drop files or Browse
                          </p>
                          <p className=" font-IBMPlex text-[#939393] font-normal text-[12px]">
                            Drag & drop files or Browse
                          </p>
                          <p className=" font-IBMPlex text-[#939393] font-normal text-[12px]">
                            Maximum size 50 mb
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="desktop:w-[400px] mobile:w-full desktop:h-[230px] mobile:h-[230px]  border-dashed border-[#BB213D] border rounded-[4px] relative"
                        onClick={onImageUpload}
                      >
                        <img
                          src={backCard?.[0]?.data_url}
                          alt="frontcard"
                          className="w-full h-full object-contain"
                        />
                        {/* <img src={iconLoading} alt="iconLoading" className="absolute w-8 h-8" /> */}
                      </div>
                    )}

                    {/* Loading */}

                    <p className=" font-IBMPlex text-[16px] font-bold text-white text-center">
                      Please upload a valid photo
                    </p>
                    <div className="desktop:w-[672px] mobile:w-full flex justify-between">
                      <div className="desktop:w-[200px] mobile:w-[120px] flex flex-col gap-3">
                        <div className="w-full desktop:h-[100px] mobile:h-[80px] flex justify-center items-center">
                          <img src={Back_Icomplete} alt="Back_Icomplete" />
                        </div>
                        <div className="w-full flex flex-col items-center gap-1">
                          <img src={Icon_Incorrect} alt="Icon_Incorrect" />
                          <p className="text-[16px] text-white font-bold font-IBMPlex">
                            Incomplete
                          </p>
                        </div>
                      </div>
                      <div className="desktop:w-[200px] mobile:w-[120px] flex flex-col gap-3">
                        <div className="w-full desktop:h-[100px] mobile:h-[80px] flex justify-center items-center">
                          <img src={Back_Blurry} alt="Back_Blurry" />
                        </div>
                        <div className="w-full flex flex-col items-center gap-1">
                          <img src={Icon_Incorrect} alt="Icon_Incorrect" />
                          <p className="text-[16px] text-white font-bold font-IBMPlex">
                            Incomplete
                          </p>
                        </div>
                      </div>
                      <div className="desktop:w-[200px] mobile:w-[120px] flex flex-col gap-3">
                        <div className="w-full desktop:h-[100px] mobile:h-[80px] flex justify-center items-center">
                          <img src={Back_Reflectibe} alt="Front_Reflective" />
                        </div>
                        <div className="w-full flex flex-col items-center gap-1">
                          <img src={Icon_Incorrect} alt="Icon_Incorrect" />
                          <p className="text-[16px] text-white font-bold font-IBMPlex">
                            Incomplete
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="text-white font-IBMPlex font-normal text-[12px]">
                      Files must be less than 8 MB and must be jpg, jpeg file
                      types only.
                    </p>
                    <button
                      className="desktop:w-[400px] desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold bg-[#BB213D] hover:bg-RED001/[0.8] text-white active:bg-[#BB213D]/80 mobile:h-[52px] mobile:w-full"
                      onClick={() => {
                        setOpenCardIDDialog("");
                        setOpen("idcard");
                        setConfirmBack(true);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                )}
              </ImageUploading>
            </div>
          </DialogBody>
        </Dialog>
      </ThemeProvider>

      {/* Step 1-2 */}
      <ThemeProvider value={theme}>
        <Dialog
          open={open === "idcard"}
          size={windowSize[0] > 768 ? "sm" : "xs"}
          className="bg-[#1A1A1A] relative rounded overflow-hidden button-0"
        >
          <DialogBody className="desktop:relative  flex flex-col desktop:items-center desktop:h-[761px] mobile:h-[90vh] overflow-auto">
            {/*Close Button  */}
            <CloseButton handleOpen={handleOpen} />

            <div className="w-full h-full desktop:p-10 flex flex-col desktop:items-center">
              <p className="desktop:text-[32px] text-white font-IBMPlex font-bold mobile:text-[24px]">
                ID CARD / PASSPORT IDENTITY
              </p>
              <p className="w-[400px] text-center text-white font-IBMPlex font-normal desktop:text-[16px] mobile:text-[14px]">
                Make sure that ID card is complete, the image is clear, and the
                light is even when taking pictures.
              </p>
              <div className="flex flex-col items-center desktop:w-[580px] mobile:w-full gap-[20px]">
                <div className="flex desktop:flex-row mobile:flex-col w-full desktop:justify-between mobile:gap-5">
                  <div
                    className="desktop:w-[280px] desktop:h-[156px] border-dashed border-[#BB213D] border rounded-[4px] relative cursor-pointer "
                    onClick={() => {
                      setOpenCardIDDialog("front_card");
                      setOpen("");
                    }}
                  >
                    {frontCard ? (
                      <div className="w-full h-full relative">
                        <img
                          src={frontCard?.[0]?.data_url}
                          alt="frontcard"
                          className="w-full h-full object-contain"
                        />
                        {qualityUploadPic?.status === "start" && (
                          <img
                            src={iconLoading}
                            alt="iconLoading"
                            className={`absolute w-8 h-8 top-0 right-0 animate-spin`}
                          />
                        )}
                        {qualityUploadPic?.status === false && (
                          <img
                            src={Icon_Incorrect}
                            alt="Icon_Incorrect"
                            className={`absolute w-8 h-8 top-0 right-0 `}
                          />
                        )}
                        {qualityUploadPic?.status === true && (
                          <img
                            src={Icon_Correct}
                            alt="Icon_Correct"
                            className={`absolute w-8 h-8 top-0 right-0 `}
                          />
                        )}
                      </div>
                    ) : (
                      <>
                        <img
                          src={iconDragonCardID}
                          alt="dragon"
                          className=" object-cover w-full h-full opacity-30"
                        />
                        <div className=" absolute flex flex-col top-0 w-full h-full items-center justify-center gap-[22px]">
                          <img src={iconUpload} alt="upload" className="" />
                          <p className="text-white font-IBMPlex font-bold text-[16px]">
                            Front of ID card
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="desktop:w-[280px] desktop:h-[156px] border-dashed border-[#BB213D] border rounded-[4px] relative cursor-pointer "
                    onClick={() => {
                      setOpenCardIDDialog("back_card");
                      setOpen("");
                    }}
                  >
                    {backCard ? (
                      <div className="w-full h-full relative">
                        <img
                          src={backCard?.[0]?.data_url}
                          alt="frontcard"
                          className="w-full h-full object-contain"
                        />
                        {qualityUploadPic?.status === "start" && (
                          <img
                            src={iconLoading}
                            alt="iconLoading"
                            className={`absolute w-8 h-8 top-0 right-0 animate-spin`}
                          />
                        )}
                        {qualityUploadPic?.status === false && (
                          <img
                            src={Icon_Incorrect}
                            alt="Icon_Incorrect"
                            className={`absolute w-8 h-8 top-0 right-0 `}
                          />
                        )}
                        {qualityUploadPic?.status === true && (
                          <img
                            src={Icon_Correct}
                            alt="Icon_Correct"
                            className={`absolute w-8 h-8 top-0 right-0 `}
                          />
                        )}
                      </div>
                    ) : (
                      <>
                        <img
                          src={iconDragonCardID}
                          alt="dragon"
                          className=" object-cover w-full h-full opacity-30"
                        />
                        <div className=" absolute flex flex-col top-0 w-full h-full items-center justify-center gap-[22px]">
                          <img src={iconUpload} alt="upload" className="" />
                          <p className="text-white font-IBMPlex font-bold text-[16px]">
                            Back of ID card
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="w-full gap-1">
                  <p className="text-white font-IBMPlex font-normal text-[16px]">
                    ID card number
                  </p>
                  <IMaskInput
                    // {...register("id_card_number")}
                    disabled={
                      !qualityUploadPic?.status || typeof qualityUploadPic?.status === "string"
                    }
                    mask={"0-0000-00000-000"}
                    className="w-full h-12 rounded-[4px] bg-[#282828] focus:outline-none placeholder:text-[#7C7C7C] p-[10px] text-white"
                    placeholder="0-0000-00000-000"
                    onChange={handleCard_id}
                    value={card_number}
                  />
                </div>

                <div className="w-full flex desktop:flex-row mobile:flex-col gap-5 ">
                  <div className=" gap-1 desktop:w-[280px] mobile:w-full">
                    <p className="text-white font-IBMPlex font-normal text-[16px]">
                      First name
                    </p>
                    <input
                      // {...register("first_name")}
                      disabled={
                        !qualityUploadPic?.status || typeof qualityUploadPic?.status === "string"
                      }
                      type="text"
                      className="w-full h-12 rounded-[4px] bg-[#282828] focus:outline-none placeholder:text-[#7C7C7C] p-[10px] text-white"
                      placeholder="Write first name"
                      onChange={(e) => setFirst_name(e.target.value)}
                      value={first_name}
                      maxLength="100"
                    />
                  </div>
                  <div className=" gap-1 desktop:w-[280px] mobile:w-full">
                    <p className="text-white font-IBMPlex font-normal text-[16px]">
                      Last name
                    </p>
                    <input
                      // {...register("last_name")}
                      disabled={
                        !qualityUploadPic?.status || typeof qualityUploadPic?.status === "string"
                      }
                      type="text"
                      className="w-full h-12 rounded-[4px] bg-[#282828] focus:outline-none placeholder:text-[#7C7C7C] p-[10px] text-white"
                      placeholder="Write last name"
                      onChange={(e) => setLast_name(e.target.value)}
                      value={last_name}
                      maxLength="100"
                    />
                  </div>
                </div>

                <div className="w-full flex desktop:flex-row mobile:flex-col gap-5 ">
                  <div className=" gap-1 desktop:w-[280px] mobile:w-full">
                    <p className="text-white font-IBMPlex font-normal text-[16px]">
                      Birthday
                    </p>
                    <IMaskInput
                      // {...register("birthday")}
                      disabled={
                        !qualityUploadPic?.status || typeof qualityUploadPic?.status === "string"
                      }
                      mask={"00/00/0000"}
                      className="w-full h-12 rounded-[4px] bg-[#282828] focus:outline-none placeholder:text-[#7C7C7C] p-[10px] text-white"
                      placeholder="DD/MM/YYYY"
                      autofix={true}
                      onChange={(e) => setBirthday(e.target.value)}
                      value={birthday}
                    />
                  </div>
                  <div className=" gap-1 desktop:w-[280px] mobile:w-full">
                    <p className="text-white font-IBMPlex font-normal text-[16px]">
                      Laser No.
                    </p>
                    <IMaskInput
                      mask={"**0-0000000-00"}
                      // {...register("laser_no")}
                      disabled={
                        !qualityUploadPic?.status || typeof qualityUploadPic?.status === "string"
                      }
                      type="text"
                      className="w-full h-12 rounded-[4px] bg-[#282828] focus:outline-none placeholder:text-[#7C7C7C] p-[10px] text-white uppercase"
                      placeholder="JT0-0000000-00"
                      onChange={(e) => setLaser_no(e.target.value)}
                      value={laser_no}
                      onKeyDown={(e) =>
                        (e?.key?.length === 1 && e.key.match(/[^A-z0-9]/g)) && e.preventDefault()
                      }
                    />
                  </div>
                </div>

                <div className="w-full relative flex justify-center items-center">
                  {step1_2_Success === "start" && (
                    <img
                      src={iconLoading}
                      alt="iconLoading"
                      className={` w-8 h-8 top-0 right-0 animate-spin`}
                    />
                  )}
                  {step1_2_Success === false && (
                    <img
                      src={Icon_Incorrect}
                      alt="Icon_Incorrect"
                      className={` w-8 h-8 top-0 right-0 `}
                    />
                  )}
                  {step1_2_Success === true && (
                    <img
                      src={Icon_Correct}
                      alt="Icon_Correct"
                      className={` w-8 h-8 top-0 right-0 `}
                    />
                  )}
                </div>

                <div className="flex desktop:mt-[0px] mobile:mt-4 desktop:w-[420px] desktop:justify-between mobile:w-full">
                  <button
                    className="desktop:w-[200px] desktop:flex  desktop:justify-center desktop:items-center desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold desktop:border-[3px] desktop:border-[#BB213D] hover:bg-GRAY666/[0.2] desktop:bg-[#1A1A1A] desktop:text-white active:bg-[#242424] mobile:hidden"
                    onClick={handleOpen}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={
                      !qualityUploadPic?.status || typeof qualityUploadPic?.status === "string"
                    }
                    className={`desktop:w-[200px] desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold bg-[#BB213D]  text-white  mobile:h-[52px] mobile:w-full ${!qualityUploadPic?.status || typeof qualityUploadPic?.status === "string"
                      ? "opacity-60"
                      : "opacity-100 hover:bg-RED001/[0.8] active:bg-[#BB213D]/80"
                      }`}
                    onClick={() => {
                      let err = [];
                      const fromValue = {
                        first_name: !!first_name
                          ? first_name
                          : err.push("first_name"),
                        last_name: !!last_name
                          ? last_name
                          : err.push("last_name"),
                        id_card_number: !!card_number
                          ? card_number?.split("-").join("")
                          : err.push("card_number"),
                        birthday: !!birthday
                          ? Number(birthday?.split("/").join(""))
                          : err.push("birthday"),
                        laser_no: !!laser_no
                          ? laser_no?.split("-").join("")
                          : err.push("laser_no"),
                      };
                      // step1_2(fromValue, setOpen, setStep1_2_Success)
                      if (err?.length === 0) {
                        step1_2(fromValue, setOpen, setStep1_2_Success);
                        setStep1_2_Success("start");
                      } else {
                        setErr_step1_2([...err])
                        setStep1_2_Success(false);
                      };
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </DialogBody>
        </Dialog>
      </ThemeProvider>

      {/* step 2 */}
      <Step_2
        theme={theme}
        open={open}
        windowSize={windowSize}
        handleOpen={handleOpen}
        setOpenNotifyStep2={setOpenNotifyStep2}
        setErrorMessage={setErrorMessage}
        setSuccess3Step={setSuccess3Step}
        setPendingPicture={setPendingPicture}
      />
      <Step_Bookbank
        theme={theme}
        open={open}
        windowSize={windowSize}
        handleOpen={handleOpen}
        setOpen={setOpen}
        setSuccess4Step={setSuccess4Step}
      />
      <NotifyStepFacerecognition
        theme={theme}
        windowSize={windowSize}
        openNotifyStep2={openNotifyStep2}
        handleOpenNotifyStep2={handleOpenNotifyStep2}
        errorMessage={errorMessage}
      />

      {/* loading pendding picture */}
      <BaseDialog open={pendingPicture} stylesDialog="desktop:min-w-[45%]">
        <div className="w-[100%] flex flex-col desktop:gap-6 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            Face recognition in process
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
            <div className="flex justify-center">
              <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                please wait a moment The system is currently verifying your
                information
              </p>
            </div>
          </DialogBody>
        </div>
      </BaseDialog>

      {/* complete Buyer */}
      <BaseDialog open={success3Step} stylesDialog="desktop:min-w-[45%]">
        <div className="w-[100%] flex flex-col desktop:gap-6 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            3 STEPS COMPLETED (BUYER)
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
            <div className="flex justify-center">
              <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                You have completed 3 steps of the KYC and the system will take
                approximately 3 days to verify.
              </p>
            </div>
            <div className="flex justify-center desktop:gap-5">
              <div className="desktop:w-[60%] mobile:w-[100%] ">
                <SubmitButton
                  text="OK"
                  styleBtn="mobile:rounded-none mobile:rounded-br desktop:rounded"
                  onClick={() => setSuccess3Step(false)}
                />
              </div>
            </div>
          </DialogBody>
        </div>
      </BaseDialog>

      {/* complete Seller */}
      <BaseDialog open={success4Step} stylesDialog="desktop:min-w-[45%]">
        <div className="w-[100%] flex flex-col desktop:gap-6 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            ALL STEPS COMPLETED (SELLER)
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
            <div className="flex justify-center">
              <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                You have completed all steps of the KYC and the system will take
                approximately 3 days to verify.
              </p>
            </div>
            <div className="flex justify-center desktop:gap-5">
              <div className="desktop:w-[60%] mobile:w-[100%] ">
                <SubmitButton
                  text="OK"
                  styleBtn="mobile:rounded-none mobile:rounded-br desktop:rounded"
                  onClick={() => setSuccess4Step(false)}
                />
              </div>
            </div>
          </DialogBody>
        </div>
      </BaseDialog>
    </div>
  );
};

export default KYC;
