import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
const Authorization = Cookies.get("access_token")


export const sendOTP = async (phoneNumber, setSendStatus, setReCode, setReCodeTime) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_SMS_OTP, {
        phone: phoneNumber?.toString(),
        ref: "12333"
    }, {
        headers: {
            Authorization: `Bearer ${Authorization}`
        }
    })
        .then((res) => {
            setSendStatus(true)
            setReCode(true)
            setReCodeTime(15)

        })
        .catch((err) => {
        })
}

export const validateOTP = async (otp_Code, phoneNumber, success, setSuccess, setOpen, setInvalidOTP) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_VALIDATE_OTP, {
        otp_code: otp_Code?.toString(),
        phone: phoneNumber.toString()
    }, {
        headers: {
            Authorization: `Bearer ${Authorization}`
        }
    })
        .then((res) => {
            step_OTP(success, setSuccess, setOpen)
        })
        .catch((err) => {
            setInvalidOTP(true)
        })
}

export const step_OTP = async (success, setSuccess, setOpen) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_PSOT_STEP_OTP_KYC, {},
        {
            headers: {
                Authorization: `Bearer ${Authorization}`
            }
        })
        .then((res) => {
            setSuccess(!success)
            setOpen("")
        })
        .catch((err) => {
        })
}

export const UploadPicMultiple = (
    frontCard,
    backCard,
    setStep1_1_Success,
    setRes_Data,
    setQuantityUploadPic
) => {
    let formdata = new FormData();
    formdata.append("files", frontCard[0].file);
    formdata.append("files", backCard[0].file);
    uploadmultiple(formdata,
        setStep1_1_Success,
        setRes_Data,
        setQuantityUploadPic
    );
}

const uploadmultiple = async (formdata,
    setStep1_1_Success,
    setRes_Data,
    setQuantityUploadPic
) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_UPLOAD_MULTIPLE,
        formdata
        ,
        {
            headers: {
                accept: "application/json",
                "Content-Type":
                    "multipart/form-data; boundary=<calculated when request is sent>",
                Authorization: `Bearer ${Authorization}`
            }
        })
        .then((res) => {
            step1_1(res.data.upload,
                setStep1_1_Success,
                setRes_Data,
                setQuantityUploadPic
            )

            setStep1_1_Success("start")
            setQuantityUploadPic((prev) => ({
                ...prev, status: "start"
            }))
        })
        .catch((err) => {
        })
}

export const step1_1 = async (dataUpload,
    setStep1_1_Success,
    setRes_Data,
    setQuantityUploadPic
) => {

    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_KYC_STEP1_1,
        {
            front_id_card: dataUpload.img1,
            back_id_card: dataUpload.img2
        },
        {
            headers: {
                Authorization: `Bearer ${Authorization}`
            }
        })
        .then((res) => {
            setStep1_1_Success(true)
            setRes_Data({
                crad_number: res?.data?.data?.citizen_id?.toString(),
                first_name: res?.data?.data?.first_name?.toString(),
                last_name: res?.data?.data?.last_name?.toString(),
                birthday: res?.data?.data?.birth_day?.toString(),
                laser_no: res?.data?.data?.laser_code?.toString()
            })
            setQuantityUploadPic((prev) => ({
                ...prev,
                send: prev?.send + 1,
                status: prev?.upload - prev?.send === 1 ? true : "start"
            }))
            // setCard_number(res?.data?.data?.citizen_id?.toString())
            // setFirst_name(res?.data?.data?.first_name?.toString())
            // setLast_name(res?.data?.data?.last_name?.toString())
            // setBirthday(res?.data?.data?.birth_day?.toString())
            // setLaser_no(res?.data?.data?.laser_code?.toString())
        })
        .catch((err) => {
            setStep1_1_Success(false)
            setQuantityUploadPic((prev) => ({
                ...prev,
                send: prev?.send + 1,
                status: false,
            }))
        })
}

export const step1_2 = async (fromValue, setOpen, setStep1_2_Success) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_KYC_STEP1_2, {
        CitizenID: Number(fromValue.id_card_number),
        FirstName: fromValue.first_name,
        LastName: fromValue.last_name,
        BEBirthDate: Number(fromValue.birthday),
        LaserCode: fromValue.laser_no
    }, {
        headers: {
            Authorization: `Bearer ${Authorization}`
        }
    })
        .then((res) => {
            setOpen("")
            setStep1_2_Success(true)
        })
        .catch((err) => {
            setStep1_2_Success(false)
        })
}

const faceRecognitionFront = async (front_img_url, setFrontSuccess) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_KYC_STEP2_Front, {
        file: front_img_url
    }, {
        headers: {
            Authorization: `Bearer ${Authorization}`
        }
    })
        .then((res) => {
            setFrontSuccess(true)
        })
        .catch((err) => {
            console.error(err);
        })
}

const faceRecognitionLetf = async (left_img_url, setLetfSuccess) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_KYC_STEP2_Left, {
        file: left_img_url
    }, {
        headers: {
            Authorization: `Bearer ${Authorization}`
        }
    })
        .then((res) => {
            setLetfSuccess(true)
        })
        .catch((err) => {
            console.error(err);
        })
}

const faceRecognitionRight = async (right_img_url, setRightSuccess) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_KYC_STEP2_Right, {
        file: right_img_url
    }, {
        headers: {
            Authorization: `Bearer ${Authorization}`
        }
    })
        .then((res) => {
            setRightSuccess(true)
        })
        .catch((err) => {
            console.error(err);
        })
}

export const uploadPicture = (picture, handleOpen, status, setFrontSuccess, setLetfSuccess, setRightSuccess, setOpenNotifyStep2, setErrorMessage, setPendingPicture) => {


    faceRecognitionFront(picture[0], setFrontSuccess)
    faceRecognitionLetf(picture[1], setLetfSuccess)
    faceRecognitionRight(picture[2], setRightSuccess)
    if (!status?.includes(false)) {
        step2FaceRecognition(handleOpen, setOpenNotifyStep2, setErrorMessage, setPendingPicture)
    }
}


const step2FaceRecognition = async (handleOpen, setOpenNotifyStep2, setErrorMessage, setPendingPicture) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_KYC_STEP2, {},
        {
            headers: {
                Authorization: `Bearer ${Authorization}`
            }
        })
        .then((res) => {
            let str = ''
            if (!res.data.front_verified) { str += ' front view' }
            if (!res.data.left_verified) { str += ' letf view' }
            if (!res.data.right_verified) { str += ' right view' }

            if (res.data.status !== 'Fail') {
                handleOpen()
                setPendingPicture(false)
            } else {
                setOpenNotifyStep2(true)
                setPendingPicture(false)
                setErrorMessage(str)

            }
        })
        .catch(() => {
            let str = ''
            setOpenNotifyStep2(true)
            setPendingPicture(false)
            setErrorMessage(str)
        })
}

export const uploadBookBank = async (bookBank, fromValue, bankName, setOpen, setSuccess4Step) => {
    let formdata = new FormData();
    formdata.append("files", bookBank[0].file);

    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_UPLOAD_MULTIPLE,
        formdata
        ,
        {
            headers: {
                accept: "application/json",
                "Content-Type":
                    "multipart/form-data; boundary=<calculated when request is sent>",
                Authorization: `Bearer ${Authorization}`
            }
        })
        .then((res) => {
            step_bookbank(res.data.upload, fromValue, bankName, setOpen, setSuccess4Step)
        })
        .catch((err) => {
        })
}

const step_bookbank = async (image, fromValue, bankName, setOpen, setSuccess4Step) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_POST_STEP_BOOKBANK_KYC, {
        bank_firstname: fromValue.first_name,
        bank_lastname: fromValue.last_name,
        bank_number: fromValue.account_number,
        bank_name: bankName,
        bank_image_front: image.img1
    }, {
        headers: {
            Authorization: `Bearer ${Authorization}`
        }
    })
        .then((res) => {
            setOpen("")
            setSuccess4Step(true)
        })
        .catch((err) => {

        })
}