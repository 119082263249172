import Header from "./component/layout/Header";
import Footer from "./component/layout/Footer";
import NavBar from "./component/layout/NavBar";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNewRefresh_token, checkRefresh } from "./api/post/home";
import { checkEmailVerify } from "./api/get/login";
import Cookies from "js-cookie";

function App() {
  const [open, setOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [openFooter, setOpenFooter] = useState(true);
  const [activeNav, setActiveNav] = useState("0");
  const [isProfile, setIsProfile] = useState(false);
  const [pathRedirect, setPathRedirect] = useState('/login')
  const user = useSelector((state) => state.user);
  const auth = Cookies.get("access_token");
  const location = useLocation()
  const path = location.pathname?.split('/')[1]
  console.log(location);
  console.log(path);
  const pathProtect = ['setting', 'profile',]
  const navigation = useNavigate()

  // const ProtectedRoute = () => {

  // }

  useEffect(() => {
    if (!auth) {
      setPathRedirect('/login')
    } else {
      if (!user?.email_verification_status) {
        setPathRedirect('/register?stage=verify_email')
        navigation(`/register?stage=verify_email`)

      } else if (!user?.display_Name) {
        setPathRedirect('/register/create_displayname')
        navigation(`/register/create_displayname`)

      }
    }

    // ProtectedRoute()
  }, [location])

  useEffect(() => {
    if (!!user.access_token && !auth) {
      checkRefresh(user);
    }
  }, []);

  useEffect(() => {
    if (!!auth) {
      checkEmailVerify();
      // window.location.reload(true)
    }
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "ze-snippet";
    script.src =
      "https://static.zdassets.com/ekr/snippet.js?key=7acb18cd-8e9c-4d36-b444-434b06bdb2af";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!auth && pathProtect?.includes(path)) {
    return <Navigate to={pathRedirect} />
  }

  return (
    <div className="w-full h-full bg-GRAY650 flex flex-col items-center relative">
      <Header
        setFocus={setOpen}
        activeNav={activeNav}
        setActiveNav={setActiveNav}
        isProfile={isProfile}
        setIsProfile={setIsProfile}
        setOpenFooter={setOpenFooter}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <NavBar
        setFocus={setOpen}
        setFooter={setOpenFooter}
        activeNav={activeNav}
        setActiveNav={setActiveNav}
        isProfile={isProfile}
        setIsProfile={setIsProfile}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <div className="flex justify-center w-full desktop:min-h-[calc(100%-683px)] mobile:min-h-[calc(100vh-4.5rem)]">
        <Outlet context={open} />
      </div>
      <div className="w-full">{openFooter ? <Footer /> : ""}</div>
    </div>
  );
}

export default App;
