import React, { useState } from "react";
import InfoIcon from "../../../../assets/images/payment/Icon.png";
import QRCode from "../../../../assets/images/payment/QRCode.svg";
import KbankComingSoon from "../../../../assets/images/payment/KbankComingSoon.png";
import SCBComingSoon from "../../../../assets/images/payment/SCBComingSoon.png";
import MastercardComingSoon from "../../../../assets/images/payment/mastercardComingSoon.png";
import VisaComingSoon from "../../../../assets/images/payment/visaComingSoon.png";
import { changePxToRem } from "../../../../utils/changePixeltoRem";
import { BorderButton, SubmitButton } from "../../../Button";
import SelectEnterCode from "./SelectEnterCode";
import {
  Grid,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import {
  RED001,
  GRAY750,
  GRAY760,
  GRAY910,
  RED003,
} from "../../../../theme/color";
import { useNavigate } from "react-router-dom";

const SelectGatewayPayment = ({
  amountPrice,
  gatewayFee,
  setNameGateway,
  onClickCheckoutItem,
}) => {
  const navigate = useNavigate();
  const [valueNamePayment, setValueNamePayment] = useState("QRCode");

  const handleChange = (event) => {
    setValueNamePayment(event.target.value);
    setNameGateway(event.target.value === "QRCode" ? "QR" : event.target.value);
  };

  return (
    <div>
      {/* Select Gateway Payment Mobile */}
      <div className="desktop:hidden mobile:flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2  ">
            <p className="text-2xl font-bold text-white pl-1">Cart Order</p>
            <p className="text-sm font-[500] text-white pl-1">
              Please select a gateway
            </p>
          </div>
          <div>
            <div className="bg-GRAY700  flex flex-col  rounded">
              <div className="bg-GRAY700  flex flex-col   rounded-sm">
                <FormControl
                  sx={{
                    width: "100%",
                    margin: `${changePxToRem(10)} ${changePxToRem(
                      0
                    )} ${changePxToRem(0)} ${changePxToRem(0)}`,
                  }}
                >
                  <RadioGroup
                    name="radio-buttons-group"
                    aria-labelledby="radio-buttons-group-label"
                    value={valueNamePayment}
                    onChange={handleChange}
                  >
                    <Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: changePxToRem(20),
                          padding: `${changePxToRem(10)} ${changePxToRem(
                            30
                          )} ${changePxToRem(20)} ${changePxToRem(30)}`,
                          borderBottom: `1px solid  ${GRAY750}`,
                        }}
                      >
                        <Grid>
                          <p className="text-base font-[700] text-white">
                            QR Code (Free)
                          </p>
                        </Grid>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: changePxToRem(12),
                          }}
                        >
                          <GatewaySelectPayment
                            value="QRCode"
                            image={QRCode}
                            nameGateway="Gmillion"
                            CountTime={10}
                            price={amountPrice}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: changePxToRem(20),
                          padding: `${changePxToRem(20)} ${changePxToRem(30)}`,
                          borderBottom: `1px solid  ${GRAY750}`,
                        }}
                      >
                        <Grid>
                          <p className="text-base font-[700] text-white">
                            Online Banking
                          </p>
                        </Grid>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: changePxToRem(12),
                          }}
                        >
                          <GatewaySelectPayment
                            value="SCBBank"
                            image={SCBComingSoon}
                            nameGateway="SCB Bank"
                            CountTime={10}
                            price={amountPrice}
                          />
                          <GatewaySelectPayment
                            value="KBank"
                            image={KbankComingSoon}
                            nameGateway="K Bank"
                            CountTime={10}
                            price={amountPrice}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: changePxToRem(20),
                          padding: `${changePxToRem(20)} ${changePxToRem(30)}`,
                          //   borderBottom: `1px solid  ${GRAY750}`,
                        }}
                      >
                        <Grid>
                          <p className="text-base font-[700] text-white">
                            Credit / Debit cards
                          </p>
                        </Grid>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: changePxToRem(12),
                          }}
                        >
                          <GatewaySelectPayment
                            value="mastercard"
                            image={MastercardComingSoon}
                            nameGateway="Mastercard"
                            CountTime={10}
                            price={amountPrice}
                          />
                          <GatewaySelectPayment
                            value="visa"
                            image={VisaComingSoon}
                            nameGateway="Visa"
                            CountTime={10}
                            price={amountPrice}
                          />
                        </Grid>
                      </Grid>
                      {/* <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: changePxToRem(20),
                        padding: `${changePxToRem(20)} ${changePxToRem(30)}`,
                        paddingBottom: changePxToRem(5),
                      }}
                    >
                      <Grid>
                        <p
                          type={16}
                          fontWeight={700}
                          color="white"
                          sx={{
                            lineHeight: changePxToRem(26),
                          }}
                        >
                          E-Wallet
                        </p>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: changePxToRem(12),
                        }}
                      >
                        <GatewaySelectPayment
                          value="TrueMoney"
                          image="/payment/TrueMoneyComingSoon.png"
                          nameGateway="True Money"
                          CountTime={10}
                          price={amountPrice}
                          //   data={data}
                        />
                      </Grid>
                    </Grid> */}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <SelectEnterCode />

          <div className="flex justify-between items-center pt-3 border-t border-GRAY636">
            <p className="text-base font-[700] text-white">Total</p>
            <p className="text-2xl font-[700] text-white">
              {Number(amountPrice + gatewayFee).toLocaleString()}&nbsp;THB
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-3 grow pt-3">
          <SubmitButton text="confirm" onClick={onClickCheckoutItem} />
          <BorderButton
            text="cancel"
            onClick={() => navigate("/", { replace: true })}
          />
        </div>
      </div>

      {/* Select Gateway Payment Desktop */}
      <div className="mobile:hidden desktop:flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div>
            <div className="bg-GRAY700  flex flex-col  rounded">
              <div className="py-6 px-8 flex flex-col gap-3 rounded-sm  border-b-[1px] border-GRAY750">
                <p className="text-base font-[500] text-white">
                  Please select a gateway
                </p>
              </div>
              <FormControl
                sx={{
                  width: "100%",
                  height: "470px",
                  margin: `${changePxToRem(10)} ${changePxToRem(
                    0
                  )} ${changePxToRem(0)} ${changePxToRem(0)}`,

                  //---- custom scroll bar
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: 4,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: GRAY910,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: GRAY760,
                  },
                }}
              >
                <RadioGroup
                  name="radio-buttons-group"
                  aria-labelledby="radio-buttons-group-label"
                  value={valueNamePayment}
                  onChange={handleChange}
                >
                  <Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: changePxToRem(20),
                        padding: `${changePxToRem(10)} ${changePxToRem(
                          30
                        )} ${changePxToRem(20)} ${changePxToRem(30)}`,
                        borderBottom: `1px solid  ${GRAY750}`,
                      }}
                    >
                      <Grid>
                        <p className="text-base font-[700] text-white">
                          QR Code (Free)
                        </p>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: changePxToRem(12),
                        }}
                      >
                        <GatewaySelectPayment
                          value="QRCode"
                          image={QRCode}
                          nameGateway="QR Code"
                          CountTime={10}
                          price={amountPrice}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: changePxToRem(20),
                        padding: `${changePxToRem(20)} ${changePxToRem(30)}`,
                        borderBottom: `1px solid  ${GRAY750}`,
                      }}
                    >
                      <Grid>
                        <p className="text-base font-[700] text-white">
                          Online Banking
                        </p>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: changePxToRem(12),
                        }}
                      >
                        <GatewaySelectPayment
                          value="SCBBank"
                          image={SCBComingSoon}
                          nameGateway="SCB Bank"
                          CountTime={10}
                          price={amountPrice}
                        />
                        <GatewaySelectPayment
                          value="KBank"
                          image={KbankComingSoon}
                          nameGateway="K Bank"
                          CountTime={10}
                          price={amountPrice}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: changePxToRem(20),
                        padding: `${changePxToRem(20)} ${changePxToRem(30)}`,
                        // borderBottom: `1px solid  ${GRAY750}`,
                      }}
                    >
                      <Grid>
                        <p className="text-base font-[700] text-white">
                          Credit / Debit cards
                        </p>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: changePxToRem(12),
                        }}
                      >
                        <GatewaySelectPayment
                          value="mastercard"
                          image={MastercardComingSoon}
                          nameGateway="Mastercard"
                          CountTime={10}
                          price={amountPrice}
                        />
                        <GatewaySelectPayment
                          value="visa"
                          image={VisaComingSoon}
                          nameGateway="Visa"
                          CountTime={10}
                          price={amountPrice}
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: changePxToRem(20),
                        padding: `${changePxToRem(20)} ${changePxToRem(30)}`,
                        paddingBottom: changePxToRem(5),
                      }}
                    >
                      <Grid>
                        <p
                          type={16}
                          fontWeight={700}
                          color="white"
                          sx={{
                            lineHeight: changePxToRem(26),
                          }}
                        >
                          E-Wallet
                        </p>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: changePxToRem(12),
                        }}
                      >
                        <GatewaySelectPayment
                          value="TrueMoney"
                          image="/payment/TrueMoneyComingSoon.png"
                          nameGateway="True Money"
                          CountTime={10}
                          price={amountPrice}
                          //   data={data}
                        />
                      </Grid>
                    </Grid> */}
                  </Grid>
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectGatewayPayment;

const GatewaySelectPayment = ({
  value,
  image,
  nameGateway,
  price,
  CountTime,
}) => {
  return (
    <div className="flex flex-wrap items-center">
      <div className="grow">
        <div className="flex items-center">
          <FormControlLabel
            value={value}
            control={
              <Radio
                sx={{
                  color: RED001,
                  margin: "0px",
                  "& .MuiSvgIcon-root": {},
                  "&.Mui-disabled": {
                    color: RED003,
                  },
                }}
                color="error"
                disabled={value === "QRCode" ? false : true}
              />
            }
            sx={{ marginRight: { xs: "0px", md: "10px" } }}
          />
          <div className="flex items-center gap-3">
            <img src={image} alt={nameGateway} width={36} height={36} />
            <p
              className={`desktop:text-base mobile:text-sm desktop:font-[500] mobile:font-[400] desktop:w-[5.5rem] mobile:w-[4.5rem] ${
                nameGateway === "QR Code" || nameGateway === "Gmillion"
                  ? "text-white"
                  : "text-GRAY920"
              }`}
            >
              {nameGateway}
            </p>
          </div>
        </div>
      </div>

      <div className="grow">
        <div className="flex justify-center">
          <p
            className={`desktop:text-xl mobile:text-sm font-[700] mr-3 ${
              nameGateway === "QR Code" || nameGateway === "Gmillion"
                ? "text-white"
                : "text-GRAY920"
            }`}
          >
            {nameGateway === "QR Code" || nameGateway === "Gmillion"
              ? price
                ? `${Number(price).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })} THB`
                : "-"
              : "-"}
          </p>
        </div>
      </div>

      <div className="grow">
        <div className="flex justify-end items-center gap-2">
          <p className="desktop:text-base mobile:text-[0.625rem] font-[400] text-GRAY920 text-end">
            {nameGateway === "QR Code" || nameGateway === "Gmillion"
              ? `in ${CountTime} mins`
              : "Coming Soon"}
          </p>
          <img
            src={InfoIcon}
            alt="InfoIcon"
            className="desktop:w-5 desktop:h-5 mobile:w-4 mobile:h-4"
          />
        </div>
      </div>
    </div>
  );
};
