import React from "react";
import { Link } from "react-router-dom";

const FooterLRF = () => {
  return (
    <div className="flex desktop:flex-row mobile:flex-col-reverse  mobile:bottom-4 w-full text-black z-10 font-IBMPlex mobile:px-[2vw] desktop:pl-[3.5vw] desktop:gap-[22vw] mobile:gap-2">
      <div className="text-white desktop:text-base mobile:text-xs desktop:block mobile:flex mobile:justify-center desktop:font-[500] mobile:font-[400] ">
        <p className="text-center">@2022 RetoHub. All rights reserved.</p>
      </div>

      <div className=" flex justify-center text-white font-bold  desktop:gap-5  mobile:justify-center  ">
        <Link
          to="/privacy_policy"
          className="text-center mobile:mr-[3vw] desktop:mr-0 mobile:text-[0.62 5rem]"
        >
          <p className="desktop:text-base mobile:text-[0.625rem]">
            Terms of Conditions
          </p>
        </Link>

        <Link
          to="/term_policy"
          className="text-center mobile:mr-[3vw] desktop:mr-0"
        >
          <p className="desktop:text-base mobile:text-[0.625rem]">
            Privacy Policy
          </p>
        </Link>

        <Link
          to="/comingsoon"
          className="text-center mobile:mr-[3vw] desktop:mr-0"
        >
          <p className="desktop:text-base mobile:text-[0.625rem]">
            Copyright Policy
          </p>
        </Link>

        <Link
          to="/comingsoon"
          className="text-center mobile:mr-[3vw] desktop:mr-0"
        >
          <p className="desktop:text-base mobile:text-[0.625rem]">
            Cookie Policy
          </p>
        </Link>
      </div>
    </div>
  );
};

export default FooterLRF;
