import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setDisplay } from "../slice/goBackBtnSlice";

const TopUpPage = () => {
  const lock = useRef(false);
  const goBack = useDispatch();

  useEffect(() => {
    if (lock.current) {
      goBack(setDisplay(false));
    }

    return () => (lock.current = true);
  }, []);

    return (
        <div className="w-full mobile:h-screen bg-GRAY111">
          <div className="p-4">
            <h2 className="text-white font-IBMPlex text-[24px] font-bold">COMING SOON</h2>
            <span className="text-white text-[14px] font-IBMPlex">These features are currently planned in development process.</span>
          </div>
        </div>
    )
}

export default TopUpPage