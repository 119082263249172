import { createSlice } from "@reduxjs/toolkit";

const category = [
    { name: "Account", id: 2 },
    { name: "Gold", id: 3 },
    { name: "Item", id: 1 },
    { name: "Service", id: 4 },
]

export const marketplaceSlice = createSlice({
    name: 'market',
    initialState: {
        game_name: null,
        user_id: null,
        category_id: null,
        gameId: null,

    },
    reducers: {
        setFilterMarletplace: (state, { payload: { game_name, category_id, gameId } }) => {
            let cateIndex = category.findIndex(cate => cate.name === category_id)
            state.category_id = category[cateIndex]?.id;
            state.gameId = gameId
            state.game_name = game_name
        },
        getUserID: (state, { payload: { user_id } }) => {
            state.user_id = user_id
        },
        removeFilter: (state) => {
            state.category_id = null;
            state.gameId = null
        }
    }
})

export const { setFilterMarletplace, removeFilter, getUserID ,removeUserID} = marketplaceSlice.actions
export default marketplaceSlice.reducer