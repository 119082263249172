import React, { useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import NoResult from "../../../common/NoResult";
import { useDispatch } from "react-redux";
import { setDisplay } from "../../../../slice/goBackBtnSlice";

const ItemDetailMobileCheckoutProgress = ({
  dataPayment,
  dataItemQC,
  filter,
}) => {
  const goBack = useDispatch();

  useEffect(() => {
    goBack(setDisplay(false));
  }, []);
  return (
    <div>
      {filter === "payment" ? (
        <div>
          {dataPayment?.data ? (
            <div>
              {dataPayment?.data?.map((props, index) => {
                return <ListItemPayment data={props} />;
              })}
            </div>
          ) : (
            <div className="flex justify-center h-[100vh]">
              <NoResult />
            </div>
          )}
        </div>
      ) : (
        <div>
          {dataItemQC?.totalItem !== 0 ? (
            <div>
              {dataItemQC?.data?.map((props, index) => {
                return <ListItemQC data={props} />;
              })}
            </div>
          ) : (
            <div className="flex justify-center h-[500px]">
              <NoResult />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ItemDetailMobileCheckoutProgress;

const ListItemPayment = ({ data }) => {
  const navigate = useNavigate();
  const progress = data?.progress;
  const orderId = data?.orderId;
  const itemName = data?.itemName;
  const transactionTrackId = data?.transactionTrackId;

  const onClickViewProgress = (
    progress,
    orderId,
    itemName,
    transactionTrackId
  ) => {
    if (progress === "Success") {
      navigate({
        pathname: "/complete",
        search: `${createSearchParams({
          itemName: itemName,
          trackId: transactionTrackId,
        })}`,
      });
    }
    if (progress === "Pending") {
      navigate({
        pathname: "/payment",
        search: `${createSearchParams({
          orderNo: orderId,
        })}`,
      });
    }
    if (progress === "Delivery") {
      navigate({
        pathname: "/delivery",
        search: `${createSearchParams({
          itemName: itemName,
          trackId: transactionTrackId,
        })}`,
      });
    }
  };
  return (
    <div className="w-full py-3 border-b border-GRAY910 last:border-none first:pt-0 flex flex-wrap gap-4">
      <div>
        <img
          src={data?.itemPic["media_url1"]}
          alt="pictureItem"
          className="w-[30vw] h-[30vw] max-w-[7.5rem] max-h-[7.5rem] rounded"
        />
      </div>

      <div className="flex flex-col grow gap-3 justify-end w-[45%] min-w-[45%]">
        <div className="flex flex-col gap-2">
          <p className={`text-xs font-[700] text-white truncate`}>
            {data?.itemName}
          </p>
          <div className="flex gap-1 items-center">
            <p
              className={`text-xs font-[700] ${
                data?.progress === "Success"
                  ? "text-GREEN050"
                  : data?.progress === "Pending"
                  ? "text-YELLOW200"
                  : data?.progress === "Canceled"
                  ? "text-GRAY920"
                  : "text-RED005"
              }`}
            >
              {`[${data.progress}]`}
            </p>
            <p className={`text-xs font-[400] text-GRAY920 truncate`}>
              {data?.itemDesc}
            </p>
          </div>
        </div>

        <div>
          {data?.progress === "Canceled" ? (
            <div className="w-full font-[700] text-sm text-RED004 text-center flex justify-center items-center cursor-default bg-black/[0.3] mobile:h-[3rem] rounded">
              <p>REFUND</p>
            </div>
          ) : (
            <div
              className={`w-full bg-RED001 flex justify-center items-center rounded  hover:bg-RED001/[0.8] desktop:h-[3.5rem] mobile:py-[0.625rem] cursor-pointer `}
              onClick={() =>
                onClickViewProgress(
                  progress,
                  orderId,
                  itemName,
                  transactionTrackId
                )
              }
            >
              <p className={`uppercase font-[700] text-sm text-white`}>View</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ListItemQC = ({ data }) => {
  //   const navigate = useNavigate();
  //   const progress = data?.status;
  //   const orderId = data?.orderId;
  //   const itemName = data?.item_name;
  //   const transactionTrackId = data?.transactionTrackId;

  //   const onClickViewProgress = (
  //     progress,
  //     orderId,
  //     itemName,
  //     transactionTrackId
  //   ) => {
  //     if (progress === "Success") {
  //       navigate({
  //         pathname: "/complete",
  //         search: `${createSearchParams({
  //           itemName: itemName,
  //           trackId: transactionTrackId,
  //         })}`,
  //       });
  //     }
  //     if (progress === "Pending") {
  //       navigate({
  //         pathname: "/payment",
  //         search: `${createSearchParams({
  //           orderNo: orderId,
  //         })}`,
  //       });
  //     }
  //     if (progress === "Delivery") {
  //       navigate({
  //         pathname: "/delivery",
  //         search: `${createSearchParams({
  //           itemName: itemName,
  //           trackId: transactionTrackId,
  //         })}`,
  //       });
  //     }
  //   };
  return (
    <div className="w-full py-3 border-b border-GRAY910 last:border-none first:pt-0 flex flex-wrap gap-4">
      <div>
        <img
          src={data?.item_media["media_url1"]}
          alt="pictureItem"
          className="w-[30vw] h-[30vw] max-w-[7.5rem] max-h-[7.5rem] rounded"
        />
      </div>

      <div className="flex flex-col grow gap-3 justify-end w-[45%] min-w-[45%]">
        <div className="flex flex-col gap-2">
          <p className={`text-xs font-[700] text-white truncate`}>
            {data?.item_name}
          </p>
          <div className="flex gap-1 items-center">
            <p
              className={`text-xs font-[700] ${
                data?.status === "Expired" ? "text-GRAY920" : "text-YELLOW200"
              }`}
            >
              {`[${data.status}]`}
            </p>
            <p className={`text-xs font-[400] text-GRAY920 truncate`}>
              {data?.item_description}
            </p>
          </div>
        </div>

        <div>
          <div
            className={`w-full bg-RED003 flex justify-center items-center rounded  hover:bg-RED003 desktop:h-[3.5rem] mobile:py-[0.625rem] cursor-default `}
          >
            <p className={`uppercase font-[700] text-sm text-white`}>View</p>
          </div>
        </div>
      </div>
    </div>
  );
};
