import React, { useState, useEffect, useRef } from "react";

const formatTime = (time) => {
  if (!time) return "";
  let minutes = time.getTimeValues().minutes;
  let seconds = time.getTimeValues().seconds;

  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;
  return minutes > 0
    ? `Recode in ${minutes} MIN ${seconds} SEC`
    : `Recode in ${seconds} SEC`;
};

const CountDownQR = ({ timeCountdown }) => {
  const [countdown, setCountdown] = useState(timeCountdown);

  return <div>{formatTime(countdown)}</div>;
};

export default CountDownQR;
