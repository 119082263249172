import React, { useState, useEffect } from "react";
import Minus from "../../../../assets/images/Icon/Minus.svg";
import Plus from "../../../../assets/images/Icon/Plus.svg";
import { changePxToRem } from "../../../../utils/changePixeltoRem";

const CountItemOrder = ({ quantity, setQuantity, item }) => {
  const onClickQuantityPlus = () => {
    if (quantity < item) {
      const plus = quantity + 1;
      setQuantity(plus);
    }
  };

  const onClickQuantityMinus = () => {
    if (quantity > 1) {
      const minus = quantity - 1;
      setQuantity(minus);
    }
  };

  return (
    <div>
      {/* CountItemOrder Mobile */}
      <div className="desktop:hidden bg-GRAY111 flex rounded">
        <div
          className={`w-5 h-5 flex justify-center items-center cursor-pointer ${
            quantity === 1 ? "" : "hover:bg-white/[0.1]"
          } rounded-l`}
          onClick={onClickQuantityMinus}
        >
          <img
            src={Minus}
            alt="IconMinus"
            style={{
              width: changePxToRem(16),
              height: changePxToRem(16),
            }}
          />
        </div>
        <div className="w-8 h-5 flex justify-center items-center">
          <p className=" font-[700] text-[0.625rem] text-white">{quantity}</p>
        </div>
        <div
          className={`w-5 h-5 flex justify-center items-center cursor-pointer ${
            quantity === item ? "" : "hover:bg-white/[0.1]"
          } rounded-r`}
          onClick={onClickQuantityPlus}
        >
          <img
            src={Plus}
            alt="IconPlus"
            style={{
              width: changePxToRem(15),
              height: changePxToRem(15),
            }}
          />
        </div>
      </div>

      {/* CountItemOrder Desktop */}
      <div className="mobile:hidden desktop:flex w-[6.8rem] h-9 bg-GRAY700  border-[1px] border-GRAY750">
        <div
          className={`w-[35%] h-full flex justify-center items-center cursor-pointer ${
            quantity === 1 ? "" : "hover:bg-white/[0.1]"
          }  border-r-[1px] border-GRAY910`}
          onClick={onClickQuantityMinus}
        >
          <img
            src={Minus}
            alt="IconMinus"
            style={{
              width: changePxToRem(10),
              height: changePxToRem(10),
            }}
          />
        </div>
        <div className="w-[40%] h-full flex justify-center items-center px-2">
          <p className=" font-[700] text-base text-white">{quantity}</p>
        </div>
        <div
          className={`w-[35%] h-full flex justify-center items-center cursor-pointer ${
            quantity === item ? "" : "hover:bg-white/[0.1]"
          } border-l-[1px] border-GRAY910`}
          onClick={onClickQuantityPlus}
        >
          <img
            src={Plus}
            alt="IconPlus"
            style={{
              width: changePxToRem(10),
              height: changePxToRem(10),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CountItemOrder;
