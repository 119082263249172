import axios from "axios"

export const searchGamesIndex  = async (value,setResults) => {
    await axios
        .get("https://retohub-api-dev.vekin.co.th/" + process.env.REACT_APP_SEARCH_GAME + `${value}`)
		.then(res => {
            const results = res.data.data.sortByName.filter((games) => {
                return (
                    value && games.game_name && games.game_name.toLowerCase().includes(value) 
                )
            })
            setResults(results);
		})
		.catch(err => console.log(err))
}

