import ImageUploading from "react-images-uploading";
import { useState } from "react";
import { icon_Upload, icon_Image } from "../../assets/createOrder";
import { iconClose } from "../../assets/setting/KYC";

const UploadItempicture = ({ pictureArray, setPictureArray }) => {

    const onChange = (imageList, addUpdateIndex) => {
        setPictureArray(imageList);
    };

    const deletePicture = (index) => {
        let newArr = [...pictureArray]
        newArr?.splice(index, 1)
        setPictureArray(newArr)
    }



    return (
        <div className="desktop:max-w-full desktop:w-full desktop:min-w-0 desktop:h-[36.25rem] mobile:h-auto gap-3 flex flex-col mobile:w-full justify-between ">
            <ImageUploading
                multiple
                value={pictureArray}
                onChange={onChange}
                maxNumber={4}
                dataURLKey="data_url"
            >
                {({ onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                    <div>
                        {!pictureArray?.[0]?.data_url ? (
                            <div
                                {...dragProps}
                                className="desktop:h-[25rem] mobile:h-[57vw] w-full rounded border-2 border-dashed border-[#BB213D] bg-[#282828] flex justify-center items-center cursor-default"
                                onClick={pictureArray?.length === 0 ? onImageUpload : null}
                            >
                                <div className="desktop:w-[18.5625rem] desktop:h-[11.625rem] flex flex-col items-center justify-center gap-3">
                                    <img src={icon_Upload} alt="icon_Upload" className="w-[4.375rem] h-[4.375rem]  object-contain" />
                                    <div className="flex flex-col items-center gap-2">
                                        <p className="text-[22px]  text-white font-bold">Drag & drop <span className="text-[#BB213D]">Files</span> or <span className="text-[#BB213D]">Browse</span></p>
                                        <p className="text-[#939393] text-[12px] font-medium">Supported formates: JPEG, PNG, GIF, MP4</p>
                                        <p className="text-[#939393] text-[12px] font-medium">Maximum size 50 mb</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="desktop:h-[25rem] group/pic1 mobile:h-[57vw] w-full bg-[#282828] relative">
                                <img src={pictureArray?.[0]?.data_url} alt="pictrue1" className="object-cover w-full h-full " />
                                <div
                                    className="absolute top-1 right-1  justify-center items-center p-2 bg-gray-700 rounded hidden group-hover/pic1:flex cursor-pointer"
                                    onClick={() => deletePicture(0)}
                                >
                                    <img src={iconClose} className=" " />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </ImageUploading>
            <ImageUploading
                multiple
                value={pictureArray}
                onChange={onChange}
                maxNumber={4}
                dataURLKey="data_url"
            >
                {({ onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                    <div className="flex w-full  justify-between gap-1 ">
                        {!pictureArray?.[1]?.data_url ? (
                            <div
                                {...dragProps}
                                onClick={pictureArray?.length === 1 ? onImageUpload : null}
                                className="desktop:h-[10rem] mobile:h-[calc(100vw/3-8px)] desktop:max-w-[13.75rme] desktop:min-w-0 desktop:w-[13.75rem] mobile:max-w-[13.75rem] mobile:min-w-0 mobile:w-full bg-[#282828] flex justify-center items-center"
                            >
                                <img src={icon_Image} alt="icon_Image" className="desktop:w-[3.375rem] desktop:h-[3.375rem] mobile:w-10 mobile:h-10 object-contain " />
                            </div>
                        ) : (
                            <div className="desktop:h-[10rem] mobile:h-[calc(100vw/3-8px)] desktop:max-w-[13.75rme] desktop:min-w-0 desktop:w-[13.75rem] mobile:max-w-[13.75rem] mobile:min-w-0 mobile:w-full  flex justify-center items-center bg-[#282828] relative group/pic2">
                                <img src={pictureArray?.[1]?.data_url} alt="pic2" className="w-full h-full object-cover" />
                                <div
                                    className="absolute top-1 right-1  justify-center items-center p-2 bg-gray-700 rounded hidden group-hover/pic2:flex cursor-pointer"
                                    onClick={() => deletePicture(1)}
                                >
                                    <img src={iconClose} className=" " />
                                </div>
                            </div>
                        )}
                        {!pictureArray?.[2]?.data_url ? (
                            <div
                                {...dragProps}
                                onClick={pictureArray?.length === 2 ? onImageUpload : null}
                                className="desktop:h-[10rem] mobile:h-[calc(100vw/3-8px)] desktop:max-w-[13.75rme] desktop:min-w-0 desktop:w-[13.75rem] mobile:max-w-[13.75rem] mobile:min-w-0 mobile:w-full bg-[#282828] flex justify-center items-center"
                            >
                                <img src={icon_Image} alt="icon_Image" className="desktop:w-[3.375rem] desktop:h-[3.375rem] mobile:w-10 mobile:h-10 object-contain " />
                            </div>
                        ) : (
                            <div className="desktop:h-[10rem] mobile:h-[calc(100vw/3-8px)] desktop:max-w-[13.75rme] desktop:min-w-0 desktop:w-[13.75rem] mobile:max-w-[13.75rem] mobile:min-w-0 mobile:w-full  flex justify-center items-center bg-[#282828] relative group/pic3">
                                <img src={pictureArray?.[2]?.data_url} alt="pic2" className="w-full h-full object-cover" />
                                <div
                                    className="absolute top-1 right-1  justify-center items-center p-2 bg-gray-700 rounded hidden group-hover/pic3:flex cursor-pointer"
                                    onClick={() => deletePicture(2)}
                                >
                                    <img src={iconClose} className=" " />
                                </div>
                            </div>
                        )}
                        {!pictureArray?.[3]?.data_url ? (
                            <div
                                {...dragProps}
                                onClick={pictureArray?.length === 3 ? onImageUpload : null}
                                className="desktop:h-[10rem] mobile:h-[calc(100vw/3-8px)] desktop:max-w-[13.75rme] desktop:min-w-0 desktop:w-[13.75rem] mobile:max-w-[13.75rem] mobile:min-w-0 mobile:w-full bg-[#282828] flex justify-center items-center"
                            >
                                <img src={icon_Image} alt="icon_Image" className="desktop:w-[3.375rem] desktop:h-[3.375rem] mobile:w-10 mobile:h-10 object-contain " />
                            </div>
                        ) : (
                            <div className="desktop:h-[10rem] mobile:h-[calc(100vw/3-8px)] desktop:max-w-[13.75rme] desktop:min-w-0 desktop:w-[13.75rem] mobile:max-w-[13.75rem] mobile:min-w-0 mobile:w-full  flex justify-center items-center bg-[#282828] relative group/pic4">
                                <img src={pictureArray?.[3]?.data_url} alt="pic2" className="w-full h-full object-cover" />
                                <div
                                    className="absolute top-1 right-1  justify-center items-center p-2 bg-gray-700 rounded hidden group-hover/pic4:flex cursor-pointer"
                                    onClick={() => deletePicture(3)}
                                >
                                    <img src={iconClose} className=" " />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </ImageUploading>




        </div>
    )
}

export default UploadItempicture