export default function Icon_guarantee(props) {
    return (
        <div {...props}>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" 
                    d="M13.5834 0.285107L15.2442 2.1905L17.7279 1.13146C18.1303 0.961075 18.5937 1.18048 18.7222 1.59814L19.48 4.05401L22.1738 4.10195C22.6093 4.10886 22.9501 4.49758 22.8964 4.93233L22.5967 7.48836L25.0389 8.63403C25.4084 8.80809 25.5685 9.25057 25.3953 9.62247C25.0625 10.3157 24.4596 11.2095 24.0518 11.8975L25.8207 13.9448C26.1063 14.2761 26.0427 14.7883 25.6873 15.0399L23.5976 16.5242L24.3848 19.1164C24.5122 19.539 24.2478 19.9761 23.8187 20.0612L21.3128 20.5628L20.9839 23.2513C20.9278 23.7117 20.4687 24.0093 20.0308 23.8743L17.5875 23.3174L16.1993 25.6379C15.9742 26.0156 15.4714 26.1151 15.1214 25.8525L13.0692 24.3127L10.8613 25.8657C10.4997 26.1194 10.0075 26.0009 9.79178 25.6173L8.54328 23.3755L5.89414 23.8925C5.44381 23.9789 5.03022 23.6403 5.01454 23.1835L4.78529 20.6676L2.15981 20.0578C1.7301 19.9586 1.48493 19.5101 1.62353 19.0929L2.44686 16.6575L0.294673 15.026C-0.0763786 14.7448 -0.0995842 14.1958 0.239671 13.8825L1.93639 12.0388L0.630078 9.66849C0.415871 9.28128 0.584314 8.79665 0.98695 8.62261L3.33446 7.60935L3.10048 4.9088C3.06052 4.44298 3.45628 4.05752 3.91391 4.10219L6.40662 4.1361L7.28518 1.57376C7.43407 1.1386 7.93233 0.944449 8.33429 1.15696L10.6203 2.21732L12.4586 0.23724C12.7704 -0.0994334 13.3023 -0.0724432 13.5836 0.284318L13.5834 0.285107ZM6.93138 13.2837C5.94843 12.2957 7.44316 10.7928 8.42657 11.7808L11.7379 15.1096L17.5368 8.64898C18.4641 7.61305 20.0338 9.0323 19.1063 10.0689L12.5973 17.3206C12.2026 17.8085 11.4731 17.8482 11.0293 17.4018L6.93182 13.2838L6.93138 13.2837Z" 
                    fill="#00B67A"
                />
            </svg>
        </div>
        
    )
}