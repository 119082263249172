import React, { useEffect } from "react";
import { SubmitButton } from "../../../Button";
import SelectEnterCode from "./SelectEnterCode";

const StickyDetailAboutAmount = ({
  amountPrice,
  gatewayFee,
  onClickCheckoutItem,
}) => {
  return (
    <div className="flex gap-6 w-full">
      <div className="w-[50%] flex flex-col justify-between">
        <div className="flex items-center justify-between">
          <p className="text-base font-[500] text-white">Sub total</p>
          <p className="text-xl font-[700] text-white">
            {Number(amountPrice).toLocaleString()}&nbsp;THB
          </p>
        </div>

        <SelectEnterCode />
      </div>

      <div className="w-[50%] pl-[2vw] flex flex-col gap-6">
        <div className="flex items-center justify-between">
          <p className="text-base font-[500] text-white">Gateway fee</p>
          <p className="text-xl font-[700] text-white">
            {Number(gatewayFee).toLocaleString()}&nbsp;THB
          </p>
        </div>
        <div className="flex flex-wrap items-center justify-between gap-2">
          <p className="text-xl font-[700] text-white">Grand&nbsp;Total</p>
          <p className="text-xl font-[700] text-GREEN050">
            {Number(amountPrice + gatewayFee).toLocaleString()}&nbsp;THB
          </p>
          <div className="grow 3xl:max-w-[11.25rem]">
            <SubmitButton
              text="CHECK OUT"
              styleBtn="px-11"
              onClick={onClickCheckoutItem}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickyDetailAboutAmount;
