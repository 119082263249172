import { Link } from "react-router-dom";

const CreateSuccess = ({ createSuccess, pictureUpload }) => {

    const refreshPage = () => {
        window.location.reload();
    }
    return (
        <div className="w-[99.8125rem] flex flex-col items-center py-[3.75rem] gap-5 font-IBMPlex mobile:mx-4">
            <div className="mobile:flex flex-col justify-start w-full desktop:hidden">
                <p className="text-white font-bold desktop:text-[32px] mobile:text-[24px]">CREATE ORDER</p>
                <p className="mobile:flex flex:hidden text-white text-base font-bold"><span className="text-[#FFD262]">Waiting</span> for admin approval!</p>
            </div>
            <div className="w-full desktop:h-[36rem] bg-[#282828] flex flex-col justify-center items-center">
                <p className="desktop:flex mobile:hidden text-white text-[32px] font-bold">WAITING FOR APPROVAL!</p>

                <div className="desktop:w-[18.75rem] desktop:h-[18.75rem] mobile:w-full  overflow-hidden flex items-center">
                    <img src={pictureUpload?.img1} alt="pictureUpload" className="w-full " />
                </div>
                <p className="desktop:text-[24px] mobile:text-[16px] font-bold text-white">Offer no. <span className="text-[#BB213D]">PD{createSuccess?.item_product_id}</span></p>
                <div className="flex desktop:flex-row mobile:flex-col-reverse w-full items-center justify-center gap-[28px] mt-7">
                    <button
                        className="desktop:w-[12.125rem] mobile:w-full desktop:h-[3rem] mobile:h-[3.25rem] rounded uppercase text-white font-bold text-[16px] border border-[#bb213d] cursor-pointer flex justify-center items-center  mobile:bg-[#111] desktop:bg-transparent hover:bg-GRAY666/[0.2] "
                        onClick={() => refreshPage()}
                    >
                        create order
                    </button>
                    <Link
                        to="/marketplace"
                        className="desktop:w-[12.125rem] mobile:w-full desktop:h-[3rem] mobile:h-[3.25rem] rounded uppercase text-white font-bold text-[16px] bg-[#bb213d] flex justify-center items-center cursor-pointer hover:bg-RED001/[0.8]"
                    >
                        go to market
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default CreateSuccess