import React, { useState, useEffect } from "react";
import DetailCheckoutProgress from "../../component/layout/checkoutProgressPage/DetailCheckoutProgress";
import {
  useMutationClientCheckoutProgress,
  useMutationClientCheckoutProgressItemQC,
} from "../../api/mutation/checkoutProgress";

const progressStatus = Object.freeze({
  ALL: "All",
  PENDING: "Pending",
  DELIVERY: "Delivery",
  SUCCESS: "Success :",
});

const CheckoutProgressPage = () => {
  const [dataCheckoutProgress, setDataCheckoutProgress] = useState();
  const [dataCheckoutProgressItemQC, setDataCheckoutProgressItemQC] =
    useState();
  const [filter, setFilter] = useState("payment");
  const [selectProgress, setSelectProgress] = useState(progressStatus.ALL);

  //----- Set Pagination
  const [page, setPage] = useState(1);
  const pageForPagination =
    filter === "payment"
      ? dataCheckoutProgress?.totalPages
      : dataCheckoutProgressItemQC?.totalPages;

  const {
    mutate: mutateCheckoutProgress,
    isSuccess: isSuccessCheckoutProgress,
  } = useMutationClientCheckoutProgress({
    onSuccess: (resp) => {
      setDataCheckoutProgress(resp?.data);
    },
    onError: () => {
      setDataCheckoutProgress("");
    },
  });

  const {
    mutate: mutateCheckoutProgressItemQC,
    isSuccess: isSuccessCheckoutProgressItemQC,
  } = useMutationClientCheckoutProgressItemQC({
    onSuccess: (resp) => {
      setDataCheckoutProgressItemQC(resp?.data);
    },
    onError: () => {
      setDataCheckoutProgress("");
    },
  });

  useEffect(() => {
    if (filter === "payment") {
      mutateCheckoutProgress({
        pageSize: 10,
        page: page,
        progress: selectProgress === "All" ? null : selectProgress,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    if (filter === "Items QC") {
      mutateCheckoutProgressItemQC({
        pageSize: 10,
        page: page,
        filter:
          selectProgress === "All"
            ? "all"
            : selectProgress === "Product QC"
            ? "qc"
            : selectProgress,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    if (filter === "expired") {
      setDataCheckoutProgress("");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [
    selectProgress,
    filter,
    page,
    mutateCheckoutProgress,
    mutateCheckoutProgressItemQC,
  ]);

  return (
    <div className="bg-GRAY111 desktop:bg-GRAY710 w-full">
      <div className=" bg-GRAY111 desktop:bg-GRAY710 w-full mobile:p-4 desktop:py-0 desktop:px-0 flex flex-col justify-between gap-4 desktop:gap-5">
        <div className="desktop:flex desktop:px-[9%] desktop:pt-10 desktop:pb-60 w-full">
          <div className="flex gap-4 items-center w-full ">
            <DetailCheckoutProgress
              filter={filter}
              setFilter={setFilter}
              selectProgress={selectProgress}
              setSelectProgress={setSelectProgress}
              progressStatus={progressStatus}
              page={page}
              setPage={setPage}
              pageForPagination={pageForPagination}
              dataCheckoutProgress={dataCheckoutProgress}
              dataCheckoutProgressItemQC={dataCheckoutProgressItemQC}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CheckoutProgressPage;
