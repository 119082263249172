import axios from "axios";
import Cookies from "js-cookie";

export const checkEmailVerify = async () => {
    await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_CHECK_VERIFY_EMAIL, {
        headers: {
            Authorization: `Bearer ${Cookies.get('access_token')}`,
        }
    })
        .then((res) => {
        })
        .catch((err) => {
        })
}