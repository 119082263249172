import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  TextField,
  FormControl,
  SwipeableDrawer,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import { GRAY700, GRAY910, GRAY920 } from "../../../../theme/color";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useMutationClientWalletUserWithdraw } from "../../../../api/mutation/security";
import { changePxToRem } from "../../../../utils/changePixeltoRem";
import bg_defaultprofile from "../../../../assets/images/MyWallet/bg_defaultprofile.jpg";
import iconSCB from "../../../../assets/images/payment/SCB.png";
import iconFee from "../../../../assets/images/complete/iconQuestion.svg";
import { SubmitButton, BorderButton } from "../../../Button";
import { useSelector } from "react-redux";
import { getStatusOpenDialogMyWallet } from "../../../../slice/myWalletSlice";
import { Link, useNavigate } from "react-router-dom";
import { useMutationClientGetMyWallet } from "../../../../api/mutation/complete";

const MyWallet = ({ onClickOpenMyWallet, onClickCloseMyWallet }) => {
  const anchor = "right";
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [openWithdrawStep1, setOpenWithdrawStep1] = useState(false);
  const [openWithdrawStep2, setOpenWithdrawStep2] = useState(false);
  const [openWithdrawStep3, setOpenWithdrawStep3] = useState(false);
  const [openEnterCode, setOpenEnterCode] = useState(false);
  const [endCode, setEndCode] = useState();
  const [myWalletData, setMyWalletData] = useState();
  const [openPopupSuccessWithdraw, setOpenPopupSuccessWithdraw] =
    useState(false);
  const [openPopupFailWithdraw, setOpenPopupFailWithdraw] = useState(false);
  const [openPopupFailWalletUserWithdraw, setOpenPopupFailWalletUserWithdraw] =
    useState(false);
  const [inputWithDrawMoney, setInputWithDrawMoney] = useState();
  const [withDrawMoneyAmount, setWithDrawMoneyAmount] = useState();
  const [bankingNumber, setBankingNumber] = useState();
  const openMyWallet = useSelector(getStatusOpenDialogMyWallet);
  const [isMobile, setIsMobile] = useState(false);

  const googleAuth = myWalletData?.data?.googleAuth;
  const totalValues = myWalletData
    ? Number(myWalletData?.data?.balance).toFixed(0)
    : 0;
  const feeCredit = 0;
  const fee = 20;
  const profilePicture = myWalletData?.data?.userProfilePicture;
  const displayName = myWalletData?.data?.userDisplayName;
  const bankingFirstName = myWalletData?.data?.banking?.firstName;
  const bankingLastName = myWalletData?.data?.banking?.lastName;

  //   dataMyWallet
  const { mutate: mutateMyWallet, isSuccess: isSuccessMyWallet } =
    useMutationClientGetMyWallet({
      onSuccess: (resp) => {
        setMyWalletData(resp);
      },
      onError: () => {},
    });

  useEffect(() => {
    if (openMyWallet === true) {
      mutateMyWallet({});
    }
  }, [openMyWallet, mutateMyWallet]);

  useEffect(() => {
    // const bankingNumber = "424-0-35856-1";
    const bankingNumber = myWalletData?.data?.banking?.bankingNumber;
    const NewBankingNumber = bankingNumber
      ? `${bankingNumber.slice(0, 1)}${bankingNumber.slice(
          1,
          2
        )}${bankingNumber.slice(2, 3)}-${bankingNumber.slice(
          4,
          5
        )}-${bankingNumber.slice(6, 7)}${bankingNumber.slice(7, 8)}XXX-X`
      : "";
    setBankingNumber(NewBankingNumber);
  }, []);

  const {
    mutate: mutateWalletUserWithdraw,
    isSuccess: isSuccessWalletUserWithdraw,
  } = useMutationClientWalletUserWithdraw({
    onSuccess: (resp) => {
      setEndCode("");
      setOpenPopupSuccessWithdraw(!openPopupSuccessWithdraw);
    },
    onError: (e) => {
      const messageErr = e?.response?.data?.message;
      setEndCode("");
      if (messageErr === "Invalid token") {
        setOpenPopupFailWithdraw(!openPopupFailWithdraw);
      }
      if (messageErr === "Insufficient balance") {
        setOpenPopupFailWalletUserWithdraw(!openPopupFailWalletUserWithdraw);
      }
    },
  });

  useEffect(() => {
    const handleWindowResize = () => {
      const isMobileScreen = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(isMobileScreen);
    };

    // Initial check when the component mounts
    handleWindowResize();

    // When isn't MobileScreen setIsTransactionPurchasePage
    if (isMobile === true) {
      if (openMyWallet) {
        navigate("/", { replace: true });
      }
    }

    // Add event listener to update the state on window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isMobile]);

  //   const {
  //     mutate: mutateVerifyGoogleAuthSecurity,
  //     isSuccess: isSuccessVerifyGoogleAuthSecurity,
  //   } = useMutationClientVerifyGoogleAuthSecurity({
  //     onSuccess: (resp) => {
  //       setEndCode("");
  //       const dataAmount = {};
  //       mutateWalletUserWithdraw(dataAmount);
  //     },
  //     onError: () => {
  //       setEndCode("");
  //     },
  //   });

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onChangeInputCodeGoogleAuth = (e) => {
    setEndCode(e.target.value);
  };

  const handleChangeWithDrawMoney = (e) => {
    setInputWithDrawMoney(e.target.value);
    setWithDrawMoneyAmount(Number(e.target.value));
  };

  const onClickConfirmWithdrawStep1 = (e) => {
    e.stopPropagation();
    if (
      withDrawMoneyAmount < 20 ||
      withDrawMoneyAmount > 100000 ||
      withDrawMoneyAmount > totalValues
    ) {
    } else {
      setOpenWithdrawStep1(!openWithdrawStep1);
      setOpenWithdrawStep2(!openWithdrawStep2);
    }
  };

  const onClickConfirmWithdrawStep2 = (e) => {
    e.stopPropagation();
    setOpenWithdrawStep2(!openWithdrawStep2);
    setOpenWithdrawStep3(!openWithdrawStep3);
  };

  const onClickConfirmWithdrawStep3 = (e) => {
    e.stopPropagation();
    if (endCode) {
      setOpenWithdrawStep3(!openWithdrawStep3);
      const dataConfirm = {
        amount: withDrawMoneyAmount,
        token: endCode,
      };
      mutateWalletUserWithdraw(dataConfirm);
    }
  };

  const onClickOpenWithdrawStep1 = (e) => {
    e.stopPropagation();
    setOpenWithdrawStep1(!openWithdrawStep1);
    setOpenEnterCode(false);
    setInputWithDrawMoney("");
    if (openWithdrawStep1 === true) {
      onClickOpenMyWallet(onClickOpenMyWallet("right", false));
      setWithDrawMoneyAmount(0);
    }
  };
  const onClickOpenWithdrawStep2 = (e) => {
    e.stopPropagation();
    setOpenWithdrawStep2(!openWithdrawStep2);
    setOpenEnterCode(false);
    setInputWithDrawMoney("");
    if (openWithdrawStep2 === true) {
      onClickOpenMyWallet(onClickOpenMyWallet("right", false));
      setWithDrawMoneyAmount(0);
    }
  };
  const onClickOpenWithdrawStep3 = (e) => {
    e.stopPropagation();
    setOpenWithdrawStep3(!openWithdrawStep3);
    setOpenEnterCode(false);
    setInputWithDrawMoney("");
    if (openWithdrawStep3 === true) {
      onClickOpenMyWallet(onClickOpenMyWallet("right", false));
      setWithDrawMoneyAmount(0);
    }
  };

  const onClickSuccessWithdraw = (e) => {
    e.stopPropagation();
    setOpenPopupSuccessWithdraw(!openPopupSuccessWithdraw);
    setWithDrawMoneyAmount(0);
    onClickOpenMyWallet(onClickOpenMyWallet("right", false));
  };

  const onClickFailWithdraw = (e) => {
    e.stopPropagation();
    setOpenPopupFailWithdraw(!openPopupFailWithdraw);
    setOpenWithdrawStep3(!openWithdrawStep3);
  };

  const onClickFailWithdrawUserWithdraw = (e) => {
    e.stopPropagation();
    setOpenPopupFailWalletUserWithdraw(!openPopupFailWalletUserWithdraw);
    setWithDrawMoneyAmount(0);
    onClickOpenMyWallet(onClickOpenMyWallet("right", false));
  };

  const onClickMaxAmount = () => {
    setWithDrawMoneyAmount(totalValues);
    setInputWithDrawMoney(totalValues);
  };

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={openMyWallet}
      onOpen={onClickCloseMyWallet}
      onClose={onClickOpenMyWallet}
      sx={{
        zIndex: 15,
        "& .MuiDrawer-paper": {
          bgcolor: GRAY700,
          boxSizing: "border-box",
          height: "100%",
          marginTop: changePxToRem(88),
        },
      }}
      className="mobile:hidden desktop:flex"
    >
      <Box
        sx={{
          width: 520,
          padding: ` ${changePxToRem(40)} ${changePxToRem(60)}`,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "auto",
        }}
        role="presentation"
        onClick={onClickCloseMyWallet}
        onKeyDown={onClickCloseMyWallet}
      >
        <Grid
          sx={{ display: "flex", alignItems: "center", gap: changePxToRem(26) }}
        >
          {profilePicture ? (
            <img
              src={profilePicture}
              alt="profilePicture"
              className="w-16 h-16 rounded-full object-cover"
            />
          ) : (
            <img
              src={bg_defaultprofile}
              alt="profilePicture"
              className="w-16 h-16 rounded-full object-cover"
            />
          )}
          <p className="text-3xl font-[700] text-white uppercase">My Wallet</p>
        </Grid>
        <Grid
          sx={{
            paddingTop: changePxToRem(40),
            paddingBottom: changePxToRem(20),
          }}
        >
          <p className="text-base font-[400] text-white">
            You need a crpto wallet to use Retohub. If you don’t have a wallet
            yet, you can select a provider and create one now.
          </p>
        </Grid>
        <Grid sx={{ paddingBottom: changePxToRem(16) }}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: `${changePxToRem(12)} ${changePxToRem(0)}`,
              borderBottom: `1px solid ${GRAY920}`,
            }}
          >
            <p className="text-base font-[700] text-white">Total Value :</p>
            <p className="text-base font-[700] text-GREEN050">
              {Math.ceil(totalValues).toLocaleString()} Bath
            </p>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: `${changePxToRem(12)} ${changePxToRem(0)}`,
            }}
          >
            {/* <p className="text-xs font-[400] text-white">Withdraw limit :</p>
            <p className="text-xs font-[400] text-white">(20-100,000 Baht)</p> */}

            <p className="text-base font-[700] text-white">Fee Credit :</p>
            <p className="text-base font-[700] text-white">
              {Math.ceil(feeCredit).toLocaleString()} Bath
            </p>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: changePxToRem(20),
          }}
        >
          {/* Online Banking */}
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChangeAccordion("panel1")}
            sx={{
              padding: "0",
              borderRadius: changePxToRem(4),
              "&.MuiAccordion-root": {
                borderRadius: changePxToRem(4),
                bgcolor: GRAY910,
              },
              "& .MuiAccordionSummary-root": {
                "&.Mui-expanded": {
                  minHeight: changePxToRem(48),
                },
              },
            }}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                bgcolor: GRAY910,
                padding: `${changePxToRem(0)} ${changePxToRem(24)}`,
                borderRadius: changePxToRem(4),
                margin: changePxToRem(0),
                "& .MuiAccordionSummary-content": {
                  margin: `${changePxToRem(10)} ${changePxToRem(0)}`,
                  "&.Mui-expanded": {
                    margin: `${changePxToRem(10)} ${changePxToRem(0)}`,
                  },
                },
              }}
            >
              <p className="text-base font-[700] text-white">Online Banking</p>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                bgcolor: "black",
                padding: `${changePxToRem(0)} ${changePxToRem(24)}`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                borderBottomLeftRadius: changePxToRem(4),
                borderBottomRightRadius: changePxToRem(4),
              }}
            >
              <Grid
                sx={{
                  padding: `${changePxToRem(0)} ${changePxToRem(0)}`,
                  paddingTop: changePxToRem(20),
                }}
              >
                {googleAuth ? (
                  <Grid
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ minWidth: "100%" }}>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value="SCB"
                          renderValue={(filter) => (
                            <Grid
                              sx={{
                                display: "flex",
                                gap: changePxToRem(10),
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={iconSCB}
                                alt="SCBIcon"
                                width={24}
                                height={24}
                              />
                              <p className="text-base font-[400] text-white">
                                SCB (ธนาคารไทยพาณิชย์)
                              </p>
                            </Grid>
                          )}
                          //   onChange={handleChange}
                          sx={{
                            bgcolor: GRAY910,
                            "& .MuiOutlinedInput-input": {
                              padding: `${changePxToRem(11)}`,
                            },
                            "& .MuiSelect-icon": {
                              color: "white",
                              fontSize: changePxToRem(35),
                            },
                          }}
                          MenuProps={{
                            sx: {
                              marginTop: changePxToRem(3),
                              "& .MuiList-root": {
                                backgroundColor: GRAY910,
                              },
                              "& .MuiPopover-paper": {
                                backgroundColor: GRAY910,
                              },
                            },
                          }}
                        >
                          <MenuItem
                            disabled
                            value="payment"
                            sx={{
                              fontSize: changePxToRem(16),
                              fontWeight: 700,
                              padding: changePxToRem(20),
                              backgroundColor: GRAY910,
                              "&:hover": {
                                backgroundColor: GRAY910,
                              },
                            }}
                          ></MenuItem>
                          {/* <MenuItem
                            value="items Qc"
                            sx={{
                              fontSize: changePxToRem(16),
                              fontWeight: 700,
                            }}
                          ></MenuItem> */}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                ) : (
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p className="text-base font-[400] text-white text-center">
                      Checking identity making Withdrawals
                      <br /> Mobile Phone (OTP) or Google Authenticator
                    </p>
                  </Grid>
                )}
              </Grid>
              <Grid
                sx={{
                  padding: `${changePxToRem(20)} ${changePxToRem(0)}`,
                  width: "100%",
                }}
              >
                {googleAuth ? (
                  <SubmitButton
                    text="Withdraw"
                    onClick={onClickOpenWithdrawStep1}
                  />
                ) : (
                  <Link
                    to={{
                      pathname: "/setting/security",
                    }}
                  >
                    <SubmitButton
                      text="GO TO security"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickOpenMyWallet(
                          onClickOpenMyWallet("right", false)
                        );
                      }}
                    />
                  </Link>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Wallet */}
          <Accordion
            disabled
            expanded={expanded === "panel2"}
            onChange={handleChangeAccordion("panel2")}
            sx={{
              padding: "0",
              borderRadius: changePxToRem(4),
              "&.MuiAccordion-root": {
                borderRadius: changePxToRem(4),
              },
              "& .Mui-disabled": {
                borderRadius: changePxToRem(4),
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <p className="text-xs font-[300] text-GRAY920">
                  Out of Service
                </p>
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                bgcolor: "black",
                padding: `${changePxToRem(0)} ${changePxToRem(24)}`,
              }}
            >
              <p className="text-base font-[700] text-white">Wallet</p>
            </AccordionSummary>
            {/* <AccordionDetails
              sx={{
                bgcolor: "black",
                padding: `${changePxToRem(0)} ${changePxToRem(24)}`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid
                sx={{
                  padding: `${changePxToRem(16)} ${changePxToRem(0)}`,
                  borderBottom: `1px solid ${GRAY920}`,
                }}
              >
                <Text
                  type={16}
                  fontWeight={700}
                  color="white"
                  sx={{
                    lineHeight: changePxToRem(25),
                    width: "100%",
                    //   padding: "0",
                  }}
                >
                  Verify your withdrawals (2FA)
                </Text>
              </Grid>
            </AccordionDetails> */}
          </Accordion>

          {/* PayPal */}
          <Accordion
            disabled
            expanded={expanded === "panel3"}
            onChange={handleChangeAccordion("panel3")}
            sx={{
              padding: "0",
              borderRadius: changePxToRem(4),
              "&.MuiAccordion-root": {
                borderRadius: changePxToRem(4),
              },
              "& .Mui-disabled": {
                borderRadius: changePxToRem(4),
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <p className="text-xs font-[300] text-GRAY920">
                  Out of Service
                </p>
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                bgcolor: "black",
                padding: `${changePxToRem(0)} ${changePxToRem(24)}`,
              }}
            >
              <p className="text-base font-[700] text-white">PayPal</p>
            </AccordionSummary>
            {/* <AccordionDetails
              sx={{
                bgcolor: "black",
                padding: `${changePxToRem(0)} ${changePxToRem(24)}`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid
                sx={{
                  padding: `${changePxToRem(16)} ${changePxToRem(0)}`,
                  borderBottom: `1px solid ${GRAY920}`,
                }}
              >
                <Text
                  type={16}
                  fontWeight={700}
                  color="white"
                  sx={{
                    lineHeight: changePxToRem(25),
                    width: "100%",
                    //   padding: "0",
                  }}
                >
                  Verify your withdrawals (2FA)
                </Text>
              </Grid>
            </AccordionDetails> */}
          </Accordion>

          {/* Credit/Debit Card */}
          <Accordion
            disabled
            expanded={expanded === "panel4"}
            onChange={handleChangeAccordion("panel4")}
            sx={{
              padding: "0",
              borderRadius: changePxToRem(4),
              "&.MuiAccordion-root": {
                borderRadius: changePxToRem(4),
              },
              "& .Mui-disabled": {
                borderRadius: changePxToRem(4),
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <p className="text-xs font-[300] text-GRAY920">
                  Out of Service
                </p>
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                bgcolor: "black",
                padding: `${changePxToRem(0)} ${changePxToRem(24)}`,
              }}
            >
              <p className="text-base font-[700] text-white">
                Credit/Debit Card
              </p>
            </AccordionSummary>
            {/* <AccordionDetails
              sx={{
                bgcolor: "black",
                padding: `${changePxToRem(0)} ${changePxToRem(24)}`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid
                sx={{
                  padding: `${changePxToRem(16)} ${changePxToRem(0)}`,
                  borderBottom: `1px solid ${GRAY920}`,
                }}
              >
                <Text
                  type={16}
                  fontWeight={700}
                  color="white"
                  sx={{
                    lineHeight: changePxToRem(25),
                    width: "100%",
                    //   padding: "0",
                  }}
                >
                  Verify your withdrawals (2FA)
                </Text>
              </Grid>
            </AccordionDetails> */}
          </Accordion>

          {/* Cryptocurrency */}
          <Accordion
            disabled
            expanded={expanded === "panel5"}
            onChange={handleChangeAccordion("panel5")}
            sx={{
              padding: "0",
              borderRadius: changePxToRem(4),
              "&.MuiAccordion-root": {
                borderRadius: changePxToRem(4),
              },
              "& .Mui-disabled": {
                borderRadius: changePxToRem(4),
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <p className="text-xs font-[300] text-GRAY920">
                  Out of Service
                </p>
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                bgcolor: "black",
                padding: `${changePxToRem(0)} ${changePxToRem(24)}`,
              }}
            >
              <p className="text-base font-[700] text-white">Cryptocurrency</p>
            </AccordionSummary>
          </Accordion>
        </Grid>
      </Box>

      {/* openWithdrawStep1 */}
      <Dialog
        open={openWithdrawStep1}
        onClose={onClickOpenWithdrawStep1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 99,
          "& .MuiDialog-paper": {
            maxWidth: changePxToRem(2000),
            backgroundColor: GRAY700,
          },
        }}
      >
        <Grid
          sx={{
            bgcolor: GRAY700,
            padding: `${changePxToRem(40)} 13.025vw`,
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              bgcolor: GRAY700,
              padding: "0",
              display: "flex",
              justifyContent: "center",
              paddingBottom: changePxToRem(20),
            }}
          >
            <p className="text-3xl font-[700] text-white">
              Withdraw Confirmation
            </p>
          </DialogTitle>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: changePxToRem(20),
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: `${changePxToRem(15)} ${changePxToRem(20)}`,
                bgcolor: "black",
                borderRadius: changePxToRem(4),
                gap: changePxToRem(6),
              }}
            >
              <p className="text-base font-[400] text-YELLOW262">
                *ถอนเงินฟรี 2 สัปดาห์ ต่อครั้ง ไม่เกิน 100,000 บาท ต่อวัน
              </p>
              <Grid sx={{ display: "flex", gap: changePxToRem(5) }}>
                <p className="text-base font-[400] text-YELLOW262">
                  *ระยะเวลาในการโอนเงินภายใน 7 วันทำการ
                </p>
                <p className="text-base font-[400] text-white underline cursor-pointer">
                  (อ่านเพิ่มเติม)
                </p>
              </Grid>
            </Grid>

            <Grid sx={{ position: "relative" }}>
              <DialogContent
                sx={{
                  bgcolor: GRAY700,
                  padding: "0",
                }}
              >
                <DialogContentText
                  id="alert-dialog-description"
                  sx={{
                    gap: changePxToRem(12),
                  }}
                >
                  <TextField
                    //   name={}
                    inputProps={{
                      autocomplete: "off",
                    }}
                    type="number"
                    placeholder="ระบุจำนวนเงินที่ต้องการถอน"
                    onChange={(e) => handleChangeWithDrawMoney(e)}
                    onKeyDown={(e) =>
                      (e.key === "e" && e.preventDefault()) ||
                      (e.key === "-" && e.preventDefault()) ||
                      (e.key === "+" && e.preventDefault()) ||
                      (e.key === "." && e.preventDefault())
                    }
                    value={inputWithDrawMoney}
                    sx={{
                      input: {
                        position: "relative",
                        color: "white",
                        backgroundColor: GRAY910,
                        width: changePxToRem(413.63),
                        padding: `${changePxToRem(11)} ${changePxToRem(10)}`,
                        borderRadius: "4px",
                        "&::placeholder": {
                          // <----- Add this.
                          opacity: 0.7,
                          color: GRAY920,
                        },
                      },
                    }}
                    color="secondary"
                  ></TextField>
                </DialogContentText>
              </DialogContent>
              <p
                className="text-base font-[500] text-RED001 cursor-pointer absolute top-[0.625rem] right-[1.5625rem]"
                onClick={onClickMaxAmount}
              >
                MAX
              </p>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              gap: changePxToRem(20),
              paddingTop: changePxToRem(30),
              width: "100%",
            }}
          >
            <div className="w-[50%]">
              <BorderButton
                text="Cancel"
                styleBtn="border-[3px]"
                onClick={onClickOpenWithdrawStep1}
              />
            </div>

            <div className="w-[50%]">
              <SubmitButton
                type="submit"
                text="Confirm"
                styleBtn={
                  withDrawMoneyAmount < 20 ||
                  withDrawMoneyAmount > 100000 ||
                  withDrawMoneyAmount > totalValues
                    ? "bg-RED004 hover:!bg-RED004 !cursor-default"
                    : "bg-RED001"
                }
                onClick={onClickConfirmWithdrawStep1}
              />
            </div>
          </Grid>
        </Grid>
        <ClearRoundedIcon
          sx={{
            position: "absolute",
            top: changePxToRem(10),
            right: changePxToRem(12),
            color: "white",
            cursor: "pointer",
            zIndex: 1,
          }}
          onClick={onClickOpenWithdrawStep1}
        />
        <Grid
          sx={{
            position: "absolute",
            top: changePxToRem(0),
            right: changePxToRem(0),
            width: changePxToRem(55),
            height: changePxToRem(50),
            backgroundColor: GRAY910,
            borderBottomLeftRadius: changePxToRem(40),
            borderRight: 0,
            borderTop: 0,
            cursor: "pointer",
          }}
          onClick={onClickOpenWithdrawStep1}
        ></Grid>
      </Dialog>

      {/* openWithdrawStep2 */}
      <Dialog
        open={openWithdrawStep2}
        onClose={onClickOpenWithdrawStep2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 99,
          "& .MuiDialog-paper": {
            maxWidth: changePxToRem(2000),
            backgroundColor: GRAY700,
          },
        }}
      >
        <Grid
          sx={{
            bgcolor: GRAY700,
            padding: `${changePxToRem(40)} 13.025vw`,
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              bgcolor: GRAY700,
              padding: "0",
              display: "flex",
              justifyContent: "center",
              paddingBottom: changePxToRem(24),
            }}
          >
            <p className="text-3xl font-[700] text-white">
              Withdraw Confirmation
            </p>
          </DialogTitle>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: changePxToRem(12),
              width: changePxToRem(450),
            }}
          >
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p className="text-base font-[400] text-white">โอนเข้าบัญชี</p>
              <p className="text-base font-[400] text-white">
                {bankingFirstName} {bankingLastName}
              </p>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p className="text-base font-[400] text-white">เลขที่บัญชี</p>
              <p className="text-base font-[400] text-white">{bankingNumber}</p>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="flex items-center gap-2">
                <p className="text-base font-[400] text-white">ค่าธรรมเนียม</p>
                <img
                  src={iconFee}
                  alt="viewMoreFee"
                  className="w-4 h-4 cursor-pointer"
                />
              </div>
              <p className="text-base font-[400] text-white">{fee}</p>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p className="text-base font-[400] text-white">ยอดรวมที่ได้รับ</p>
              <p className="text-base font-[400] text-white">
                {withDrawMoneyAmount
                  ? Number(withDrawMoneyAmount) - Number(fee)
                  : 0}{" "}
                THB
              </p>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              gap: changePxToRem(20),
              paddingTop: changePxToRem(30),
              width: "100%",
            }}
          >
            <div className="w-[50%]">
              <BorderButton
                text="Cancel"
                styleBtn="border-[3px]"
                onClick={onClickOpenWithdrawStep2}
              />
            </div>

            <div className="w-[50%]">
              <SubmitButton
                type="submit"
                text="Confirm"
                styleBtn="bg-RED001"
                onClick={onClickConfirmWithdrawStep2}
              />
            </div>
          </Grid>
        </Grid>
        <ClearRoundedIcon
          sx={{
            position: "absolute",
            top: changePxToRem(10),
            right: changePxToRem(12),
            color: "white",
            cursor: "pointer",
            zIndex: 1,
          }}
          onClick={onClickOpenWithdrawStep2}
        />
        <Grid
          sx={{
            position: "absolute",
            top: changePxToRem(0),
            right: changePxToRem(0),
            width: changePxToRem(55),
            height: changePxToRem(50),
            backgroundColor: GRAY910,
            borderBottomLeftRadius: changePxToRem(40),
            borderRight: 0,
            borderTop: 0,
            cursor: "pointer",
          }}
          onClick={onClickOpenWithdrawStep2}
        ></Grid>
      </Dialog>

      {/* openWithdrawStep3 */}
      <Dialog
        open={openWithdrawStep3}
        onClose={onClickOpenWithdrawStep3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 99,
          "& .MuiDialog-paper": {
            maxWidth: changePxToRem(2000),
            backgroundColor: GRAY700,
          },
        }}
      >
        <Grid
          sx={{
            bgcolor: GRAY700,
            padding: `${changePxToRem(40)} 13.025vw`,
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              bgcolor: GRAY700,
              padding: "0",
              display: "flex",
              justifyContent: "center",
              paddingBottom: changePxToRem(20),
            }}
          >
            <p className="text-3xl font-[700] text-white">
              Withdraw Confirmation
            </p>
          </DialogTitle>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: changePxToRem(20),
            }}
          >
            <p className="text-base font-[400] text-white">
              Please verify your Google Authentication Code (2FA 6 numbers)
            </p>

            <Grid sx={{ position: "relative" }}>
              <DialogContent
                sx={{
                  bgcolor: GRAY700,
                  padding: "0",
                }}
              >
                <DialogContentText
                  id="alert-dialog-description"
                  sx={{
                    display: "flex",
                    gap: changePxToRem(12),
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    //   name={}
                    inputProps={{
                      autocomplete: "off",
                      maxlength: "6",
                    }}
                    type={openEnterCode ? "text" : "password"}
                    placeholder="Enter Code"
                    sx={{
                      input: {
                        position: "relative",
                        color: "white",
                        backgroundColor: GRAY910,
                        width: changePxToRem(380),
                        padding: `${changePxToRem(11)} ${changePxToRem(10)}`,
                        borderRadius: "4px",
                        "&::placeholder": {
                          // <----- Add this.
                          opacity: 0.7,
                          color: GRAY920,
                        },
                      },
                    }}
                    color="secondary"
                    onChange={onChangeInputCodeGoogleAuth}
                  ></TextField>
                </DialogContentText>
              </DialogContent>
              {openEnterCode ? (
                <VisibilityIcon
                  sx={{
                    position: "absolute",
                    top: changePxToRem(10),
                    right: changePxToRem(45),
                    color: GRAY920,
                  }}
                  onClick={() => setOpenEnterCode(!openEnterCode)}
                />
              ) : (
                <VisibilityOffIcon
                  sx={{
                    position: "absolute",
                    top: changePxToRem(10),
                    right: changePxToRem(45),
                    color: GRAY920,
                  }}
                  onClick={() => setOpenEnterCode(!openEnterCode)}
                />
              )}
            </Grid>
            <Grid
              sx={{
                display: "flex",
                gap: changePxToRem(6),
                justifyContent: "center",
              }}
            >
              <p className="text-base font-[400] text-white">
                Do you need help ?
              </p>
              <p className="text-base font-[400] text-white underline cursor-pointer">
                Click for Google Authenticator
              </p>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              gap: changePxToRem(20),
              padding: `${changePxToRem(0)} ${changePxToRem(18)}`,
              paddingTop: changePxToRem(30),
            }}
          >
            <div className="w-[50%]">
              <BorderButton
                text="Cancel"
                styleBtn="border-[3px]"
                onClick={onClickOpenWithdrawStep3}
              />
            </div>

            <div className="w-[50%]">
              <SubmitButton
                type="submit"
                text="Confirm"
                styleBtn={
                  endCode
                    ? "bg-RED001"
                    : "bg-RED004 hover:bg-RED004 !cursor-default"
                }
                onClick={onClickConfirmWithdrawStep3}
              />
            </div>
          </Grid>
        </Grid>
        <ClearRoundedIcon
          sx={{
            position: "absolute",
            top: changePxToRem(10),
            right: changePxToRem(12),
            color: "white",
            cursor: "pointer",
            zIndex: 1,
          }}
          onClick={onClickOpenWithdrawStep3}
        />
        <Grid
          sx={{
            position: "absolute",
            top: changePxToRem(0),
            right: changePxToRem(0),
            width: changePxToRem(55),
            height: changePxToRem(50),
            backgroundColor: GRAY910,
            borderBottomLeftRadius: changePxToRem(40),
            borderRight: 0,
            borderTop: 0,
            cursor: "pointer",
          }}
          onClick={onClickOpenWithdrawStep3}
        ></Grid>
      </Dialog>

      {/* openPopupSuccessWithdraw */}
      <Dialog
        open={openPopupSuccessWithdraw}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 99,
          "& .MuiDialog-paper": {
            maxWidth: changePxToRem(2000),
            backgroundColor: GRAY700,
          },
        }}
      >
        <Grid
          sx={{
            bgcolor: GRAY700,
            padding: `${changePxToRem(40)} 13.025vw`,
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              bgcolor: GRAY700,
              padding: "0",
              display: "flex",
              justifyContent: "center",
              paddingBottom: changePxToRem(20),
            }}
          >
            <p className="text-3xl font-[700] text-white">
              Processing Withdrawal
            </p>
          </DialogTitle>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: changePxToRem(20),
            }}
          >
            <p className="text-base font-[400] text-white">
              Please wait while we are processing your withdrawal appoximal 3-7
              days
            </p>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: changePxToRem(20),
              padding: `${changePxToRem(0)} ${changePxToRem(18)}`,
              paddingTop: changePxToRem(30),
            }}
          >
            <div className="w-[80%]">
              <BorderButton
                text="OK"
                styleBtn="border-[3px]"
                onClick={onClickSuccessWithdraw}
              />
            </div>
          </Grid>
        </Grid>
      </Dialog>

      {/* openPopupFailWithdraw */}
      <Dialog
        open={openPopupFailWithdraw}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 99,
          "& .MuiDialog-paper": {
            maxWidth: changePxToRem(2000),
            backgroundColor: GRAY700,
          },
        }}
      >
        <Grid
          sx={{
            bgcolor: GRAY700,
            padding: `${changePxToRem(40)} 13.025vw`,
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              bgcolor: GRAY700,
              padding: "0",
              display: "flex",
              justifyContent: "center",
              paddingBottom: changePxToRem(20),
            }}
          >
            <p className="text-3xl font-[700] text-white">Withdraw Failed!</p>
          </DialogTitle>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: changePxToRem(20),
            }}
          >
            <p className="text-base font-[400] text-white">
              The Google Authenticator code is incorrect or has expired.
            </p>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: changePxToRem(20),
              padding: `${changePxToRem(0)} ${changePxToRem(18)}`,
              paddingTop: changePxToRem(30),
            }}
          >
            <div className="w-[90%]">
              <SubmitButton text="try again" onClick={onClickFailWithdraw} />
            </div>
          </Grid>
        </Grid>
      </Dialog>

      {/* openPopupFailWalletUserWithdraw */}
      <Dialog
        open={openPopupFailWalletUserWithdraw}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 99,
          "& .MuiDialog-paper": {
            maxWidth: changePxToRem(2000),
            backgroundColor: GRAY700,
          },
        }}
      >
        <Grid
          sx={{
            bgcolor: GRAY700,
            padding: `${changePxToRem(40)} 13.025vw`,
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              bgcolor: GRAY700,
              padding: "0",
              display: "flex",
              justifyContent: "center",
              paddingBottom: changePxToRem(20),
            }}
          >
            <p className="text-3xl font-[700] text-white">Withdraw Failed!</p>
          </DialogTitle>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: changePxToRem(20),
            }}
          >
            <p className="text-base font-[400] text-white">
              Please check amount wallet for you
            </p>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: changePxToRem(20),
              padding: `${changePxToRem(0)} ${changePxToRem(18)}`,
              paddingTop: changePxToRem(30),
            }}
          >
            <div className="w-[90%]">
              <SubmitButton
                text="OK"
                onClick={onClickFailWithdrawUserWithdraw}
              />
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </SwipeableDrawer>
  );
};

export default MyWallet;
