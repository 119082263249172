import React, { useState, useEffect } from "react";
import { BorderButton, SubmitButton } from "../../../../Button";
import ChatRoom from "../ChatRoom";
import dayjs from "dayjs";

const DetailDeliveryResponseMobile = ({
  data,
  handleOpenDialogCancelOrder,
  handleOpenDialogConfirmOrderForSeller,
  handleOpenDialogConfirmOrderForBuyer,
  openDeliveryProgress,
  dataChatHistory,
}) => {
  const sellerResponse = data?.sellerAvailable;
  const sellerConfirm = data?.sellerConfirmed;
  const buyerConfirm = data?.buyerConfirmed;
  return (
    <div>
      {/*  PaymentQR Code Mobile */}
      <div className="desktop:hidden mobile:flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-3  ">
            <p className="text-2xl font-[700] text-white pl-1">DELIVERY</p>
            <p className="text-xs font-[400] text-white pl-1">
              Please make a delivery within 24 hours, otherwise order will be
              cancelled.
            </p>
          </div>
          <div>
            <div className="relative py-3 px-4 bg-GRAY910 flex flex-col gap-3 rounded">
              <p className="text-sm font-[500] text-white">
                Delivery in progress
              </p>

              <div className="flex flex-col gap-2">
                {/* Awaiting seller response */}
                <div className="flex items-center w-full">
                  <p
                    className={`text-xs font-[400] ${
                      sellerResponse ? "text-GREEN050" : "text-RED007"
                    } w-[35%]`}
                  >
                    {data?.txnDatetime
                      ? dayjs(data?.txnDatetime).format("DD MMM YYYY - HH:mm")
                      : ""}
                  </p>
                  <div className="w-[25%] flex justify-center">
                    <div
                      className={`w-3 h-3 ${
                        sellerResponse ? "bg-GREEN050" : "bg-RED007"
                      } rounded-full`}
                    ></div>
                  </div>
                  <p
                    className={`text-xs font-[400] ${
                      sellerResponse ? "text-GREEN050" : "text-RED007"
                    } w-[40%] text-end`}
                  >
                    Awaiting seller response
                  </p>
                </div>

                {/* Seller confirm delivery */}
                <div className="flex items-center w-full">
                  <p
                    className={`text-xs font-[400] ${
                      sellerResponse
                        ? sellerConfirm
                          ? "text-GREEN050"
                          : "text-RED007"
                        : "text-GRAY920"
                    } w-[35%] ${sellerConfirm ? "pl-0" : "pl-12"}`}
                  >
                    {sellerConfirm
                      ? dayjs(sellerConfirm).format("DD MMM YYYY - HH:mm")
                      : "-"}
                  </p>
                  <div className="w-[25%] flex justify-center">
                    <div
                      className={`w-3 h-3  ${
                        sellerResponse
                          ? sellerConfirm
                            ? "bg-GREEN050"
                            : "bg-RED007"
                          : "bg-GRAY920"
                      } rounded-full`}
                    ></div>
                  </div>
                  <p
                    className={`text-xs font-[400]  ${
                      sellerResponse
                        ? sellerConfirm
                          ? "text-GREEN050"
                          : "text-RED007"
                        : "text-GRAY920"
                    } w-[40%] text-end`}
                  >
                    Seller confirm delivery
                  </p>
                </div>

                {/* Buyer confirm delivery */}
                <div className="flex items-center w-full">
                  <p
                    className={`text-xs font-[400] ${
                      sellerConfirm
                        ? buyerConfirm
                          ? "text-GREEN050"
                          : "text-RED007"
                        : "text-GRAY920"
                    } w-[35%] ${buyerConfirm ? "pl-0" : "pl-12"}`}
                  >
                    {buyerConfirm
                      ? dayjs(buyerConfirm).format("DD MMM YYYY - HH:mm")
                      : "-"}
                  </p>
                  <div className="w-[25%] flex justify-center ">
                    <div
                      className={`w-3 h-3  ${
                        sellerConfirm
                          ? buyerConfirm
                            ? "bg-GREEN050"
                            : "bg-RED007"
                          : "bg-GRAY920"
                      } rounded-full`}
                    ></div>
                  </div>
                  <p
                    className={`text-xs font-[400]  ${
                      sellerConfirm
                        ? buyerConfirm
                          ? "text-GREEN050"
                          : "text-RED007"
                        : "text-GRAY920"
                    } w-[40%] text-end`}
                  >
                    Buyer confirm delivery
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center gap-2 ">
          <p className="text-xs font-[400] text-GRAY920">
            {data?.txnDatetime
              ? dayjs(data?.txnDatetime).format("MMMM DD, YYYY HH:mm:ss")
              : ""}
          </p>
          <ChatRoom
            openDeliveryProgress={openDeliveryProgress}
            dataChatHistory={dataChatHistory}
            data={data}
          />
        </div>

        <div className="flex flex-col gap-4">
          {data?.tokenSeller ? (
            data?.sellerConfirmed ? (
              <SubmitButton
                text="Waiting..."
                styleBtn="bg-RED004 hover:bg-RED004 !cursor-default"
              />
            ) : (
              <SubmitButton
                text="Confirm delivery"
                onClick={handleOpenDialogConfirmOrderForSeller}
              />
            )
          ) : data?.sellerConfirmed ? (
            <SubmitButton
              text="Confirm delivery"
              onClick={handleOpenDialogConfirmOrderForBuyer}
            />
          ) : (
            <SubmitButton
              text="Waiting..."
              styleBtn="bg-RED004 hover:bg-RED004 !cursor-default"
            />
          )}
          <BorderButton
            text="Cancel"
            styleBtn="bg-[#1A1A1A] border-none"
            onClick={handleOpenDialogCancelOrder}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailDeliveryResponseMobile;
