import React, { useState, useRef, useEffect } from "react";
import {
  styled,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import {
  RED001,
  RED002,
  RED005,
  GRAY700,
  GRAY750,
  GRAY760,
  GRAY910,
  GRAY920,
  gray5757,
  GREEN050,
  YELLOW100,
} from "../../theme/color";
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs from "dayjs";
import { useMutationClientCheckoutProgress } from "../../api/mutation/checkoutProgress";
import { useMutationClientDeliveryTransaction } from "../../api/mutation/delivery";
import { Link } from "react-router-dom";
import { changePxToRem } from "../../utils/changePixeltoRem";
import IconClock from "../../assets/images/transaction purchased/IconClock.svg";
import ArrowDown from "../../assets/images/transaction purchased/ArrowDown.svg";
import { useNavigate } from "react-router-dom";
import { useMutationClientDeliveryTransactionHistory } from "../../api/mutation/transactionPurchased";

const TransactionPurchasedMobile = () => {
  const [dataTransactionPurchased, setDataTransactionPurchased] = useState([]);
  const [dataDeliveryTransactionHistory, setDataDeliveryTransactionHistory] =
    useState();
  const [expanded, setExpanded] = useState(false);

  const [getDataMore, setGetDataMore] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const {
    mutate: mutateCheckoutProgress,
    isSuccess: isSuccessCheckoutProgress,
  } = useMutationClientCheckoutProgress({
    onSuccess: (resp) => {
      setDataTransactionPurchased((old) => [...old, ...resp?.data?.data]);
      setTotalPages(resp?.data.totalPages);
    },
    onError: () => {
      setDataTransactionPurchased("");
    },
  });

  const {
    mutate: mutateDeliveryTransaction,
    isSuccess: isSuccessDeliveryTransaction,
  } = useMutationClientDeliveryTransactionHistory({
    onSuccess: (resp) => {
      setDataDeliveryTransactionHistory(resp?.data?.data);
    },
    onError: () => {},
  });

  useEffect(() => {
    mutateCheckoutProgress({
      pageSize: 10,
      page: page,
      progress: null,
      noti: true,
    });
  }, [page]);

  const fetchData = () => {
    const nextPage = page + 1;
    if (nextPage <= totalPages) {
      setPage(nextPage);
    } else {
      setGetDataMore(false);
    }
  };

  const onChangeExpanded =
    (index, transactionTrackId) => (event, isExpanded) => {
      const dataTransaction = {
        transactionTrackId: transactionTrackId,
      };
      setExpanded(isExpanded ? index : false);
      mutateDeliveryTransaction(dataTransaction);
    };
  return (
    <div className="bg-GRAY111 h-full  flex flex-col gap-4">
      <div className="text-2xl font-[700] p-4 pb-0 text-white flex gap-2 flex-wrap items-center justify-between">
        <p>TRANSACTION PURCHASED</p>
        <Link
          to={`/checkoutprogress`}
          className="text-sm flex items-end underline"
        >
          See All
        </Link>
      </div>
      <InfiniteScroll
        dataLength={dataTransactionPurchased?.length} //This is important field to render the next data
        next={fetchData}
        hasMore={getDataMore}
        // height={500}
        // loader={<h4>Loading...</h4>}
        // endMessage={
        //   <p style={{ textAlign: "center" }}>
        //     <b>Yay! You have seen it all</b>
        //   </p>
        // }
      >
        {dataTransactionPurchased?.length > 1 &&
          dataTransactionPurchased?.map(
            (
              {
                itemName,
                itemPic,
                progress,
                transactionTrackId,
                type,
                txnDatetime,
                buyerId,
                gameName,
                grandTotal,
                itemDesc,
                itemId,
                namePayment,
                paymentGatewayFee,
                price,
                productCount,
                productTransactionId,
                sellerId,
              },
              index
            ) => {
              const date = dayjs(txnDatetime).format("DD-MM-YYYY HH:mm");
              return (
                <Accordion
                  key={index}
                  expanded={expanded === index}
                  onChange={onChangeExpanded(index, transactionTrackId)}
                  disableGutters
                  sx={{
                    background: "#1A1A1A",
                    boxShadow: "none",
                    "& .MuiAccordionSummary-root": {
                      display: "flex",
                      padding: "0px 20px 0px 16px",
                      background: index % 2 === 0 ? "#202020" : "#282828",
                    },
                    "& .MuiAccordionSummary-root > .MuiAccordionSummary-content":
                      {
                        gap: "12px",
                      },
                    "& .MuiAccordionDetails-root": {
                      padding: "0px 10px 15px 30px",
                    },
                    "& .MuiAccordionSummary-content": {
                      margin: "8px 0px",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <img src={ArrowDown} alt="" width="14" height="26" />
                    }
                  >
                    <div>
                      <img
                        src={itemPic["media_url1"]}
                        alt="gameName"
                        className="w-20 h-20 rounded"
                      />
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "5px",
                        justifyContent: "center",
                      }}
                    >
                      <Grid sx={{ display: "flex", gap: changePxToRem(3) }}>
                        <p className="text-sm font-[700] text-white">
                          {gameName}
                        </p>
                        <p
                          className={`text-sm font-[700] ${
                            type === "Buy" ? "text-GREEN050" : "text-RED001"
                          }`}
                        >
                          {type === "Buy" ? "(B)" : "(S)"}
                        </p>
                      </Grid>

                      <Grid
                        sx={{
                          display: "flex",
                          gap: changePxToRem(4),
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p className="text-xs font-[400] text-white">
                          {progress === "Success"
                            ? "Your order has been successfully processed."
                            : "Request received, You’re on delivery processing."}
                        </p>
                      </Grid>

                      <div className="flex gap-2 items-center">
                        <img src={IconClock} alt="" className="w-4 h-4" />
                        <p className="text-[0.625rem] font-[400] text-GRAY920 pt-[2px]">
                          {date}
                        </p>
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TimelineWrapper>
                      <TimelineContainer
                        confirmed={
                          dataDeliveryTransactionHistory?.seller_confirm ===
                            null || undefined
                            ? dataDeliveryTransactionHistory?.seller_available
                            : dataDeliveryTransactionHistory?.seller_confirm
                        }
                      >
                        <div className="flex flex-col gap-1 items-start mt-[0.375rem]">
                          <p
                            className={`text-xs font-[400] ${
                              dataDeliveryTransactionHistory?.seller_available ||
                              dataDeliveryTransactionHistory?.seller_confirm
                                ? "text-GREEN050"
                                : "text-RED001"
                            }`}
                          >
                            Awaiting seller response
                          </p>
                          <p className="text-xs font-[400] text-white text-start">
                            You can integrate a live chat messaging system into
                            your marketplace. Buyer Seller Chat Marketplace
                            Add-on will allow a customer to send and receive
                            instant messages from the vendors.
                          </p>
                          <p className="text-xs font-[400] text-GRAY920 mt-1">
                            {date}
                          </p>
                        </div>
                      </TimelineContainer>
                    </TimelineWrapper>
                    <TimelineWrapper>
                      <TimelineContainerII
                        sellerAvailable={
                          dataDeliveryTransactionHistory?.seller_available
                        }
                        sellerConfirmed={
                          dataDeliveryTransactionHistory?.seller_confirm
                        }
                      >
                        <div className="flex flex-col gap-1 items-start mt-[0.375rem]">
                          <p
                            className={`text-xs font-[400] ${
                              dataDeliveryTransactionHistory?.seller_available ||
                              dataDeliveryTransactionHistory?.seller_confirm
                                ? dataDeliveryTransactionHistory?.seller_confirm
                                  ? "text-GREEN050"
                                  : "text-RED001"
                                : "text-white"
                            }`}
                          >
                            Seller confirm delivery
                          </p>
                          <p className="text-xs font-[400] text-white text-start">
                            Right information helps them understand your
                            products and shape accurate expectations, reducing
                            time, returns and refunds from buyer decision.
                          </p>
                          <p className="text-xs font-[400] text-GRAY920 mt-1">
                            {dataDeliveryTransactionHistory?.seller_confirm
                              ? dayjs(
                                  dataDeliveryTransactionHistory?.seller_confirm
                                ).format("DD-MM-YYYY HH:mm")
                              : "-"}
                          </p>
                        </div>
                      </TimelineContainerII>
                    </TimelineWrapper>
                    <TimelineWrapper>
                      <TimelineContainerIII
                        sellerConfirmed={
                          dataDeliveryTransactionHistory?.seller_confirm
                        }
                        buyerConfirmed={
                          dataDeliveryTransactionHistory?.buyer_confirm
                        }
                      >
                        <div className="flex flex-col gap-1 items-start mt-[0.375rem]">
                          <p
                            className={`text-xs font-[400]  ${
                              dataDeliveryTransactionHistory?.seller_confirm
                                ? dataDeliveryTransactionHistory?.buyer_confirm
                                  ? "text-GREEN050"
                                  : "text-RED001"
                                : "text-white"
                            }`}
                          >
                            Buyer confirm delivery
                          </p>
                          <p className="text-xs font-[400] text-white text-start">
                            Please check that all items for your order has been
                            delivered. If you are satisfied with your order,
                            click confirm to release payment to the seller. Rate
                            the products.
                          </p>
                          <p className="text-xs font-[400] text-GRAY920 mt-1">
                            {dataDeliveryTransactionHistory?.buyer_confirm
                              ? // <DateTimeConfirm
                                //   date={dataDeliveryTransaction?.buyerConfirmed}
                                // />
                                dayjs(
                                  dataDeliveryTransactionHistory?.buyer_confirm
                                ).format("DD-MM-YYYY HH:mm")
                              : "-"}
                          </p>
                        </div>
                      </TimelineContainerIII>
                    </TimelineWrapper>
                  </AccordionDetails>
                </Accordion>
              );
            }
          )}
      </InfiniteScroll>
    </div>
  );
};

export default TransactionPurchasedMobile;

const TimelineWrapper = styled(Box)({
  width: "100%",
  paddingLeft: "12px 77px 12px 37px",
  "&::after": {
    content: '""',
    position: "absolute",
    borderLeft: "2px solid #ffffff",
    top: "96px",
    left: "29.9px",
    bottom: "0",
  },
});

const TimelineContainer = styled(Box)(({ confirmed }) => ({
  position: "relative",
  padding: "11.5px 29px 0px 29px",
  background: "inherit",
  "&::after": {
    content: '""',
    position: "absolute",
    width: "12px",
    height: "12px",
    background: confirmed ? GREEN050 : RED001,
    left: "-5px",
    top: "18px",
    borderRadius: "50%",
    zIndex: 1,
  },
}));
const TimelineContainerII = styled(Box)(
  ({ sellerAvailable, sellerConfirmed }) => ({
    position: "relative",
    padding: "11.5px 29px 0px 29px",
    background: "inherit",
    "&::after": {
      content: '""',
      position: "absolute",
      width: "12px",
      height: "12px",

      background:
        sellerAvailable || sellerConfirmed
          ? sellerConfirmed
            ? GREEN050
            : RED001
          : "white",
      left: "-5px",
      top: "19px",
      borderRadius: "50%",
      zIndex: 1,
    },
  })
);
const TimelineContainerIII = styled(Box)(
  ({ buyerConfirmed, sellerConfirmed }) => ({
    position: "relative",
    padding: "11.5px 29px 0px 29px",
    background: "inherit",
    "&::after": {
      content: '""',
      position: "absolute",
      width: "12px",
      height: "12px",
      background: sellerConfirmed
        ? buyerConfirmed
          ? GREEN050
          : RED001
        : "white",
      left: "-5px",
      top: "19px",
      borderRadius: "50%",
      zIndex: 1,
    },
  })
);
