import { Link } from "react-router-dom";

export const SearchResult = ({ result, setInput, setIsOpen, setResults }) => {
  return (
    <>
      {/* display resultSearch desktop */}
      <Link
        to={`marketplace/?name=${result}`}
        className="p-4 text-white hover:bg-[#414141] mobile:hidden desktop:block cursor-pointer"
        onClick={() => setInput(result)}
      >
        {result}
      </Link>

      {/* display resultSearch mobile */}
      <Link
        to={`marketplace/?name=${result}`}
        className="py-[14px] pl-5 pr-2 text-white hover:bg-[#414141] mobile:block desktop:hidden cursor-pointer"
        onClick={() => {
          setInput("");
          setResults([]);
          setIsOpen(false);
        }}
      >
        {result}
      </Link>
    </>
  );
};
