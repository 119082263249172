import React from "react";
import IconRatingStar from "./IconRatingStar";
import dayjs from "dayjs";

const InformationUserRating = ({ data }) => {
  return (
    <div className="flex flex-col desktop:flex-row gap-1 desktop:gap-5 pb-2 desktop:pb-3 border-b border-GRAY636">
      {/* profile Review */}
      <div className="w-full desktop:w-[20%]">
        <div className="flex desktop:flex-col-reverse justify-between gap-3">
          <div className="flex gap-4 pl-[0.375rem]">
            {data?.reviewer_profile_picture ? (
              <img
                alt="reviewerProfilePicture"
                src={data?.reviewer_profile_picture}
                className="w-10 h-10 min-w-[2.5rem] rounded-full object-cover"
              />
            ) : (
              <div className="w-10 h-10 min-w-[2.5rem] rounded-full bg-white/[0.1]"></div>
            )}
            <div>
              <p className="text-xs text-white font-bold leading-5">
                {data?.reviewer_display_name}
              </p>
              <p className="text-[0.625rem] text-GRAY920 font-normal">
                {data?.review_datetime
                  ? dayjs(data?.review_datetime).format("DD-MM-YYYY")
                  : ""}
              </p>
            </div>
          </div>
          <div>
            <IconRatingStar rating={data?.review_rating} />
          </div>
        </div>
      </div>

      {/* comment Review */}
      <div className="desktop:bg-GRAY910 w-full h-full desktop:min-h-[77px] rounded p-2">
        <p className="text-[0.625rem] text-white font-normal">
          {data?.review_comment}
        </p>
      </div>
    </div>
  );
};

export default InformationUserRating;
