import axios from "axios"

export const getFilter = async (payload, setFilter, checkFilter, setMobileFilter) => {
    const category = [
        { name: "Account", id: 2 },
        { name: "Gold", id: 3 },
        { name: "Item", id: 1 },
        { name: "Service", id: 4 },
    ]
    let cate_id = category.findIndex(cate => cate.name === payload?.category_id)


    await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_GET_FILTERNAME + `?game_string_id=${payload?.gameId}&category_id=${category[cate_id]?.id}`)
        .then((res) => {
            // setFilter([...res.data])
            checkFilter([...res.data])
            setMobileFilter([...res.data])
        })
        .catch((err) => {
            console.error(err);
        })
}

export const getTotalItem = async (setTotalItem) => {
    await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_ENDPOINT_GET_TOTAL_ITEM)
        .then((res) => {
            setTotalItem(res.data.totalItems)
        })
        .catch((err) => {
            console.error(err);
        })
}


