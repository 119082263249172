import {
    Dialog,
    DialogBody,
    ThemeProvider,
} from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { CloseButton } from "../setting/kyc_component";
const Promocode = ({ open, setOpen }) => {
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])

    const theme = {
        dialog: {
            styles: {
                sizes: {
                    sm: {
                        width: "w-[944px]",
                        minWidth: "min-w-[944px]",
                        maxWidth: "max-w-[944px]",
                    },
                    xs: {
                        width: "w-full",
                        minWidth: "min-w-full",
                        maxWidth: "max-w-full",
                    }
                },
            },
        },
    };
    const handleOpen = () => {
        setOpen(!open)
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight])
        }

        window.addEventListener('resize', handleWindowResize)
        return () => window.removeEventListener('resize', handleWindowResize)
    }, []);
    return (
        <ThemeProvider
            value={theme}
        >
            <Dialog
                open={open}
                size={windowSize[0] > 430 ? "sm" : "xs"}
                className="bg-[#1A1A1A] relative rounded overflow-hidden button-0"
            >
                <DialogBody
                    className="desktop:relative  flex flex-col desktop:items-center desktop:h-[19.5625rem] mobile:h-[90vh] overflow-auto desktop:p-10 font-IBMPlex gap-3"
                >
                    {/*Close Button  */}
                    <CloseButton handleOpen={handleOpen} />
                    <p className="text-white text-[24px]  font-bold">PROMO CODE</p>
                    <div className="flex gap-3 desktop:w-[35rem] mt-2 mobile:w-full">
                        <input
                            type="text"
                            className="focus:outline-none desktop:w-[25rem] mobile:w-full desktop:h-[3rem] mobile:h-[2.625rem] mobile:p-3 text-base text-white desktop:p-[10px] placeholder:text-[#7C7C7C] bg-[#282828] rounded"
                            placeholder="Enter promo code"
                            maxLength="100"
                        />
                        <button
                            className="desktop:h-[3rem] desktop:w-[9.25rem] rounded uppercase text-white font-bold bg-[#bb213d] desktop:flex mobile:hidden justify-center items-center"
                        >
                            redeem
                        </button>
                    </div>
                    <div className="flex desktop:w-[35rem] desktop:h-[7.0625rem] rounded bg-[#282828] mobile:h-full justify-center items-center">
                        <p className="text-[#7c7c7c] font-medium">Empty</p>
                    </div>

                </DialogBody>
            </Dialog>
        </ThemeProvider>
    )
}

export default Promocode