import React from 'react'

const rank = () => {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 1C6.5 0.447715 6.94772 0 7.5 0H14C14.5523 0 15 0.447715 15 1V10H20.5C21.0523 10 21.5 10.4477 21.5 11V19C21.5 19.5523 21.0523 20 20.5 20H1C0.447715 20 0 19.5523 0 19V7C0 6.44772 0.447715 6 1 6H6.5V1ZM13 18V2H8.5V18H13ZM15 18H19.5V12H15V18ZM6.5 8V18H2V8H6.5Z" fill="white" />
        </svg>

    )
}

export default rank