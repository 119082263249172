import { useState, useEffect } from "react";
import { noti_image } from "../assets/home/index";
import { getNotification } from "../api/get/Homepage";
import { Link } from "react-router-dom";
import IconClock from "../assets/images/transaction purchased/IconClock.svg";
import dayjs from "dayjs";

const Notification = () => {
  const [notice, setNotice] = useState();
  useEffect(() => {
    getNotification(setNotice);
  }, []);

  return (
    <div className="desktop:absolute desktop:w-[39.5rem] desktop:h-[48.75rem] h-full w-full desktop:right-0 desktop:top-[3.625rem] bg-GRAY700 desktop:rounded desktop:z-50">
      <div className="desktop:flex desktop:items-center desktop:justify-between desktop:p-4 desktop:h-[3.75rem]">
        <h2 className="text-xl font-bold text-white mobile:p-4 desktop:p-0">
          Notification
        </h2>
        <Link
          to={"/comingsoon"}
          className="text-base font-[500] text-white underline hover:text-RED001 hover:underline-offset-1 mobile:hidden desktop:block"
        >
          See All ({notice?.length ? notice?.length : 0})
        </Link>
      </div>

      <div className="h-[calc(100%-3.75rem)] desktop:overflow-x-hidden">
        {notice?.map((notification) => {
          const date = dayjs(notification?.create_date).format(
            "DD-MM-YYYY HH:mm"
          );
          return (
            <div className="bg-[#202020] odd:bg-[#282828] desktop:w-full flex text-start gap-4 desktop:py-[0.625rem] py-2 px-4">
              <div className="w-20 h-20 min-w-[5rem] min-h-[5rem] desktop:w-100 desktop:h-100 desktop:min-w-[6.25rem] desktop:min-h-[6.25rem]">
                <img
                  alt="imageNotification"
                  src={notification?.image ? notification?.image : noti_image}
                  className="w-full h-full rounded object-center"
                />
              </div>

              <div className="flex flex-col grow justify-between gap-2 desktop:py-[0.375rem]">
                <p className="text-white desktop:text-xl mobile:text-sm font-bold">
                  {notification?.title}
                </p>

                <p className="text-white desktop:text-sm mobile:text-[12px] font-IBMPlex">
                  {notification?.detail}
                </p>
                <div className="flex items-center gap-2">
                  <img
                    alt="iconClock"
                    src={IconClock}
                    className="mobile:w-[15px] mobile:h-[15px] desktop:w-[1.125rem] desktop:h-[1.125rem]"
                  />
                  <p className="text-GRAY920 desktop:text-sm mobile:text-[0.625rem] font-IBMPlex">
                    {date}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Notification;
