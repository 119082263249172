import React from "react";
import CountItemOrder from "./CountItemOrder";
import { Link } from "react-router-dom";

const DetailItem = ({ data, quantity, setQuantity, gameLogo }) => {
  const profilePicture = data
    ? data?.item_game?.account?.profile_picture
    : gameLogo;

  return (
    <div className="bg-GRAY700 rounded w-full">
      <div className="py-18 px-5 flex gap-4 items-center border-b-[1px] border-GRAY750">
        <img
          src={profilePicture}
          alt="pictureItem"
          className="w-9 h-9 min-w-[2.25rem] min-h-[2.25rem] rounded-full flex object-cover"
        />
        <p className="text-xl font-[700] text-white grow w-[calc(90%-20px)] break-words">
          {data?.item_game?.account?.display_name}
        </p>
      </div>
      <div className="p-5 flex flex-wrap justify-end gap-5 w-full ">
        <div className="min-w-[7.5rem] min-h-[7.5rem] w-[7.5rem] h-[7.5rem] rounded">
          <img
            src={
              data?.item_game?.item_media["media_url1"]
                ? data?.item_game?.item_media["media_url1"]
                : ""
            }
            alt="pictureItem"
            className="w-full h-full rounded flex object-cover"
          />
        </div>
        <div className="flex flex-col justify-around py-3 w-[20%] grow">
          <p className="text-xl font-[700] text-white truncate ">
            {data?.item_game?.item_name}
          </p>
          <div className="flex w-[100%] gap-1">
            <p className="text-xs font-[700] text-white">Option&nbsp;: </p>
            <p className="text-xs font-[400] text-white truncate">
              {data?.item_game?.item_description}
            </p>
          </div>
          <Link
            to={`/marketplace/${data?.item_game?.game?.game_name}?gameID=${data?.item_game?.game?.game_id}&cate=All`}
          >
            <p className="text-xs font-[700] text-RED001 underline">
              {data?.item_game?.game?.game_name}
            </p>
          </Link>
        </div>
        <div className="flex flex-col justify-between py-3 gap-3">
          <div className="flex justify-end">
            <CountItemOrder
              quantity={quantity}
              setQuantity={setQuantity}
              item={data?.item_game?.item_quantity}
            />
          </div>
          <div className="text-end">
            <p className="text-xl font-[700] text-white">
              {Number(data?.item_game?.item_price).toLocaleString()}&nbsp;THB
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailItem;
