import { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogBody,
  ThemeProvider,
} from "@material-tailwind/react";
import { CloseButton } from "../page/setting/kyc_component";
import { Link, Navigate } from "react-router-dom";

const NoticeKYCstatus = ({ open, setOpen }) => {
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])

  const handleOpen = () => setOpen(false);

  useEffect(() => {

    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, []);
  const theme = {
    dialog: {
      styles: {
        sizes: {
          sm: {
            width: "w-[944px]",
            minWidth: "min-w-[944px]",
            maxWidth: "max-w-[944px]",
          },
          xs: {
            width: "w-full",
            minWidth: "min-w-full",
            maxWidth: "max-w-full",
          }
        },
      },
    },
  };
  return (
    <ThemeProvider
      value={theme}
    >
      <Dialog
        open={open}
        size={windowSize[0] > 768 ? "sm" : "xs"}
        className="bg-[#1A1A1A] relative rounded overflow-hidden button-0"
      >
        <DialogBody
          className="desktop:relative  flex flex-col gap-5 desktop:justify-center desktop:items-center desktop:h-[275px] mobile:h-[90vh] overflow-auto font-IBMPlex"
        >
          {/*Close Button  */}
          <div onClick={() => window.location.href = '/setting/kyc'}>
            <CloseButton handleOpen={handleOpen} />
          </div>
          <p className="text-white font-bold text-3xl">Permission was denied</p>
          <p className="text-white font-normal text-xl">Please complete your verification step </p>
          <Link
            to={'/setting/kyc'}
            className="flex justify-center items-center cursor-pointer uppercase rounded bg-[#BB213D] text-white  w-[400px] h-[56px] text-base font-bold "
            onClick={() => setOpen(false)}
          >
            OK
          </Link>
        </DialogBody>
      </Dialog>
    </ThemeProvider>
  )
}

export default NoticeKYCstatus