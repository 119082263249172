import React, { useState, useEffect } from "react";
import GameLogo from "../../../assets/images/Icon/game_logo.png";
import InProgressCartOrder from "../../../assets/images/Icon/IconProgressPayment/awaitingProgress/awaitingCartOrder.png";
import InProgressPayment from "../../../assets/images/Icon/IconProgressPayment/awaitingProgress/awaitingPayment.png";
import InProgressDelivery from "../../../assets/images/Icon/IconProgressPayment/awaitingProgress/awaitingDelivery.png";
import InProgressComplete from "../../../assets/images/Icon/IconProgressPayment/awaitingProgress/awaitingComplete.png";
import DoneCartOrder from "../../../assets/images/Icon/IconProgressPayment/doneProgress/doneCartOrder.png";
import DonePayment from "../../../assets/images/Icon/IconProgressPayment/doneProgress/donePayment.png";
import DoneDelivery from "../../../assets/images/Icon/IconProgressPayment/doneProgress/doneDelivery.png";
import DoneComplete from "../../../assets/images/Icon/IconProgressPayment/doneProgress/doneComplete.png";
import InProgress1 from "../../../assets/images/ProgressMobileBar/InProgress1.svg";
import InProgress2 from "../../../assets/images/ProgressMobileBar/InProgress2.svg";
import InProgress3 from "../../../assets/images/ProgressMobileBar/InProgress3.svg";
import NextProgress1 from "../../../assets/images/ProgressMobileBar/NextProgress1.svg";
import NextProgress2 from "../../../assets/images/ProgressMobileBar/NextProgress2.svg";

import { changePxToRem } from "../../../utils/changePixeltoRem.js";

const StepProgress = ({ stepProgressName }) => {
  return (
    <div className="w-full">
      {/* ProgressMobile */}

      {/* <div className=" desktop:hidden mobile:flex rounded w-full  gap-2">
        <div
          className={`w-[25%] flex justify-center py-[0.625rem] bg-RED001  rounded-l`}
        >
          <p className="text-[0.625rem] font-[700] text-white ">CART ORDER</p>
        </div>
        <div
          className={`w-[25%]  bg-GRAY700 flex justify-center py-[0.625rem] ${
            stepProgressName === "checkout" ? "bg-GRAY700" : "bg-RED001"
          }`}
        >
          <p className="text-[0.625rem]  font-[700] text-white ">PAYMENT</p>
        </div>
        <div
          className={`w-[25%] flex "bg-GRAY700" justify-center py-[0.625rem] ${
            stepProgressName === "checkout" || stepProgressName === "payment"
              ? "bg-GRAY700"
              : "bg-RED001"
          }`}
        >
          <p className="text-[0.625rem] font-[700] text-white ">DELIVERY</p>
        </div>
        <div
          className={`w-[25%] flex justify-center py-[0.625rem] rounded-r ${
            stepProgressName === "checkout" ||
            stepProgressName === "payment" ||
            stepProgressName === "delivery"
              ? "bg-GRAY700"
              : stepProgressName === "complete"
              ? "bg-RED001"
              : "bg-GRAY700"
          }`}
        >
          <p className="text-[0.625rem] font-[700] text-white ">COMPLETE</p>
        </div>
      </div> */}

      <div className="relative desktop:hidden mobile:flex flex-wrap rounded w-full ">
        <div className="relative w-[27%]">
          <img src={InProgress1} alt="progressCartOrder" className="w-full " />
          <p className="text-[1.5vw] font-[700] text-white absolute top-[37%] left-[25%] z-30">
            CART ORDER
          </p>
        </div>

        <div className="absolute left-[24.3%] w-[27%]">
          <img
            src={stepProgressName === "checkout" ? NextProgress1 : InProgress2}
            alt="progressCartOrder"
            className="w-full "
          />
          <p className="text-[1.5vw] font-[700] text-white absolute top-[37%] left-[35%] z-30">
            PAYMENT
          </p>
        </div>

        <div className="absolute left-[48.7%] w-[27%]">
          <img
            src={
              stepProgressName === "checkout" || stepProgressName === "payment"
                ? NextProgress1
                : InProgress2
            }
            alt="progressCartOrder"
            className="w-full "
          />
          <p className="text-[1.5vw] font-[700] text-white absolute top-[37%] left-[35%] z-30">
            DELIVERY
          </p>
        </div>

        <div className="absolute left-[73%] w-[27%]">
          <img
            src={
              stepProgressName === "checkout" ||
              stepProgressName === "payment" ||
              stepProgressName === "delivery"
                ? NextProgress2
                : InProgress3
            }
            alt="progressCartOrder"
            className="w-full"
          />
          <p className="text-[1.5vw] font-[700] text-white absolute top-[37%] left-[40%] z-30">
            COMPLETE
          </p>
        </div>
      </div>

      {/* ProgressDesktop */}
      <div className=" mobile:hidden desktop:flex w-full  items-center justify-around bg-GRAY700/[0.9] px-[20%] py-6 shadow-[10px_4px_20px_rgba(0,0,0,0.5)]">
        {/* Cart Order */}
        <div>
          {stepProgressName === "checkout" ? (
            <IconInProgress
              imagePath={InProgressCartOrder}
              name="InProgressCartOrder"
              text="Cart&nbsp;Order"
            />
          ) : (
            <IconDoneProgress
              imagePath={DoneCartOrder}
              name="DoneCartOrder"
              text="Cart&nbsp;Order"
            />
          )}
        </div>

        {/* Dotted 1 */}
        <div
          className={`w-[12%] border-b-[6px] ${
            stepProgressName === "checkout" ? "border-GRAY750" : "border-RED002"
          } border-dotted`}
        ></div>

        {/* Payment */}
        <div>
          {stepProgressName === "payment" ? (
            <IconInProgress
              imagePath={InProgressPayment}
              name="InProgressPayment"
              text="Payment"
            />
          ) : stepProgressName === "checkout" ? (
            <IconAwaitingProgress
              imagePath={InProgressPayment}
              name="AwaitingProgressPayment"
              text="Payment"
            />
          ) : (
            <IconDoneProgress
              imagePath={DonePayment}
              name="DonePayment"
              text="Payment"
            />
          )}
        </div>

        {/* Dotted 2 */}
        <div
          className={`w-[12%] border-b-[6px] ${
            stepProgressName === "delivery" || stepProgressName === "complete"
              ? "border-RED002"
              : "border-GRAY750"
          } border-dotted`}
        ></div>

        {/* Delivery */}
        <div>
          {stepProgressName === "delivery" ? (
            <IconInProgress
              imagePath={InProgressDelivery}
              name="InProgressDelivery"
              text="Delivery"
            />
          ) : stepProgressName === "checkout" ||
            stepProgressName === "payment" ? (
            <IconAwaitingProgress
              imagePath={InProgressDelivery}
              name="AwaitingProgressDelivery"
              text="Delivery"
            />
          ) : (
            <IconDoneProgress
              imagePath={DoneDelivery}
              name="DoneDelivery"
              text="Delivery"
            />
          )}
        </div>

        {/* Dotted 3 */}
        <div
          className={`w-[12%] border-b-[6px] ${
            stepProgressName === "complete" ? "border-RED002" : "border-GRAY750"
          } border-dotted`}
        ></div>

        {/* Complete */}
        <div>
          {stepProgressName === "complete" ? (
            <IconDoneProgress
              imagePath={DoneComplete}
              name="DoneComplete"
              text="Complete"
            />
          ) : (
            <IconAwaitingProgress
              imagePath={InProgressComplete}
              name="AwaitingProgressComplete"
              text="Complete"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StepProgress;

const IconInProgress = ({ imagePath, name, text }) => {
  return (
    <div className="flex flex-col justify-center items-center gap-2 ">
      <div className="w-10 h-10 bg-RED002 rounded-full flex justify-center items-center">
        <div className="w-8 h-8 bg-RED002 rounded-full flex justify-center items-center border-[3px] border-GRAY700">
          <img
            src={imagePath}
            alt={name}
            style={{
              width: changePxToRem(18),
              height: changePxToRem(16),
            }}
          />
        </div>
      </div>
      <p className={`text-base font-[700] text-RED002 `}>{text}</p>
    </div>
  );
};

const IconAwaitingProgress = ({ imagePath, name, text }) => {
  return (
    <div className="flex flex-col justify-center items-center gap-2 ">
      <div className="w-10 h-10 bg-GRAY750 rounded-full flex justify-center items-center">
        <img
          src={imagePath}
          alt={name}
          style={{
            width: changePxToRem(20),
            height: changePxToRem(20),
          }}
        />
      </div>
      <p className={`text-base font-[700] text-GRAY750 `}>{text}</p>
    </div>
  );
};

const IconDoneProgress = ({ imagePath, name, text }) => {
  return (
    <div className="flex flex-col justify-center items-center gap-2 ">
      <div className="w-10 h-10 bg-RED002 rounded-full flex justify-center items-center">
        <img
          src={imagePath}
          alt={name}
          style={{
            width: changePxToRem(20),
            height: changePxToRem(20),
          }}
        />
      </div>
      <p className={`text-base font-[700] text-white `}>{text}</p>
    </div>
  );
};
