import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setDisplay } from "../slice/goBackBtnSlice";
import { bg_main, bg_shadow_1, bg_shadow_2 } from "../assets/register";
import { Link } from "react-router-dom";

const SupportPage = () => {
  const lock = useRef(false);
  const goBack = useDispatch();

  useEffect(() => {
    if (lock.current) {
      goBack(setDisplay(false));
    }

    return () => (lock.current = true);
  }, []);

  return (
    <>
     <div className="w-full left-0 p-4 bg-GRAY111 h-screen desktop:hidden mobile:block">
       <h2 className="text-white font-IBMPlex text-[24px] font-bold">COMING SOON</h2>
       <span className="text-white text-[14px] font-IBMPlex">These features are currently planned in development process.</span>
     </div>

    <div className="desktop:w-full desktop:h-screen desktop:relative mobile:hidden desktop:block">
      <div
        className="desktop:w-full desktop:h-screen desktop:absolute"
        style={{
          backgroundImage: `url(${bg_main})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <div
        className="desktop:w-full desktop:h-screen desktop:absolute"
        style={{
          backgroundImage: `url(${bg_shadow_1})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <div
        className="desktop:w-full desktop:h-screen desktop:absolute"
        style={{
          backgroundImage: `url(${bg_shadow_2})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
    </div>


    <div className="desktop:absolute desktop:top-96 desktop:left-2/3 desktop:space-y-4 desktop:block mobile:hidden">
      <h2 className="text-white font-IBMPlex desktop:text-[32px] font-bold">COMING SOON</h2>
      <span className="text-white text-[14px] font-IBMPlex">These features are currently planned in development process.</span>
      <Link to={""} className="desktop:w-[400px] py-2 bg-RED001 flex justify-center items-center rounded  hover:bg-RED001/[0.8] text-white font-bold font-IBMPlex cursor-pointer">
        GO BACK HOMEPAGE
      </Link>
    </div>
    </>
  );
};

export default SupportPage;
