import { useMutation } from "@tanstack/react-query";
import { completeApi } from "../../services";

export const useMutationClientReviewRating = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return completeApi.ClientReviewRating(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationClientGetMyWallet = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return completeApi.ClientMyWallet(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};

export const useMutationClientMailReceipt = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (request) => {
      return completeApi.ClientMailReceipt(request);
    },
    onSuccess: onSuccess,
    onError: onError,
  });
  return mutation;
};
