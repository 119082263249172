import axios from "axios";
import Cookies from "js-cookie";

let ATK =
  typeof window !== "undefined"
    ? Cookies.get("access_token")
    : "";

let RTK =
  typeof window !== "undefined"
    ? Cookies.get("refresh_token")
    : "";

export const requestAnonymous = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: false,
});

export const requestClientAnonymous = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const requestClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${ATK}`,
  },
  // withCredentials: false,
});

export const requestClientChatHistory = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${ATK}`,
    "api-key": "f1pfn0zzfz824uvb0p5a3nm2fq2l4l",
  },
  // withCredentials: false,
});

export const requestClientUploadPic = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  headers: {
    accept: "application/json",
    "Content-Type":
      "multipart/form-data; boundary=<calculated when request is sent>",
    Authorization: `Bearer ${ATK}`,
  },
  // withCredentials: false,
});

export const requestClientSendEmail = (access_token) =>
  axios.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

export const requestClientPayment = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_ENDPOINT,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const requestClientPaymentAPIKey = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_ENDPOINT,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    "api-key": process.env.REACT_APP_APIKEY,
  },
});

export const requestClientPaymentAuth = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_ENDPOINT,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${ATK}`,
  },
});

export const requestClientPaymentAPIkey = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_ENDPOINT,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${ATK}`,
    "api-key": process.env.REACT_APP_APIKEY,
  },
});
