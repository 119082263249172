import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { changePxToRem } from "../../../../../utils/changePixeltoRem";
import iconFee from "../../../../../assets/images/complete/iconQuestion.svg";
import { SubmitButton } from "../../../../Button";

const WithdrawConfirmation2 = ({
  myWalletData,
  withDrawMoneyAmount,
  setStateWallet,
}) => {
  const [bankingNumber, setBankingNumber] = useState();

  const fee = 20;
  const profilePicture = myWalletData?.data?.userProfilePicture;
  const displayName = myWalletData?.data?.userDisplayName;
  const bankingFirstName = myWalletData?.data?.banking?.firstName;
  const bankingLastName = myWalletData?.data?.banking?.lastName;

  useEffect(() => {
    // const bankingNumber = "424-0-35856-1";
    const bankingNumber = myWalletData?.data?.banking?.bankingNumber;
    const NewBankingNumber = bankingNumber
      ? `${bankingNumber.slice(0, 1)}${bankingNumber.slice(
          1,
          2
        )}${bankingNumber.slice(2, 3)}-${bankingNumber.slice(
          4,
          5
        )}-${bankingNumber.slice(6, 7)}${bankingNumber.slice(7, 8)}XXX-X`
      : "";
    setBankingNumber(NewBankingNumber);
  }, [myWalletData]);
  return (
    <div className="flex flex-col gap-4 w-full">
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: changePxToRem(12),
        }}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p className="text-base font-[400] text-white">โอนเข้าบัญชี</p>
          <p className="text-base font-[400] text-white">
            {bankingFirstName} {bankingLastName}
          </p>
        </Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p className="text-base font-[400] text-white">เลขที่บัญชี</p>
          <p className="text-base font-[400] text-white">{bankingNumber}</p>
        </Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="flex items-center gap-2">
            <p className="text-base font-[400] text-white">ค่าธรรมเนียม</p>
            <img
              src={iconFee}
              alt="viewMoreFee"
              className="w-4 h-4 cursor-pointer"
            />
          </div>
          <p className="text-base font-[400] text-white">{fee}</p>
        </Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p className="text-base font-[400] text-white">ยอดรวมที่ได้รับ</p>
          <p className="text-base font-[400] text-white">
            {withDrawMoneyAmount
              ? Number(withDrawMoneyAmount) - Number(fee)
              : 0}{" "}
            THB
          </p>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          gap: changePxToRem(20),
          paddingTop: changePxToRem(6),
          width: "100%",
        }}
      >
        <div className="w-[100%]">
          <SubmitButton
            type="submit"
            text="continue"
            styleBtn="bg-RED001"
            onClick={() => setStateWallet("withdrawConfirmation3")}
          />
        </div>
      </Grid>
    </div>
  );
};

export default WithdrawConfirmation2;
