import React, { useState, useEffect } from "react";
import IconNotification from "../../../../assets/images/delivery/Other/IconNoti.svg";
import IconCreate from "../../../../assets/images/delivery/Other/IconCreate.svg";
import IconContact from "../../../../assets/images/delivery/Other/IconContact.svg";
import bg_defaultprofile from "../../../../assets/images/MyWallet/bg_defaultprofile.jpg";
import iconEllipse from "../../../../assets/images/complete/Ellipse.svg";
import { changePxToRem } from "../../../../utils/changePixeltoRem";
import { useNavigate } from "react-router-dom";

const DetailItemComplete = ({ data, handleOpenDialogSubscribe, disable }) => {
  const isSeller = data?.tokenSeller;
  const navigate = useNavigate();

  return (
    <div>
      {/* Select Gateway Payment Desktop */}
      <div className="mobile:hidden desktop:flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div>
            <div className="  flex flex-col  rounded">
              <div className="py-5 px-8 flex  justify-between items-center rounded-sm  border-b-[1px] border-GRAY750">
                <div className="flex gap-5 items-center">
                  <div className="w-9 h-9 min-w-9 min-h-9">
                    <img
                      src={data?.gameIcon ? data?.gameIcon : iconEllipse}
                      alt="iconGame"
                      className="w-full h-full rounded-full object-cover"
                    />
                  </div>
                  <p className="text-xl font-[700] text-white">
                    {data?.itemName}
                  </p>
                </div>
              </div>

              <div className="pt-5 pb-10 px-9 flex flex-col gap-6">
                <div className="flex flex-col gap-3">
                  <p className="text-[2.3vw] font-[700] text-white text-center">
                    CONGRATULATION
                  </p>
                  <div className="flex gap-1 justify-center">
                    {/* <p className="text-base font-[400] text-white text-center">
                      You already own the product:
                    </p> */}
                  </div>
                </div>

                <div className="flex flex-col justify-center items-center gap-5">
                  <div className="w-[14.35vw] h-[14.35vw] min-w-[14.35vw] min-h-[14.35vw]">
                    <img
                      src={
                        data?.itemPic["media_url1"]
                          ? data?.itemPic["media_url1"]
                          : bg_defaultprofile
                      }
                      alt="pictureItem"
                      className="w-full h-full rounded object-cover"
                    />
                  </div>
                  <div className="flex gap-1">
                    <p className="text-base font-[400] text-white">
                      {isSeller ? "Buyer :" : "Seller :"}
                    </p>
                    <p className="text-base font-[700] text-white">
                      {isSeller ? data?.buyerName : data?.sellerName}
                    </p>
                  </div>
                </div>

                <div className="flex flex-wrap gap-4">
                  {isSeller ? (
                    <div
                      className={`flex items-center justify-center gap-4 p-4 bg-GRAY910 hover:bg-white/[0.1] rounded w-[48%] grow cursor-pointer`}
                      onClick={() => {
                        navigate("/create_order");
                      }}
                    >
                      <img
                        src={IconCreate}
                        alt="Notification"
                        className="w-6 h-6 rounded"
                      />
                      <p className="text-base font-[700] text-white uppercase">
                        CREATE NEW ORDER
                      </p>
                    </div>
                  ) : (
                    <div
                      className={`flex items-center justify-center gap-4 p-4 ${
                        disable
                          ? "bg-transparent"
                          : "bg-GRAY910 hover:bg-white/[0.1]"
                      } rounded w-[48%] grow cursor-pointer`}
                      onClick={disable ? () => {} : handleOpenDialogSubscribe}
                    >
                      <img
                        src={IconNotification}
                        alt="Notification"
                        className="w-5 h-5 rounded"
                      />
                      <p className="text-base font-[700] text-white uppercase">
                        subscribe seller
                      </p>
                    </div>
                  )}

                  <div
                    className="flex items-center justify-center gap-4 p-4 bg-GRAY700 hover:bg-white/[0.1] rounded w-[48%] grow cursor-pointer border-2 border-RED001"
                    onClick={() => {
                      window.zE && window.zE("webWidget", "open");
                    }}
                  >
                    <img
                      src={IconContact}
                      alt="Notification"
                      className="w-5 h-5 rounded"
                    />
                    <p className="text-base font-[700] text-white uppercase">
                      Contact Help Centre
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailItemComplete;
