import { useState, useEffect } from "react";
import {
  bg_account,
  bg_cover,
  bg_profile,
  icon_uploadProfileImage,
  icon_SelectProfile,
  icon_SelectBanner,
  icon_SeclectFrame,
} from "../../../assets/setting/account";
import { set, useForm } from "react-hook-form";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import icon_arrow_down from "../../../assets/setting/icon_arrow_down.svg";
import {
  getUserProfile,
  getFrameProfile,
} from "../../../api/get/setting/account";
import {
  Dialog,
  DialogBody,
  ThemeProvider,
  DialogHeader,
} from "@material-tailwind/react";
import ImageUploading from "react-images-uploading";
import { CloseButton } from "../kyc_component";
import { uploadmultiple } from "../../../api/post/setting/account";
import { default_Profile_cover } from "../../../assets/home";
import { SubmitButton } from "../../../component/Button";
import BaseDialog from "../../../component/common/BaseDialog";
import { useSelector, useDispatch } from "react-redux";
import { getProfileIamge } from "../../../slice/userslice";

const Account = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ defaultValues: {} });
  const [profileImage, setProfileImage] = useState();
  const [date, setDate] = useState();
  const [indexCountry, setIndexCountry] = useState(0);
  const [indexGender, setIndexGender] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [profileData, setProfileData] = useState();
  const [frameProfileData, setFramProfileData] = useState([]);
  const [coverProfile, setCoverProfile] = useState([]);
  const [selectFrameProfile, setSelectFrameProfile] = useState();
  const [selectEdit, setSelectEdit] = useState("profile");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [bio, setBio] = useState();
  const [first_name, setFirst_Name] = useState();
  const [last_name, setLast_name] = useState();
  const [address, setAddress] = useState();
  const [district, setDistrict] = useState();
  const [city, setCity] = useState();
  const [zipcode, setZipcode] = useState();
  const [showError, setShowError] = useState([]);
  const [showMessage, setShowMessage] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  const dispatch = useDispatch()

  const GENDER = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
  ];

  const COUNTRY = [
    { label: "Thailand" },
    { label: "Japan" },
    { label: "USA" },
    { label: "US" },
  ];

  const searchGender = (data) => {
    let num = GENDER.findIndex((e) => e.value === data);
    setIndexGender(num);
  };

  const onChangeProfileImage = (imageList, addUpdateIndex) => {
    if (profileImage?.length > 1) {
      let newArr = profileData;
      newArr?.splice(0, 1, imageList);
      setProfileImage(newArr);
    } else setProfileImage(imageList);
  };

  const onChangeCoveProfileImage = (imageList, addUpdateIndex) => {
    setCoverProfile(imageList);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  useEffect(() => {
    getUserProfile(
      setProfileData,
      setBio,
      setFirst_Name,
      setLast_name,
      setAddress,
      setDistrict,
      setCity,
      setZipcode,
      searchGender,
      setDate
    );
    searchGender();
  }, [createSuccess]);

  useEffect(() => {
    getFrameProfile(setFramProfileData);
  }, []);
  const handleOpen = () => {
    setOpenDialog(!openDialog);
    setProfileImage();
    setCoverProfile();
    setSelectFrameProfile();
  };
  const theme = {
    dialog: {
      styles: {
        sizes: {
          sm: {
            width: "w-[626px]",
            minWidth: "min-w-[626px]",
            maxWidth: "max-w-[626px]",
          },
          xs: {
            width: "w-full",
            minWidth: "min-w-full",
            maxWidth: "max-w-full",
          },
        },
      },
    },
  };

  const handleData = (formValue) => {
    let err = [];

    const payload = {
      bio: !!bio ? bio : err.push("bio"),
      first_name: !!first_name ? first_name : err.push("first_name"),
      last_name: !!last_name ? last_name : err.push("last_name"),
      address: !!address ? address : err.push("address"),
      city: !!city ? city : err.push("city"),
      district: !!district ? district : err.push("district"),
      zipcode: !!zipcode ? zipcode : err.push("zipcode"),
    };
    if (!date) {
      err.push("date");
    }
    if (indexGender < 0) {
      err.push("gender");
    }
    // Buggggg!!
    setShowError([...err]);
    if (err?.length <= 0) {
      uploadmultiple(
        profileData,
        payload,
        profileImage,
        date,
        GENDER[indexGender]?.value,
        selectFrameProfile?.frame_profile_id,
        setCreateSuccess,
        coverProfile
      );
    } else {
      setShowMessage(err[0]);
    }
  };

  return (
    <div className="px-3 flex flex-col items-start bg-[#1E1E1E] mobile:w-full desktop:max-w-full desktop:min-w-0 desktop:w-full mobile:h-full desktop:h-full mobile:gap-8 desktop:gap-[16px]">
      <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[20px] desktop:text-[32px] font-bold">
        ACCOUNT
      </p>
      <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[16px] desktop:text-[24px] font-bold">
        Basic Information
      </p>
      <p className="font-IBMPlex text-[#FFFFFF] text-[16px] font-normal">
        You can choose a NFT from My NFTs as your avatar, and your user name
        will be rainbowized when used.
      </p>
      <div className=" h-[180px] max-w-full min-w-0 w-full relative flex mobile:mb-[64px]">
        <img
          src={
            coverProfile?.length > 0
              ? coverProfile?.[0]?.data_url
              : profileData?.cover_picture
                ? profileData?.cover_picture
                : bg_account
          }
          alt="bg_account"
          className="w-full h-full desktop:object-cover mobile:object-cover mobile:object-left desktop:object-top"
        />
        <div className="w-[150px] h-[150px] absolute top-[calc(50%-75px)] ml-5 flex justify-center items-center bg-contain ">
          <img
            src={
              selectFrameProfile
                ? selectFrameProfile?.frame_profile_url
                : profileData?.frame_profile?.frame_profile_url
                  ? profileData?.frame_profile?.frame_profile_url
                  : default_Profile_cover
            }
            alt="frampicture"
            className="w-[150px] h-[150px] absolute top-[calc(50%-75px)]  flex justify-center items-center object-contain z-10"
          />
          <img
            src={
              profileImage
                ? profileImage?.[0].data_url
                : profileData?.profile_picture
                  ? profileData?.profile_picture
                  : bg_profile
            }
            alt=""
            className="w-[100px] h-[100px] bg-contain  bg-center object-cover overflow-hidden rounded-full z-0"
          />
        </div>
        <div
          className="w-[148px] h-[48px] bg-[#282828]  rounded-[4px] cursor-pointer desktop:absolute desktop:top-[calc(50%-24px)] desktop:right-[30px] text-white font-IBMPlex font-bold desktop:text-[16px] uppercase flex justify-center items-center mobile:absolute mobile:top-[100%] desktop:mt-0 mobile:mt-[16px]"
          onClick={() => {
            handleOpen();
            setSelectEdit("profile");
          }}
        >
          Edit profile
        </div>
        <div
          className="w-[148px] h-[48px] bg-[#282828] rounded-[4px] cursor-pointer   text-white font-IBMPlex font-bold  uppercase flex justify-center items-center mobile:absolute mobile:top-[100%] mobile:right-0 mobile:mt-[16px] desktop:hidden mobile:flex"
          onClick={() => {
            handleOpen();
            setSelectEdit("frame_picture");
          }}
        >
          Edit frame
        </div>
      </div>
      <div className="max-w-full min-w-0 w-full flex flex-col gap-4">
        <div className="flex flex-col">
          <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">
            Display name
          </p>
          <input
            // {...register("display_name")}
            disabled
            type="text"
            className={`desktop:min-w-[18rem] desktop:max-w-[25rem]  desktop:w-full mobile:w-full focus:outline-none h-12 bg-black p-[10px] text-[white] rounded-[4px] ${profileData?.display_name
              ? "placeholder:text-white"
              : "placeholder:text-[#7C7C7C]"
              }`}
            value={profileData?.display_name}
          />
        </div>
        {/* Intro or bio */}
        <div className="flex flex-col">
          <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">
            Intro or bio
          </p>
          <div className="relative ">
            <textarea
              // {...register("bio")}
              type="text"
              className={`desktop:min-w-[18rem] desktop:max-w-[25rem]  desktop:w-full mobile:w-full h-[150px] resize-none focus:outline-none bg-[#282828] p-[10px] text-[white] rounded-[4px]  placeholder:text-[#7C7C7C] ${showError.includes("bio")
                ? "border border-[#EB4335]"
                : "border-none"
                }`}
              placeholder={"Write something..."}
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              maxLength="100"
              onClick={() => setShowMessage("bio")}
            />
            {showError.includes("bio") && showMessage === "bio" && (
              <div className="mobile:hidden desktop:flex absolute desktop:p-2 top-[calc(50%)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
                <div className="flex justify-center items-center relative w-full h-full ">
                  <p className=" text-white font-IBMPlex">
                    Please enter your bio
                  </p>
                  <div className="w-0 h-0 absolute left-[100%] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"></div>
                </div>
              </div>
            )}
          </div>
        </div>

        <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[16px] desktop:text-[24px] font-bold">
          Personal Information
        </p>

        <div className="grid desktop:grid-cols-2 mobile:grid-cols-1 gap-4 max-w-full min-0 w-full">
          {/* fisrt name */}
          <div className="flex flex-col">
            <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">
              First name
            </p>
            <div className="relative">
              <input
                // {...register("first_name")}
                type="text"
                className={`desktop:min-w-[18rem] desktop:max-w-[25rem]  desktop:w-full mobile:w-full focus:outline-none h-12 bg-[#282828] p-[10px] text-[white] rounded-[4px]  placeholder:text-[#7C7C7C] ${showError.includes("first_name")
                  ? "border border-[#EB4335]"
                  : "border-none"
                  }`}
                placeholder={`Write first name`}
                value={first_name}
                onChange={(e) => setFirst_Name(e.target.value)}
                onClick={() =>
                  setShowMessage(
                    showMessage === "first_name" ? "" : "first_name"
                  )
                }
                maxLength="100"
              />
              {showError.includes("first_name") &&
                showMessage === "first_name" && (
                  <div className="mobile:hidden desktop:flex absolute desktop:p-2 top-[calc(50%)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
                    <div className="flex justify-center items-center relative w-full h-full ">
                      <p className=" text-white font-IBMPlex">
                        Please enter your name
                      </p>
                      <div className="w-0 h-0 absolute left-[100%] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"></div>
                    </div>
                  </div>
                )}
            </div>
          </div>

          {/* last name */}
          <div className="flex flex-col">
            <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">
              Last name
            </p>
            <div className=" relative">
              <input
                // {...register("last_name")}
                type="text"
                className={`desktop:min-w-[18rem] desktop:max-w-[25rem]  desktop:w-full mobile:w-full focus:outline-none h-12 bg-[#282828] p-[10px] text-[white] rounded-[4px] placeholder:text-[rgb(124,124,124)] ${showError.includes("last_name")
                  ? "border border-[#EB4335]"
                  : "border-none"
                  }`}
                placeholder={"Write last name"}
                value={last_name}
                onChange={(e) => setLast_name(e.target.value)}
                onClick={() =>
                  setShowMessage(showMessage === "last_name" ? "" : "last_name")
                }
                maxLength="100"
              />
              {showError.includes("last_name") &&
                showMessage === "last_name" && (
                  <div className="mobile:hidden desktop:flex absolute desktop:p-2 top-[calc(50%)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
                    <div className="flex justify-center items-center relative w-full h-full ">
                      <p className=" text-white font-IBMPlex">
                        Please enter your surname
                      </p>
                      <div className="w-0 h-0 absolute left-[100%] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"></div>
                    </div>
                  </div>
                )}
            </div>
          </div>

          {/* date of birth */}
          <div className="flex flex-col desktop:min-w-[18rem] desktop:max-w-[25rem]  desktop:w-full mobile:w-full">
            <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">
              Date of birth
            </p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker"]}
                sx={{ position: "relative" }}
              >
                <DatePicker
                  sx={{
                    width: "100%",
                    border: showError.includes("date")
                      ? "1px solid #EB4335"
                      : "none",
                    borderRadius: "4px",
                    "& .MuiInputBase-root": {
                      color: "white",
                      background: "#282828",
                      border: "none",
                    },
                    "& .MuiIconButton-root": {
                      color: "white",
                    },
                    "& > .Mui-focused fieldset.MuiOutlinedInput-notchedOutline":
                    {
                      border: "none",
                      width: "100%",
                      height: "48px",
                    },
                  }}
                  value={date}
                  onChange={(value) => setDate(value)}
                  format="DD/MM/YYYY"
                  onClick={() =>
                    setShowMessage(showMessage === "date" ? "" : "date")
                  }
                />
                {showError.includes("date") && showMessage === "date" && (
                  <div className="mobile:hidden desktop:flex absolute desktop:p-2 top-[calc(50%)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
                    <div className="flex justify-center items-center relative w-full h-full ">
                      <p className=" text-white font-IBMPlex">
                        Plase enter your date of birth
                      </p>
                      <div className="w-0 h-0 absolute left-[100%] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"></div>
                    </div>
                  </div>
                )}
              </DemoContainer>
            </LocalizationProvider>
          </div>

          {/* Gender */}
          <div className="flex flex-col justify-between">
            <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">
              Gender
            </p>
            <div
              className={`desktop:min-w-[18rem] desktop:max-w-[25rem]  desktop:w-full mobile:w-full h-12 bg-[#282828] p-[10px] text-[white] rounded-[4px] flex justify-between items-center cursor-pointer relative ${showError.includes("gender")
                ? "border border-[#EB4335]"
                : "border-none"
                }`}
              onClick={() => {
                isOpen === "gender" ? setIsOpen("") : setIsOpen("gender");
                setShowMessage(showMessage === "gender" ? "" : "gender");
              }}
            >
              {indexGender >= 0 ? (
                <p className="text-[#FFFFFF]">{GENDER[indexGender].label}</p>
              ) : (
                <p className="text-[#7C7C7C]">Select gender</p>
              )}
              <img
                src={icon_arrow_down}
                alt="icon_arrow_down"
                onClick={() =>
                  isOpen === "gender" ? setIsOpen("") : setIsOpen("gender")
                }
              />
              {isOpen === "gender" && (
                <div className=" absolute top-[105%] left-0 desktop:min-w-[15rem] desktop:max-w-[25rem]  desktop:w-[20.83vw] mobile:w-full  h-[100px] bg-[#282828] z-10">
                  {GENDER?.map(({ label }, index) => (
                    <div
                      className="w-full h-12 flex items-center px-[10px] hover:bg-[#242424] cursor-pointer"
                      key={index}
                      onClick={() => setIndexGender(index)}
                    >
                      <p className="text-[#FFFFFF]">{label}</p>
                    </div>
                  ))}
                </div>
              )}
              {showError.includes("gender") && showMessage === "gender" && (
                <div className="mobile:hidden desktop:flex absolute desktop:p-2 top-[calc(50%)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
                  <div className="flex justify-center items-center relative w-full h-full ">
                    <p className=" text-white font-IBMPlex">
                      Please enter your gender
                    </p>
                    <div className="w-0 h-0 absolute left-[100%] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"></div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Address */}
          <div className="flex flex-col">
            <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">
              Address
            </p>
            <div className=" relative">
              <input
                // {...register("address")}
                type="text"
                className={`desktop:min-w-[18rem] desktop:max-w-[25rem]  desktop:w-full mobile:w-full focus:outline-none h-12 bg-[#282828] p-[10px] text-[white] rounded-[4px] placeholder:text-[#7C7C7C] ${showError.includes("address")
                  ? "border border-[#EB4335]"
                  : "border-none"
                  }`}
                placeholder={"Write address"}
                maxLength="100"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                onClick={() =>
                  setShowMessage(showMessage === "address" ? "" : "address")
                }
              />
              {showError.includes("address") && showMessage === "address" && (
                <div className="mobile:hidden desktop:flex absolute desktop:p-2 top-[calc(50%)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
                  <div className="flex justify-center items-center relative w-full h-full ">
                    <p className=" text-white font-IBMPlex">
                      Please enter your adress
                    </p>
                    <div className="w-0 h-0 absolute left-[100%] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"></div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* District */}
          <div className="flex flex-col">
            <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">
              District
            </p>
            <div className=" relative">
              <input
                // {...register("district")}
                type="text"
                className={`desktop:min-w-[18rem] desktop:max-w-[25rem]  desktop:w-full mobile:w-full focus:outline-none h-12 bg-[#282828] p-[10px] text-[white] rounded-[4px]  placeholder:text-[#7C7C7C] ${showError.includes("district")
                  ? "border border-[#EB4335]"
                  : "border-none"
                  }`}
                placeholder={"Write district"}
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                maxLength="100"
                onClick={() =>
                  setShowMessage(showMessage === "district" ? "" : "district")
                }
              />
              {showError.includes("district") && showMessage === "district" && (
                <div className="mobile:hidden desktop:flex absolute desktop:p-2 top-[calc(50%)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
                  <div className="flex justify-center items-center relative w-full h-full ">
                    <p className=" text-white font-IBMPlex">
                      Please enter your district
                    </p>
                    <div className="w-0 h-0 absolute left-[100%] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"></div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* City */}
          <div className="flex flex-col">
            <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">
              City
            </p>
            <div className=" relative">
              <input
                // {...register("city")}
                type="text"
                className={`desktop:min-w-[18rem] desktop:max-w-[25rem]  desktop:w-full mobile:w-full focus:outline-none h-12 bg-[#282828] p-[10px] text-[white] rounded-[4px] placeholder:text-[#7C7C7C] ${showError.includes("city")
                  ? "border border-[#EB4335]"
                  : "border-none"
                  }`}
                maxLength="100"
                placeholder={"Write city"}
                value={city}
                onChange={(e) => setCity(e.target.value)}
                onClick={() =>
                  setShowMessage(showMessage === "city" ? "" : "city")
                }
              />
              {showError.includes("city") && showMessage === "city" && (
                <div className="mobile:hidden desktop:flex absolute desktop:p-2 top-[calc(50%)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
                  <div className="flex justify-center items-center relative w-full h-full ">
                    <p className=" text-white font-IBMPlex">
                      Please enter your city
                    </p>
                    <div className="w-0 h-0 absolute left-[100%] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"></div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Country */}
          <div className="flex flex-col relative">
            <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">
              Country
            </p>
            <div
              className={`desktop:min-w-[18rem] desktop:max-w-[25rem]  desktop:w-full mobile:w-full h-12 bg-[#282828] p-[10px] text-[white] rounded-[4px] flex justify-between items-center cursor-pointer ${showError.includes("country")
                ? "border border-[#EB4335]"
                : "border-none"
                } `}
              // onClick={() => isOpen === "country" ? setIsOpen("") : setIsOpen("country")}
              onClick={() =>
                setShowMessage(showMessage === "country" ? "" : "country")
              }
            >
              {indexCountry >= 0 ? (
                <p className="text-[#FFFFFF]">{COUNTRY[indexCountry].label}</p>
              ) : (
                <p className="text-[#7C7C7C]">Select country</p>
              )}
              <img src={icon_arrow_down} alt="icon_arrow_down" />
            </div>

            {isOpen === "country" && (
              <div className=" absolute top-[105%] min-w-[15rem] max-w-[25rem]  w-[20.83vw] mobile:w-full  h-[200px] bg-[#282828]">
                {COUNTRY?.map(({ label }, index) => (
                  <div
                    className="w-full h-12 flex items-center px-[10px] hover:bg-[#242424] cursor-pointer"
                    key={index}
                    onClick={() => setIndexCountry(index)}
                  >
                    <p className="text-[#FFFFFF]">{label}</p>
                  </div>
                ))}
              </div>
            )}
            {showError.includes("country") && showMessage === "country" && (
              <div className="mobile:hidden desktop:flex absolute desktop:p-2 top-[calc(50%)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
                <div className="flex justify-center items-center relative w-full h-full ">
                  <p className=" text-white font-IBMPlex">
                    Plase enter your country
                  </p>
                  <div className="w-0 h-0 absolute left-[100%] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"></div>
                </div>
              </div>
            )}
          </div>

          {/* Zipcode */}
          <div className="flex flex-col">
            <p className="font-IBMPlex text-[#FFFFFF] mobile:text-[14px] desktop:text-[16px] font-bold">
              Zip code
            </p>
            <div className=" relative">
              <input
                // {...register("zipcode")}
                type="number"
                className={`desktop:min-w-[18rem] desktop:max-w-[25rem]  desktop:w-full mobile:w-full focus:outline-none h-12 bg-[#282828] p-[10px] text-[white] rounded-[4px] placeholder:text-[#7C7C7C] ${showError.includes("zipcode")
                  ? "border border-[#EB4335]"
                  : "border-none"
                  }`}
                placeholder={"Write zip code"}
                value={zipcode}
                maxLength="5"
                onChange={(e) => setZipcode(e.target.value?.slice(0, 5))}
                onClick={() =>
                  setShowMessage(showMessage === "zipcode" ? "" : "zipcode")
                }
                onKeyDown={(e) =>
                  (e.key === "e" && e.preventDefault()) ||
                  (e.key === "-" && e.preventDefault()) ||
                  (e.key === "+" && e.preventDefault()) ||
                  (e.key === "." && e.preventDefault())
                }
              />
              {showError.includes("zipcode") && showMessage === "zipcode" && (
                <div className="mobile:hidden desktop:flex absolute desktop:p-2 top-[calc(50%)] -translate-y-[50%] right-[calc(100%+12px)] bg-[#AE3C33] min-w-[15rem] max-w-[25rem]  w-[20.83vw]  rounded-[4px]">
                  <div className="flex justify-center items-center relative w-full h-full ">
                    <p className=" text-white font-IBMPlex">
                      Please enter your post code
                    </p>
                    <div className="w-0 h-0 absolute left-[100%] top-[calc(50%-12px)]  border-solid border-transparent border-l-[#AE3C33] border-l-[18px] border-t-[12px] border-b-[12px]"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <button
          className="desktop:w-[200px] desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold bg-[#BB213D] hover:bg-RED001/[0.8] text-white active:bg-[#BB213D]/80 mobile:h-[52px] mobile:w-full mt-6"
          // onClick={handleSubmit((formValue => {
          // 	handleData(formValue)
          // }))}
          onClick={() => setOpenConfirm(true)}
        >
          SAVE
        </button>
      </div>
      <ThemeProvider value={theme}>
        <Dialog
          open={openDialog}
          size={windowSize[0] > 768 ? "sm" : "xs"}
          className="bg-[#1A1A1A] relative rounded overflow-hidden button-0"
        >
          <DialogBody className="desktop:relative  flex flex-col desktop:items-center desktop:h-[624px] mobile:h-[90vh] overflow-auto">
            {/*Close Button  */}
            <CloseButton handleOpen={handleOpen} />
            <div className="w-full h-full desktop:p-10 flex flex-col desktop:items-center">
              <p className=" font-IBMPlex desktop:text-[32px] text-white font-bold desktop:flex mobile:hidden">
                CHANGE YOUR PICTURE
              </p>
              <div className="w-[150px] h-[150px]    mobile:hidden desktop:flex justify-center items-center bg-center bg-contain z-20 relative">
                <div
                  className="w-[150px] h-[150px]  mobile:hidden desktop:flex justify-center items-center bg-center bg-contain z-20 absolute top-0 left-0"
                  style={{
                    backgroundImage: selectFrameProfile
                      ? `url(${selectFrameProfile?.frame_profile_url})`
                      : `url(${profileData?.frame_profile?.frame_profile_url})`,
                  }}
                ></div>
                <div
                  className="w-[100px] h-[100px] bg-contain rounded-full z-0 bg-center absolute"
                  style={{
                    backgroundImage: `url(${!!profileImage
                      ? profileImage?.[0].data_url
                      : profileData?.profile_picture
                        ? profileData?.profile_picture
                        : bg_profile
                      })`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: 'cover'
                  }}
                ></div>
              </div>
              <div className="relative desktop:min-w-[15rem] desktop:max-w-[25rem]  desktop:w-full desktop:h-[238px]  bg-[#111111] rounded-[4px] mobile:h-[426px] ">
                <div className=" absolute desktop:h-[144px] desktop:w-[48px] desktop:right-[100%] top-0  desktop:flex-col rounded-l-[4px] bg-[#272727] desktop:flex mobile:hidden ">
                  <div
                    onClick={() => setSelectEdit("profile")}
                    className={`desktop:w-full desktop:h-12 flex justify-center items-center ${selectEdit === "profile" ? "bg-black" : "bg-transparent"
                      }`}
                  >
                    <img src={icon_SelectProfile} alt="icon_SelectProfile" />
                  </div>
                  <div
                    onClick={() => setSelectEdit("frame_picture")}
                    className={`desktop:w-full desktop:h-12 flex justify-center items-center ${selectEdit === "frame_picture"
                      ? "bg-black"
                      : "bg-transparent"
                      }`}
                  >
                    <img src={icon_SeclectFrame} alt="icon_SeclectFrame" />
                  </div>
                  <div
                    onClick={() => setSelectEdit("banner")}
                    className={`desktop:w-full desktop:h-12 flex justify-center items-center ${selectEdit === "banner" ? "bg-black" : "bg-transparent"
                      }`}
                  >
                    <img src={icon_SelectBanner} alt="icon_SelectBanner" />
                  </div>
                </div>
                {selectEdit === "profile" && (
                  <div className="desktop:w-full desktop:h-full mobile:w-[100vw] desktop:grid desktop:grid-cols-5 mobile:flex mobile:flex-wrap">
                    <ImageUploading
                      value={profileImage}
                      onChange={onChangeProfileImage}
                      dataURLKey="data_url"
                      maxNumber={2}
                    >
                      {({
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        dragProps,
                      }) => (
                        <div
                          className="bg-black desktop:w-[60px] desktop:h-[60px] mobile:w-[33vw] mobile:h-[142px] flex justify-center  items-center cursor-pointer"
                          onClick={onImageUpload}
                        >
                          <img
                            src={icon_uploadProfileImage}
                            alt="icon_uploadProfileImage"

                          />
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                )}
                {selectEdit === "frame_picture" && (
                  <div className="w-full h-full grid desktop:grid-cols-5 mobile:grid-cols-3 overflow-y-scroll">
                    {frameProfileData?.map((frameProfileData, index) => (
                      <div
                        key={frameProfileData?.frame_profile_name}
                        onClick={() => setSelectFrameProfile(frameProfileData)}
                        className={`${selectFrameProfile?.frame_profile_id ===
                          frameProfileData?.frame_profile_id
                          ? "bg-[#414141]"
                          : ""
                          }`}
                      >
                        <img
                          src={frameProfileData?.frame_profile_url}
                          alt={frameProfileData?.frame_profile_id}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {selectEdit === "banner" && (
                  <div className="desktop:w-full h-full mobile:w-[100vw] flex flex-col">
                    <ImageUploading
                      value={coverProfile}
                      onChange={onChangeCoveProfileImage}
                      dataURLKey="data_url"
                      maxNumber={1}
                    >
                      {({
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        dragProps,
                      }) => (
                        <div
                          className="bg-black desktop:w-full desktop:h-[60px] mobile:w-full mobile:h-[142px] flex justify-center items-center cursor-pointer"
                          onClick={onImageUpload}
                        >
                          <img
                            src={icon_uploadProfileImage}
                            alt="icon_uploadProfileImage"
                          />
                        </div>
                      )}
                    </ImageUploading>
                    <div className="w-full h-[calc(100%-60px)]">
                      {coverProfile?.length > 0 && (
                        <img
                          src={coverProfile?.[0]?.data_url}
                          alt="coverPic"
                          className="w-full h-full object-cover mobile:object-left desktop:object-top"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>

              <button
                className="desktop:min-w-[15rem] desktop:max-w-[25rem]  desktop:w-[20.83vw] desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold bg-[#BB213D] text-white active:bg-[#BB213D]/80  mobile:h-[52px] mobile:w-full hover:bg-RED001/[0.8] border-none mt-3"
                onClick={() => {
                  setOpenDialog(!openDialog);
                }}
              >
                {windowSize[0] > 786 ? "submit" : "apply"}
              </button>
            </div>
          </DialogBody>
        </Dialog>
      </ThemeProvider>
      <BaseDialog open={openConfirm} stylesDialog="desktop:min-w-[45%]">
        <div className="w-[100%] flex flex-col desktop:gap-6 mobile:gap-3">
          <DialogHeader className="flex justify-center py-0 font-[700] desktop:text-[2rem] desktop:leading-9 mobile:text-base text-white text-center">
            CONFIRM PROFILE UPDATE
          </DialogHeader>
          <DialogBody className="flex flex-col  w-[100%]  desktop:gap-7 mobile:gap-5 p-0">
            <div className="flex justify-center">
              <p className="font-[400] desktop:text-base mobile:text-sm text-white text-center">
                Do you really want to keep these changes?
              </p>
            </div>
            <div className="flex justify-center desktop:gap-5">
              <div className="desktop:w-[60%] mobile:w-[100%] ">
                <div className="flex desktop:mt-[30px] mobile:mt-4 desktop:max-w-[420px] desktop:min-w-0 desktop:w-full gap-5 justify-between ">
                  <button
                    className="desktop:w-full desktop:min-w-0 desktop:max-w-[200px] desktop:flex  desktop:justify-center desktop:items-center desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold desktop:border-[3px] desktop:border-[#BB213D] hover:bg-GRAY666/[0.2] desktop:bg-[#1A1A1A] desktop:text-white active:bg-[#242424] mobile:hidden"
                    onClick={() => setOpenConfirm(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="desktop:w-full desktop:min-w-0 desktop:max-w-[200px] desktop:h-[56px] rounded-[4px] uppercase font-IBMPlex text-[16px] font-bold bg-[#BB213D] text-white hover:bg-RED001/[0.8] active:bg-[#BB213D]/80 mobile:h-[52px] mobile:w-full"
                    onClick={handleSubmit((formValue) => {
                      handleData(formValue);
                      setOpenConfirm(false);
                      dispatch(
                        getProfileIamge({
                          profile_image: profileImage?.[0].data_url
                        })
                      )
                    })}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </DialogBody>
        </div>
      </BaseDialog>
    </div>
  );
};

export default Account;
