import { requestClientPaymentAPIkey, requestClient } from "./client";

export const QRGoogleAuthSecurity = async (data) => {
  const response = await requestClient.post(
    process.env.REACT_APP_SECURITY_QRGOOGLEAUTH_ENDPOINT
  );
  return response;
};

export const verifyGoogleAuthSecurity = async (data) => {
  const response = await requestClient.post(
    process.env.REACT_APP_SECURITY_VERIFYGOOGLEAUTH_ENDPOINT,
    data
  );
  return response;
};

export const walletUserWithdraw = async (data) => {
  const response = await requestClientPaymentAPIkey.post(
    process.env.REACT_APP_WALLET_WITHDRAW_ENDPOINT,
    data
  );
  return response;
};

export const eKycStatus = async (data) => {
  const response = await requestClient.get(
    process.env.REACT_APP_EKYC_STATUS_ENDPOINT
  );
  return response;
};
