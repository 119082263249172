import React, { useState } from "react";
import IconSelectCode from "../../../../assets/images/Icon/IconSelectCode.svg";
import IconClose from "../../../../assets/images/Icon/IconClose.svg";
import { changePxToRem } from "../../../../utils/changePixeltoRem";
import { DialogHeader, DialogBody } from "@material-tailwind/react";
import { SubmitButton } from "../../../Button";
import BaseDialog from "../../../common/BaseDialog";

const SelectEnterCode = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <div>
      <div className="flex gap-3 items-center cursor-pointer mb-[0.8rem] mobile:mb-0">
        <img
          src={IconSelectCode}
          alt="IconSelectCode"
          style={{
            width: changePxToRem(20),
            height: changePxToRem(14),
          }}
        />
        <p
          className="text-base font-[500] text-white underline "
          onClick={handleOpen}
        >
          Add Promo Code
        </p>
      </div>

      {/* Dialog Select Enter Code */}
      <BaseDialog
        open={open}
        handler={handleOpen}
        stylesDialog="desktop:min-w-[45%] lg:min-w-[35%]"
      >
        <div className="w-[100%]">
          <DialogHeader className="flex justify-center py-0 font-[700] text-2xl text-white text-center">
            ADD PROMO CODE
          </DialogHeader>
          <DialogBody className="flex flex-wrap items-center w-[100%] gap-2  justify-between p-0 pt-5">
            <div className="desktop:w-[68%] desktop:min-w-[68%] mobile:w-[67.5%] mobile:min-w-[67.5%] grow">
              <input
                placeholder="Please select or enter code"
                color="red"
                className="p-[0.6875rem] w-[100%] rounded bg-GRAY910  text-white  placeholder-GRAY780   focus-visible:border-red-700 focus:ring-red-900"
              />
            </div>
            <div className="w-[30%] grow">
              <SubmitButton
                text="Confirm"
                styleBtn="desktop:h-[3rem] mobile:h-[3rem]"
              />
            </div>
          </DialogBody>
        </div>
        <img
          src={IconClose}
          alt="closeIcon"
          className="w-4 h-4 absolute top-6 right-6 cursor-pointer"
          onClick={handleOpen}
        />
      </BaseDialog>
    </div>
  );
};

export default SelectEnterCode;
