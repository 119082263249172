import Cookies from "js-cookie"
import axios from "axios"


export const check_2fa_status = async (setStatus_2fa) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_SECURITY_CHECK_STATUS_GOOGLEAUTH_ENDPOINT, {}, {
        headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`,
        }
    })
        .then((res) => {
            setStatus_2fa(res?.data?.["2fa_status"])
        })
        .catch((err) => {
            console.error(err);
        })
}

export const activate_2fa = async (inputCode, setStatus_2fa, setDialogOpen) => {
    await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_SECURITY_ACTIVEGOOGLEAUTH_ENDPOINT,
        {
            token: inputCode
        }, {
        headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`,
        }
    })
        .then((res) => {
            console.log(res);
            setDialogOpen(false)
            setStatus_2fa(true)
        })
        .catch((err) => {
            console.error(err);
        })
}