import { useState } from 'react'
import { Icon_search } from '../assets/icons'

export default function Searchbar_marketplace({ handleChange, keyEnterSearchbar }) {
    return (
        <div className='desktop:w-[19.31rem] mobile:max-w-full mobile:min-w-0 mobile:w-full h-12 flex items-center px-3 rounded bg-GRAY910'>
            <Icon_search className='opacity-50 hover:cursor-pointer' />
            <input
                type='text'
                placeholder='Search Order Name'
                onChange={handleChange}
                className='flex-grow ml-4 bg-transparent focus-visible:outline-none 
                placeholder:text-GRAY920 text-white'
                onKeyDown={(e) => keyEnterSearchbar(e)}
            />
        </div>
    )
}